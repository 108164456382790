import React, { Component, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

//import { BackTop } from 'antd';
//import { FaChevronCircleUp } from 'react-icons/fa';

//import components//
import { Scrollbars } from 'react-custom-scrollbars';
//import CustomScrollbars from './components/CustomScrollbars';
import TopHeader from './components/header/Header';
import Login from './features/auth/Login';
import Register from './features/auth/Register';
import Forgot from './features/auth/Forgot';
//import Upgrade from './features/auth/Upgrade';

import Home from './components/Home';
import Footer from './components/Footer';

//import tools components//
import MergePDF from './features/tools/merge_pdf/MergePDF';
import SplitPDF from './features/tools/split_pdf/SplitPDF';
import PDFToWord from './features/tools/pdf_to_word/PDFToWord';
import PDFToPPT from './features/tools/pdf_to_ppt/PDFToPPT';
import PDFToExcel from './features/tools/pdf_to_excel/PDFToExcel';
import RotatePDF from './features/tools/rotate_pdf/RotatePDF';//robber
import WordToPDF from './features/tools/word_to_pdf/WordToPDF';

import PPTToPDF from './features/tools/ppt_to_pdf/PPTToPDF';
import ExcelToPDF from './features/tools/excel_to_pdf/ExcelToPDF';
import CompressPDF from './features/tools/compress_pdf/CompressPDF';
import ProtectPDF from './features/tools/protect_pdf/ProtectPDF';
import UnlockPDF from './features/tools/unlock_pdf/UnlockPDF';
import PDFToJPG from './features/tools/pdf_to_jpg/PDFToJPG';


import Html2PDF from './features/tools/html_to_pdf/Html2PDF';//robber
import Jpg2Pdf from './features/tools/jpg_to_pdf/Jpg2Pdf';//robber
import RepairPDF from './features/tools/repair_pdf/RepairPDF';//robber
import PageNumbers from './features/tools/page_numbers/PageNumbers';//robber
import Watermark from './features/tools/watermark/Watermark';//robber
import SignPDF from './features/tools/sign_pdf/SignPDF';//robber


import OrganizePDF from './features/tools/organize_pdf/OrganizePDF';
import EditPDF from './features/tools/edit_pdf/EditPDF';
import PDFToPDFA from './features/tools/pdf_to_pdfA/PDFToPDFA';

// import footer components //
import Cookies from './components/popover/Cookies';
import Features from './components/footers/Features';
import Tools from './components/footers/Tools';
import Faq from './components/footers/Faq';
import Privacy from './components/footers/Privacy';

// i18n //
import { useTranslation } from 'react-i18next';
import { getCurrentLang } from './components/Languages';

import { GoogleOAuthProvider } from '@react-oauth/google';


//import css//
import './App.css';
import 'antd/dist/antd.min.css';
import './assets/css/auth.css';
import './assets/css/rcss.css';
import './assets/css/pdfall.css';


//////////all routes///////
const AppRoutes = ({ setChangedLang }) => {

    return (
      <Routes>
          <Route path="/" element={<Home setChangedLang={setChangedLang}/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Register />} />
		  <Route path="/forgot" element={<Forgot />} />
          
          <Route path='/merge-pdf' element = { <MergePDF/> }/>
          <Route path='/split-pdf' element = { <SplitPDF/> }/>
          <Route path='/pdf-to-word' element = { <PDFToWord/> }/>          
          <Route path='/pdf-to-ppt' element = { <PDFToPPT/> }/>
          <Route path='/pdf-to-xls' element = { <PDFToExcel/> }/>
          <Route path='/rotate-pdf' element = { <RotatePDF/> }/>
          <Route path='/word-to-pdf' element = { <WordToPDF/> }/>

          <Route path='/ppt-to-pdf' element = { <PPTToPDF/> }/>
          <Route path='/xls-to-pdf' element = { <ExcelToPDF/> }/>
          <Route path='/compress-pdf' element = { <CompressPDF/> }/>
          <Route path='/protect-pdf' element = { <ProtectPDF/> }/>
          <Route path='/unlock-pdf' element = { <UnlockPDF/> }/>
          <Route path='/pdf-to-jpg' element = { <PDFToJPG/> }/>

          <Route path='/organize-pdf' element = { <OrganizePDF/> }/>
          <Route path='/edit-pdf' element = { <EditPDF/> }/>

          <Route path='/html-to-pdf' element = { <Html2PDF/> }/>
          <Route path='/jpg-to-pdf' element = { <Jpg2Pdf/> }/>
          <Route path='/repair-pdf' element = { <RepairPDF/> }/>
          <Route path='/page-numbers' element = { <PageNumbers/> }/>

          <Route path='/add-watermark' element = { <Watermark/> }/>

          <Route path='/pdf-to-pdfA' element = { <PDFToPDFA/> }/>
          <Route path='/sign-pdf' element = { <SignPDF/> }/>

          {/* =============== FOOTER COMPONENTS ================== */}
          <Route path='/features' element = { (<> <Features/> <Footer /> </>)}/>
          <Route path='/tools' element = { (<> <Tools/> <Footer /> </>)}/>
          <Route path='/faq' element = { (<> <Faq/> <Footer /> </>)}/>
          <Route path='/privacy-policy' element = { (<> <Privacy/> <Footer /> </>)}/>
      </Routes>
    );
}


//////////////main app//////////////
const App = () => {
    const {i18n} = useTranslation();

    useEffect(() => {
      //component mount//
      i18n.changeLanguage(getCurrentLang());

      return () => {
        //component will unmount
      };
    }, []);

    const setChangedLang = (lang) => {
      i18n.changeLanguage(lang);
    }

    return (
      <BrowserRouter>
        <GoogleOAuthProvider clientId="54314532311-f9inv6e921brabvroku98nukjb8v1m3f.apps.googleusercontent.com">
          <div className="App">
              <TopHeader/>
              <Scrollbars style={{ width: '100%', height: '100%' }}>
                {/*<CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>*/}
                <AppRoutes setChangedLang={setChangedLang}/>
                {/*</CustomScrollbars>*/}              
              </Scrollbars>
              <Cookies/>
              {/*<BackTop>
                  <FaChevronCircleUp style={{width:34, height:34, padding:3, color:'#242323'}} />
              </BackTop>*/}
          </div>
        </GoogleOAuthProvider>
      </BrowserRouter>
    );
}

export default App;



