import React, { useState } from 'react';
import SelectHtml from './SelectHtml';
import WorkSpaceHtml2 from './WorkSpaceHtml2';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { useSelector, useDispatch } from 'react-redux';
import { toolsListInfo } from '../../../utils/toolsInfo';
import { useTranslation } from 'react-i18next';
import { setUrl, setTool } from './html2Slice';

var links = [];
const Html2PDF = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const url = useSelector((state) => state.html2Option.url);
    const screen_size = useSelector((state) => state.html2Option.screen_size);
    const page_size = useSelector((state) => state.html2Option.page_size);
    const one_page = useSelector((state) => state.html2Option.one_page);
    const orientation = useSelector((state) => state.html2Option.orientation);
    const page_margin = useSelector((state) => state.html2Option.page_margin);
    const block_ads = useSelector((state) => state.html2Option.block_ads);
    const remove_popup = useSelector((state) => state.html2Option.remove_popup);

    const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);
    const id = 15;

    const goProcFunction = (files) => {     
        dispatch(setUrl(files));   
        setFile(files);
        setProc(1);
    }

    const goAction = () => {
        //rotate action//
        setProc(2);        
    }

    
    const goDownload = (downlinks) => {
        //rotate download action//
        links = downlinks;
        setProc(3);
    }
    /*****************rendering*****************/
    
    
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectHtml id={id} proc={goProcFunction}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpaceHtml2 files={file} goAction={goAction}/>
            </div>
        );
    } else if (proc === 2) {//action space
        //option payload//
        var _files = [];
        
        //uploading//
        /**
         * tool = HTML2PDF
         * option = {
         *          html = ''
         *          screensize = 0 (0 ~ 3)
         *          pagesize = 0 ( 0 ~ 3)
         *          onepage = 0 ( 0: nocheck , 1: check)
         *          pagetype = 0 (0 : portrait, 1, landscape)
         *          margin = 0 (0, 1, 2)
         *          ads = 0  ( 0: nocheck , 1: check)
         *          popup = 0  ( 0: nocheck , 1: check)
         * }    
         */
         var option = {
            url: url,
            //screensize: screen_size,
            pagesize: page_size,
            onepage: one_page,
            pagetype: orientation,
            margin:page_margin,
            ads:block_ads,
            popup:remove_popup,
        }
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api} 
                    goDownload={ goDownload } 
                    text={t('tools.html2pdf.RotatingPDF')}
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default Html2PDF;