import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Html2OptionState {
  tool_header: string,
  uploadpath: Array,
  url:String,
  upload_status: Number,
  screen_size: Number,
  page_size: Number,
  one_page: Number,
  orientation: Number,
  page_margin: Number,
  block_ads: Number,
  remove_popup:Number,
}

const initialState: Html2OptionState = {
  tool_header: "html2",
  url:"",
  upload_status:0,
  screen_size: 0,
  page_size: 0,
  one_page: 0,
  orientation: 0,
  page_margin: 0,
  block_ads: 0,
  remove_popup: 0,
}

export const html2Slice = createSlice({
  name: 'html2Option',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setUrl: (state, action:PayloadAction<string>) =>{
      state.url = action.payload;
      state.upload_status = 0;
      //console.log("slice: ", action.payload);
    },
    setScreenSize: (state, action:PayloadAction<Number>) =>{
      state.screen_size = action.payload;
    },
    setPageSize: (state, action:PayloadAction<Number>) =>{
      state.page_size = action.payload;
    },
    setOnePage: (state, action:PayloadAction<Number>) =>{
      state.one_page = action.payload;
    },
    setOrientation: (state, action:PayloadAction<Number>) =>{
      state.orientation = action.payload;
    },
    setPageMargin: (state, action:PayloadAction<Number>) =>{
      state.page_margin = action.payload;
    },
    setBlockAds: (state, action:PayloadAction<Number>) =>{
      state.block_ads = action.payload;
    },
    setRemovePopup: (state, action:PayloadAction<Number>) =>{
      state.remove_popup = action.payload;
    },
    setUploadStatus: (state, action) => {
      state.upload_status = action.payload;
      //console.log("status: ", action.payload);
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setUrl, 
  setScreenSize, 
  setPageSize, 
  setOnePage,
  setOrientation,
  setPageMargin,
  setBlockAds,
  setRemovePopup,
  setUploadStatus
} = html2Slice.actions

export default html2Slice.reducer