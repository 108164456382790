import React from 'react';
import { useTranslation } from 'react-i18next'

const ToolItem = (propsparam) => {
  const {t} = useTranslation();
  const {tool_info, idx} = propsparam;
  
  return (
    <div className="tools__item">
      <a href={tool_info.link} title={tool_info.title}>
          <h3>{tool_info.title}</h3>
          <div style={{width:'100%', height:1, borderBottom:'1px solid #626870'}}></div>
          <div className="tools__item__content">
            <p>{t(`home.tools.${idx}`)}</p>
          </div>
          <div className="tools__item__icon">
            <img src = {tool_info.svg} alt=""></img>
          </div>
      </a>
    </div>
  );
}

export default ToolItem;