import React, { useState } from 'react';

import {  Button, Layout, Input, notification, Row, Col } from 'antd';
import pdfsvg from '../../../assets/svg/pdfmade.svg';

import pdf_association_logo_svg from '../../../assets/svg/pdf-association-logo.svg';
import iso_logo_svg from '../../../assets/svg/iso-logo.svg';
import secure_payment_logo_svg from '../../../assets/svg/secure-payment-logo.svg';
import ssl_encrypted_logo_svg from '../../../assets/svg/ssl-encrypted-logo.svg';
import google_svg from '../../../assets/svg/auth_google.svg';
import facebook_svg from '../../../assets/svg/auth_facebook.svg';
import { axiosInstance } from '../../../utils/axiosInstance';
import { useTranslation } from 'react-i18next';
import {getCookie, setCookie} from '../../../utils/cookie';
//import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { FaFacebook } from "react-icons/fa";

import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

const googleClientId = '54314532311-f9inv6e921brabvroku98nukjb8v1m3f.apps.googleusercontent.com';
const Auth_form = (param) => {
	const {t} = useTranslation();
	const openNotification = ( title, description ) => {
	  notification.error({
	    message: title,
	    description: description,
	    placement: 'topRight',
	  });
	};
	
	const responseFacebook = (response) => {
	  console.log('Facebook Login Response : ', response);
	}
	
	const successGoogle = (response) => {
	  //console.log('Success Google Logn : ', response);
	  const profile = jwt_decode(response.credential);
	  //console.log(profile);
	  setCookie('user', profile.name);
	  setCookie('token', response.credential);
	  window.location.href = '/';
	}
	const failureGoogle = (response) => {
	  console.log('Failed Google Login : ', response);
	}
	
	var brandelement = (
		<div className="brand">
			<div className="brand_logo">
				<a href="/" title="PDF Made">
					<img src={pdfsvg} alt="PDF Made" style={{width:'200px', height:'100px'}}/>
				</a>
			</div>
		</div>
	);

	// auth element //
	var authelement = (
		<>
		{/*<div className="auth-clients">*/}		
		<Row justify="center" align="end" style={{marginBottom: 16, paddingRight:20}}>
			<div style={{marginRight:6}}>
				<FacebookLogin
				    appId="371163355108365"
				    autoLoad={false}
				    fields="name,email,picture"
				    callback={responseFacebook} 
					icon={(<Row justify="center" align="middle"><FaFacebook style={{fontSize: 26}}/></Row>)}
					cssClass="facebook auth-link"					
				    textButton=""
				/>
			</div>

			{/*<GoogleLogin
					className="google auth-link"
				    clientId={googleClientId}
				    onSuccess={successGoogle}
				    onFailure={failureGoogle}
					icon={false}
					cookiePolicy={"single_host_origin"}
					isSignedIn={true}
			>
			    <img src={google_svg} alt="Google icon" style={{marginLeft:'-10px', marginRight:'10px',width:'20px', height:'20px'}}/>
			    <span style={{fontSize:'15px'}}> {t('auth.LoginGoogle')}</span>
			</GoogleLogin>*/}			
			
			<div>
				<GoogleLogin
				  onSuccess={successGoogle}
				  onError={failureGoogle}
				  theme="outline"
				  size="large"
				  text="signin_with"
				  locale="en"
				  type="icon"
				  theme="filled_blue"
				/>
			</div>
		</Row>
		{/*</div>*/}
		</>
		
	);
	const onSend = (api, payload) => {
		axiosInstance.post(`/auth/${api}`,  payload)
        .then((res) => {
			console.log('return success', res.data);
            if(res.data.msg === "Success"){
				if (api === 'login'){
					//console.log('login success');
					setCookie('token', res.data.access_token);
					setCookie('user', res.data.user);
					window.location.href = '/';
				}
				else if (api === 'register')
				{
					//console.log('register success');
					setCookie('token', res.data.access_token);
					setCookie('user', res.data.user);
					window.location.href = '/';
				}
				else if (api === 'forgot')
				{
					//console.log('forgot success');
					setHashValue(res.data.verify_hash);
				}
				else if (api === 'verify') 
				{
					//console.log('verify success');
					setCookie('token', res.data.access_token);
					setCookie('user', res.data.user);
					window.location.href = '/';
				}
            } else {
                console.log('return failed');
				if (api === 'login') {
					if (res.data.msg === "No Exist"){
						openNotification("Login", t('auth.LoginNoExist'));
					}
				}
				else if (api === 'register') {
					if (res.data.msg === "Already Exist") {
						openNotification("Register", t('auth.AlreadyExist'));
					}
				}
				else if (api === 'forgot') {
					if (res.data.msg === "No Exist"){
						openNotification("Forgot password", t('auth.NoExist'));
					}
				}
				else if (api === 'verify') {
					if (res.data.msg === "Failed"){
						openNotification("Verify", t('auth.VerifyFailed'));
					}
				}
            }
            
        })
        .catch((error) => {
			if (error.code === 'ERR_NETWORK')
				openNotification("ERROR", t('auth.ERR_NETWORK'));
            console.log(error);
        });
	}
	const onClickLogin = () => {
		if (loginEmail === ''){setLoginEmailStatus('error'); return;} 
		if (loginPwd === '') {setLoginPwdStatus('error'); return;}

		var option = {
            email: loginEmail,
            pwd: loginPwd,
        }
		onSend('login', option);
    }
	const onClickRegister = () => {
		if (signName === '') {setSignNameStatus('error'); return;}
		if (signEmail === ''){setSignEmailStatus('error'); return;}
		if (signPwd === '') {setSignPwdStatus('error'); return;}
		if (signConfirmPwd === '') {setSignConfirmPwdStatus('error');return;}
		
		if (signPwd !== signConfirmPwd) {setSignPwdStatus('error');setSignConfirmPwdStatus('error');return;}

		var option = {
			name: signName,
			email: signEmail,
			pwd: signPwd
		}
		onSend('register', option);
	}
	const onClickSend = () => {
		if (hashvalue === ''){
			if (forgotEmail === '') {setForgotEmailStatus('error');return;}
			var option = {email: forgotEmail};
			onSend('forgot', option);
		}
		else {
			if (verifyCode === '') {setVerifyCodeStatus('error');return;}
			var option = {email: forgotEmail, verify_hash:hashvalue, verify_code: verifyCode};
			onSend('verify', option);
		}
	}

	const [loginEmail, setLoginEmail] = useState('');
	const [loginPwd, setLoginPwd] = useState('');
	const [signName, setSignName] = useState('');
	const [signEmail, setSignEmail] = useState('');
	const [signPwd, setSignPwd] = useState('');
	const [signConfirmPwd, setSignConfirmPwd] = useState('');
	const [forgotEmail, setForgotEmail] = useState('');
    const [hashvalue, setHashValue] = useState('');
	const [verifyCode, setVerifyCode] = useState('');

	const [loginEmailStatus,setLoginEmailStatus] = useState('');
	const [loginPwdStatus,setLoginPwdStatus] = useState('');
	const [signNameStatus,setSignNameStatus] = useState('');
	const [signEmailStatus,setSignEmailStatus] = useState('');
	const [signPwdStatus,setSignPwdStatus] = useState('');
	const [signConfirmPwdStatus, setSignConfirmPwdStatus] = useState('');
	const [forgotEmailStatus, setForgotEmailStatus] = useState('');
	const [verifyCodeStatus, setVerifyCodeStatus] = useState('');
	
	const onLoginEmail = (e) => 	{setLoginEmail(e.target.value); 	if(e.target.value !== '') setLoginEmailStatus('');		}
	const onLoginPwd = (e) => 		{setLoginPwd(e.target.value);		if(e.target.value !== '') setLoginPwdStatus('');		}
	const onSignName = (e) => 		{setSignName(e.target.value);		if(e.target.value !== '') setSignNameStatus('');		}
	const onSignEmail = (e) => 		{setSignEmail(e.target.value);		if(e.target.value !== '') setSignEmailStatus('');		}
	const onSignPwd = (e) => 		{setSignPwd(e.target.value);		if(e.target.value !== '') setSignPwdStatus('');			}
	const onSignConfirmPwd = (e) => {setSignConfirmPwd(e.target.value);	if(e.target.value !== '') setSignConfirmPwdStatus('');	}
	const onForgotEmail = (e) => 	{setForgotEmail(e.target.value);	if(e.target.value !== '') setForgotEmailStatus('');		}
	const onVerifyCode = (e) => 	{setVerifyCode(e.target.value);		if(e.target.value !== '') setVerifyCodeStatus('');		}
	
	if(param.type === 'login')
	{
		return (
			<div className="auth_box">
				{brandelement}
				<h2 className="form_title">{t('auth.Logintoyouraccount')}</h2>
				<div className="social-auth">
					{authelement}
					<div className="FormData">
						<Input className="input" placeholder={t('auth.Enteryouremail')} onChange={onLoginEmail} status={loginEmailStatus}/>
						<Input className="input" type='password' placeholder={t('auth.EnterPassword')} onChange={onLoginPwd} status={loginPwdStatus}/>
						<a className="CreateAccount" href="/forgot">{t('auth.Forgotyourpassword')}</a>
						<br/>
						<br/>

						<Button className="auth__btns" onClick={onClickLogin}>
							{t('auth.Login')}
						</Button>

						<br/>
						<br/>
						<h4>	
							{t('auth.Donthave')}

							<a className="CreateAccount" href="/signup">
								{t('auth.CreateanAccount')}
							</a>
						</h4>
					</div>
				</div>
			</div>
		);
	}
	else if(param.type === "register"){
		return (
		<div className="auth_box">
			{brandelement}
			<h2 className="form_title">{t('auth.Createnewaccount')}</h2>
			<div className="social-auth">
				{authelement}
				<div className="FormData">
					<Input className="input" name="signupName" placeholder={t('auth.EnterName')} onChange={onSignName} status={signNameStatus}/>
					<Input className="input" name="signupEmail" placeholder={t('auth.Enteryouremail')} onChange={onSignEmail} status={signEmailStatus}/>
					<Input className="input" type='password' placeholder={t('auth.EnterPassword')} onChange={onSignPwd} status={signPwdStatus}/>
					<Input className="input" type='password' placeholder={t('auth.ConfirmPassword')} onChange={onSignConfirmPwd} status={signConfirmPwdStatus}/>
					<br/>
					<Button className="auth__btns" onClick={onClickRegister} > {t('auth.Signup')} </Button>
					<br/>
					<br/>

					<h4>
						{t('auth.Alreadymember')}
						<a className="CreateAccount" href="/login">
							{t('auth.Login')}
						</a>
					</h4>			    	
				</div>
				<div style={{color:'blue'}}>{t('auth.Bycreating')}</div>
			</div>
		</div>
		);
	}
  	else{
		var element = '';
		if (hashvalue === '')
		{
			element = (
			<Input className="input" placeholder={t('auth.Enteryouremail')} onChange={onForgotEmail} status={forgotEmailStatus} defaultValue=''/>
			);
		}
		else
		{
			element = (
			<Input className="input" placeholder={t('auth.VerifyCode')} onChange={onVerifyCode} status={verifyCodeStatus} value={verifyCode}/>
			);
		}
		const onClickBack = () => {
			window.location.href = '/login';
		}
  		return (
		<div className="auth_box">
			{brandelement}
			<h2 className="form_title">{t('auth.Resetyourpassword')}</h2>
			<div className="social-auth">
				{authelement}
				<div className="FormData">
					{element}
					<br/>
					<Button className="auth__btns" onClick={onClickSend} >{t('auth.Send')}  </Button>
					<br/>
					<br/>
					<Button className="auth__btns" onClick={onClickBack} >{t('auth.Back')}  </Button>			    	
				</div>
			</div>
		</div>
		);
  	}
}

export default Auth_form;