import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import {  Button, Layout, Select, Slider, InputNumber, Row, Col, Radio, Checkbox, Space, Popover, Input } from 'antd';
import { AiOutlineBold, AiOutlineItalic, AiOutlineUnderline, AiOutlineFontColors, AiOutlineFontSize } from 'react-icons/ai';
import { Page } from 'react-pdf/dist/esm/entry.webpack'
import { SendOutlined} from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';

import {setPageModeOption, setFirstCoverOption, setPositionOption, setMarginOption, setFirstNumberOption, setSingleStartOption, setSingleEndOption, setMultipleStartOption, setMultipleEndOption,
        setNumberTypeOption, setCustomTextOption, setFontNameOption, setFontSizeOption, setBoldOption, setItalicOption, setUnderlineOption, setFontColorOption 
    } from './pageNumbersSlice';
import { useTranslation } from 'react-i18next';
const { Header, Footer, Content } = Layout;
const {Option} = Select;
const WorkSidePageNumbers = ({onAction}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.pageNumbersOption.filelist);
    const maxPage = useSelector((state) => state.pageNumbersOption.maxPage);
    const firstCover = useSelector((state) => state.pageNumbersOption.firstCover);
    const position = useSelector((state) => state.pageNumbersOption.position);
    const margin = useSelector((state) => state.pageNumbersOption.margin);
    const singlestart = useSelector((state) => state.pageNumbersOption.singlestart);
    const singleend = useSelector((state) => state.pageNumbersOption.singleend);

    const dispatch = useDispatch();
    
    //console.log("side single end:", singleend);
    const [selmode, setSelMode] = useState(-1);

    var cnt = filelist.length;
    
    const onClickAction = () => {
        onAction(filelist);
    }
    

    ///////// Page Mode Part
    const onPageModeChange = (value) => {
        dispatch(setPageModeOption(value));
    }
    const onFirstCoverChange = (value) => {
        //console.log("send first cover",value);
        dispatch(setFirstCoverOption(value));
    }

    //////// Position and Margin
    const onPositionGridChange = (e) => {
        dispatch(setPositionOption(e));
    }
    const onMarginChange = (e) => {
        dispatch(setMarginOption(e));
    }
    
    //////// Pages
    const onFirstNumberChange = (e) => {
        dispatch(setFirstNumberOption(e));
    }
    const onSingleStartChange = (e) => {
        dispatch(setSingleStartOption(e));
    }
    const onSingleEndChange = (e) => {
        dispatch(setSingleEndOption(e));
    }
    const onMultipleStartChange = (e) => {
        dispatch(setMultipleStartOption(e));
    }
    const onMultipleEndChange = (e) => {
        dispatch(setMultipleEndOption(e));
    }     
    

    //////////// TEXT
    const onNumberType = (e) => {
        dispatch(setNumberTypeOption(e));
    }
    const onCustomText = (e) => {
        dispatch(setCustomTextOption(e));
    }
    
    //////////// TEXT FORMAT
    const onFontNameChange = (e) => {
        dispatch(setFontNameOption(e));
    }
    const onFontSizeChange = (e) => {
        dispatch(setFontSizeOption(e));
    }
    const onBoldChange = (e) => {
        dispatch(setBoldOption(e));
    }
    const onItalickChange = (e) => {
        dispatch(setItalicOption(e));
    }
    const onUnderlineChange = (e) => {
        dispatch(setUnderlineOption(e));
    }
    const onFontColorChange = (e) => {
        //alert(e);
        dispatch(setFontColorOption(e));
    }
    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>PAGE NUMBERS</Header>

                <Content>
                    <div style={{height:'calc(100vh - 152px - 60px)'}}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <PageMode
                                fcover={firstCover} 
                                rpro={onPageModeChange} 
                                cpro={onFirstCoverChange}
                            />
                            <PositionMargin 
                                gpro={onPositionGridChange} 
                                spro={onMarginChange} 
                                pos={position}
                                margin={margin}
                            />
                            
                            <Pages 
                                cnt={cnt}
                                maxPage={maxPage}
                                fproc={onFirstNumberChange}
                                sproc={cnt === 1 ? onSingleStartChange : onMultipleStartChange}
                                eproc={cnt === 1 ? onSingleEndChange: onMultipleEndChange}
                                start={singlestart}
                                end={singleend}
                            />
                            
                            <TextPage 
                                nproc={onNumberType} 
                                cproc={onCustomText}
                            />
                            
                            <TextFormat 
                                nproc={onFontNameChange}
                                sproc={onFontSizeChange}
                                bproc={onBoldChange}
                                iproc={onItalickChange}
                                uproc={onUnderlineChange}
                                cproc={onFontColorChange}
                            />

                        </Scrollbars>
                    </div>
                </Content>
                
                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={filelist.length> 0?false:true}>
                    {t('tools.pageNumbers.Addpagenumbers')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSidePageNumbers;

const ColorPicker = ({proc}) => {
    const [color, setColor] = useState('#200D03');
    
    const onChange = (color) => {
        setColor(color.hex);
        proc(color.hex);
        //console.log(color.hex);
    };
    return (
        <SketchPicker  color={ color } onChange={ onChange }></SketchPicker>
    );
}

const FontSize = ({proc}) => {
  
    const [inputValue, setInputValue] = useState(14);
    const onChange = value => {
      setInputValue(value);
      proc(value);
    };
  
      return (
          <Row style={{width: '270px'}}>
              <Col style={{marginRight:'15px', marginTop:'5px'}}>Font Size:</Col>
              <Col style={{width: '30%'}}>
                  <Slider
                  min={1}
                  max={100}
                  onChange={onChange}
                  value={typeof inputValue === 'number' ? inputValue : 0}
                  />
              </Col>
              <Col style={{width: '20%'}}>
                  <InputNumber
                  min={1}
                  max={100}
                  style={{ margin: '0 16px' }}
                  value={inputValue}
                  onChange={onChange}
                  />
              </Col>
          </Row>
      );
  }

  const PageMode = ({fcover, rpro, cpro}) => {
    const {t} = useTranslation();
    const [radioSel, setRadioSel] = useState(0)
    const [firstSel, setFirstSel] = useState(fcover)

    const onRadioChange = (e) => {
        setRadioSel(e.target.value);
        rpro(e.target.value);
    }
    const onFirstCoverPage = () => {
        var nsel = firstSel === 0 ? 1: 0;
        //console.log("start first cover", nsel);
        setFirstSel(nsel);
        cpro(nsel);
    }
    
    var element = '';
    if (radioSel === 1)
    {
        element = (
            <div>
                <Checkbox checked={firstSel} onChange={onFirstCoverPage} style={{width:'88%', textAlign:'left', marginTop:'10px', marginLeft:'12px'}}>{t('tools.pageNumbers.FirstPage')}</Checkbox>
            </div>
        );
    }
    return (
        <div>
            <div> <p> {t('tools.pageNumbers.PageMode')} </p> </div> 
            <div>
                <Radio.Group onChange={onRadioChange} value={radioSel}>
                    <Radio value={0}>{t('tools.pageNumbers.SinglePage')}</Radio>
                    <Radio value={1}>{t('tools.pageNumbers.FacingPage')}</Radio>
                </Radio.Group>
            </div>
            {element}
        </div>
    );
}

const Pages = ({cnt, maxPage,fproc, sproc, eproc, start, end}) => {
    /**
     * 
     * @param {*} e
     * cnt: file number
     * maxPage: when one pdf is opened, total page number
     * fproc: first number process
     * sproc: from process
     * eproc: to process 
     * start: start value
     * end: end value
     */
     const {t} = useTranslation();
    const [firstValue, setFirstValue] = useState(1);
    const [startValue, setStartValue] = useState(start);
    const [endValue, setEndValue] = useState(end);
    //console.log("pages singleend1:", end);
    //console.log("pages singleend:", endValue);
    
    const onFirstCoverPage = (e) => {
        fproc(e);
        setFirstValue(e);
    }
    const onSingleStartChange = (e) => {
        sproc(e);
        setStartValue(e);
    }
    const onSingleEndChange = (e) => {
        eproc(e);
        setEndValue(e);
    }
    const onMultipleStartChange = (e) => {
        sproc(e);
    }
    const onMultipleEndChange = (e) => {
        eproc(e);
    }     
    var elementWhich = '';
    if (cnt === 1){
        elementWhich = (
            <div style={{marginTop:'5px'}}>
                <InputNumber min={1} max={maxPage} value={startValue} onChange = {onSingleStartChange} prefix={<span> from </span>} style={{ width: 120, marginRight:5, left:'-10px' }}/>
                <InputNumber min={1} max={maxPage} value={end} onChange = {onSingleEndChange} prefix={<span>to</span>} style={{ width: 90 }}/>
            </div>
        );
    }
    else{
        elementWhich = (
            <div>
                <div>
                <Select defaultValue={0} style={{marginTop:'5px', width:'88%', textAlign:'left'}}  onChange={onMultipleStartChange} >
                    <Option value={0}>{t('tools.pageNumbers.begin0')}</Option>
                    <Option value={1}>{t('tools.pageNumbers.begin1')}</Option>
                    <Option value={2}>{t('tools.pageNumbers.begin2')}</Option>
                    <Option value={3}>{t('tools.pageNumbers.begin3')}</Option>
                    <Option value={4}>{t('tools.pageNumbers.begin4')}</Option>
                    <Option value={5}>{t('tools.pageNumbers.begin5')}</Option>
                </Select>
                </div>
                <div>
                <Select defaultValue={0} style={{marginTop:'5px', width:'88%', textAlign:'left'}}  onChange={onMultipleEndChange} >
                    <Option value={0}>{t('tools.pageNumbers.end0')}</Option>
                    <Option value={1}>{t('tools.pageNumbers.end1')}</Option>
                    <Option value={2}>{t('tools.pageNumbers.end2')}</Option>
                    <Option value={3}>{t('tools.pageNumbers.end3')}</Option>
                    <Option value={4}>{t('tools.pageNumbers.end4')}</Option>
                    <Option value={5}>{t('tools.pageNumbers.end5')}</Option>
                </Select>    
                </div>
            </div>
        );
    }
    //className="pagenumbers_first_number1" 
    return (
        <div>
            <div>
                <p>  {t('tools.pageNumbers.Pages')} </p>
                <div>
                <InputNumber 
                    min={1}
                    max={50000}
                    value={firstValue}
                    prefix={<span>{t('tools.pageNumbers.Firstnumber')}</span>} 
                    style={{ width: '200px', marginLeft:'-35px' }}
                    onChange={onFirstCoverPage}
                />
                </div>
                <div style={{marginTop:'5px'}}>{t('tools.pageNumbers.which')}</div>
            </div> 
            {elementWhich}
        </div>
            
    );
}

const PositionGrid = ({proc, pos}) => {
    const [sel, setSel] = useState(pos);
    
    const onClick1 = () => {
      setSel(0);proc(0);
    }
    const onClick2 = () => {
      setSel(1);proc(1);
    }
    const onClick3 = () => {
      setSel(2);proc(2);
    }
    const onClick7 = () => {
      setSel(3);proc(3);
    }
    const onClick8 = () => {
      setSel(4);proc(4);
    }
    const onClick9 = () => {
      setSel(5);proc(5);
    }
    ///////////////////rendering////////////////
    var name = [];
    name[0] = sel == 0 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[1] = sel == 1 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[2] = sel == 2 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[3] = sel == 3 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[4] = sel == 4 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[5] = sel == 5 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    return (
      <div className="pagenumbers_group_select_position" style={{marginLeft: '20px'}}>
        <Row>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[0]} onClick={onClick1}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[1]} onClick={onClick2}>
              </div>
            </div>  
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[2]} onClick={onClick3}>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className="pagenumbers_select_position">
              </div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className="pagenumbers_select_position">
              </div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className="pagenumbers_select_position">
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[3]} onClick={onClick7}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[4]} onClick={onClick8}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[5]} onClick={onClick9}></div>
            </div>
          </Col>
        </Row>
      </div>
    );
}

const PositionMargin = ({gpro, spro, pos, margin}) => {
    /**
     * 
     * @param {*} e 
     * gpro: grid process
     * spro: select process
     * pos: selected position
     */
    const {t} = useTranslation();
    const onPositionGridChange = (e) => {
        gpro(e);
    }
    const onMarginChange = (e) => {
        spro(e);
    }
    return (
        <div>
            <Row>
                <Col xs={2} sm={4} md={6} lg={8} xl={10} style={{marginRight:'10px'}}> 
                    <p style={{width:"100px"}}> {t('tools.pageNumbers.Position')} </p>
                    <div>   <PositionGrid proc={onPositionGridChange} pos={pos}/>    </div>
                </Col>
                <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                    <p style={{width:"100px"}}>
                    {t('tools.pageNumbers.Margin')}
                    </p>
                    <div>
                        <Select defaultValue={margin} style={{marginLeft:'15px', width:'100px', textAlign:'left'}}  onChange={onMarginChange} >
                            <Option value={0}>{t('tools.pageNumbers.Small')}</Option>
                            <Option value={1}>{t('tools.pageNumbers.Recommended')}</Option>
                            <Option value={2}>{t('tools.pageNumbers.Big')}</Option>
                        </Select>
                    
                    </div>
                </Col>
            
            </Row>
        </div>
    );
}

const RangeElement = ({ start, end, pos, margin, bshow1, bshow2 }) => {

    var clsName = "pdf-pagenumber-area_face_righttop1";
    var clsName1 = "pdf-pagenumber-area_face_lefttop2";
    if (pos === 1)      {clsName = "pdf-pagenumber-area_face_middletop1";     clsName1="pdf-pagenumber-area_face_middletop2"}
    else if (pos === 2) {clsName = "pdf-pagenumber-area_face_lefttop1";       clsName1 = "pdf-pagenumber-area_face_righttop2";}
    else if (pos === 3) {clsName = "pdf-pagenumber-area_face_rightbottom1";   clsName1 = "pdf-pagenumber-area_face_leftbottom2";}
    else if (pos === 4) {clsName = "pdf-pagenumber-area_face_middlebottom1";  clsName1 = "pdf-pagenumber-area_face_middlebottom2";}
    else if (pos === 5) {clsName = "pdf-pagenumber-area_face_leftbottom1";    clsName1 = "pdf-pagenumber-area_face_rightbottom2";}
  
    var marginName = "recommargin";
    if (margin === 0) marginName = "smallmargin";
    else if(margin === 2) marginName = "bigmargin";
  
    ///////////////////rendering////////////////
    return (
          <div style={{marginBottom:20}}>
            <div className="pdf-range-element">
                <div className="pdf-canvas_page">
                  <Page pageNumber={start+1} loading="" />
                </div>
                <div className={bshow1?clsName:"pdf-non-checked"}>
                  <div className={marginName}>
                    <div className="pdf-pagenumber-circle">
                    </div>
                  </div>
                </div>
                <div className="pdf-canvas_page">
                  <Page pageNumber={end+1} loading="" />
                </div>
                <div className={bshow2?clsName1:"pdf-non-checked"}>
                  <div className={marginName}>
                    <div className="pdf-pagenumber-circle">
                    </div>
                  </div>
                </div>
                
            </div>
          </div>
    );
  }
  
  const TextFormat = ({nproc, sproc, bproc, iproc, uproc, cproc}) => {
    /**
     * 
     * @param {*} e 
     * nproc: name process
     * sproc: font size process
     * bproc: bold process
     * iproc: italick process
     * uproc: underline process
     * cproc: font color process
     */
    const {t} = useTranslation();
    const onSelectFontName = (e) => {
        //alert(e);
        nproc(parseInt(e));
    }

    const [bold, setBold] = useState(0);
    const [italic, setItalic] = useState(0);
    const [underline, setUnderline] = useState(0);
    const [fontcolor, setFontColor] = useState('#ff9b63');
    const [fontsize, setFontSize] = useState(14);

    const onBoldClick = () => {
        var nsel = bold === 0 ? 1 : 0;
        setBold(nsel);
        bproc(nsel);
    }
    const onItalicClick = () => {
        var nsel = italic === 0 ? 1 : 0;
        setItalic(nsel);
        iproc(nsel);
    }
    const onUnderlineClick = () => {
        var nsel = underline === 0 ? 1 : 0;
        setUnderline(nsel);
        uproc(nsel);
    }
    const onFontColorChange = (e) => {
        setFontColor(e);
        cproc(e);
        //console.log("font color:", e);
    }
    const onFontSizeChange = (e) => {
        setFontSize(e);
        sproc(e);
        //console.log("font size:", e);
    }
    const fontSizeContent = (
        <div>
            <FontSize proc={onFontSizeChange} style={{width: '200px'}}></FontSize>
        </div>
    )
    const colorPickerContent = (
        <ColorPicker proc={onFontColorChange}></ColorPicker>
    )

    const fontName = [
        {id:'0',name:'Arial'},
        {id:'1',name:'Calibri'},
        {id:'2',name:'Cambria'},
        {id:'3',name:'Corbel'},
        {id:'4',name:'Comic Sans MS'},
        {id:'5',name:'Consolas'},
        {id:'6',name:'Constantia'},
        {id:'7',name:'Courie New'},
        {id:'8',name:'Georgia'},
        {id:'9',name:'Palatino Linotype'},
        {id:'10',name:'Sitka Heading'},
        {id:'11',name:'Times New Roman'},
        {id:'12',name:'Verdana'},
    ]
    
    return (
        <div >
            <div>
                <p>
                    {t('tools.pageNumbers.textformat')}
                </p>
            </div>
            <div style={{float:'left', marginLeft:'15px'}} >
                <Select 
                    defaultValue={fontName[0].id}
                    style={{marginRight: '10px', marginTop:'-5px'}}
                    dropdownMatchSelectWidth={false}
                    placement="topLeft"
                    onChange={onSelectFontName}
                >    
                {fontName.map(province => (
                    <Option key={province.id}>{province.name}</Option>
                    ))}
                </Select>
                <Popover placement="topRight" content={fontSizeContent}>
                    <AiOutlineFontSize style={{fintSize:'35px'}}/>
                </Popover>
            </div>
            <div style={{float:'left', marginLeft:'15px', marginTop:'0px'}}>
                <Space>
                    <div><AiOutlineBold className={bold === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onBoldClick}/></div>
                    <div><AiOutlineItalic className={italic === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onItalicClick}/></div>
                    <div><AiOutlineUnderline className={underline === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onUnderlineClick}/></div>
                    <Popover placement="topRight" content={colorPickerContent}>
                        <AiOutlineFontColors style={{ fontSize: '20px'}}/>
                    </Popover>
                </Space>
            </div>
        </div>
    );
}

const TextPage = ({nproc, cproc}) => {
    /**
     * nproc: number type process
     * cproc: custom text process
     */
    const {t} = useTranslation();
    const [numberType, setNumberType] = useState(0);
    const onNumberType = (e) => {
        setNumberType(e);
        nproc(e);
    }
    
    var bracketN = ' \{n\} ';
    var bracketP = ' \{p\} ';
    var page1 = t('tools.pageNumbers.Page') + bracketN;
    var page2 = page1 +  ' of ' + bracketP;

    var elementCustomNumber = '';
    const onCustomText = (e) => {
        cproc(e.target.value);
    }
    if (numberType === 3)
    {
        elementCustomNumber = (
            <div>
                <div style={{textAlign:'left', marginLeft:'17px'}}>{t('tools.pageNumbers.CustomText')}</div>
                <Input style={{width:'88%'}} placeholder="Page {n} of {p}" onChange={onCustomText}/>
                <div style={{textAlign:'left', marginLeft:'17px'}}><small>{t('tools.pageNumbers.Textsample')} {bracketN}, Page {bracketN}, Page {bracketN} of {bracketP}</small></div>
            </div>
        );
    }
    return (
        <div>
            <div>
                <p>  {t('tools.pageNumbers.TEXT')}  </p>
            </div>
            <div>
                <Select defaultValue={0} style={{width: '88%', textAlign:'left'}} onChange={onNumberType}>
                    <Option value={0}>{t('tools.pageNumbers.Insert')}</Option>
                    <Option value={1}>{page1}</Option>
                    <Option value={2}>{page2}</Option>
                    <Option value={3}>{t('tools.pageNumbers.Custom')}</Option>
                </Select>
            </div>
            {elementCustomNumber}
        </div>
    );
}