import doc from '../assets/svg/ext/doc.svg';
import docx from '../assets/svg/ext/docx.svg';
import ppt from '../assets/svg/ext/ppt.svg';
import pptx from '../assets/svg/ext/pptx.svg';
import xls from '../assets/svg/ext/xls.svg';
import xlsx from '../assets/svg/ext/xlsx.svg';
import bmp from '../assets/svg/ext/bmp.svg';
import jpg from '../assets/svg/ext/jpg.svg';
import png from '../assets/svg/ext/png.svg';
import tif from '../assets/svg/ext/tif.svg';
import pdf from '../assets/svg/ext/pdf.svg';

const svgFiles = {
	"doc": doc,
	"docx": docx,
	"ppt": ppt,
	"pptx": pptx,
	"xls": xls,
	"xlsx": xlsx,
	"bmp": bmp,
	"jpg": jpg,
	"png": png,
	"tif": tif,
	"pdf": pdf,
};

export const getSvgImageFromType = (filetype) => {
	var str = filetype.toLowerCase();
	return svgFiles[str];
}