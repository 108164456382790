import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { updateBorderColor, 
    removeElement, 
    updateOpacity,
    setEnableDraw, 
    addElement,
    updateElementTextFormat,
    updateFillColor, } from '../editSlice';

import { Tooltip, Popover, Select } from 'antd';
import TextFormat from './TextFormat';
import ColorPicker, { getRGBFromRGBA } from './ColorPicker';


import { BsSquareFill, BsBorder } from 'react-icons/bs';
import { /*RotateLeftOutlined, RotateRightOutlined,*/ DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import opacity_svg from '../../../../assets/svg/opacity.svg';
import { useTranslation } from 'react-i18next';

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////EDIT TOOLBAR///////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
const { Option } = Select;
const SubToolBar = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const added_elements = useSelector((state) => state.editOption.added_elements);
    const selected_element = useSelector((state) => state.editOption.selected_element);
    const vertex_info = useSelector((state) => state.editOption.vertex_info);
    const enable_draw = useSelector((state) => state.editOption.enable_draw);
    const page_rect = useSelector((state) => state.editOption.page_rect);

    useEffect(() => {
        //component mount//
        window.addEventListener('keydown', handleKeyDown);

        return () => {
          //component will unmount
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (e) => {
        if(e.key === 'Delete'){
          //remove element//
          document.getElementById("remove_element").click();
        }
    }


    //get enabled info//
    var selected_type = "";
    if(selected_element.page >= 0 && selected_element.idx >= 0)
        selected_type = added_elements[selected_element.page][selected_element.idx].type;
    const {
        enable_text,
        enable_backcolor,
        enable_bordercolor,
        enable_opacity,
        enable_confirm,
        enable_remove,
    } = getEnabledSubMenu(selected_type, enable_draw);

    //get default values//
    var backcolor = "rgba(80,80,80,1.0)";
    if(selected_element.page >= 0 && selected_element.idx >= 0)
        backcolor = added_elements[selected_element.page][selected_element.idx].fill_color;


    var bordercolor = "rgba(80,80,80,1.0)";
    if(selected_element.page >= 0 && selected_element.idx >= 0)
    {
        if(selected_type === "img"){
            var tmp = added_elements[selected_element.page][selected_element.idx].pen_color.split(' ');
            bordercolor = tmp[2];//e.g. 1px solid rgba(0,0,0,0)
        }            
        else
            bordercolor = added_elements[selected_element.page][selected_element.idx].pen_color;
    }

    var opacity = "100%";
    if(selected_element.page >= 0 && selected_element.idx >= 0)
        opacity = added_elements[selected_element.page][selected_element.idx].opacity;

    //////////// TEXT FORMAT
    const onFontNameChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            font: value,
        }
        dispatch(updateElementTextFormat(payload));
    }
    const onFontSizeChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            fontSize: value,
        }

        dispatch(updateElementTextFormat(payload));
    }
    const onBoldChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            bold: value,
        }
        dispatch(updateElementTextFormat(payload));
    }
    const onItalickChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            italic: value,
        }
        dispatch(updateElementTextFormat(payload));
    }
    const onUnderlineChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            underline: value,
        }
        dispatch(updateElementTextFormat(payload));
    }
    const onFontColorChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            pen_color: value,
        }
        dispatch(updateElementTextFormat(payload));
    }
    const onAlignChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            align: value,
        }
        dispatch(updateElementTextFormat(payload));
    }

    ///////////////BACKGROUND COLOR/////////////////
    const onBackgroundColorChange = (value) => {
        //setBackcolor(value);
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            fill_color: value,
        }
        dispatch(updateFillColor(payload));
    }

    ///////////////BORDER COLOR/////////////////
    const onBorderColorChange = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;     
        //console.log('BORDER COLOR', selected_element, value);        
        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            pen_color: value,
        }
        dispatch(updateBorderColor(payload));
    }

    ///////////////REMOVE/////////////////
    const onClickRemove = () => {
        if(enable_draw) {
            dispatch(setEnableDraw(0));
        } else {
            if(selected_element.page < 0 || selected_element.idx < 0)   return;

            const payload = {
                page: selected_element.page,
                idx: selected_element.idx,
            }
            //console.log('remove: ', selected_element);
            dispatch(removeElement(payload));
        }
    }

    ///////////////OPACITY/////////////////
    const onChangeOpacity = (value) => {
        if(selected_element.page < 0 || selected_element.idx < 0)   return;

        const payload = {
            page: selected_element.page,
            idx: selected_element.idx,
            opacity: value,
        }
        dispatch(updateOpacity(payload));
    }

    ///////////////PEN DRAWING CONFIRM/////////////////
    const onClickDrawConfirm = () => {
        dispatch(setEnableDraw(0));
        //add draw element//
        
        for(let i=0; i < vertex_info.length; i++)//page
        {
            if (vertex_info[i].length === 0) continue;
            //calc rect//
            var minX = 0, minY = 0, maxX = 0, maxY = 0;
            for (let j = 0; j < vertex_info[i].length; j++) {//path idx
                for (let k = 0; k < vertex_info[i][j].length; k++) {//vertex array
                    if(k === 0 && j === 0)
                    {
                        minX = vertex_info[i][j][k].x;
                        minY = vertex_info[i][j][k].y;
                        maxX = vertex_info[i][j][k].x;
                        maxY = vertex_info[i][j][k].y;
                    }
                    else
                    {
                        if(minX > vertex_info[i][j][k].x)  minX = vertex_info[i][j][k].x;
                        if(minY > vertex_info[i][j][k].y)  minY = vertex_info[i][j][k].y;
                        if(maxX < vertex_info[i][j][k].x)  maxX = vertex_info[i][j][k].x;
                        if(maxY < vertex_info[i][j][k].y)  maxY = vertex_info[i][j][k].y;
                    }
                }
            }

            let d = new Date();
            const payload = {
                page: i,
                element: {
                    type: 'pen',
                    page: i,
                    id: 'element_' + d.getTime().toString(),
                    pos: {x: minX + page_rect.left, y: minY + page_rect.top},
                    size:{w: maxX - minX, h:maxY - minY},
                    relative_pos: {x: minX, y: minY},
                    vertices: vertex_info[i],
                    pen_color: 'rgba(24,144,255,1.0)',//not used
                    fill_color: 'rgba(24,144,255,1.0)',
                    opacity: '100%',
                    pen_size: 2.3,
                    opacity: '100%',
                    rotate: 0,
                    active: 0,
                    remove: 0,
                },
            };

            dispatch(addElement(payload));
        }
    }

    

    /////////// RENDERING ////////////
    return (
        <div className="editpdf-submenu">
            <TextFormat 
                nproc={onFontNameChange}
                sproc={onFontSizeChange}
                bproc={onBoldChange}
                iproc={onItalickChange}
                uproc={onUnderlineChange}
                cproc={onFontColorChange}
                aproc={onAlignChange}
                clsName={enable_text? "":"disable-element"}
                element={enable_text? 
                            added_elements[selected_element.page][selected_element.idx]
                            :{
                                font: "Arial",
                                fontSize: 22,
                                bold: 0,
                                italic: 0,
                                underline: 0,
                                pen_color: 'rgba(0,0,0,1.0)',
                                fill_color: 'rgba(0,0,0,0)',
                                align: 'left',
                            }}
            />

            <Tooltip title={selected_type==="text"? t('tools.edit.tool_bkcolor'):t('tools.edit.tool_color')} placement="top">
                <div 
                    className={enable_backcolor?"editpdf__menu_item":"editpdf__menu_item disable-element"}
                    style={{marginLeft: 20}}>
                    
                    <Popover placement="bottom" content={(<ColorPicker value={backcolor} proc={onBackgroundColorChange}></ColorPicker>)}>
                        <BsSquareFill style={{ fontSize: '16px', color:getRGBFromRGBA(backcolor)}}/>
                    </Popover>
                </div>
            </Tooltip>

            <Tooltip title={t('tools.edit.tool_bordercolor')} placement="top">
                <div className={enable_bordercolor?"editpdf__menu_item":"editpdf__menu_item disable-element"}>
                    <Popover placement="bottom" content={(<ColorPicker value={bordercolor} proc={onBorderColorChange}></ColorPicker>)}>
                        <BsBorder style={{ fontSize: '16px', color:getRGBFromRGBA(bordercolor)}}/>
                    </Popover>
                </div>
            </Tooltip>

            <Tooltip title={t('tools.edit.tool_opacity')} placement="top">
                <div className={enable_opacity?"":"disable-element"} style={{display:'flex', alignItems:'center', marginLeft:15}}>
                    <img src={opacity_svg} alt=""/>
                    <Select value={opacity} style={{ width: 80, height:32, marginLeft:10, textAlign:'left' }} onChange={onChangeOpacity}>
                      <Option value="100%">100%</Option>
                      <Option value="75%">75%</Option>
                      <Option value="50%">50%</Option>
                      <Option value="25%">25%</Option>
                      <Option value="0%">0%</Option>
                    </Select>
                </div>
            </Tooltip>
            

            <Tooltip title={t('tools.edit.tool_confirm')} placement="top">
                <div className={enable_confirm?"editpdf__menu_item hovermenu":"editpdf__menu_item hovermenu disable-element"} 
                onClick={onClickDrawConfirm} style={{marginLeft: 20}}>
                    <CheckOutlined />
                </div>
            </Tooltip>

            <Tooltip title={t('tools.edit.tool_remove')} placement="top">
                <div 
                    id="remove_element" 
                    className={enable_remove?"editpdf__menu_item hovermenu":"editpdf__menu_item hovermenu disable-element"}
                    onClick={onClickRemove}>
                    <DeleteOutlined style={{ fontSize: '16px'}}/>
                </div>
            </Tooltip>

        </div>
    );
}

export default SubToolBar;


const getEnabledSubMenu = (type, enable_draw) => {
    var retObject = {};

    //TextFormat//
    if(type === "text"){
        retObject.enable_text = 1
    } else {
        retObject.enable_text = 0
    }

    //Background Color//
    if(type === "text" || 
        type === "pen" || 
        type === "line" || 
        type === "rectangle" || 
        type === "circle"){
        retObject.enable_backcolor = 1
    } else {
        retObject.enable_backcolor = 0
    }

    //Border Color//
    if( type === "img" || 
        type === "rectangle" || 
        type === "circle"){
        retObject.enable_bordercolor = 1
    } else {
        retObject.enable_bordercolor = 0
    }

    //Opacity//
    if(type === "text" || 
        type === "img" || 
        type === "rectangle" || 
        type === "circle"){
        retObject.enable_opacity = 1
    } else {
        retObject.enable_opacity = 0
    }

    //Confirm//
    if(enable_draw){
        retObject.enable_confirm = 1
    } else {
        retObject.enable_confirm = 0
    }

    //Remove//
    if(type !== "" || enable_draw){
        retObject.enable_remove = 1
    } else {
        retObject.enable_remove = 0
    }

    return retObject;
}