import React from 'react';
import { useSelector } from 'react-redux';

import {  Button, Layout } from 'antd';
import { SendOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
const { Header, Footer, Content } = Layout;


    
const WorkSideRepair = ({onAction}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.repairOption.filelist);
    
    const onClickAction = () => {
        onAction(filelist);
    }

    
    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>REPAIR PDF</Header>

                <Content>
                <p>
                    {t('tools.repair.try')}
                
                <br/>
                {t('tools.repair.youcan')}
                </p>
                </Content>
                
                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction}>
                    {t('tools.repair.repair')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSideRepair;