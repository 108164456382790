import React, { useEffect, useState } from 'react';

import { toolsListInfo } from '../../utils/toolsInfo';
import { axiosInstance, domainFrontEnd } from '../../utils/axiosInstance';
import { Progress, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ActionSpin = ({ text }) => {
	const { t } = useTranslation();
	const antIcon = <LoadingOutlined style={{ fontSize: 140, fontWeight: 1800 }} spin />;

	return (
		<div className="action-spin">
			<h2>{t('tools.upload.action')}</h2>
			<Spin indicator={antIcon} />
			<p>{t('tools.upload.note')}</p>
		</div>
	);
}

var uploading = 0;
const baseURL = process.env.REACT_APP_API_URL_UPLOADS;
const UploadingFile = ({ id, api, goDownload, text, tool_header, filelist, option, }) => {
	const { t } = useTranslation();

	const [progress, setProgress] = useState(0);//0%-100%
	const [staus, setStatus] = useState('normal');//normal, active, exception, success
	/*const tool_header = useSelector((state) => state.toolOption.tool_header);
	const filelist = useSelector((state) => state.toolOption.filelist);*/

	const openNotification = (title, description, nType) => {
		if (nType === 1) {//error
			notification.error({
				message: title,
				description: description,
				placement: 'topRight',
				duration: 8,
			});
		} else if (nType === 2) {//info
			notification.warning({
				message: title,
				description: description,
				placement: 'topRight',
				duration: 8,
			});
		}
	};


	//uploading//	

	useEffect(() => {

		const sendOption = (filepath) => {

			var payload = {
				tool_header: tool_header,
				option: option,
				path: filepath,
			}
			//axiosInstance.post(`${baseURL}/action/${api}`, payload)
			axiosInstance.post(`${baseURL}/${api}`, payload)
				.then((res) => {
					if (res.data.msg === "Success") {
						console.log(res.data);
						const { downlinks } = res.data;
						var linkvalue = domainFrontEnd + '/download/' + downlinks;
						linkvalue = linkvalue.replaceAll("\\", "/");
						//setTimeout(() => {goDownload(downlinks[0]);}, 1000000);//testing
						goDownload(linkvalue);
					} else if (res.data.msg === "Failed Action") {
						if (tool_header === "PDF2JPG") {
							openNotification(t('tools.notice_title'), t('tools.notice_pdf2jpg'), 2);
						} else if (tool_header === "PDF2EXCEL") {
							openNotification(t('tools.notice_title'), t('tools.notice_pdf2excel'), 2);
						} else {
							openNotification(t('tools.failed_title'), t('tools.failed_msg'), 1);
						}
						goDownload('');
					} else {
						//message.
						openNotification(t('tools.failed_title'), t('tools.failed_msg'), 1);
						goDownload('');
					}
				})
				.catch((error) => {
					console.log(error);
					window.alert('hello Erorr')
					openNotification(t('tools.failed_title'), t('tools.failed_msg'), 1);
					goDownload('');
				})

		}

		const submitFormData = (e) => {
			if (e) {
				e.preventDefault(); // Prevent default form submission behavior
			}
			// if (uploading === 0) {
			//	uploading = 1;
			let formData = new FormData();

			for (var i = 0; i < filelist.length; i++) {
				formData.append("files", filelist[i]);
			}

			axios.post(`${baseURL}/uploads`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: data => {
					// Set the progress value to show the progress bar
					setProgress(Math.round((150 * data.loaded) / data.total));
				},
			})
				.then((res) => {
					if (res.data.msg === "Uploading Failed") {
						setStatus('exception');
						setTimeout(() => { goDownload(''); }, 1500);//failed 
					} else {
						setStatus('success');
						var { path } = res.data;
						// Send option
						sendOption(path);
						return false;
					}
				})
				.catch((error) => {
					console.log(error);
					setStatus('exception');
					setTimeout(() => { goDownload(''); }, 1500);//failed
				})
		}
		//};

		submitFormData();

	}, []);

	


	/////////////////////////////////////////////////////////
	var statusElement = '';
	if (staus === "success") {
		statusElement = (
			<div>
				<div className="prog-success">
					<span>{t('tools.upload.complete')} ({id === 9 || id === 21 ? 1 : filelist.length}{t('tools.upload.files')})</span>
				</div>
				<ActionSpin text={text} />
			</div>
		);
	} else if (staus === "exception") {
		statusElement = (
			<div className="prog-failed">
				<span>{t('tools.upload.failed')}</span>
			</div>
		);
	} else {
		statusElement = (
			<div className="prog-normal">
				<span>{t('tools.upload.uploading')}</span>
			</div>
		);
	}

	//rendering//
	return (
		<form className="select-file">
			<div className="tool__header">
				<h1>{toolsListInfo[id].title}</h1>
			</div>

			< div>
				<Progress type="circle" percent={progress} status={staus} />
			</div>

			{statusElement}
		</form>
	);
}

export default UploadingFile;
export function uploadFiles(event, filelist, setProgress, setStatus, goDownload, sendOption) {
	console.log('function called')
	console.log(event)
	if (uploading === 0) {
		uploading = 1;

		let formData = new FormData();

		for (var i = 0; i < filelist.length; i++) {
			formData.append("files", filelist[i]);
		}

		axiosInstance.post(`${baseURL}/uploads`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress: data => {
				// Set the progress value to show the progress bar
				setProgress(Math.round((150 * data.loaded) / data.total));
			},
		})
			.then((res) => {
				if (res.data.msg === "Uploading Failed") {
					setStatus('exception');
					setTimeout(() => { goDownload(''); }, 1500);//failed 
					return;
				} else {
					setStatus('success');
					var { path } = res.data;

					window.alert('hello Done upload')
					// Send option
					sendOption(path);
					return;
				}
			})
			.catch((error) => {
				console.log(error);
				setStatus('exception');
				setTimeout(() => { goDownload(''); }, 1500);//failed
				return;
			});

	}
}
