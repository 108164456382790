import React from 'react';
import { MailOutlined} from '@ant-design/icons';
import {  Menu, Popover, Row, Dropdown } from 'antd';
import {getCookie, setCookie, removeCookie} from '../../utils/cookie';
import usersvg from '../../assets/svg/tools/sign_user.svg';

const UserMenu = () => {
	
	const onLogout = () => {
		removeCookie('user');
		window.location.href = '/';
	}

	return(
		<div className="user__menu">
			<div className="button" onClick={onLogout}>
					Log out
			</div>
		</div>
	);
}


/////////////////////////////////

const RightSide = () => {
  var element = '';
  var user = getCookie('user');
  
  if (user === '' || user === undefined) {
			element=(
				<div className="header__right">
		      	<a href="/login" className="login">Log In</a>
		      	<a href='/signup' className="signup">Sign Up</a>
		    </div>
		   );
  }
  else{
			element=(
				<div className="header__right">
					<Dropdown overlay={<UserMenu/>}>
						<a className="login">
							<img src={usersvg} alt='user' style={{fontSize:'12px', marginRight:'7px'}}/>
							{user}
						</a>
					</Dropdown>
				</div>
			);
  }

  return (
		<>
	    {element}
		</>
  );
}

export default RightSide;