import React, { useState }  from 'react';

import { Col, Row } from 'antd';

import features_svg from '../../assets/svg/footers/features.svg';
import quality_svg from '../../assets/svg/footers/quality.svg';
import security_svg from '../../assets/svg/footers/security.svg';
import files_svg from '../../assets/svg/footers/files.svg';

//lang test//
import { useTranslation } from 'react-i18next';


///
const Features = () => {
	const {t} = useTranslation();

	return (
		<div className="features">
			<div className="block--grey ">
				<h1 style={{color:'#fff', fontWeight:'600', fontSize:48}}>{t('home.features.title1')}</h1>
				<h4 style={{color:'#fff', fontWeight:'450', fontSize:22}}>{t('home.features.subtitle1')}</h4>
				<div style={{display:'flex', marginTop:40}}>
					<Col span={8}>
		  				<h4 style={{color:'#fff', fontWeight:'500', fontSize:24}}>
		  					{t('home.features.msgtitle1')}
		  				</h4>
		  				<p style={{color:'#fff'}}>
		  					{t('home.features.msg1')}					
		  				</p>
		            </Col>
		            <Col span={8}>
		  				<img src={features_svg} alt="feauters" style={{marginTop:20, width:'80%'}}/>
		            </Col>
		            <Col span={8}>
		  				<h4 style={{color:'#fff', fontWeight:'500', fontSize:24}}>
		  					{t('home.features.msgtitle2')}
		  				</h4>
		  				<p style={{color:'#fff'}}>
		  					{t('home.features.msg2')}
		  				</p>
		            </Col>
				</div>
			</div>
			
			<div className="block--white ">
				<h1 style={{color:'#000', fontWeight:'600', fontSize:36}}>
					{t('home.features.subtitle2')}
				</h1>
				<h4 style={{color:'#000', fontWeight:'450', fontSize:22, maxWidth:800, margin:'0 auto'}}>
					{t('home.features.subdesc2')}
				</h4>
				<div style={{display:'flex', marginTop:20}}>
					<Col span={4}/>
					<Col span={6}>
						<img src={quality_svg} alt="feauters" style={{marginTop:20, marginBottom:10, width:'25%'}}/>
						<h4 style={{color:'#000', fontWeight:'500', fontSize:24}}>
		  					{t('home.features.msgtitle3')}
		  				</h4>
		  				<p style={{color:'#000'}}>
		  					{t('home.features.msg3')}
		  				</p>
					</Col>
					<Col span={4}/>
					<Col span={6}>
						<img src={security_svg} alt="feauters" style={{marginTop:20, marginBottom:10, width:'25%'}}/>
						<h4 style={{color:'#000', fontWeight:'500', fontSize:24}}>
							{t('home.features.msgtitle4')}
		  				</h4>
		  				<p style={{color:'#000'}}>
		  					{t('home.features.msg4')}
		  				</p>
					</Col>
					<Col span={4}/>
				</div>
			</div>

			<div className="block--blue-light">
				<h1 style={{color:'#000', fontWeight:'600', fontSize:36}}>
					{t('home.features.subtitle3')}
				</h1>
				<h4 style={{color:'#000', fontWeight:'450', fontSize:22, maxWidth:800, margin:'0 auto'}}>
					{t('home.features.subdesc3')}
				</h4>
				<div style={{marginTop:20}}>
					<Row justify="space-around" align="middle">
						<Col span={10}>
							<img src={files_svg} alt="feauters" style={{marginTop:20, marginBottom:10, width:'80%'}}/>
						</Col>
						<Col span={8}>
							<h4 style={{color:'#000', fontWeight:'500', fontSize:24, textAlign:'left'}}>
			  					{t('home.features.msgtitle5')}
			  				</h4>
			  				<p style={{color:'#000', textAlign:'left'}}>
			  					{t('home.features.msg5')}
			  				</p>
			  				<br/>
			  				<h4 style={{color:'#000', fontWeight:'500', fontSize:24, textAlign:'left'}}>
			  					{t('home.features.msgtitle6')}
			  				</h4>
			  				<p style={{color:'#000', textAlign:'left'}}>
			  					{t('home.features.msg6')}
			  				</p>
						</Col>						
				    </Row>	
				</div>				
			</div>
		</div>
	);
}

export default Features;