import en from './default.en.json';
import it from './default.it.json';
import cn from './default.cn.json';
import es from './default.es.json';
import fr from './default.fr.json';
import de from './default.de.json';
import pt from './default.pt.json';
import ru from './default.ru.json';
import ko from './default.ko.json';
import ja from './default.ja.json';
import ar from './default.ar.json';

//---------------home-------------------//
import homeEn from '../../components/locales/en.json';
import homeIt from '../../components/locales/it.json';
import homeCn from '../../components/locales/cn.json';
import homeEs from '../../components/locales/es.json';
import homeFr from '../../components/locales/fr.json';
import homeDe from '../../components/locales/de.json';
import homePt from '../../components/locales/pt.json';
import homeRu from '../../components/locales/ru.json';
import homeKo from '../../components/locales/ko.json';
import homeJa from '../../components/locales/ja.json';
import homeAr from '../../components/locales/ar.json';

//--------------- popover -------------------//
import popoverEn from '../../components/popover/locales/en.json';
import popoverIt from '../../components/popover/locales/it.json';
import popoverCn from '../../components/popover/locales/cn.json';
import popoverEs from '../../components/popover/locales/es.json';
import popoverFr from '../../components/popover/locales/fr.json';
import popoverDe from '../../components/popover/locales/de.json';
import popoverPt from '../../components/popover/locales/pt.json';
import popoverRu from '../../components/popover/locales/ru.json';
import popoverKo from '../../components/popover/locales/ko.json';
import popoverJa from '../../components/popover/locales/ja.json';
import popoverAr from '../../components/popover/locales/ar.json';

//---------------tools-------------------//
import toolsEn from '../../features/tools/locales/en.json';
import toolsIt from '../../features/tools/locales/it.json';
import toolsCn from '../../features/tools/locales/cn.json';
import toolsEs from '../../features/tools/locales/es.json';
import toolsFr from '../../features/tools/locales/fr.json';
import toolsDe from '../../features/tools/locales/de.json';
import toolsPt from '../../features/tools/locales/pt.json';
import toolsRu from '../../features/tools/locales/ru.json';
import toolsKo from '../../features/tools/locales/ko.json';
import toolsJa from '../../features/tools/locales/ja.json';
import toolsAr from '../../features/tools/locales/ar.json';

//compress//
import compressEn from '../../features/tools/compress_pdf/locales/en.json';
import compressIt from '../../features/tools/compress_pdf/locales/it.json';
import compressCn from '../../features/tools/compress_pdf/locales/cn.json';
import compressEs from '../../features/tools/compress_pdf/locales/es.json';
import compressFr from '../../features/tools/compress_pdf/locales/fr.json';
import compressDe from '../../features/tools/compress_pdf/locales/de.json';
import compressPt from '../../features/tools/compress_pdf/locales/pt.json';
import compressRu from '../../features/tools/compress_pdf/locales/ru.json';
import compressKo from '../../features/tools/compress_pdf/locales/ko.json';
import compressJa from '../../features/tools/compress_pdf/locales/ja.json';
import compressAr from '../../features/tools/compress_pdf/locales/ar.json';

//edit//
import editEn from '../../features/tools/edit_pdf/locales/en.json';
import editIt from '../../features/tools/edit_pdf/locales/it.json';
import editCn from '../../features/tools/edit_pdf/locales/cn.json';
import editEs from '../../features/tools/edit_pdf/locales/es.json';
import editFr from '../../features/tools/edit_pdf/locales/fr.json';
import editDe from '../../features/tools/edit_pdf/locales/de.json';
import editPt from '../../features/tools/edit_pdf/locales/pt.json';
import editRu from '../../features/tools/edit_pdf/locales/ru.json';
import editKo from '../../features/tools/edit_pdf/locales/ko.json';
import editJa from '../../features/tools/edit_pdf/locales/ja.json';
import editAr from '../../features/tools/edit_pdf/locales/ar.json';

//excel2pdf//
import excel2pdfEn from '../../features/tools/excel_to_pdf/locales/en.json';
import excel2pdfIt from '../../features/tools/excel_to_pdf/locales/it.json';
import excel2pdfCn from '../../features/tools/excel_to_pdf/locales/cn.json';
import excel2pdfEs from '../../features/tools/excel_to_pdf/locales/es.json';
import excel2pdfFr from '../../features/tools/excel_to_pdf/locales/fr.json';
import excel2pdfDe from '../../features/tools/excel_to_pdf/locales/de.json';
import excel2pdfPt from '../../features/tools/excel_to_pdf/locales/pt.json';
import excel2pdfRu from '../../features/tools/excel_to_pdf/locales/ru.json';
import excel2pdfKo from '../../features/tools/excel_to_pdf/locales/ko.json';
import excel2pdfJa from '../../features/tools/excel_to_pdf/locales/ja.json';
import excel2pdfAr from '../../features/tools/excel_to_pdf/locales/ar.json';

//html2pdf//
import html2pdfEn from '../../features/tools/html_to_pdf/locales/en.json';
import html2pdfIt from '../../features/tools/html_to_pdf/locales/it.json';
import html2pdfCn from '../../features/tools/html_to_pdf/locales/cn.json';
import html2pdfEs from '../../features/tools/html_to_pdf/locales/es.json';
import html2pdfFr from '../../features/tools/html_to_pdf/locales/fr.json';
import html2pdfDe from '../../features/tools/html_to_pdf/locales/de.json';
import html2pdfPt from '../../features/tools/html_to_pdf/locales/pt.json';
import html2pdfRu from '../../features/tools/html_to_pdf/locales/ru.json';
import html2pdfKo from '../../features/tools/html_to_pdf/locales/ko.json';
import html2pdfJa from '../../features/tools/html_to_pdf/locales/ja.json';
import html2pdfAr from '../../features/tools/html_to_pdf/locales/ar.json';

//jpg2pdf//
import jpg2pdfEn from '../../features/tools/jpg_to_pdf/locales/en.json';
import jpg2pdfIt from '../../features/tools/jpg_to_pdf/locales/it.json';
import jpg2pdfCn from '../../features/tools/jpg_to_pdf/locales/cn.json';
import jpg2pdfEs from '../../features/tools/jpg_to_pdf/locales/es.json';
import jpg2pdfFr from '../../features/tools/jpg_to_pdf/locales/fr.json';
import jpg2pdfDe from '../../features/tools/jpg_to_pdf/locales/de.json';
import jpg2pdfPt from '../../features/tools/jpg_to_pdf/locales/pt.json';
import jpg2pdfRu from '../../features/tools/jpg_to_pdf/locales/ru.json';
import jpg2pdfKo from '../../features/tools/jpg_to_pdf/locales/ko.json';
import jpg2pdfJa from '../../features/tools/jpg_to_pdf/locales/ja.json';
import jpg2pdfAr from '../../features/tools/jpg_to_pdf/locales/ar.json';

//merge//
import mergeEn from '../../features/tools/merge_pdf/locales/en.json';
import mergeIt from '../../features/tools/merge_pdf/locales/it.json';
import mergeCn from '../../features/tools/merge_pdf/locales/cn.json';
import mergeEs from '../../features/tools/merge_pdf/locales/es.json';
import mergeFr from '../../features/tools/merge_pdf/locales/fr.json';
import mergeDe from '../../features/tools/merge_pdf/locales/de.json';
import mergePt from '../../features/tools/merge_pdf/locales/pt.json';
import mergeRu from '../../features/tools/merge_pdf/locales/ru.json';
import mergeKo from '../../features/tools/merge_pdf/locales/ko.json';
import mergeJa from '../../features/tools/merge_pdf/locales/ja.json';
import mergeAr from '../../features/tools/merge_pdf/locales/ar.json';

//organize//
import organizeEn from '../../features/tools/organize_pdf/locales/en.json';
import organizeIt from '../../features/tools/organize_pdf/locales/it.json';
import organizeCn from '../../features/tools/organize_pdf/locales/cn.json';
import organizeEs from '../../features/tools/organize_pdf/locales/es.json';
import organizeFr from '../../features/tools/organize_pdf/locales/fr.json';
import organizeDe from '../../features/tools/organize_pdf/locales/de.json';
import organizePt from '../../features/tools/organize_pdf/locales/pt.json';
import organizeRu from '../../features/tools/organize_pdf/locales/ru.json';
import organizeKo from '../../features/tools/organize_pdf/locales/ko.json';
import organizeJa from '../../features/tools/organize_pdf/locales/ja.json';
import organizeAr from '../../features/tools/organize_pdf/locales/ar.json';

//pageNumbers//
import pageNumbersEn from '../../features/tools/page_numbers/locales/en.json';
import pageNumbersIt from '../../features/tools/page_numbers/locales/it.json';
import pageNumbersCn from '../../features/tools/page_numbers/locales/cn.json';
import pageNumbersEs from '../../features/tools/page_numbers/locales/es.json';
import pageNumbersFr from '../../features/tools/page_numbers/locales/fr.json';
import pageNumbersDe from '../../features/tools/page_numbers/locales/de.json';
import pageNumbersPt from '../../features/tools/page_numbers/locales/pt.json';
import pageNumbersRu from '../../features/tools/page_numbers/locales/ru.json';
import pageNumbersKo from '../../features/tools/page_numbers/locales/ko.json';
import pageNumbersJa from '../../features/tools/page_numbers/locales/ja.json';
import pageNumbersAr from '../../features/tools/page_numbers/locales/ar.json';

//pdf2excel//
import pdf2excelEn from '../../features/tools/pdf_to_excel/locales/en.json';
import pdf2excelIt from '../../features/tools/pdf_to_excel/locales/it.json';
import pdf2excelCn from '../../features/tools/pdf_to_excel/locales/cn.json';
import pdf2excelEs from '../../features/tools/pdf_to_excel/locales/es.json';
import pdf2excelFr from '../../features/tools/pdf_to_excel/locales/fr.json';
import pdf2excelDe from '../../features/tools/pdf_to_excel/locales/de.json';
import pdf2excelPt from '../../features/tools/pdf_to_excel/locales/pt.json';
import pdf2excelRu from '../../features/tools/pdf_to_excel/locales/ru.json';
import pdf2excelKo from '../../features/tools/pdf_to_excel/locales/ko.json';
import pdf2excelJa from '../../features/tools/pdf_to_excel/locales/ja.json';
import pdf2excelAr from '../../features/tools/pdf_to_excel/locales/ar.json';

//pdf2jpg//
import pdf2jpgEn from '../../features/tools/pdf_to_jpg/locales/en.json';
import pdf2jpgIt from '../../features/tools/pdf_to_jpg/locales/it.json';
import pdf2jpgCn from '../../features/tools/pdf_to_jpg/locales/cn.json';
import pdf2jpgEs from '../../features/tools/pdf_to_jpg/locales/es.json';
import pdf2jpgFr from '../../features/tools/pdf_to_jpg/locales/fr.json';
import pdf2jpgDe from '../../features/tools/pdf_to_jpg/locales/de.json';
import pdf2jpgPt from '../../features/tools/pdf_to_jpg/locales/pt.json';
import pdf2jpgRu from '../../features/tools/pdf_to_jpg/locales/ru.json';
import pdf2jpgKo from '../../features/tools/pdf_to_jpg/locales/ko.json';
import pdf2jpgJa from '../../features/tools/pdf_to_jpg/locales/ja.json';
import pdf2jpgAr from '../../features/tools/pdf_to_jpg/locales/ar.json';

//pdf2pdfA//
import pdf2pdfAEn from '../../features/tools/pdf_to_pdfA/locales/en.json';
import pdf2pdfAIt from '../../features/tools/pdf_to_pdfA/locales/it.json';
import pdf2pdfACn from '../../features/tools/pdf_to_pdfA/locales/cn.json';
import pdf2pdfAEs from '../../features/tools/pdf_to_pdfA/locales/es.json';
import pdf2pdfAFr from '../../features/tools/pdf_to_pdfA/locales/fr.json';
import pdf2pdfADe from '../../features/tools/pdf_to_pdfA/locales/de.json';
import pdf2pdfAPt from '../../features/tools/pdf_to_pdfA/locales/pt.json';
import pdf2pdfARu from '../../features/tools/pdf_to_pdfA/locales/ru.json';
import pdf2pdfAKo from '../../features/tools/pdf_to_pdfA/locales/ko.json';
import pdf2pdfAJa from '../../features/tools/pdf_to_pdfA/locales/ja.json';
import pdf2pdfAAr from '../../features/tools/pdf_to_pdfA/locales/ar.json';

//pdf2ppt//
import pdf2pptEn from '../../features/tools/pdf_to_ppt/locales/en.json';
import pdf2pptIt from '../../features/tools/pdf_to_ppt/locales/it.json';
import pdf2pptCn from '../../features/tools/pdf_to_ppt/locales/cn.json';
import pdf2pptEs from '../../features/tools/pdf_to_ppt/locales/es.json';
import pdf2pptFr from '../../features/tools/pdf_to_ppt/locales/fr.json';
import pdf2pptDe from '../../features/tools/pdf_to_ppt/locales/de.json';
import pdf2pptPt from '../../features/tools/pdf_to_ppt/locales/pt.json';
import pdf2pptRu from '../../features/tools/pdf_to_ppt/locales/ru.json';
import pdf2pptKo from '../../features/tools/pdf_to_ppt/locales/ko.json';
import pdf2pptJa from '../../features/tools/pdf_to_ppt/locales/ja.json';
import pdf2pptAr from '../../features/tools/pdf_to_ppt/locales/ar.json';

//pdf2word//
import pdf2wordEn from '../../features/tools/pdf_to_word/locales/en.json';
import pdf2wordIt from '../../features/tools/pdf_to_word/locales/it.json';
import pdf2wordCn from '../../features/tools/pdf_to_word/locales/cn.json';
import pdf2wordEs from '../../features/tools/pdf_to_word/locales/es.json';
import pdf2wordFr from '../../features/tools/pdf_to_word/locales/fr.json';
import pdf2wordDe from '../../features/tools/pdf_to_word/locales/de.json';
import pdf2wordPt from '../../features/tools/pdf_to_word/locales/pt.json';
import pdf2wordRu from '../../features/tools/pdf_to_word/locales/ru.json';
import pdf2wordKo from '../../features/tools/pdf_to_word/locales/ko.json';
import pdf2wordJa from '../../features/tools/pdf_to_word/locales/ja.json';
import pdf2wordAr from '../../features/tools/pdf_to_word/locales/ar.json';

//ppt2pdf//
import ppt2pdfEn from '../../features/tools/ppt_to_pdf/locales/en.json';
import ppt2pdfIt from '../../features/tools/ppt_to_pdf/locales/it.json';
import ppt2pdfCn from '../../features/tools/ppt_to_pdf/locales/cn.json';
import ppt2pdfEs from '../../features/tools/ppt_to_pdf/locales/es.json';
import ppt2pdfFr from '../../features/tools/ppt_to_pdf/locales/fr.json';
import ppt2pdfDe from '../../features/tools/ppt_to_pdf/locales/de.json';
import ppt2pdfPt from '../../features/tools/ppt_to_pdf/locales/pt.json';
import ppt2pdfRu from '../../features/tools/ppt_to_pdf/locales/ru.json';
import ppt2pdfKo from '../../features/tools/ppt_to_pdf/locales/ko.json';
import ppt2pdfJa from '../../features/tools/ppt_to_pdf/locales/ja.json';
import ppt2pdfAr from '../../features/tools/ppt_to_pdf/locales/ar.json';

//protect//
import protectEn from '../../features/tools/protect_pdf/locales/en.json';
import protectIt from '../../features/tools/protect_pdf/locales/it.json';
import protectCn from '../../features/tools/protect_pdf/locales/cn.json';
import protectEs from '../../features/tools/protect_pdf/locales/es.json';
import protectFr from '../../features/tools/protect_pdf/locales/fr.json';
import protectDe from '../../features/tools/protect_pdf/locales/de.json';
import protectPt from '../../features/tools/protect_pdf/locales/pt.json';
import protectRu from '../../features/tools/protect_pdf/locales/ru.json';
import protectKo from '../../features/tools/protect_pdf/locales/ko.json';
import protectJa from '../../features/tools/protect_pdf/locales/ja.json';
import protectAr from '../../features/tools/protect_pdf/locales/ar.json';

//repair//
import repairEn from '../../features/tools/repair_pdf/locales/en.json';
import repairIt from '../../features/tools/repair_pdf/locales/it.json';
import repairCn from '../../features/tools/repair_pdf/locales/cn.json';
import repairEs from '../../features/tools/repair_pdf/locales/es.json';
import repairFr from '../../features/tools/repair_pdf/locales/fr.json';
import repairDe from '../../features/tools/repair_pdf/locales/de.json';
import repairPt from '../../features/tools/repair_pdf/locales/pt.json';
import repairRu from '../../features/tools/repair_pdf/locales/ru.json';
import repairKo from '../../features/tools/repair_pdf/locales/ko.json';
import repairJa from '../../features/tools/repair_pdf/locales/ja.json';
import repairAr from '../../features/tools/repair_pdf/locales/ar.json';

//rotate//
import rotateEn from '../../features/tools/rotate_pdf/locales/en.json';
import rotateIt from '../../features/tools/rotate_pdf/locales/it.json';
import rotateCn from '../../features/tools/rotate_pdf/locales/cn.json';
import rotateEs from '../../features/tools/rotate_pdf/locales/es.json';
import rotateFr from '../../features/tools/rotate_pdf/locales/fr.json';
import rotateDe from '../../features/tools/rotate_pdf/locales/de.json';
import rotatePt from '../../features/tools/rotate_pdf/locales/pt.json';
import rotateRu from '../../features/tools/rotate_pdf/locales/ru.json';
import rotateKo from '../../features/tools/rotate_pdf/locales/ko.json';
import rotateJa from '../../features/tools/rotate_pdf/locales/ja.json';
import rotateAr from '../../features/tools/rotate_pdf/locales/ar.json';

//sign//
import signEn from '../../features/tools/sign_pdf/locales/en.json';
import signIt from '../../features/tools/sign_pdf/locales/it.json';
import signCn from '../../features/tools/sign_pdf/locales/cn.json';
import signEs from '../../features/tools/sign_pdf/locales/es.json';
import signFr from '../../features/tools/sign_pdf/locales/fr.json';
import signDe from '../../features/tools/sign_pdf/locales/de.json';
import signPt from '../../features/tools/sign_pdf/locales/pt.json';
import signRu from '../../features/tools/sign_pdf/locales/ru.json';
import signKo from '../../features/tools/sign_pdf/locales/ko.json';
import signJa from '../../features/tools/sign_pdf/locales/ja.json';
import signAr from '../../features/tools/sign_pdf/locales/ar.json';

//split//
import splitEn from '../../features/tools/split_pdf/locales/en.json';
import splitIt from '../../features/tools/split_pdf/locales/it.json';
import splitCn from '../../features/tools/split_pdf/locales/cn.json';
import splitEs from '../../features/tools/split_pdf/locales/es.json';
import splitFr from '../../features/tools/split_pdf/locales/fr.json';
import splitDe from '../../features/tools/split_pdf/locales/de.json';
import splitPt from '../../features/tools/split_pdf/locales/pt.json';
import splitRu from '../../features/tools/split_pdf/locales/ru.json';
import splitKo from '../../features/tools/split_pdf/locales/ko.json';
import splitJa from '../../features/tools/split_pdf/locales/ja.json';
import splitAr from '../../features/tools/split_pdf/locales/ar.json';

//unlock//
import unlockEn from '../../features/tools/unlock_pdf/locales/en.json';
import unlockIt from '../../features/tools/unlock_pdf/locales/it.json';
import unlockCn from '../../features/tools/unlock_pdf/locales/cn.json';
import unlockEs from '../../features/tools/unlock_pdf/locales/es.json';
import unlockFr from '../../features/tools/unlock_pdf/locales/fr.json';
import unlockDe from '../../features/tools/unlock_pdf/locales/de.json';
import unlockPt from '../../features/tools/unlock_pdf/locales/pt.json';
import unlockRu from '../../features/tools/unlock_pdf/locales/ru.json';
import unlockKo from '../../features/tools/unlock_pdf/locales/ko.json';
import unlockJa from '../../features/tools/unlock_pdf/locales/ja.json';
import unlockAr from '../../features/tools/unlock_pdf/locales/ar.json';

//watermark//
import watermarkEn from '../../features/tools/watermark/locales/en.json';
import watermarkIt from '../../features/tools/watermark/locales/it.json';
import watermarkCn from '../../features/tools/watermark/locales/cn.json';
import watermarkEs from '../../features/tools/watermark/locales/es.json';
import watermarkFr from '../../features/tools/watermark/locales/fr.json';
import watermarkDe from '../../features/tools/watermark/locales/de.json';
import watermarkPt from '../../features/tools/watermark/locales/pt.json';
import watermarkRu from '../../features/tools/watermark/locales/ru.json';
import watermarkKo from '../../features/tools/watermark/locales/ko.json';
import watermarkJa from '../../features/tools/watermark/locales/ja.json';
import watermarkAr from '../../features/tools/watermark/locales/ar.json';

//word2pdf//
import word2pdfEn from '../../features/tools/word_to_pdf/locales/en.json';
import word2pdfIt from '../../features/tools/word_to_pdf/locales/it.json';
import word2pdfCn from '../../features/tools/word_to_pdf/locales/cn.json';
import word2pdfEs from '../../features/tools/word_to_pdf/locales/es.json';
import word2pdfFr from '../../features/tools/word_to_pdf/locales/fr.json';
import word2pdfDe from '../../features/tools/word_to_pdf/locales/de.json';
import word2pdfPt from '../../features/tools/word_to_pdf/locales/pt.json';
import word2pdfRu from '../../features/tools/word_to_pdf/locales/ru.json';
import word2pdfKo from '../../features/tools/word_to_pdf/locales/ko.json';
import word2pdfJa from '../../features/tools/word_to_pdf/locales/ja.json';
import word2pdfAr from '../../features/tools/word_to_pdf/locales/ar.json';

//auth//
import authEn from '../../features/auth/locales/en.json';
import authIt from '../../features/auth/locales/it.json';
import authCn from '../../features/auth/locales/cn.json';
import authEs from '../../features/auth/locales/es.json';
import authFr from '../../features/auth/locales/fr.json';
import authDe from '../../features/auth/locales/de.json';
import authPt from '../../features/auth/locales/pt.json';
import authRu from '../../features/auth/locales/ru.json';
import authKo from '../../features/auth/locales/ko.json';
import authJa from '../../features/auth/locales/ja.json';
import authAr from '../../features/auth/locales/ar.json';


/*************English - en**************/
en.home = homeEn;
en.popover = popoverEn;
en.tools = toolsEn;
en.tools.compress = compressEn;
en.tools.edit = editEn;
en.tools.excel2pdf = excel2pdfEn;
en.tools.html2pdf = html2pdfEn;
en.tools.jpg2pdf = jpg2pdfEn;
en.tools.merge = mergeEn;
en.tools.organize = organizeEn;
en.tools.pageNumbers = pageNumbersEn;
en.tools.pdf2excel = pdf2excelEn;
en.tools.pdf2jpg = pdf2jpgEn;
en.tools.pdf2pdfA = pdf2pdfAEn;
en.tools.pdf2ppt = pdf2pptEn;
en.tools.pdf2word = pdf2wordEn;
en.tools.ppt2pdf = ppt2pdfEn;
en.tools.protect = protectEn;
en.tools.repair = repairEn;
en.tools.rotate = rotateEn;
en.tools.sign = signEn;
en.tools.split = splitEn;
en.tools.unlock = unlockEn;
en.tools.watermark = watermarkEn;
en.tools.word2pdf = word2pdfEn;
en.auth = authEn;

/***********Italian - it****************/
it.home = homeIt;
it.popover = popoverIt;
it.tools = toolsIt;
it.tools.compress = compressIt;
it.tools.edit = editIt;
it.tools.excel2pdf = excel2pdfIt;
it.tools.html2pdf = html2pdfIt;
it.tools.jpg2pdf = jpg2pdfIt;
it.tools.merge = mergeIt;
it.tools.organize = organizeIt;
it.tools.pageNumbers = pageNumbersIt;
it.tools.pdf2excel = pdf2excelIt;
it.tools.pdf2jpg = pdf2jpgIt;
it.tools.pdf2pdfA = pdf2pdfAIt;
it.tools.pdf2ppt = pdf2pptIt;
it.tools.pdf2word = pdf2wordIt;
it.tools.ppt2pdf = ppt2pdfIt;
it.tools.protect = protectIt;
it.tools.repair = repairIt;
it.tools.rotate = rotateIt;
it.tools.sign = signIt;
it.tools.split = splitIt;
it.tools.unlock = unlockIt;
it.tools.watermark = watermarkIt;
it.tools.word2pdf = word2pdfIt;
it.auth = authIt;

/************Chinese - cn*************/
cn.home = homeCn;
cn.popover = popoverCn;
cn.tools = toolsCn;
cn.tools.compress = compressCn;
cn.tools.edit = editCn;
cn.tools.excel2pdf = excel2pdfCn;
cn.tools.html2pdf = html2pdfCn;
cn.tools.jpg2pdf = jpg2pdfCn;
cn.tools.merge = mergeCn;
cn.tools.organize = organizeCn;
cn.tools.pageNumbers = pageNumbersCn;
cn.tools.pdf2excel = pdf2excelCn;
cn.tools.pdf2jpg = pdf2jpgCn;
cn.tools.pdf2pdfA = pdf2pdfACn;
cn.tools.pdf2ppt = pdf2pptCn;
cn.tools.pdf2word = pdf2wordCn;
cn.tools.ppt2pdf = ppt2pdfCn;
cn.tools.protect = protectCn;
cn.tools.repair = repairCn;
cn.tools.rotate = rotateCn;
cn.tools.sign = signCn;
cn.tools.split = splitCn;
cn.tools.unlock = unlockCn;
cn.tools.watermark = watermarkCn;
cn.tools.word2pdf = word2pdfCn;
cn.auth = authCn;

/**************Espain - es*************/
es.home = homeEs;
es.popover = popoverEs;
es.tools = toolsEs;
es.tools.compress = compressEs;
es.tools.edit = editEs;
es.tools.excel2pdf = excel2pdfEs;
es.tools.html2pdf = html2pdfEs;
es.tools.jpg2pdf = jpg2pdfEs;
es.tools.merge = mergeEs;
es.tools.organize = organizeEs;
es.tools.pageNumbers = pageNumbersEs;
es.tools.pdf2excel = pdf2excelEs;
es.tools.pdf2jpg = pdf2jpgEs;
es.tools.pdf2pdfA = pdf2pdfAEs;
es.tools.pdf2ppt = pdf2pptEs;
es.tools.pdf2word = pdf2wordEs;
es.tools.ppt2pdf = ppt2pdfEs;
es.tools.protect = protectEs;
es.tools.repair = repairEs;
es.tools.rotate = rotateEs;
es.tools.sign = signEs;
es.tools.split = splitEs;
es.tools.unlock = unlockEs;
es.tools.watermark = watermarkEs;
es.tools.word2pdf = word2pdfEs;
es.auth = authEs;

/**************French - fr**************/
fr.home = homeFr;
fr.popover = popoverFr;
fr.tools = toolsFr;
fr.tools.compress = compressFr;
fr.tools.edit = editFr;
fr.tools.excel2pdf = excel2pdfFr;
fr.tools.html2pdf = html2pdfFr;
fr.tools.jpg2pdf = jpg2pdfFr;
fr.tools.merge = mergeFr;
fr.tools.organize = organizeFr;
fr.tools.pageNumbers = pageNumbersFr;
fr.tools.pdf2excel = pdf2excelFr;
fr.tools.pdf2jpg = pdf2jpgFr;
fr.tools.pdf2pdfA = pdf2pdfAFr;
fr.tools.pdf2ppt = pdf2pptFr;
fr.tools.pdf2word = pdf2wordFr;
fr.tools.ppt2pdf = ppt2pdfFr;
fr.tools.protect = protectFr;
fr.tools.repair = repairFr;
fr.tools.rotate = rotateFr;
fr.tools.sign = signFr;
fr.tools.split = splitFr;
fr.tools.unlock = unlockFr;
fr.tools.watermark = watermarkFr;
fr.tools.word2pdf = word2pdfFr;
fr.auth = authFr;

/******************Deutsh - De***********/
de.home = homeDe;
de.popover = popoverDe;
de.tools = toolsDe;
de.tools.compress = compressDe;
de.tools.edit = editDe;
de.tools.excel2pdf = excel2pdfDe;
de.tools.html2pdf = html2pdfDe;
de.tools.jpg2pdf = jpg2pdfDe;
de.tools.merge = mergeDe;
de.tools.organize = organizeDe;
de.tools.pageNumbers = pageNumbersDe;
de.tools.pdf2excel = pdf2excelDe;
de.tools.pdf2jpg = pdf2jpgDe;
de.tools.pdf2pdfA = pdf2pdfADe;
de.tools.pdf2ppt = pdf2pptDe;
de.tools.pdf2word = pdf2wordDe;
de.tools.ppt2pdf = ppt2pdfDe;
de.tools.protect = protectDe;
de.tools.repair = repairDe;
de.tools.rotate = rotateDe;
de.tools.sign = signDe;
de.tools.split = splitDe;
de.tools.unlock = unlockDe;
de.tools.watermark = watermarkDe;
de.tools.word2pdf = word2pdfDe;
de.auth = authDe;

/************Portgues - pt***************/
pt.home = homePt;
pt.popover = popoverPt;
pt.tools = toolsPt;
pt.tools.compress = compressPt;
pt.tools.edit = editPt;
pt.tools.excel2pdf = excel2pdfPt;
pt.tools.html2pdf = html2pdfPt;
pt.tools.jpg2pdf = jpg2pdfPt;
pt.tools.merge = mergePt;
pt.tools.organize = organizePt;
pt.tools.pageNumbers = pageNumbersPt;
pt.tools.pdf2excel = pdf2excelPt;
pt.tools.pdf2jpg = pdf2jpgPt;
pt.tools.pdf2pdfA = pdf2pdfAPt;
pt.tools.pdf2ppt = pdf2pptPt;
pt.tools.pdf2word = pdf2wordPt;
pt.tools.ppt2pdf = ppt2pdfPt;
pt.tools.protect = protectPt;
pt.tools.repair = repairPt;
pt.tools.rotate = rotatePt;
pt.tools.sign = signPt;
pt.tools.split = splitPt;
pt.tools.unlock = unlockPt;
pt.tools.watermark = watermarkPt;
pt.tools.word2pdf = word2pdfPt;
pt.auth = authPt;

/************Russian - ru****************/
ru.home = homeRu;
ru.popover = popoverRu;
ru.tools = toolsRu;
ru.tools.compress = compressRu;
ru.tools.edit = editRu;
ru.tools.excel2pdf = excel2pdfRu;
ru.tools.html2pdf = html2pdfRu;
ru.tools.jpg2pdf = jpg2pdfRu;
ru.tools.merge = mergeRu;
ru.tools.organize = organizeRu;
ru.tools.pageNumbers = pageNumbersRu;
ru.tools.pdf2excel = pdf2excelRu;
ru.tools.pdf2jpg = pdf2jpgRu;
ru.tools.pdf2pdfA = pdf2pdfARu;
ru.tools.pdf2ppt = pdf2pptRu;
ru.tools.pdf2word = pdf2wordRu;
ru.tools.ppt2pdf = ppt2pdfRu;
ru.tools.protect = protectRu;
ru.tools.repair = repairRu;
ru.tools.rotate = rotateRu;
ru.tools.sign = signRu;
ru.tools.split = splitRu;
ru.tools.unlock = unlockRu;
ru.tools.watermark = watermarkRu;
ru.tools.word2pdf = word2pdfRu;
ru.auth = authRu;

/************Korean - ko******************/
ko.home = homeKo;
ko.popover = popoverKo;
ko.tools = toolsKo;
ko.tools.compress = compressKo;
ko.tools.edit = editKo;
ko.tools.excel2pdf = excel2pdfKo;
ko.tools.html2pdf = html2pdfKo;
ko.tools.jpg2pdf = jpg2pdfKo;
ko.tools.merge = mergeKo;
ko.tools.organize = organizeKo;
ko.tools.pageNumbers = pageNumbersKo;
ko.tools.pdf2excel = pdf2excelKo;
ko.tools.pdf2jpg = pdf2jpgKo;
ko.tools.pdf2pdfA = pdf2pdfAKo;
ko.tools.pdf2ppt = pdf2pptKo;
ko.tools.pdf2word = pdf2wordKo;
ko.tools.ppt2pdf = ppt2pdfKo;
ko.tools.protect = protectKo;
ko.tools.repair = repairKo;
ko.tools.rotate = rotateKo;
ko.tools.sign = signKo;
ko.tools.split = splitKo;
ko.tools.unlock = unlockKo;
ko.tools.watermark = watermarkKo;
ko.tools.word2pdf = word2pdfKo;
ko.auth = authKo;
/**************Japanese - ja************/
ja.home = homeJa;
ja.popover = popoverJa;
ja.tools = toolsJa;
ja.tools.compress = compressJa;
ja.tools.edit = editJa;
ja.tools.excel2pdf = excel2pdfJa;
ja.tools.html2pdf = html2pdfJa;
ja.tools.jpg2pdf = jpg2pdfJa;
ja.tools.merge = mergeJa;
ja.tools.organize = organizeJa;
ja.tools.pageNumbers = pageNumbersJa;
ja.tools.pdf2excel = pdf2excelJa;
ja.tools.pdf2jpg = pdf2jpgJa;
ja.tools.pdf2pdfA = pdf2pdfAJa;
ja.tools.pdf2ppt = pdf2pptJa;
ja.tools.pdf2word = pdf2wordJa;
ja.tools.ppt2pdf = ppt2pdfJa;
ja.tools.protect = protectJa;
ja.tools.repair = repairJa;
ja.tools.rotate = rotateJa;
ja.tools.sign = signJa;
ja.tools.split = splitJa;
ja.tools.unlock = unlockJa;
ja.tools.watermark = watermarkJa;
ja.tools.word2pdf = word2pdfJa;
ja.auth = authJa;

/**************Arabic - ar*************/
ar.home = homeAr;
ar.popover = popoverAr;
ar.tools = toolsAr;
ar.tools.compress = compressAr;
ar.tools.edit = editAr;
ar.tools.excel2pdf = excel2pdfAr;
ar.tools.html2pdf = html2pdfAr;
ar.tools.jpg2pdf = jpg2pdfAr;
ar.tools.merge = mergeAr;
ar.tools.organize = organizeAr;
ar.tools.pageNumbers = pageNumbersAr;
ar.tools.pdf2excel = pdf2excelAr;
ar.tools.pdf2jpg = pdf2jpgAr;
ar.tools.pdf2pdfA = pdf2pdfAAr;
ar.tools.pdf2ppt = pdf2pptAr;
ar.tools.pdf2word = pdf2wordAr;
ar.tools.ppt2pdf = ppt2pdfAr;
ar.tools.protect = protectAr;
ar.tools.repair = repairAr;
ar.tools.rotate = rotateAr;
ar.tools.sign = signAr;
ar.tools.split = splitAr;
ar.tools.unlock = unlockAr;
ar.tools.watermark = watermarkAr;
ar.tools.word2pdf = word2pdfAr;
ar.auth = authAr;



//=======================//
//=========EXPORT========//
//=======================//
export {
    en,
    it,
	cn,
    es,
    fr,
    de,
    pt,
    ru,
    ko,
    ja,
    ar,
};