import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelIndex,  setRemoveOption} from './pageNumbersSlice';
import {  Select, Tooltip, Button } from 'antd';
import { ImBin } from 'react-icons/im';
import { useTranslation } from 'react-i18next';

const {Option} = Select;

const SelectFileCombo = ({filelist, selected}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  
  const onChangeCombo = (value) => {
    dispatch(setSelIndex(value));
  //  console.log("selected item: ", value);
  }
  
  const onRemoveClick = () => {
    dispatch(setRemoveOption());
  }
  var text = selected;
  if (selected === -1)
    text = t('tools.pageNumbers.Nodata');
  ///////////////////rendering//////////////// 
  return (
      <div 
        className="pdf-page-add-element" 
        style={{left:'38%'}}
      >
        <Tooltip title={t('tools.pageNumbers.pagenumber')}>
          <Select 
          id="file"
          value={text}          
          style={{ width: 150, marginRight:'20px' }} onChange={onChangeCombo}>
            {filelist.map(province => (
              <Option value={province.id} key={'selfile_' + province.id}>{province.name}</Option>
            ))}
          </Select>
        </Tooltip>
        <Tooltip>
          
        </Tooltip>
        <Tooltip title={t('tools.pageNumbers.remove')}>
          <Button icon={<ImBin />} style={{height:'30px'}} onClick={onRemoveClick}></Button>
        </Tooltip>
      </div>
  );
}

export default SelectFileCombo;