import React  from 'react';

import { Col, Row } from 'antd';

import faq1_svg from '../../assets/svg/footers/faq-1.svg';
import faq2_svg from '../../assets/svg/footers/faq-2.svg';
import faq3_svg from '../../assets/svg/footers/faq-3.svg';
import faq4_svg from '../../assets/svg/footers/faq-4.svg';
import faq5_svg from '../../assets/svg/footers/faq-5.svg';
import faq6_svg from '../../assets/svg/footers/faq-6.svg';

//lang test//
import { useTranslation } from 'react-i18next';


///
const Faq = () => {
	const {t} = useTranslation();

	return (
		<div className="footer-faq">
			<h1 style={{color:'#404040', fontWeight:'600', fontSize:38, maxWidth:'60%', margin:'0 auto'}}>
				{t('home.faq.title')}
			</h1>
			<h4 style={{color:'#404040', fontWeight:'450', fontSize:22, marginTop:20}}>
				{t('home.faq.desc')}
			</h4>

			{/*-------------------------------*/}
			<div>
				<Row justify="space-around" align="top" className="faq__group">
					<Col span={10}>
						<Row justify="space-around" align="top">
							<Col span={2}>
								<div className="faq__item__icon">
									<img src={faq1_svg} alt="feauters" style={{marginTop:8}}/>
			                    </div>
							</Col>
							<Col span={21}>
								<div className="faq__item__text">
									<h2>{t('home.faq.q1')}</h2>
									<p>
										<b>{t('home.faq.ans1txt1')}</b> {t('home.faq.ans1txt2')}
									</p>
								</div>
							</Col>							
						</Row>
					</Col>
					
					<Col span={10}>
						<Row justify="space-around" align="top">
							<Col span={2}>
								<div className="faq__item__icon">
									<img src={faq2_svg} alt="feauters" style={{marginTop:8}}/>
			                    </div>
							</Col>
							<Col span={21}>
								<div className="faq__item__text">
									<h2>{t('home.faq.q2')}</h2>
									<p>
										<b>{t('home.faq.ans2txt1')}</b>  {t('home.faq.ans2txt2')}
									</p>
								</div>
							</Col>							
						</Row>
					</Col>
				</Row>		
			</div>

			{/*-------------------------------*/}
			<div>
				<Row justify="space-around" align="top" className="faq__group">
					<Col span={10}>
						<Row justify="space-around" align="top">
							<Col span={2}>
								<div className="faq__item__icon">
									<img src={faq3_svg} alt="feauters" style={{marginTop:11}}/>
			                    </div>
							</Col>
							<Col span={21}>
								<div className="faq__item__text">
									<h2>{t('home.faq.q3')}</h2>
									<p>
										<b>{t('home.faq.ans3txt1')}</b> {t('home.faq.ans3txt2')}
									</p>
								</div>
							</Col>							
						</Row>
					</Col>
					
					<Col span={10}>
						<Row justify="space-around" align="top">
							<Col span={2}>
								<div className="faq__item__icon">
									<img src={faq4_svg} alt="feauters" style={{marginTop:11}}/>
			                    </div>
							</Col>
							<Col span={21}>
								<div className="faq__item__text">
									<h2>{t('home.faq.q4')}</h2>
									<p>
										{t('home.faq.ans4txt1')}
									</p>
								</div>
							</Col>							
						</Row>
					</Col>
				</Row>		
			</div>


			{/*-------------------------------*/}
			<div>
				<Row justify="space-around" align="top" className="faq__group">
					<Col span={10}>
						<Row justify="space-around" align="top">
							<Col span={2}>
								<div className="faq__item__icon">
									<img src={faq5_svg} alt="feauters" style={{marginTop:11}}/>
			                    </div>
							</Col>
							<Col span={21}>
								<div className="faq__item__text">
									<h2>{t('home.faq.q5')}</h2>
									<p>
										{t('home.faq.ans5txt1')}
									</p>
								</div>
							</Col>							
						</Row>
					</Col>
					
					<Col span={10}>
						<Row justify="space-around" align="top">
							<Col span={2}>
								<div className="faq__item__icon">
									<img src={faq6_svg} alt="feauters" style={{marginTop:11}}/>
			                    </div>
							</Col>
							<Col span={21}>
								<div className="faq__item__text">
									<h2>{t('home.faq.q6')}</h2>
									<p>
										{t('home.faq.ans6txt1')}
									</p>
								</div>
							</Col>							
						</Row>
					</Col>
				</Row>		
			</div>
		</div>
	);
}

export default Faq;