import React, { useState } from 'react';
//import SelectHtml from './SelectHtml';
import WorkSpaceSign from './WorkSpaceSign';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { useSelector, useDispatch } from 'react-redux';
import SelectFiles from '../SelectFiles';
import { toolsListInfo } from '../../../utils/toolsInfo';
import { OnlyMe } from './SelectModal';
import { addImageList, setFile, setOnlyMe } from './signSlice';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';

var links = [];
var imagecnt = 0;
const SignPDF = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    
    const file = useSelector((state: RootState) => state.signOption.file);
    const max_page = useSelector((state: RootState) => state.signOption.max_page);
    const added_elements = useSelector((state: RootState) => state.signOption.added_elements);
    //const onlyme = useSelector((state: RootState) => state.signOption.onlyme);
    const layer = useSelector((state: RootState) => state.signOption.layer);
    const imagelist = useSelector((state: RootState) => state.signOption.imagelist);
    const page_rect = useSelector((state: RootState) => state.signOption.page_rect);

    //console.log("layer: ", layer);
    //
    const [openfile, setOpenFile] = useState(null);
    const [proc, setProc] = useState(0);
    const id = 21;

    /////////////////////////////////
    const saveAsPNG = async (element) => {
        var selector = "#" + element.id + " div:nth-child(1)";
        //console.log('Capture', selector)
        var dom_div = document.querySelector(selector);
        

        await html2canvas(dom_div, {backgroundColor:null}).then(canvas => {
            //dom_div.style.transform = oldTransform;
            const imgUrl = canvas.toDataURL("image/png");

            //get blob from url//
            const blob = dataURItoBlob(imgUrl);
            //console.log('blob',blob);
            const file1 = new File([blob], element.id + ".png");
            //console.log('img file', file);
            dispatch(addImageList({file: file1}));
         
        });
    };

    const saveUrlasPNG = async (url, id) => {
        const blob = dataURItoBlob(url);
        //console.log('blob',blob);
        const file1 = new File([blob], id + ".png");
        //console.log('img file', file1);
        dispatch(addImageList({file: file1}));
    }

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {type:mimeString});
        return blob;
    }
    function isInclude (img, id) {
        var bexist = false;
        for (var i = 0; i < img.length; i ++)
            if (img[i] === id)
            {
                bexist = true;
                break;
            }    
        return bexist;
    }
    const getAddedElementsByImagelist = () => {
        //console.log("getAddedElementsByImagelist entered");
        imagecnt = 0;
        var imgsign = [];
        var imginit = [];
        var imgfile = [];
        var txt = [];
        for (let i = 0; i <= max_page; i++) {
            for (let j = 0; j < added_elements[i].length; j++) {
                if(added_elements[i][j].remove === 1)	continue;
                if(added_elements[i][j].type === 'img'){
                    if (added_elements[i][j].id.search("sign_img") !== -1)
                    {
                        if (isInclude(imgsign, added_elements[i][j].id))
                            continue;
                        imgsign.push(added_elements[i][j].id);
                        imagecnt ++;
                        saveAsPNG(added_elements[i][j]);
                    }    
                    else if (added_elements[i][j].id.search("init_img") !== -1)
                    {
                        if (isInclude(imginit, added_elements[i][j].id))
                            continue;
                        imgsign.push(added_elements[i][j].id);
                        imagecnt ++;
                        saveAsPNG(added_elements[i][j]);
                    }    
                    else
                    {
                        if (isInclude(imgfile, added_elements[i][j].id))
                            continue;
                        imgfile.push(added_elements[i][j].id);
                        imagecnt ++;
                        saveUrlasPNG(added_elements[i][j].imgfile, added_elements[i][j].id);
                    }    
                } 
                else if(added_elements[i][j].type === 'text')//saveAsPNG  && (added_elements[i][j].id.search("text_img") !== -1)
                {
                    if (isInclude(txt, added_elements[i][j].id))
                            continue;
                    txt.push(added_elements[i][j].id);
                    imagecnt ++;
                    saveAsPNG(added_elements[i][j]);
                }
            }
        }
        //console.log('image count: ', imagecnt);
    }

    const goProcFunction = (files) => {        
        setOpenFile(files);
        
        dispatch(setFile(files));
        var onlyme = {
            signName: '',
            initName: '',
        
            signSel: "1",
            signFont: 1,
            signFontColor: '#555555',
            signDrawUrl: '',
            signDrawColor: '#555555',
            signUpload: null,
        
            initSel: "1",
            initFont: 1,
            initFontColor: '#555555',
            initDrawUrl: '',
            initDrawColor: '#555555',
            initUpload: null,
        };
        dispatch(setOnlyMe(onlyme));

        setProc(1);
    }

    const goAction = () => {
        //rotate action//
        //console.log("action click");
        getAddedElementsByImagelist(); 
        setProc(2);        
    }

    
    const goDownload = (downlinks) => {
        //rotate download action//
        links = downlinks;
        setProc(3);
    }
    const onOther = () => {

    }

    
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles id={id} proc={goProcFunction} multiple={false} btn_text={t('tools.sign.SelectPDFFiles')} fileTypes = {["PDF"]}/>
            </div>
        );
    }else if(proc === 1) {//work space <SelectModal filename={openfile.name}/>
        element = (
            <div className="tool">
                <WorkSpaceSign files={openfile} goAction={goAction}/>
                <OnlyMe initvalue={0} filename={openfile.name} other={onOther}/>
            </div>
        );
    } else if (proc === 2 && imagecnt > 0 && imagelist.length === imagecnt) {//action space
        //option payload//
        //console.log("enter send process");
        var _files = [];
        _files.push(file);
        for (var i = 0; i < imagelist.length; i ++)
        {
            _files.push(imagelist[i].file);
            //console.log("image file: ", imagelist[i].file);
        }    

        //console.log("filelist: ", _files);

        element = getElements(added_elements, page_rect);
        //uploading//
        /**
         *  arg1: tool_header = "SIGNPDF"
	        arg2: option = {
				path: ['']
				method: 0 (0 - normal, 1 - digital)
				elements: [		//elements[page][idx]...
					[
						[
							0 or 1(0:etc, 1:sign)
							'', (image path)
							'', (text)
							12, (fontsize)
							'', (fontfamily)	ex. Blackadder ITC Regular
							[r,g,b] (fontcolor)
							[left, top, width, height] (position)
						]
					],
					[]
				]					
			}
         * }    
         */
        
        
        var option = {
            path:[''],
            method: layer,
            elements:element,  
        };
        //console.log("option: ", option);

        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api="sign_pdf" 
                    goDownload={ goDownload } 
                    text={t('tools.sign.SigningPDF')}
                    tool_header="SIGNPDF"
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default SignPDF;

var fontSize = 22;
const getImageInfo = (element, page_rect) => {
    var type = 0;
    if ((element.id.search("_sign") !== -1) && (element.id.search("name") === -1))
        type = 1;
    
    //console.log("type: ", type);
    var ret = {
        type: type,
        imgpath: element.id,
        position:   [
                        getRate(page_rect.width, element.pos.x - page_rect.left),
                        getRate(page_rect.height, element.pos.y),
                        getRate(page_rect.width, element.size.w),
                        getRate(page_rect.height, element.size.h),
                    ],
    }

    return ret;
}

const getElements = (added_elements, page_rect) => {
    var elements = [];
    for (let page = 1; page < added_elements.length; page++) {
        elements.push([]);//page array//
        for (let idx = 0; idx < added_elements[page].length; idx++){
            if(added_elements[page][idx].remove === 1)      continue;

            //----init----//
            const e_page = elements.length - 1;
            elements[e_page].push([]);//idx array//
            const e_idx = elements[e_page].length - 1;

            //----get added element info----//
            var ret = {};
            /*if(added_elements[page][idx].type === 'text'){
                if (added_elements[page][idx].id.search("text_img") === -1){
                    ret = getTextInfo(added_elements[page][idx], page_rect);
                    elements[e_page][e_idx] = [
                        ret.type,
                        '',
                        ret.text,
                        ret.fontsize,
                        ret.fontfamily,
                        ret.fontcolor,
                        ret.position,
                    ];
                }
                else {
                    ret = getImageInfo(added_elements[page][idx], page_rect);
                    elements[e_page][e_idx] = [
                        ret.type,
                        ret.imgpath,
                        '',
                        '',
                        '',
                        '',
                        ret.position,
                    ];
                }
            }  
            else if (added_elements[page][idx].type === 'img')*/
            {
                ret = getImageInfo(added_elements[page][idx], page_rect);
                elements[e_page][e_idx] = [
                    ret.type,
                    ret.imgpath,
                    '',
                    '',
                    '',
                    '',
                    ret.position,
                ];
            }           
        }
    }

    return elements;
}

const getRGB = (_color) => {
    //console.log("input color: ", _color);
    var __color = _color.replace('#', ''); 
    var aRgbHex = __color.match(/.{1,2}/g);
    //console.log("hex color: ", aRgbHex);
    var aRgbNew = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
    //console.log("new color: ", aRgbNew);
    return aRgbNew;
}

const getRate = (total, value) => {
    return value / total;
}
const getTextInfo = (element, page_rect) => {
    var ret = {
        type: 0,
        text: element.text,
        fontsize: fontSize,
        fontfamily: element.font,
        fontcolor: getRGB(element.pen_color),
        position:   [
                        getRate(page_rect.width, element.pos.x - page_rect.left),
                        getRate(page_rect.height, element.pos.y),
                        getRate(page_rect.width, element.size.w),
                        getRate(page_rect.height, element.size.h),
                    ],
    }

    return ret;
}


