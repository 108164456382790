import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RotateOptionState {
  tool_header: string,
  filelist: Array,
  option: Object,
  uploadpath: Array,
}

const initialState: RotateOptionState = {
  tool_header: "REPAIR",
  filelist: [],
  option: {},
}

export const repairSlice = createSlice({
  name: 'repairOption',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setOption: (state, action:PayloadAction<Object>) =>{
      state.option = action.payload;
    },
    setFileOptionList: (state, action:PayloadAction<Array>) => {
      state.filelist = action.payload;
    },
    setRemoveOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].remove = action.payload.remove;
    },
    setRotateOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].rotate = action.payload.rotate;
    },
    setUploadPath: (state, action:PayloadAction<Array>) => {
      state.uploadpath = action.payload;
    },
  
  },
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setOption, 
  setFileOptionList, 
  setRemoveOption, 
  setRotateOption,
  setUploadPath,
  } = repairSlice.actions

export default repairSlice.reducer