import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import PagePreviews from './pageviews/PagePreviews';
import PageEditView from './pageviews/PageEditView';

import { setMaxPage, setPdf, setPlaceToAdd } from './signSlice';// 
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Layout } from 'antd';
import EditToolBar from './EditToolBar';
const { Header, Content, Sider } = Layout;

const WorkAreaSign = () => {
    const dispatch = useDispatch();    
    
    //const file = useSelector((state: RootState) => state.signOption.file);
    const url = useSelector((state: RootState) => state.signOption.url);
    //const pdf = useSelector((state: RootState) => state.signOption.pdf);
    //const max_page = useSelector((state: RootState) => state.signOption.max_page);

    function onDocumentLoadSuccess({ numPages }) {
        //console.log("max page: ", numPages);
        dispatch(setMaxPage(numPages));

        var payload = {
          from: 1,
          to: numPages,
        };
        dispatch(setPlaceToAdd(payload));
    }

    /*const reader = new FileReader();
    reader.onload = () => {
        if(reader.readyState === 2){
           dispatch(setPdf(reader.result));
           localStorage.setItem('pdf', reader.result);
        }
    }
    //console.log("before read, file: ", file);
    reader.readAsDataURL(file);*/
    
    
    ////////rendering////////////
    
    return (        
        <div className="tool__workarea b" style={{padding:0}}>            
              <Layout>
                  <Header className="toolbar_header_sign">
                      <EditToolBar/>
                  </Header>
                  
                  <Layout>
                    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                      <Sider width={140}>
                        <PagePreviews/>
                      </Sider>
                      <Layout style={{ padding: '0 24px 24px' }}>
                        <Content style={{padding: 24, margin: 0, minHeight: 280}}>
                          <PageEditView/>
                        </Content>                        
                      </Layout>
                    </Document>
                  </Layout>

              </Layout>
        </div>
    );
}

export default WorkAreaSign;