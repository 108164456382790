import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeAllElement, unactiveSeletedElement } from './editSlice';

import { Scrollbars } from 'react-custom-scrollbars';

import {  Button, Layout } from 'antd';
import { SendOutlined, SyncOutlined } from '@ant-design/icons';
import AddedElement from './AddedElement';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const max_page = useSelector((state) => state.editOption.max_page);
    const added_elements = useSelector((state) => state.editOption.added_elements);
    
    const onClickAction = () => {
        //selected item none-active//
        dispatch(unactiveSeletedElement());
        setTimeout(() => {onAction();}, 100);//testing
    }

    var addedElementComponent = [];
    for (let i = 0; i < max_page; i++) {
        const page = i + 1;
        addedElementComponent.push(
            <AddedElement page={page} key={'added_' + i.toString()} />
        );
    }

    //get added elements cnt//
    var cnt = 0;
    for (let page = 0; page < added_elements.length; page++) {            
        for (let idx = 0; idx < added_elements[page].length; idx++) {
            if(added_elements[page][idx].remove === 1)   continue;
            cnt++;
        }
    }
    

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>PDF EDIT</Header>

                <Content>
                    <div className="center-tab">
                    <Scrollbars style={{ width: '100%', height: '100%' }}>
                        <div style={{margin: '10px'}}>
                            <div style={{color:'#1890ff', fontSize:'18px', textAlign:'left', marginBottom:15}}>
                                {t('tools.edit.side_added')}
                            </div>
                            <Button 
                                size="small" 
                                icon={<SyncOutlined/>} 
                                style={{float:'right'}} 
                                onClick={() => dispatch(removeAllElement())}>
                                {t('tools.edit.side_rmall')}
                            </Button>
                        </div>

                        <div style={{marginTop:40}}>
                            {addedElementComponent}
                        </div>
                    </Scrollbars>
                    </div>
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt>0?false:true}>
                      {t('tools.edit.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;