import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {  Button, Layout, Image } from 'antd';
import { SendOutlined} from '@ant-design/icons';
import { BiRotateLeft, BiRotateRight } from 'react-icons/bi';
import { IoTabletLandscapeOutline, IoTabletPortraitOutline } from 'react-icons/io5';
import { setRotateOption, setSelectOption,rightRotationOption,
    leftRotationOption,resetAllOption,selectAllOption,selectPortraitOption,selectLandscapeOption} from './rotateSlice';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Sider, Content } = Layout;

const WorkSideRotate = ({onAction}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.rotateOption.filelist);
    const dispatch = useDispatch();
    
    
    const [selmode, setSelMode] = useState(-1);

    var cnt = 0;
    
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) cnt++;
    }

    const onClickAction = () => {
        onAction(filelist);
    }
    const onClickRight = () => {
        dispatch(rightRotationOption());
    }
    const onClickLeft = () => {//
        dispatch(leftRotationOption());
    }
    const onResetAll = () => {
        dispatch(resetAllOption());
    }
    const onSelectAll = () => {
        setSelMode(0);
        dispatch(selectAllOption());
    }
    const onPortrait = () => {
        setSelMode(1);
        dispatch(selectPortraitOption());
    }
    const onLandScape = () => {
        setSelMode(2);
        dispatch(selectLandscapeOption());
    }
    var element = '';
    if (cnt === 1) {
        element = (
        <div>
            <p>
            {t('tools.rotate.Mouseover')}
            <br/>
            {t('tools.rotate.Clickonit')}
            </p>
            <div className="rotate_reset__tag" onClick={onResetAll}>
            {t('tools.rotate.ROTATION')} <Button className="rotate_reset__btn1"> {t('tools.rotate.Resetall')}</Button>
            </div>
        </div>      
        );
    }
     else {//work space
        element = (
            <div>
                <div>
                    <p>
                    {t('tools.rotate.Mouseover')}
                    <br/>
                    {t('tools.rotate.Clickonit')}
                    </p>
                    <div className="rotate_reset__tag" onClick={onResetAll} style={{marginLeft:'-10px'}}>
                    {t('tools.rotate.SELECTFILESTOROTATE')} 
                    <Button className="rotate_reset__btn2" style={{marginLeft:'10px'}}> {t('tools.rotate.Resetall')}</Button>
                    </div>
                </div>  
                <div className="side__btns__container">
                    <div className={selmode === 0 ? "side__btns1_select":"side__btns1"}  onClick={onSelectAll}>
                        <div className="side__btns__items">
                            <div>
                            <IoTabletLandscapeOutline style={{ fontSize: '30px', color: 'white', marginLeft:5, marginRight:0, marginTop:0 }}/>
                            <IoTabletPortraitOutline style={{ fontSize: '30px', color: 'white', marginLeft:-20, marginRight:0, marginTop:-40 }}/>
                            </div>
                            <div className="side__btns__item_text">
                            {t('tools.rotate.All')}
                            </div>
                        </div>
                    </div>
                    <div className={selmode === 1 ? "side__btns1_select":"side__btns1"}  onClick={onPortrait}>
                        <div className="side__btns__items">
                            <div>
                                <IoTabletPortraitOutline style={{ fontSize: '30px', color: 'white', marginLeft:10, marginRight:10 }}/>
                            </div>
                            <div  className="side__btns__item_text">
                            {t('tools.rotate.Portrait')}
                            </div>
                        </div>
                    </div>
                    <div className={selmode === 2 ? "side__btns1_select":"side__btns1"}  onClick={onLandScape}>
                        <div className="side__btns__items">
                            <div>
                                <IoTabletLandscapeOutline style={{ fontSize: '30px', color: 'white', marginLeft:10, marginRight:10 }}/>
                            </div>
                            <div  className="side__btns__item_text">
                            {t('tools.rotate.LandScape')}
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>ROTATE</Header>

                <Content>
                    {element}
                </Content>
                <Content>
                    <div className = "side__btns" style={{minWidth:150}} onClick = {onClickRight}>
                        <BiRotateRight style={{ fontSize: '24px', color: 'white', marginLeft:-10, marginRight:10 }} /> 
                        {t('tools.rotate.Right')}
                    </div>
                </Content>
                <Content>
                    <div className = "side__btns" style={{minWidth:150}}  onClick = {onClickLeft}>
                        <BiRotateLeft style={{ fontSize: '24px', color: 'white', marginLeft:-18, marginRight:10 }} />
                        {t('tools.rotate.Left')}
                    </div>
                </Content>
                
                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction}>
                    {t('tools.rotate.RotatePDF')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSideRotate;