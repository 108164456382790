import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { updateElementText } from '../editSlice';


import { Input } from 'antd';
const { TextArea } = Input;

export const generatePathFromVetices = (vertex_array, pos) => {
	var str = "";
	for(let i = 0; i < vertex_array.length; i++)
	{
		if (i === 0) {
			str= str + "M" + (vertex_array[i].x - pos.realX).toString() + " " + (vertex_array[i].y - pos.realY).toString() + " ";
		} else {
			str= str + "L" + (vertex_array[i].x - pos.realX).toString() + " " + (vertex_array[i].y - pos.realY).toString() + " ";
		}
	}
	return str;
}

//text content//
const ChildTextContent = ({ pagenum, idx, element, rect }) => {
	const dispatch = useDispatch();

    const txtstyle = {
		color: element.pen_color,
		background: element.fill_color, 
		textAlign: element.align, 
		opacity: element.opacity,
		fontFamily: element.font,
		fontSize: element.fontSize,
		fontWeight: element.bold?'700':'400',
		fontStyle: element.italic?'italic': '',
		textDecoration: element.underline?'underline':'',
		zIndex: 1,
		padding: '4px 11px',
		width: rect.width,
		height: rect.height,
		overflowWrap: 'break-word',
    };

    const onChangeText = (e) => {
	    const payload = {
	        page: pagenum,
	        idx: idx,
	        text: e.target.value,              
	    }
	    dispatch(updateElementText(payload));
    }

    if (element.editable === 1) {
      console.log('text editable', element.editable);
      return(
            <TextArea
                value={element.text}
                autoSize={{
                    minRows: 1,
                    maxRows: 100,
                }}
                onChange={onChangeText}
                style={txtstyle}
            />
      );
    } else {
      var txtarray = element.text.split('\n');
      var txt_element = [];
      for(let i = 0; i < txtarray.length; i++){
        txt_element.push(
            <label key={'label' + element.id + i.toString()}>
                {i===0? '':(<br/>)}
                {txtarray[i]}
            </label>
        );
      }

      return(
            <div style={txtstyle}>
                {txt_element}
            </div>
      );
    }
}

///////////img content//////////
const ChildImgContent = ({ pagenum, idx, element, rect }) => {
    return (
    	<img 
            height='100%'
            width='100%' 
            src={URL.createObjectURL(element.imgfile)} 
            draggable={false}
            style={{
                opacity: element.opacity,
                border: element.pen_color
            }}
            alt={element.id}
        />
    );
}

///////////pen content//////////
const ChildPenContent = ({ pagenum, idx, element, rect }) => {
	var path_element = [];
	const pos = {
		x: rect.left,
		y: rect.height,
		realX: element.relative_pos.x,
		realY: element.relative_pos.y
	}
    for (let i = 0; i < element.vertices.length; i++) {
        path_element.push(
            <path className="" fill="none" stroke={element.fill_color} strokeWidth={`${element.pen_size}`}
        		d={generatePathFromVetices(element.vertices[i], pos)} strokeLinecap="round" strokeLinejoin="round" key={'draw_pen_path_' + element.id.toString() + i.toString()}>
            </path>
        );
    }

    return (
    	<svg style={{width: rect.width, height: rect.height}}>
			{path_element}
		</svg>
    );    
}

///////////line content//////////
const ChildLineContent = ({ pagenum, idx, element, rect }) => {
    return (
    	<svg width={rect.width.toString()} height={rect.height.toString()}>
            <line 
				x1="0"
				y1={((rect.height) / 2).toString()}
				x2={(rect.width).toString()}
				y2={((rect.height) / 2).toString()}
				stroke={element.fill_color} 
				strokeWidth={`${element.pen_size}`}
				fillOpacity={element.opacity}
				strokeLinecap="butt">
            </line>
        </svg>
    );
}

///////////rect content//////////
const ChildRectContent = ({ pagenum, idx, element, rect }) => {
    return (
    	<svg style={{width: rect.width, height: rect.height}}>
            <rect 
                x="0"
                y="0"
                width={(rect.width).toString()}
                height={(rect.height).toString()}
                fill={element.fill_color} 
                stroke={element.pen_color} 
                strokeWidth={`${element.pen_size}`}
                fillOpacity={element.opacity}>
            </rect>
        </svg>
    );
}

///////////circle content//////////
const ChildCircleContent = ({ pagenum, idx, element, rect }) => {
    return (
    	<svg style={{width: rect.width, height: rect.height}}>
	        <ellipse 
	          rx={(rect.width / 2 - element.pen_size).toString()}
	          ry={(rect.height / 2 - element.pen_size).toString()}
	          cx={(rect.width / 2).toString()}
	          cy={(rect.height / 2).toString()}
	          fill={element.fill_color} 
	          stroke={element.pen_color} 
	          strokeWidth={`${element.pen_size}`}
	          fillOpacity={element.opacity}>
	        </ellipse>
	    </svg>
    );
}

//////////////////////////////////////
const SubContent = ( { pagenum, idx, element, rect } ) => {
    if(element.remove === 1) 	return '';

    if (element.type === 'text') {
    	return(<ChildTextContent pagenum={pagenum} idx={idx} element={element} rect={rect}/>);
    } else if(element.type === 'img'){
    	return(<ChildImgContent pagenum={pagenum} idx={idx} element={element} rect={rect}/>);
    } else if (element.type === 'pen'){                      
    	return(<ChildPenContent pagenum={pagenum} idx={idx} element={element} rect={rect}/>);
    } else if (element.type === 'line'){
    	return(<ChildLineContent pagenum={pagenum} idx={idx} element={element} rect={rect}/>);
    } else if (element.type === 'rectangle'){
    	return(<ChildRectContent pagenum={pagenum} idx={idx} element={element} rect={rect}/>);
    } else if (element.type === 'circle'){
    	return(<ChildCircleContent pagenum={pagenum} idx={idx} element={element} rect={rect}/>);
    }
}

export default SubContent;