import React from 'react';
import { Page } from 'react-pdf/dist/esm/entry.webpack';

import { useDispatch } from 'react-redux';
import { setPageChecked, setMode } from './splitSlice';

import { CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const PageElement = ({id, checked}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onClickCheck = () => {
    if (checked)
      dispatch(setPageChecked({id:id, checked: 0}));
    else
      dispatch(setPageChecked({id:id, checked: 1}));

    dispatch(setMode(t('tools.split.select_pages')));
  }
  ///////////////////rendering////////////////
  return (
        <div style={{marginBottom:20}} onClick={onClickCheck}>
          <div className="pdf-element">
              <div className="pdf-canvas">
                <Page pageNumber={id+1} loading="" />
                {id+1}
              </div>
              <div className={checked?"pdf-checked":"pdf-non-checked"}>
                  <CheckOutlined />
              </div>
          </div>
        </div>
  );
}

export default PageElement;