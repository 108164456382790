import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { insertFilelist, removeFilelist, insertPageList } from './organizeSlice';

import { Document } from 'react-pdf/dist/esm/entry.webpack'

import PDFPageElement from './PDFPageElement';
import BlankElement from './BlankElement';

import { Scrollbars } from 'react-custom-scrollbars';

import { Badge, Row, Button, Tooltip } from 'antd';
import { PlusOutlined, } from '@ant-design/icons';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from 'react-i18next';


const WorkArea = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.organizeOption.filelist);
    const pagelist = useSelector((state) => state.organizeOption.pagelist);

    const [viewpage, setViewPage] = useState(1);


    const onScrollFrame = (values) => {
        //console.log('onScroll', values);
        //const { top } = values;
        //setViewPage(parseInt(top * filelist[0].max_page));
    }

    const addFileList = (files) => {
        for (let i = 0; i < files.length; i++) {
            dispatch(insertFilelist(files[i]));
        }
    };

    function onDocumentLoadSuccess({ numPages }) {
        //console.log(fileidx, numPages);
        const payload = {
            max_page: numPages,
            fileidx: 0,
            pdf: null,
        };
        //console.log('insert pagelist', payload);
        dispatch(insertPageList(payload));
    }


    var pdf_pages_elements = [];
    //sorting by pos//
    for (let i = 0; i < pagelist.length; i++) {
        for (let j = 0; j < pagelist.length; j++) {
            if (pagelist[j].pos === i) {
                if(pagelist[j].fileidx < 0){
                    pdf_pages_elements.push(
                        <BlankElement 
                            pagelist_idx={pagelist[j].index} 
                            key={'page_' + i}/>
                    );
                }else {
                    pdf_pages_elements.push(
                        <PDFPageElement 
                            pagelist_idx={pagelist[j].index} 
                            key={'page_' + i}
                            view={true}/>
                    );
                }
            }
        }
    }

    var filecnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) filecnt++;
    }

    return (
        <Scrollbars style={{ width: '100%', height: '100%' }} onScrollFrame={onScrollFrame}>
            <div className="tool__workarea a">
                <Document file={filelist[0].url} onLoadSuccess={onDocumentLoadSuccess} loading="" noData="">
                    {pdf_pages_elements}
                </Document>
                
                {/***************************/}
                {/*<div className="sidetools">
                    <Row>
                        <div style={{marginBottom:10}}>
                            <Tooltip title={t('tools.organize.add_more')} placement="left">
                                <div className="hover-black-btn shadow-btn">
                                <Badge count={filecnt} color="#2db7f5">
                                    <FileUploader
                                            multiple={true}
                                            handleChange={addFileList}
                                            name="selectFile"
                                            types={["PDF"]}
                                            children={(<Button 
                                                            type="primary" 
                                                            shape="circle" 
                                                            icon={<PlusOutlined style={{ fontSize: '22px', color: 'white' }}/>} 
                                                            size="large">                                
                                                        </Button>)}
                                    />
                                </Badge>
                                </div>
                            </Tooltip>
                        </div>
                    </Row>


                    <Row>
                        <div className="hover-black-btn white-btn shadow-btn" style={{padding:4}}>
                            <Tooltip title={t('tools.organize.order_files')} placement="left">
                                <Button 
                                    type="primary" 
                                    shape="circle" 
                                    icon={<SortAscendingOutlined style={{ fontSize: '22px' }}/>} 
                                    size="medium"
                                    onClick = { onClickSort }/>
                            </Tooltip>
                        </div>
                    </Row>

                    <Row>
                        <div className="hover-black-btn white-btn shadow-btn" style={{padding:4}}>
                            <Tooltip title={t('tools.organize.order_pages')} placement="left">
                                <Button 
                                    type="primary" 
                                    shape="circle" 
                                    icon={<SortAscendingOutlined style={{ fontSize: '22px' }}/>} 
                                    size="medium"
                                    onClick = { onClickSort }/>
                            </Tooltip>
                        </div>
                    </Row>

                    <Row>
                        <div className="hover-black-btn white-btn shadow-btn" style={{padding:4}}>
                            <Tooltip title={t('tools.organize.mix_pages')} placement="left">
                                <Button 
                                    type="primary" 
                                    shape="circle" 
                                    icon={<SortAscendingOutlined style={{ fontSize: '22px' }}/>} 
                                    size="medium"
                                    onClick = { onClickSort }/>
                            </Tooltip>
                        </div>
                    </Row>
                </div>*/}
            </div>
        </Scrollbars>
    );
}

export default WorkArea;