import React, { useState } from 'react';

import { Button } from 'antd';
import { CloseOutlined } from "@ant-design/icons";

import {getCookie, setCookie} from '../../utils/cookie';

import { useTranslation } from 'react-i18next';

const Cookies = () => {
    const {t} = useTranslation();

    const [show, setShow] = useState('block');


    const onClickClose = () => {
        setShow('none');
    }

    const onClickAcceptCookies = () => {
        setCookie('acceptallcookies', 'check');
        setShow('none');
    }

    var accept = getCookie('acceptallcookies');
    if(accept)  return '';

    return (
        <div className="cookies" style={{display: show}}>
            <div className="white-btn hover-black-btn shadow-btn close_btn float_right">
                <Button 
                    type="primary" 
                    shape="circle" 
                    icon={<CloseOutlined style={{ fontSize: '14px' }}/>} 
                    size="small"
                    onClick={onClickClose} />
            </div>

            <h2>
                {t('popover.cookies.title')}
            </h2>
            
            <p>
                {t('popover.cookies.txt')}
            </p>

            <div style={{float:'right'}}>
                <Button type="primary" shape="round" size="middle" onClick={onClickAcceptCookies}>
                    {t('popover.cookies.btn')}
                </Button>      
            </div>
        </div>
    );
}

export default Cookies;