import React, {useState} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setMaxPage } from './editSlice';

import { Document } from 'react-pdf/dist/esm/entry.webpack';

import { Layout } from 'antd';
import EditToolBar from './toolbar/EditToolBar';
import SubToolBar from './toolbar/SubToolBar';
import PagePreviews from './pageviews/PagePreviews';
import PageEditView from './pageviews/PageEditView';

const { Header, Content, Sider } = Layout;
const WorkArea = () => {    
    const dispatch = useDispatch();    
    const url = useSelector((state) => state.editOption.url);

    function onDocumentLoadSuccess({ numPages }) {
        dispatch(setMaxPage(numPages));        
    }
    

    ////////rendering////////////
    return (        
        <div className="tool__workarea b" style={{padding:0}}>            
              <Layout>
                  <Header className="toolbar_header">
                      <EditToolBar/>
                      <SubToolBar/>
                  </Header>
                  
                  <Layout>
                    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                      <Sider width={140}>
                        <PagePreviews/>
                      </Sider>

                      <Layout style={{ padding: '0 24px 24px' }}>
                        <Content style={{padding: 24, margin: 0, minHeight: 280}}>
                          <PageEditView/>
                        </Content>
                      </Layout>
                    </Document>
                  </Layout>

              </Layout>
        </div>
    );
}

export default WorkArea;