import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Tooltip } from 'antd';
import { RotateRightOutlined, MinusOutlined, CheckOutlined } from '@ant-design/icons';
import { setSelectOption, setRemoveOption, setRotateOption } from './rotateSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PDFElement = (propsparam) => {
  const {t} = useTranslation();
  const { fileinfo, removeFile, setRotate, setSelect} = propsparam;
  
  const filelist = useSelector((state) => state.rotateOption.filelist);
  const dispatch = useDispatch();

  //const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);  
  //const [selpdf, setSelPdf] = useState(false);

  const id = "pdf_" + fileinfo.idx.toString();
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onClickRotate = () => {
    setRotate(fileinfo.idx, (fileinfo.rotate + 1) % 4);
    //var rotate = (fileinfo.rotate + 1) % 4;
    //dispatch(setRotateOption({idx:fileinfo.idx, rotate:rotate}));
  }

  /*const reader = new FileReader();
  reader.onload = () => {
      if(reader.readyState === 2){
        setPdf(reader.result)
        localStorage.setItem('pdf', reader.result);
      }
  }
  reader.readAsDataURL(fileinfo.file);*/

  const onClickSelectPDF = () => {
    //setSelPdf(!selpdf);
    var select = fileinfo.select === 0 ? 1 : 0;
    setSelect(fileinfo.idx, select);
    //dispatch(setSelectOption({idx:fileinfo.idx, select:selpdf}));
  }

  ///////////////////rendering////////////////
  const tooltip_pdf = fileinfo.file.name + " ( " + numPages + " " + t('tools.compress.pages') +" )";  
  const degvalue = (fileinfo.rotate) * 90;
  const display = fileinfo.remove === 0 ? 'flex' : 'none';
  return (
  	<div style={{marginBottom:10}}>
    <Tooltip  placement="bottom" title={tooltip_pdf}>
        <div className="pdf-element" style={{display:display}}>
          <Document file={fileinfo.url} onLoadSuccess={onDocumentLoadSuccess}>
            <div className ="pdf-canvas"  style={{transform: `rotate(${degvalue}deg)`}} onClick={onClickSelectPDF}>
                  <Page pageNumber={1} />
            </div>
          </Document>
          <div className={fileinfo.select?"rotate_pdf-checked":"rotate_pdf-non-checked"}>
                <CheckOutlined />
          </div>

          <div className="pdf-action">
            <Tooltip title={t('tools.rotate.Rotate')} onClick={onClickRotate}>
              <div className="circle-btn">
                  <RotateRightOutlined />
              </div>
            </Tooltip>

            <Tooltip title={t('tools.rotate.Remove')}>
              <div className="circle-btn" onClick={() => removeFile(fileinfo.idx)}>
                  <MinusOutlined />
              </div>
            </Tooltip>
          </div>
        </div>
    </Tooltip>
    </div>
  );
}

export default PDFElement;