import { Modal, Button, Input } from 'antd';
import React, { useState } from 'react';
import Html2Nav from './Html2Nav';
import {IoEarthOutline} from 'react-icons/io5'
import { useTranslation } from 'react-i18next';

const Html2Modal = ({handleChange}) => {
  const {t} = useTranslation();
  const [address, setAddress] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  
  const onAddClick = () => {
    if (address === '')
    {
      return;
    }
    setModalVisible(false);
    handleChange(address);
  }
  
  const onChange = (e) => {
    setAddress(e.target.value);
  }
  const onKeyDown = (e) => {
    if(e.keyCode === 13){
      e.preventDefault(); 
      setModalVisible(false);
      handleChange(address);
    }
  }//<Html2Nav />
  return (
    <div>
        
        <Button className="uploader__btn" type="primary" onClick={() => setModalVisible(true)}>
        {t('tools.html2pdf.AddHtml')}
        </Button>
        <Modal
          visible={modalVisible}
          title={t('tools.html2pdf.AddHtmlTo')}
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button className="side__btns_html" key="back" onClick={onAddClick}>
               {t('tools.html2pdf.Add')}
            </Button>
          ]}
        >
        
        <br/>
        <br/>
        {t('tools.html2pdf.WebURL')}
        <Input placeholder="https://pdfmade.com" onChange={onChange} onKeyDown={onKeyDown} prefix={<IoEarthOutline/>}/>  
        </Modal>
        
    </div>
  );
}

export default Html2Modal;
