import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Tooltip } from 'antd';
import { RotateRightOutlined, MinusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';


///////////////////////////////////////////////
const PDFElement = (propsparam) => {
  const {t} = useTranslation();
  const { fileinfo, removeFile, setRotate } = propsparam;

  const [numPages, setNumPages] = useState(null);  

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onClickRotate = () => {
    setRotate(fileinfo.idx, (fileinfo.rotate + 1) % 4);
  }

  ///////////////////rendering////////////////
  const tooltip_pdf = fileinfo.file.name + " ( " + numPages + " " + t('tools.compress.pages') +" )";  
  const degvalue = (fileinfo.rotate) * 90;
  const display = fileinfo.remove === 0 ? 'flex' : 'none';
  return (
    <div style={{marginBottom:10}}>
        <Tooltip  placement="bottom" title={tooltip_pdf}>
            {/*<PageView idx={fileinfo.idx} setRotate={setRotate} removeFile={removeFile}/>*/}
            <div className="pdf-element" style={{display:display}}>
              <Document file={fileinfo.url} onLoadSuccess={onDocumentLoadSuccess}>        
                <div className="pdf-canvas"  style={{transform: `rotate(${degvalue}deg)`}}>
                  <Page pageNumber={1} loading="" />
                </div>
              </Document>

              <div className="pdf-action">
                <Tooltip title={t('tools.merge.rotate')}>
                  <div className="circle-btn"  onClick={onClickRotate}>
                  	<RotateRightOutlined />                  	  
                  </div>
                </Tooltip>

                <Tooltip title={t('tools.merge.remove')}>
                  <div className="circle-btn" onClick={() => removeFile(fileinfo.idx)}>
                   	<MinusOutlined />
                  </div>
                </Tooltip>
              </div>
            </div>
        </Tooltip>
    </div>
  );
}

export default PDFElement;