import React from 'react';
import { useDispatch } from 'react-redux';
import { setTool, setOption } from './rotateSlice';

import WorkAreaRotate from './WorkAreaRotate';
import WorkSideRotate from './WorkSideRotate';
import { Scrollbars } from 'react-custom-scrollbars';


const WorkSpaceRotate = ({ goAction }) => {    
    const dispatch = useDispatch();
    const onAction = (filelist) => {        

        dispatch(setTool("ROTATE"));
        //const filelist = {};
        var option = {
            cnt: 0,
            rotate: [],
        }

        for (var i = 0; i < filelist.length; i++) {
            if(filelist[i].remove !== 0)    continue;
            option.cnt++;
            option.rotate.push(filelist[i].rotate);
        }

        dispatch(setOption(option));

        goAction();
    }

    /***********rendering**********/
    return (
        <>
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <WorkAreaRotate/>
            </Scrollbars>
            <WorkSideRotate onAction={onAction}/>
        </>
    );
}

export default WorkSpaceRotate;