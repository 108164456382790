import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import PDFElement from './PDFElement';

import { axiosInstance, domainFrontEnd } from '../../../utils/axiosInstance';
import { Progress, Spin, notification } from 'antd';
import { setUploadStatus } from './html2Slice';   
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';

const ActionSpin = () => {
    const {t} = useTranslation();
    const antIcon = <LoadingOutlined style={{ fontSize: 140, fontWeight: 1800 }} spin />;

    return(
        <div className="action-spin">
            <h2>{t('tools.html2pdf.action')}</h2>
            <br/>
            <Spin indicator={antIcon} />
        </div>
    );
}

///////////////////
const WorkAreaHtml2 = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const url = useSelector((state) => state.html2Option.url);
    const upload_status = useSelector((state) => state.html2Option.upload_status);
    //const  [progress,  setProgress]  =  useState(0);//0%-100%
    const [downlink, setdownlink] = useState('');

    //const [upload, setUpload] = useState(upload_status);

    

    useEffect(() => {
      if (url !== '' && upload_status === 0)
      {
        dispatch(setUploadStatus(1));
        //setUpload(1);     
        //console.log("send url: ", url);   
        var option = {
            url: url,
            onepage:0,
            pagesize:0,
            margin: 0,
            ads: 0,
            popup: 0,
            pagetype: 0
        }
        var payload = {
            tool_header: "HTML2PDF",
            option: option,
            path: '',
        }
        
        //console.log("payload:", payload);

        axiosInstance.post(`/action/html2pdf`,  payload)
        .then((res) => {
            //console.log(res.data);
            if(res.data.msg === "Success"){
                //console.log(res.data);
                const { downlinks } = res.data;
                var link = domainFrontEnd + '/download/' + downlinks;
                setdownlink(link);
                //
                dispatch(setUploadStatus(2));

            } else {
                setdownlink('');
                dispatch(setUploadStatus(4));    
            }
            
        })
        .catch((error) => {
            dispatch(setUploadStatus(3));
            setdownlink('');
        });
      }
      
    }, [upload_status]);
   
    var element = '';
    if (upload_status === 1)
    {
        element = 
        (
            <div style={{margin: '0 auto'}}>
                <ActionSpin />
            </div>
        );
    }
    else if (upload_status === 3)
    {
        element = (
            <div style={{ fontSize: 20}}>
                {t('tools.html2pdf.checkinternet')}
            </div>
        );
    }
    else if (upload_status === 4)
    {
        element = (
            <div style={{ fontSize: 20}}>
                {t('tools.html2pdf.checkurl')}
            </div>
        );
    }
    else if (upload_status === 2)
    {
        element = (<PDFElement url={downlink}/>);
    }
    return (
        <div className="tool__workarea b" style={{paddingTop:50}}>
            {element}
            
        </div>
    );
}

export default WorkAreaHtml2;