import React, { useState } from 'react'
import ResizableRect from 'react-resizable-rotatable-draggable'
import { DeleteOutlined } from '@ant-design/icons';
import { MdOutlineRepeat} from 'react-icons/md';
import { Tooltip } from 'antd';
import { PlaceModal } from '../SelectModal';
//import { removeElement, } from '../signSlice';
import { useTranslation } from 'react-i18next';
const paddingDepth = 0;

const ResizableContent = props => {
  const {t} = useTranslation();
  //const dispatch = useDispatch();
  //const selected_element = useSelector((state: RootState) => state.signOption.selected_element);
  //const onlyme = useSelector((state: RootState) => state.signOption.onlyme);
  
  const { page_rect, scale, active } = props;

  const [width, setWidth] = useState(props.width)
  const [height, setHeight] = useState(props.height)
  const [top, setTop] = useState(props.top)
  const [left, setLeft] = useState(props.left)
  const [rotateAngle, setRotateAngle] = useState(props.rotateAngle)

  const contentStyle = {
    top: top,
    left: left,
    width: width,
    height: height,
    position: 'absolute',
    //transform: `rotate(${rotateAngle}deg)`,
    padding: paddingDepth,
    scale:scale,
  }

  const removeStyle = {
    top: top - 22,
    left: left + 28,
    fontSize:'12px',
    position:'absolute',
    //transform: `rotate(${rotateAngle}deg)`,
  }

  const placeStyle = {
    top: top - 22,
    left: left + 5,
    fontSize:'12px',
    position:'absolute',
    
    //transform: `rotate(${rotateAngle}deg)`,
  }

  const checkRange = (top, left, width, height) => {
    var ret = true;
    if (top < page_rect.top){
      ret = false;
    } else if(left < page_rect.left){
      ret = false;
    } else if(top + height > page_rect.top + page_rect.height){
      ret = false;
    } else if(left + width > page_rect.left + page_rect.width){
      ret = false;
    }
    return ret;
  }

  const handleResize = (style, isShiftKey, type) => {    
    const { top, left, width, height } = style
    if(checkRange(top, left, width, height)){
      setWidth(Math.round(width))
      setHeight(Math.round(height))
      setTop(Math.round(top))
      setLeft(Math.round(left))

      //update size//
      props.resizeElement(props.id, style);
    }
  }

  const handleRotate = rotateAngle => {
    setRotateAngle(rotateAngle)
  }

  const handleDrag = (deltaX, deltaY) => {
  	if(checkRange(top + deltaY, left + deltaX, width, height)){
      setTop(top + deltaY)
      setLeft(left + deltaX)
    }
  }

  const handleDragEnd = () => {
    //console.log("drag end left:[%d],top:[%d]", left, top);
    props.dragend(props.id, left, top);
  }
  
  const onClickElement = () => {
    props.clickedElement(props.id);
  }

  const onDblClick = () => {
    if(props.type === "text")
        props.doubleClickTextElement(props.id);
  }

  var zoomable = "n, w, s, e, nw, ne, se, sw";
  if(props.type === "pen")
  {
    zoomable = "";
  } 
  else if(props.type === "line")
  {
    zoomable = "w, e";
  }


  var resizable_rect = "";
  if(active){
    //console.log(document.getElementById(props.id), left, top, width, height);
    resizable_rect = (
      <ResizableRect
        top={top}
        left={left}
        width={width}
        height={height}
        minWidth={10}        
        minHeight={10}        
        onDrag={handleDrag}
        onRotate={handleRotate}
        onResize={handleResize}
        onDragEnd={handleDragEnd}
        aspectRatio={false}
        zoomable={zoomable}
        rotatable={false}
        rotateAngle={rotateAngle}
      />
    );
  }
  const onClickRemoveElement = () => {
    //console.log("remove click");
    props.removeElement(props.id);
  }

  const [place, setPlace] = useState(false);
  const onInitPlace = (selected) => { 
    setPlace(false); 
    
    //console.log("onInitPlace click[%s][%d]", props.id, selected);
    props.placetoElement(props.id, selected);
  }
  const onClickPlaceElement = () => { setPlace(true); }
  
  var placeElement = '';
  if (place)
    placeElement = (<PlaceModal proc={onInitPlace} />);

  var display = active ? 'flex' : 'none';
  return (
    <div id={props.id} onClick={onClickElement} onDoubleClick={onDblClick}>
      {placeElement}
      <div style={contentStyle}>{props.children}</div>
      {resizable_rect} 
      <div style={{display:display}}>
        <div className="circle-btn-sign"  style={placeStyle} onClick={onClickPlaceElement}>
          <Tooltip title={t('tools.sign.Place')}>
            <MdOutlineRepeat style={{marginTop:'3px'}}/>
          </Tooltip>
        </div>
        <div className="circle-btn-sign"  style={removeStyle} onClick={onClickRemoveElement}>
          <Tooltip title={t('tools.sign.Delete')}>
            <DeleteOutlined />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default ResizableContent
