import React from 'react';
import { toolsListInfo } from '../../utils/toolsInfo';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from 'react-i18next';
const SelectFiles = ({ id, proc, multiple, btn_text, fileTypes }) => {
	const {t} = useTranslation();
    //const [file, setFile] = useState(null);
    const handleChange = (file) => {
        //setFile(file);
        proc(file);
    };

    const dropElement = (<div className="uploader__btn">{btn_text}</div>);
    
    return (
        <div className="select-file">
            <div className="tool__header">
                <h1>{toolsListInfo[id].title}</h1>
                <h3>{t(`home.tools.${id}`)}</h3>
            </div>
            <div>
                <FileUploader
                    multiple={multiple}
                    handleChange={handleChange}
                    name="selectFile"
                    types={fileTypes}
                    children={dropElement}
                />
            </div>
        </div>
    );
}

export default SelectFiles;