import React, { useState } from 'react';
import {Select, Space, Popover, Tooltip } from 'antd';
import { AiOutlineBold, AiOutlineItalic, AiOutlineUnderline, AiOutlineFontColors, AiOutlineFontSize } from 'react-icons/ai';
import FontSize from './FontSize';
import ColorPicker, { getRGBFromRGBA } from './ColorPicker';
import { AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined } from '@ant-design/icons'


const { Option } = Select;

const TextFormat = ({nproc, sproc, bproc, iproc, uproc, cproc, aproc, clsName, element}) => {
    /**
     * 
     * @param {*} e 
     * nproc: name process
     * sproc: font size process
     * bproc: bold process
     * iproc: italick process
     * uproc: underline process
     * cproc: font color process
     */

    const onSelectFontName = (value) => {
        nproc(value);
    }

    
    const onBoldClick = () => {
        bproc((element.bold + 1) % 2);
    }
    const onItalicClick = () => {
        iproc((element.italic + 1) % 2);
    }
    const onUnderlineClick = () => {
        uproc((element.underline + 1) % 2);
    }
    const onFontColorChange = (value) => {
        cproc(value);
    }
    const onFontSizeChange = (value) => {
        sproc(value);
    }
    const fontSizeContent = (
        <div>
            <FontSize 
                proc={onFontSizeChange} 
                defaultValue={element.fontSize} 
                style={{width: '200px'}}>
            </FontSize>
        </div>
    )
    const colorPickerContent = (
        <ColorPicker proc={onFontColorChange} value={element.pen_color}></ColorPicker>
    )

    const fontName = [
        {id:'0',name:'Arial'},
        {id:'1',name:'Calibri'},
        {id:'2',name:'Cambria'},
        {id:'3',name:'Corbel'},
        {id:'4',name:'Comic Sans MS'},
        {id:'5',name:'Consolas'},
        {id:'6',name:'Constantia'},
        {id:'7',name:'Courie New'},
        {id:'8',name:'Georgia'},
        {id:'9',name:'Palatino Linotype'},
        {id:'10',name:'Sitka Heading'},
        {id:'11',name:'Times New Roman'},
        {id:'12',name:'Verdana'},
    ];

    
    const onClickAlignTextLeft = () => {
        aproc('left');
    }

    const onClickAlignTextCenter = () => {
        aproc('center');
    }

    const onClickAlignTextRight = () => {
        aproc('right');
    }
    
    var align_item = '';
    if(element.align === 'left'){
        align_item = (<AlignLeftOutlined/>);
    } else if (element.align === 'center') {
        align_item = (<AlignCenterOutlined/>);
    } else if (element.align === 'right') {
        align_item = (<AlignRightOutlined/>);
    }

    const alignTextContent = (
        <div style={{display:'flex'}}>
            <div className="editpdf__menu_item hovermenu" id="left" onClick={onClickAlignTextLeft}>
                <AlignLeftOutlined/>
            </div>
            <div className="editpdf__menu_item hovermenu" style={{marginLeft:15, marginRight:15}} id="center" onClick={onClickAlignTextCenter}>
                <AlignCenterOutlined/>
            </div>
            <div className="editpdf__menu_item hovermenu" id="right" onClick={onClickAlignTextRight}>
                <AlignRightOutlined />
            </div>
        </div>
    );
    
    return (
        <div className={clsName} style={{alignItems: 'center', display: 'flex'}}>
            <div style={{float:'left', marginLeft:'15px'}} >
                <Select 
                    value={element.font}
                    style={{marginRight: '10px', width:160, textAlign:'left'}}
                    dropdownMatchSelectWidth={false}
                    onChange={onSelectFontName}
                >    
                {fontName.map(province => (
                    <Option key={province.name}>{province.name}</Option>
                    ))}
                </Select>

                <Popover placement="bottom" content={fontSizeContent}>
                    <AiOutlineFontSize style={{fintSize:'35px'}}/>
                </Popover>
            </div>
            <div style={{float:'left', marginLeft:'15px', marginTop:'0px'}}>
                <Space>
                    <div><AiOutlineBold className={element.bold === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onBoldClick}/></div>
                    <div><AiOutlineItalic className={element.italic === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onItalicClick}/></div>
                    <div><AiOutlineUnderline className={element.underline === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onUnderlineClick}/></div>
                    <Popover placement="bottom" content={alignTextContent}>
                        {align_item}
                    </Popover>
                    <Popover placement="bottom" content={colorPickerContent}>
                        <AiOutlineFontColors style={{ fontSize: '20px', color:getRGBFromRGBA(element.pen_color)}}/>
                    </Popover>
                </Space>
            </div>
        </div>
    );
}

export default TextFormat;