import React from 'react';
import pdfsvg from '../../assets/svg/pdfmade.svg';
import HeaderNav from './nav';
import RightSide from './right_side';

class Header extends React.Component{

    render(){
        return(
            <div className='header'>
                <div className='header-main'>
                    <div className="brand">
                        <a href="/" title="PDF Made">
                            <img src={pdfsvg} alt="PDFMade" style={{width:'100%', height:'100%'}}/>
                        </a>
                    </div>

                    <HeaderNav/>
                    <RightSide/>
                </div>          
            </div>
        );        
    }
}

export default Header;