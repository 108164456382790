import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { store } from './store/store';
import { Provider } from 'react-redux';

import './i18n';


const root = ReactDOM.createRoot(document.getElementById('pdfmade-root'));
root.render(
	<Provider store={ store }> 
		<App />
	</Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
//serviceWorker.register();
