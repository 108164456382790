import React from 'react';
import { Tooltip } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

import { getSvgImageFromType } from '../../../utils/svgInfo';
import { useTranslation } from 'react-i18next';

const getFileTypeFromFileName = (filename) => {
  var fileSplit = filename.split('.');
  var fileExt = '';
  if (fileSplit.length > 1) {
  fileExt = fileSplit[fileSplit.length - 1];
  } 
  return fileExt;
}

const OtherElement = (propsparam) => {
  const {t} = useTranslation();
  const { fileinfo, removeFile } = propsparam;

  ///////////////////rendering////////////////
  const tooltip_pdf = fileinfo.file.name;  
  const display = fileinfo.remove === 0 ? 'flex' : 'none';
  const filetype = getFileTypeFromFileName(fileinfo.file.name);
  
  return (
    <div style={{marginBottom:10}}>
    <Tooltip  placement="bottom" title={tooltip_pdf}>
        <div className="pdf-element" style={{display:display}}>
          <div className="pdf-canvas">
            <div className="v-align-center">
              <img src={getSvgImageFromType(filetype)} alt={filetype}/>
            </div>
          </div>

          <div className="pdf-action">
            <Tooltip title={t('tools.word2pdf.remove')}>
              <div className="circle-btn" onClick={() => removeFile(fileinfo.idx)}>
                  <MinusOutlined />
              </div>
            </Tooltip>
          </div>
        </div>
    </Tooltip>
    </div>
  );
}

export default OtherElement;