import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Tooltip } from 'antd';
import { RotateRightOutlined, MinusOutlined, FileAddOutlined } from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';
import { removePageList, rotatePageList, insertBlankPage } from './organizeSlice';
import { useTranslation } from 'react-i18next';

const PDFPageElement = ({ pagelist_idx, view }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const filelist = useSelector((state) => state.organizeOption.filelist);
  const pagelist = useSelector((state) => state.organizeOption.pagelist);
  const fileidx = pagelist[pagelist_idx].fileidx;


  const file = filelist[fileidx].file;
  const pagenumber = pagelist[pagelist_idx].pagenumber;
  const pdf = filelist[fileidx].pdf;

  const onClickRotate = () => {
    //setRotate(fileinfo.idx, (fileinfo.rotate + 1) % 4);
    dispatch(rotatePageList(pagelist_idx));
  }

  const onClickRemove = () => {
    //remove//
    dispatch(removePageList(pagelist_idx));
  }

  const addBlankPrev = () => {
    //insert blank page//
    dispatch(insertBlankPage(pagelist[pagelist_idx].pos));
  }

  const addBlankNext = () => {
    //insert blank page//
    dispatch(insertBlankPage(pagelist[pagelist_idx].pos + 1));
  }

  ///////////////////rendering////////////////
  const degvalue = pagelist[pagelist_idx].rotate;
  const display = pagelist[pagelist_idx].remove === 0 ? 'flex' : 'none';
  const r = ((fileidx + 1) * 243) % 255;
  const g = ((fileidx + 3) * 17) % 255;
  const b = ((fileidx + 11) * 143) % 255;
  const bordercolor = `rgb(${r}, ${g}, ${b})`;

  var page = '';
  if(view){
    page = (<Page pageNumber={pagenumber} loading=""/>);
  }

  return (
    <div style={{marginBottom:10}}>
      <Tooltip  placement="bottom" title={file.name}>
        <div className="pdf-element_organize text-center" style={{display:display}}>
          {/*<Document file={filelist[fileidx].url}  loading="" noData="">*/}
            <div>
              <div className="pdf-canvas"  style={{transform: `rotate(${degvalue}deg)`}}>
                {page}
              </div>
              <span style={{color:'black'}}>{pagenumber}</span>
            </div>
          {/*</Document>*/}

          <div className="pdf-action">
            <Tooltip title={t('tools.organize.rotate')} onClick={onClickRotate}>
              <div className="circle-btn">
                  <RotateRightOutlined />
              </div>
            </Tooltip>

            <Tooltip title={t('tools.organize.remove')}>
              <div className="circle-btn" onClick={onClickRemove}>
                  <MinusOutlined />
              </div>
            </Tooltip>
          </div>

          <div className="add_blank_page_prev">
            <Tooltip title={t('tools.organize.add_blank')}>
              <div className="circle-btn_add-blank" onClick={addBlankPrev}>
                  <FileAddOutlined />
              </div>
            </Tooltip>
          </div>

          <div className="add_blank_page_next">
            <Tooltip title={t('tools.organize.add_blank')}>
              <div className="circle-btn_add-blank" onClick={addBlankNext}>
                  <FileAddOutlined />
              </div>
            </Tooltip>
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default PDFPageElement;