import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
//import { Tooltip } from 'antd';
//import { RotateRightOutlined, MinusOutlined, CheckOutlined } from '@ant-design/icons';
//import { setSelectOption, setRemoveOption, setRotateOption } from './html2Slice';
import { useSelector, useDispatch } from 'react-redux';
//import { useTranslation } from 'react-i18next';

const PDFElement = (propsparam) => {
  //const {t} = useTranslation();
  const {url} = propsparam;

  //console.log("received url: ", url);

  //const filelist = useSelector((state) => state.rotateOption.filelist);
  const dispatch = useDispatch();

  //const [pdf, setPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);  
  //const [selpdf, setSelPdf] = useState(false);

  //const id = "pdf_" + fileinfo.idx.toString();
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    //console.log("page number: ", numPages);
  }

  var element = [];
  if (numPages > 0)
  {
    for (var i = 0; i < numPages; i ++)
    {
      //console.log('[%d] page loading', i + 1);
      var each = (
        <div key={'page'+i.toString()} style={{margin:'auto auto 30px auto'}}>
          <Page pageNumber={i+1} width={850}/>
        </div>
      );
      element.push(each);
    }  
  }
  return (
  	<div style={{marginBottom:10, width:'100%'}}>
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess} noData={''}>
        {element}
      </Document>
    </div>
  );
}

export default PDFElement;