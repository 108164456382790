import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ToolOptionState {
  tool_header: string, //tool header ('MERGE', 'SPLIT', ...)
  filelist: Array,//this is just for 'merge' (others use this with file)
  option: Object,//options for action (be sent to back-end)
  uploadpath: Array,//
  properties: Array,
}

const initialState: ToolOptionState = {
  tool_header: "MERGE",
  filelist: [],
  option: {},
  uploadpath: [],
  properties: [],
}

export const toolSlice = createSlice({
  name: 'toolOption',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setOption: (state, action:PayloadAction<Object>) =>{
      state.option = action.payload;
    },
    addFileInfoList: (state, action:PayloadAction<Array>) => {
      //state.filelist = action.payload;
      for(let i = 0; i < action.payload.length; i++){
        state.filelist.push({
          idx: state.filelist.length,
          file: action.payload[i],
          url: URL.createObjectURL(action.payload[i]),
          rotate: 0,
          remove: 0,
        });
      }
    },
    setRemoveOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].remove = action.payload.remove;
    },
    setRotateOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].rotate = action.payload.rotate;
    },
    setUploadPath: (state, action:PayloadAction<Array>) => {
      state.uploadpath = action.payload;
    },
    sortingByFileName: (state, action) => {
      const sorting = action.payload;
      for(let i = 0; i < state.filelist.length; i++){
        for (let j = 0; j < state.filelist.length; j++) {         
          if ((sorting === 0 && state.filelist[i].file.name > state.filelist[j].file.name)
              || (sorting === 1 && state.filelist[i].file.name < state.filelist[j].file.name)) {
              //swap//
              var tmp = state.filelist[i];
              state.filelist[i] = state.filelist[j];
              state.filelist[j] = tmp;
          }
        }
      }
    }

  },
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setOption, 
  addFileInfoList, 
  setRemoveOption, 
  setRotateOption,
  setUploadPath,
  sortingByFileName } = toolSlice.actions

export default toolSlice.reducer