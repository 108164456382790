import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Jpg2PdfOptionState {
  tool_header: string,
  filelist: Array,
  option: Object,
  uploadpath: Array,
  direction: Number,
  margin: Number,
  pagesize: Number,
  onefile: Number,
}

const initialState: Jpg2PdfOptionState = {
  tool_header: "JPG2PDF",
  filelist: [],
  option: {},
  direction: 0,
  pagesize: 0,
  margin: 0,
  onefile: 0
}

export const jpg2pdfSlice = createSlice({
  name: 'jpg2pdfOption',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setOption: (state, action:PayloadAction<Object>) =>{
      state.option = action.payload;
    },
    setFileOptionList: (state, action:PayloadAction<Array>) => {
      state.filelist = action.payload;
    },
    setRemoveOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].remove = action.payload.remove;
    },
    setRotateOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].rotate = action.payload.rotate;
    },
    setUploadPath: (state, action:PayloadAction<Array>) => {
      state.uploadpath = action.payload;
    },
    setDirectionOption: (state, action:PayloadAction<Number>) => {
      state.direction = action.payload;
    },
    setPageOption: (state, action:PayloadAction<Number>) => {
      state.pagesize = action.payload;
    },
    setMarginOption: (state, action:PayloadAction<Number>) => {
      state.margin = action.payload;
    },
    setOnefileOption: (state, action:PayloadAction<Number>) => {
      state.onefile = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setOption, 
  setFileOptionList, 
  setRemoveOption, 
  setRotateOption,
  setUploadPath,
  setSelectOption,
  setPageOption,
  setMarginOption,
  setOnefileOption,
  setDirectionOption
} = jpg2pdfSlice.actions;

export default jpg2pdfSlice.reducer;