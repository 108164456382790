import React, {useState} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setMaxPage, addCustomRange, setFixedRange } from './splitSlice';

import { Document } from 'react-pdf/dist/esm/entry.webpack';
import RangeElement from './RangeElement';
import PageElement from './PageElement';
import { useTranslation } from 'react-i18next';

const WorkArea = () => {
    const {t} = useTranslation();

    const filelist = useSelector((state) => state.splitOption.filelist);
    const custom_ranges = useSelector((state) => state.splitOption.custom_ranges);
    const fixed_range = useSelector((state) => state.splitOption.fixed_range);
    const option = useSelector((state) => state.splitOption.mode);
    const max_page = useSelector((state) => state.splitOption.max_page);
    const select_pages = useSelector((state) => state.splitOption.select_pages);
    
    const dispatch = useDispatch();
    

    //pdf//
    //const [pdf, setPdf] = useState(null);
    //const [numPages, setNumPages] = useState(null);  

    function onDocumentLoadSuccess({ numPages }) {
        //setNumPages(numPages);
        dispatch(setMaxPage(numPages));
        dispatch(setFixedRange(numPages));
        dispatch(addCustomRange([1, numPages]));
    }

    /*const reader = new FileReader();
    reader.onload = () => {
        if(reader.readyState === 2){
           setPdf(reader.result)
           localStorage.setItem('pdf', reader.result);
        }
    }
    reader.readAsDataURL(filelist[0]);*/
    

    ////////rendering////////////
    var pdf_element_list = [];
    if (option === t('tools.split.custom_ranges')) {
        for (let i = 0; i < custom_ranges.length; i++) {
            pdf_element_list.push(
                <RangeElement 
                    title={ t('tools.split.range') + (i + 1).toString() } 
                    start={custom_ranges[i][0]} 
                    end={custom_ranges[i][1]}
                    key={'crange_' + i}/>
            );
        }        
    } else if (option === t('tools.split.fixed_ranges')) {
        var cnt = Math.ceil(max_page / fixed_range);
        for (let i = 0; i < cnt; i++) {
            pdf_element_list.push(
                <RangeElement 
                    title={ t('tools.split.range') + (i + 1).toString() } 
                    start={i*fixed_range + 1}
                    end={(i+1)*fixed_range > max_page?max_page:(i+1)*fixed_range}
                    key={'frange_' + i}/>
            );
        }        
    } else {
        for (let i = 0; i < max_page; i++) {
            pdf_element_list.push(
                <PageElement 
                    id={ i } 
                    checked={select_pages[i]}
                    key={'page_' + i}/>
            );
        }
    } 

    return (
        <div className="tool__workarea a">
            <Document file={filelist[0].url} onLoadSuccess={onDocumentLoadSuccess}>
                {pdf_element_list}
            </Document>
        </div>
    );
}

export default WorkArea;