import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { insertFilelist } from './organizeSlice';

import SelectFiles from '../SelectFiles';
import WorkSpace from './WorkSpace';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';
import { useTranslation } from 'react-i18next';

var links = [];
const OrganizePDF = () => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    //const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);

    const filelist = useSelector((state) => state.organizeOption.filelist);
    const pagelist = useSelector((state) => state.organizeOption.pagelist);

    const id = 18;

    const goProcFunction = (files) => {        
        //setFile(files);
        /*for (let i = 0; i < files.length; i++) {
            dispatch(insertFilelist(files[i]));
        }*/
        dispatch(insertFilelist(files));
        setProc(1);
    }

    const goAction = () => {
        setProc(2);        
    }

    
    const goDownload = (downlinks) => {
        links = downlinks;
        setProc(3);
    }


    /*****************rendering*****************/
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles id={id} proc={goProcFunction} multiple={false} btn_text={t('tools.organize.btn')} fileTypes = {["PDF"]}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpace goAction={goAction}/>
            </div>
        );
    } else if (proc === 2) {//action space
        //option payload//
        var _files = [];
        for (let i = 0; i < filelist.length; i++) {
            //if(filelist[i].remove !== 0)  continue;
            _files.push(filelist[i].file);
            //formData.append("files",  filelist[i]);
        }

        var pages = [];
        //sorting by pos//
        //sorting by pos//
        for (let i = 0; i < pagelist.length; i++) {
            for (let j = 0; j < pagelist.length; j++) {
                if (pagelist[j].pos === i && pagelist[j].remove === 0) {
                    pages.push([pagelist[j].fileidx, pagelist[j].pagenumber, pagelist[j].rotate / 90]);
                    break;
                }
            }
        }

        var option = {
            pages: pages,
        }

        //uploading//
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api} 
                    goDownload={ goDownload } 
                    text="Organizing PDF..."
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default OrganizePDF;