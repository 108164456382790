import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface WatermarkOptionState {
  tool_header: string,
  filelist: Array,
  url: Array,
  option: Object,
  uploadpath: Array,
  selindex: Number,
  maxPage: Number,
  mode: Number,
  position: Number,
  mosaic: Number,
  transparency: Number,
  rotation: Number,
  singlestart: Number,
  singleend: Number,
  sellayer: Number,
  fontname: Number,
  fontsize: Number,
  bold: Number,
  italic: Number,
  underline: Number,
  fontcolor: Object,
  imagefile: Object,
  text: string,
}

const initialState: WatermarkState = {
  tool_header: "Watermark",
  filelist: [],
  url: [],
  option: {},
  selindex: 0,
  maxPage: 1,

  mode: 0,
  imagefile:null,
  
  text:'',

  fontname: 0,
  fontsize: 14,
  bold: 0,
  italic: 0,
  underline: 0,
  fontcolor: {r:0, b:0, g:0, a:1},

  position:0,
  mosaic:0,
  transparency:0,
  rotation:0,
  
  singlestart: 1,
  singleend: 1,
  sellayer: 0,
}

export const watermarkSlice = createSlice({
  name: 'watermarkOption',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setOption: (state, action:PayloadAction<Object>) =>{
      state.option = action.payload;
    },
    setFileOptionList: (state, action:PayloadAction<Array>) => {
      state.filelist = action.payload;
      state.url = [];
      for (var i = 0; i < state.filelist.length; i ++)
        state.url.push(URL.createObjectURL(state.filelist[i]))
    },
    setUrlOptionList: (state, action:PayloadAction<Array>) => {
      state.url = action.payload;
    },
    setRemoveOption: (state, action:PayloadAction<Object>) => {
      state.filelist.splice(state.selindex, 1);
      state.url.splice(state.selindex, 1);
      if (state.selindex >= state.filelist.length)
        state.selindex = state.selindex - 1;
      if (state.filelist.length === 0)
      {
        state.selindex = -1;
        state.maxPage = 0;
      }  
    },
    
    setUploadPath: (state, action:PayloadAction<Array>) => {
      state.uploadpath = action.payload;
    },
    setSelIndex: (state, action:PayloadAction<Number>) => {
      state.selindex = action.payload;
    },
    setMaxPageOption: (state, action:PayloadAction<Number>) => {
      state.maxPage = action.payload;
    },
    setPositionOption:(state, action:PayloadAction<Number>) => {
      state.position = action.payload;
    },
    setMosaicOption:(state, action:PayloadAction<Number>) => {
      state.mosaic = action.payload;
    },
    setTransparencyOption:(state, action) => {
      state.transparency = action.payload;

    },
    setRotationOption:(state, action) => {
      //console.log('slice', action.payload);
      state.rotation = action.payload;
    },

    setSingleStartOption:(state, action:PayloadAction<Number>) => {
      state.singlestart = action.payload;
    },
    setSingleEndOption:(state, action:PayloadAction<Number>) => {
      state.singleend = action.payload;
    },
    setSelectLayerOption:(state, action:PayloadAction<Number>) => {
    state.sellayer = action.payload;
    },
    setFontNameOption:(state, action:PayloadAction<Number>) => {
      state.fontname = action.payload;
    },
    setFontSizeOption:(state, action:PayloadAction<Number>) => {
      state.fontsize = action.payload;
    },
    setBoldOption:(state, action:PayloadAction<Number>) => {
      state.bold = action.payload;
    },
    setItalicOption:(state, action:PayloadAction<Number>) => {
      state.italic = action.payload;
    },
    setUnderlineOption:(state, action:PayloadAction<Number>) => {
      state.underline = action.payload;
    },
    setFontColorOption:(state, action:PayloadAction<Object>) => {
      state.fontcolor = action.payload;
    },
    setImageFileOption:(state, action:PayloadAction<Object>) => {
      state.imagefile = action.payload;
    },
    setTextOption:(state, action:PayloadAction<string>) => {
      state.text = action.payload;
    },
    setModeOption:(state, action:PayloadAction<Number>) => {
      state.mode = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setOption, 
  setFileOptionList, 
  setRemoveOption, 
  setUploadPath,
  setSelIndex,
  setMaxPageOption,
  setPositionOption,
  setMosaicOption,
  setTransparencyOption,
  setRotationOption,
  setSingleStartOption,
  setSingleEndOption,
  setSelectLayerOption,
  setFontNameOption,
  setFontSizeOption,
  setBoldOption,
  setItalicOption,
  setUnderlineOption,
  setFontColorOption,
  setImageFileOption,
  setTextOption,
  setModeOption,
  setUrlOptionList,
} = watermarkSlice.actions

export default watermarkSlice.reducer