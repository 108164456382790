import { SketchPicker } from 'react-color';
import React, { useState } from 'react';

const ColorPicker = ({proc, value}) => {
    const [showPicker, setShowPicker] = useState(false);
    
    const onChange = (color) => {
        //setColor(color.hex);
        const { r, g, b, a } = color.rgb;
        proc(`rgba(${r},${g},${b},${a})`);
        //console.log(color.hex);
    };
    return (
        <SketchPicker  color={ value } onChange={ onChange }></SketchPicker>
    );
}

export default ColorPicker;

/////////////////////////////////////////
export const getRGBFromRGBA = (rgbaValue) => {
    //rgba( 0, 0, 0, 0.5);

    var ret = '';
    const arry = rgbaValue.split(',');
    ret = arry[0] + ',' + arry[1] + ',' + arry[2] + ')';

    return ret;
}