import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addVertex4Drawpen }	from '../editSlice';
import useMouse from '@react-hook/mouse-position';

const DrawPenContent = ({ pagenum }) => {

	const dispatch = useDispatch();
  	const page_rect = useSelector((state) => state.editOption.page_rect);
  	const vertex_info = useSelector((state) => state.editOption.vertex_info);

  	const ref = React.useRef(null);
  	const [dragging, setDragging] = useState(0);
  	const [drawpath, setDrawpath] = useState(0);

  	const mouse = useMouse(ref, {
	    enterDelay: 100,
	    leaveDelay: 100,
	 });
	
	const contentStyle = {
		top: page_rect.top,
		left: page_rect.left,
		width: page_rect.width,
		height: page_rect.height,
		position: 'absolute',
		backgroundColor: 'none',
		cursor: 'crosshair',
	}

	var oldX = -1, oldY = -1;

	const onDragStart = (e) => {		
  		const payload = {
  			page: pagenum,
  			new: 1,
  			vertex: {x: mouse.x, y: mouse.y},
  		}
  		oldX = mouse.x;
		oldY = mouse.y;
  		dispatch(addVertex4Drawpen(payload));
	    setDragging(1);
	}

	const onThrottleDragMove = (e) => { 
	    if(dragging === 0 || (mouse.x === oldX && mouse.y === oldY))    return;
  		const payload = {
  			page: pagenum,
  			new: 0,
  			vertex: {x: mouse.x, y: mouse.y},
  		}
  		oldX = mouse.x;
		oldY = mouse.y;
  		dispatch(addVertex4Drawpen(payload));
	    setDrawpath(1);
	}

	const onDragEnd = (e) => {
	    if(dragging === 0 || (mouse.x === oldX && mouse.y === oldY))    return;
  		const payload = {
  			page: pagenum,
  			new: 0,
  			vertex: {x: mouse.x, y: mouse.y},
  		}
  		oldX = mouse.x;
		oldY = mouse.y;
  		dispatch(addVertex4Drawpen(payload));
	    setDragging(0);
	}

	var path_element = [];
	for (let i = 0; i < vertex_info[pagenum].length; i++) {
		path_element.push(
			<path className="" fill="none" stroke="#1890ff" strokeWidth="2.3" key={'pagenum' +pagenum .toString() + i.toString()}
					d={generatePathFromVetices(vertex_info[pagenum][i], {x:0, y:0})} strokeLinecap="round" strokeLinejoin="round">
			</path>
		);
	}

	return(
		<div ref={ref}
			style={contentStyle}
			onMouseDown={onDragStart}
		    onMouseMove={onThrottleDragMove}
		    onMouseUp={onDragEnd}
		    onMouseLeave={onDragEnd}>
			<svg width={page_rect.width.toString()} height={page_rect.height.toString()}>
				{path_element}
			</svg>			
		</div>
	);
}

export default DrawPenContent;



export const generatePathFromVetices = (vertex_array, pos) => {
	var str = "";
	for(let i = 0; i < vertex_array.length; i++)
	{
		if (i === 0) {
			str= str + "M" + (vertex_array[i].x - pos.x).toString() + " " + (vertex_array[i].y - pos.y).toString() + " ";
		} else {
			str= str + "L" + (vertex_array[i].x - pos.x).toString() + " " + (vertex_array[i].y - pos.y).toString() + " ";
		}
	}		
	
	return str;
}