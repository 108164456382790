import axios from 'axios';


export const axiosInstance = axios.create({
	//baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:4002' : 'https://api.velog.io/',
	baseURL: 'http://localhost:3000',
	//baseURL: 'http://2.32.26.140:4000',
})

export const domainFrontEnd = 'http://localhost:3000';
