import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setFileOptionList, setMaxPageOption, setSelIndex, setSingleEndOption } from './watermarkSlice';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Badge, Row, Button, Tooltip, Layout} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FileUploader } from "react-drag-drop-files";

import PageElement from './PageElement';
import SelectFileCombo from './SelectFileCombo';
import { useTranslation } from 'react-i18next';
const { Header, Content } = Layout;

var preSelName='';
var bChange=false;
const WorkAreaWatermark = ({scroll}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.watermarkOption.filelist);
    const selindex = useSelector((state) => state.watermarkOption.selindex);
    const maxPage = useSelector((state) => state.watermarkOption.maxPage);
    const url = useSelector((state) => state.watermarkOption.url);

    const dispatch = useDispatch();
    
    const [numPages, setNumbPages] = useState(-1);
    const [pdf, setPdf] = useState(null);
    const [container, setContainer] = useState(null);
    const [curUrl, setCurUrl] = useState('');
    //const [selmode, setSelMode] = useState(-1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumbPages(numPages);
        dispatch(setMaxPageOption(numPages));
        dispatch(setSingleEndOption(numPages));
        bChange=false;
    }

    //alert("page number 1");
    const onAddFileList = (files) => {
        var dFileList = [];
       
        filelist.map(file => (dFileList.push(file)));
        for (var i = 0; i < files.length; i++) {
            dFileList.push(files[i]);
        }

        dispatch(setFileOptionList(dFileList));
        dispatch(setSelIndex(dFileList.length - 1));
    };
    
    if (selindex != -1){
        if (preSelName !== filelist[selindex].name){
            bChange=true;
            preSelName = filelist[selindex].name;
            setCurUrl(url[selindex]);
        }
    }
    else preSelName = '';

    //console.log('curUrl: ', curUrl);

    var cnt = filelist.length;
    var pdf_files_com = [];
    for (var i = 0; i < filelist.length; i++) {
        pdf_files_com.push({id: i, name: filelist[i].name});
    }

    //console.log("scroll: ", scroll);
    
    var top = 0;
    if (scroll != null)
        top = scroll.top
    var value = top * maxPage;
    var start = value - 10;
    var end = value + 10;

    var pdf_element_list = [];
    if (!bChange){
        for (let i = 0; i < maxPage; i++) {
            var show = false;
            if (i >= start & i <= end) show = true;
            pdf_element_list.push(<PageElement id={ i } cnt={cnt} key={'page_' + i} show={show}/>);
        }
    }
    
    return (
        <div className="tool__workarea" style={{paddingTop:100}}>
            
            <Layout  style={{backgroundColor:'#f3f0ec'}}>
                <Header style={{backgroundColor:'#f3f0ec', justifyContent:'center', marginTop:'-70px'}}>
                    <SelectFileCombo filelist={pdf_files_com} selected={selindex}/>
                    <div className="sidetools_pagenumbers">
                        <Row>
                            <div style={{marginBottom:10}}>
                                <Tooltip title={t('tools.watermark.Addmore')} placement="left">
                                    <div className="hover-black-btn shadow-btn" style={{marginTop:'-70px'}}>
                                        <Badge count={cnt} color="#2db7f5" style={{marginTop:'10px'}}>
                                            <FileUploader
                                                multiple={true}
                                                handleChange={onAddFileList}
                                                name="Custom Files"
                                                types={["PDF"]}
                                                children={(<Button 
                                                                type="primary" 
                                                                shape="circle" 
                                                                icon={<PlusOutlined style={{ fontSize: '22px', color: 'white' }}/>} 
                                                                size="large">                                
                                                            </Button>)}
                                            />
                                        </Badge>
                                    </div>
                                </Tooltip>
                            </div>
                        </Row>
                    </div>
                
                </Header>
                <Content style={{backgroundColor:'#f3f0ec', margin:'0 auto'}}>
                    <Document file={curUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {pdf_element_list}
                    </Document>  
                </Content>
            </Layout>
        </div>
    );
}

export default WorkAreaWatermark;