import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {  Button, Layout } from 'antd';

import CustomScrollbars from '../../../components/CustomScrollbars'

//import { setFile} from './signSlice';
import { addElement, setLayer } from './signSlice';
import { SendOutlined } from '@ant-design/icons';
import pointsvg from '../../../assets/svg/tools/sign_side_point.svg';
import datesvg from '../../../assets/svg/tools/sign_side_date.svg';
import namesvg from '../../../assets/svg/tools/sign_side_name.svg';

import{ ReactComponent as PencilIcon } from"../../../assets/svg/tools/sign_side_pencil.svg"

import textsvg from '../../../assets/svg/tools/sign_side_textimage.svg';
import pensvg from '../../../assets/svg/tools/sign_side_gangbi.svg';
import inisvg from '../../../assets/svg/tools/sign_side_ac.svg';
import { useTranslation } from 'react-i18next';
import { OnlyMe } from './SelectModal';
import SignLayer from './SignLayer';
const { Header, Footer, Content } = Layout;

var fontSize = '22pt';
const WorkSideSign = ({onAction}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const file = useSelector((state: RootState) => state.signOption.file);
    const cur_page = useSelector((state: RootState) => state.signOption.cur_page); 
    const page_rect = useSelector((state: RootState) => state.signOption.page_rect);
    const max_page = useSelector((state: RootState) => state.signOption.max_page);
    const onlyme = useSelector((state: RootState) => state.signOption.onlyme);
    const added_elements = useSelector((state: RootState) => state.signOption.added_elements);
    const [sign, setSign] = useState(0);
    const [tab, setTab] = useState(1);
    
    const onSignApplyCancel = (e) => { 
        setSign(0); 
        // update 
        /*if (e === 1){

        }*/
    }

    const onEditClick = (e) => { setSign(1); setTab(e); }
    
    var popupElement = '';
    if (sign === 1){ popupElement = (<OnlyMe initvalue={tab} filename={file} other={onSignApplyCancel}></OnlyMe>); }
    
    var d = new Date();
    const fontName = ['Blackadder ITC','Brush Script MT','Curlz MT','Edwardian Script ITC','Kunstler Script','Lucida Calligraphy','Magneto','Vladimir Script',];
    const onClickSignature = () => {
        if (onlyme.signSel === "1") {
            const payload = {
                page: cur_page,
                element: {
                    type: 'text',
                    page: cur_page,
                    id: 'element_sign_text_img_' + d.getTime().toString(),
                    pos: {x: 50 + page_rect.width / 2, y: 100 + page_rect.top + page_rect.height / 3},
                    size:{w: 120, h:46},
                    text: onlyme.signName,
                    font: fontName[onlyme.signFont - 1],
                    fontSize: fontSize,
                    bold: 0,
                    italic: 1,
                    underline: 0,
                    pen_color: onlyme.signFontColor,//font color
                    fill_color: 'none',//background color
                    align: 'left',
                    opacity: '100%',
                    editable: 0,
                    active: 0,
                    remove: 0,
                },
            };
            dispatch(addElement(payload));
        }else if (onlyme.signSel === "2"){
            const payload = {
                page: cur_page,
                element: {
                    type: 'img',
                    page: cur_page,
                    id: 'element_sign_draw_' + d.getTime().toString(),
                    pos: {x: 0 + page_rect.width / 2 - 60, y: page_rect.top + page_rect.height / 3},
                    size:{w: 120, h:46},
                    imgfile: onlyme.signDrawUrl,
                    opacity: '100%',
                    rotate: 0,
                    pen_color: 'none',//border
                    active: 0,
                    remove: 0,
                },
            };
            dispatch(addElement(payload));
        }else if (onlyme.signSel === "3"){
            const payload = {
                page: cur_page,
                element: {
                    type: 'img',
                    page: cur_page,
                    id: 'element_sign_img_' + d.getTime().toString(),
                    pos: {x: 0 + page_rect.width / 2 - 60, y: page_rect.top + page_rect.height / 3},
                    size:{w: 120, h:120},
                    imgfile: URL.createObjectURL(onlyme.signUpload),
                    opacity: '100%',
                    rotate: 0,
                    pen_color: 'none',//border
                    active: 0,
                    remove: 0,
                },
            };
            dispatch(addElement(payload));
        }
    }

    const onClickInitials = () => {
        if (onlyme.initSel === "1") {
            const payload = {
                page: cur_page,
                element: {
                    type: 'text',
                    page: cur_page,
                    id: 'element_init_text_img_' + d.getTime().toString(),
                    pos: {x: 50 + page_rect.width / 2, y: 100 + page_rect.top + page_rect.height / 3},
                    size:{w: 46, h:46},
                    text: onlyme.initName,
                    font: fontName[onlyme.initFont - 1],
                    fontSize: fontSize,
                    bold: 0,
                    italic: 1,
                    underline: 0,
                    pen_color: onlyme.initFontColor,//font color
                    fill_color: 'none',//background color
                    align: 'left',
                    opacity: '100%',
                    editable: 0,
                    active: 0,
                    remove: 0,
                },
            };
            dispatch(addElement(payload));
        }else if (onlyme.initSel === "2"){
            const payload = {
                page: cur_page,
                element: {
                    type: 'img',
                    page: cur_page,
                    id: 'element_init_draw' + d.getTime().toString(),
                    pos: {x: 0 + page_rect.width / 2 - 60, y: page_rect.top + page_rect.height / 3},
                    size:{w: 120, h:46},
                    imgfile: onlyme.initDrawUrl,
                    opacity: '100%',
                    rotate: 0,
                    pen_color: 'none',//border
                    active: 0,
                    remove: 0,
                },
            };
            dispatch(addElement(payload));
        }else if (onlyme.initSel === "3"){
            const payload = {
                page: cur_page,
                element: {
                    type: 'img',
                    page: cur_page,
                    id: 'element_init_img_' + d.getTime().toString(),
                    pos: {x: 0 + page_rect.width / 2 - 60, y: page_rect.top + page_rect.height / 3},
                    size:{w: 120, h:120},
                    imgfile: URL.createObjectURL(onlyme.initUpload),
                    opacity: '100%',
                    rotate: 0,
                    pen_color: 'none',//border
                    active: 0,
                    remove: 0,
                },
            };
            dispatch(addElement(payload));
        }
    }

    const onClickName = () => { 
        const payload = {
            page: cur_page,
            element: {
                type: 'text',
                page: cur_page,
                id: 'element_sign_name_' + d.getTime().toString(),
                pos: {x: 150 + page_rect.width / 2, y: 150 + page_rect.top + page_rect.height / 3},
                size:{w: 150, h:46},
                text: onlyme.signName,
                font: "Arial",
                fontSize: fontSize,
                bold: 0,
                italic: 0,
                underline: 0,
                pen_color: '#000000',//font color
                fill_color: 'none',//background color
                align: 'left',
                opacity: '100%',
                editable: 0,
                active: 0,
                remove: 0,
            },
        };
        dispatch(addElement(payload));
    }

    const onClickDate = () => { 
        var _month = d.getMonth() + 1;
        var _date = d.getDate();

        if (d.getMonth() + 1 < 10)   _month = '0' + (d.getMonth() + 1);
        if (d.getDate() < 10)        _date = '0' + d.getDate();

        var dateText = d.getFullYear() + '-' + _month + '-' + _date;
        const payload = {
            page: cur_page,
            element: {
                type: 'text',
                page: cur_page,
                id: 'element_' + d.getTime().toString(),
                pos: {x: 50 + page_rect.width / 2, y: 250 + page_rect.top + page_rect.height / 3},
                size:{w: 155, h:46},
                text: dateText,
                font: "Arial",
                fontSize: fontSize,
                bold: 0,
                italic: 0,
                underline: 0,
                pen_color: '#000000',//font color
                fill_color: 'none',//background color
                align: 'left',
                opacity: '100%',
                editable: 0,
                active: 0,
                remove: 0,
            },
        };
        dispatch(addElement(payload));
    }
    
    //const onChangePageNumber = (value) => {
    //    dispatch(setCurrentPage({page:value, changed:1}));
    //}
    const onClickAddText = () => {
        const payload = {
            page: cur_page,
            element: {
                type: 'text',
                page: cur_page,
                id: 'element_' + d.getTime().toString(),
                pos: {x: 50 + page_rect.width / 2, y: 50 + page_rect.top + page_rect.height / 3},
                size:{w: 230, h:46},
                text: 'Your text here',
                font: "Arial",
                fontSize: fontSize,
                bold: 0,
                italic: 0,
                underline: 0,
                pen_color: '#000000',//font color
                fill_color: 'none',//background color
                align: 'left',
                opacity: '100%',
                editable: 0,
                active: 0,
                remove: 0,
            },
        };
        dispatch(addElement(payload));
    }

    const onClickAction = () => {onAction();}
    const onClickSignatureLayer = (e) => {
        //console.log("signature :", e);
        dispatch(setLayer(e));
    }

    var cnt = 0;
    for (var  i = 1; i <= max_page; i ++)
        for (var j = 0; j < added_elements[i].length; j ++){
            if (added_elements[i][j].remove === 1)
                continue;
            if (added_elements[i][j].id.search("sign") !== -1) {
                cnt ++; continue;
            }
            if (added_elements[i][j].id.search("init") !== -1) {
                cnt ++; continue;
            }
        }
    return (
        <>
            {popupElement}
            <div className="tool__sidebar">
                <Layout>
                    <Header>SIGN PDF</Header>
                    <Content>
                        <div style={{height:'calc(100vh - 152px - 60px)'}}>
                            <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}>
                                <SignLayer pro={onClickSignatureLayer} />
                                <SideItem tag={1} image={pensvg} text={t('tools.sign.signature')} count={0} proc={onClickSignature} edit={onEditClick}/>
                                <SideItem tag={2} image={inisvg} text={t('tools.sign.initials')} count={0} proc={onClickInitials} edit={onEditClick}/>
                                <SideItem tag={0} image={namesvg} text={t('tools.sign.name')} count={0} proc={onClickName}/>
                                <SideItem tag={0} image={datesvg} text={t('tools.sign.date')} count={0} proc={onClickDate}/>
                                <SideItem tag={0} image={textsvg} text={t('tools.sign.text')} count={0} proc={onClickAddText}/>
                            </CustomScrollbars>
                        </div>
                    </Content>
                    <Footer>
                        <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt >= 1 ? false:true}>
                        {t('tools.sign.SignPDF')}
                        </Button>
                    </Footer>
                </Layout>
            </div>
        </>
    );
}

export default WorkSideSign;

const SideItem = ({tag, image, text, count, proc, edit}) => {
    const onlyme = useSelector((state: RootState) => state.signOption.onlyme);

    const onEditClick = () => {
        edit(tag);
    }

    var preElem = '';
    if (onlyme != null){
        const fontName = ['Blackadder ITC','Brush Script MT','Curlz MT','Edwardian Script ITC','Kunstler Script','Lucida Calligraphy','Magneto','Vladimir Script',];
        if (tag === 1) {
            if(onlyme.signSel === "2")          preElem = (<img alt='draw' src={onlyme.signDrawUrl} style={{width:'80px', height:'40px'}}></img>);
            else if(onlyme.signSel === "3")     preElem = (<img alt='upload' src={URL.createObjectURL(onlyme.signUpload)} style={{width:'30px', height:'30px'}}></img>);
            else if(onlyme.signSel === "1")     preElem = (<div style={{fontFamily:fontName[onlyme.signFont-1], color:onlyme.signFontColor}}> <i>{onlyme.signName}</i> </div>);
        }
        else if (tag === 2)
        {
            if(onlyme.initSel === "2")          preElem = (<img alt='draw' src={onlyme.initDrawUrl} style={{width:'80px', height:'40px'}}></img>);
            else if(onlyme.initSel === "3")     preElem = (<img alt='upload' src={URL.createObjectURL(onlyme.initUpload)} style={{width:'30px', height:'30px'}}></img>);
            else if (onlyme.initSel === "1")    preElem = (<div style={{fontFamily:fontName[onlyme.initFont-1], color:onlyme.initFontColor}}> <i>{onlyme.initName}</i> </div>);
        }
    }
    //console.log("preview: ",preview);
    var clsName = "sign__draggable draggable";
    var previewElement = '';
    var editElement = '';
    if (tag >= 1){
        clsName = "sign__draggable draggable preview__active";
        previewElement = (
            <div className="sign__draggable__preview">
                <div className="signature__area__signature">
                    <div className="signature__preview signature__preview--initials">
                        {preElem}
                    </div>
                </div>
            </div>
        );
        editElement = (
            <div className="sign__draggable__actions__edit" onClick={onEditClick}>
                <PencilIcon style={{width:'50%', fill:'#4a7aab', marginLeft:'8px'}} />
            </div>
        );
    }

    var countElement = '';
    if (count > 0)
        countElement = (
            <div className="sign__draggable__count">
                <span>{count}</span>
            </div>
        );
        
    return (
        <div className={clsName} style={{width:'95%'}} onDoubleClick={proc}>
            <div className="sign__draggable__content sign__draggable__content--btn" style={{marginLeft:'10px'}}>
                <div className="sign__draggable__drag" style={{marginRight:'10px', marginLeft:'10px'}}>
                    <img src={pointsvg} alt='point'/>
                </div>
                <div className="sign__draggable__icon">
                    <img src={image} alt='drag icon' />
                </div>
                <div className="sign__draggable__text">
                {text}
                </div>
                {previewElement}
                <div className="sign__draggable__actions">
                    {editElement}
                    {countElement}
                </div>
            </div>
        </div>
    );
}

