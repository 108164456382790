import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SignOptionState {
  tool_header: string,
  uploadpath: Array,
  file: File,
  url: String,
  pdf: Object,
  max_page: Number,
  cur_page: Number,
  page_changed: Number,
  page_rect: Object,
  viewscale: Number,
  panselect: Number,
  added_elements: Array,
  id_object: Object,
  selected_element: Object,
  enable_draw: Number,
  vertex_info: Array,

  onlyme: Object,
  placeto: Array,
  layer: Number,
  imagelist: Array,
}

const initialState: SignOptionState = {
  tool_header: "sign",
  file: null,
  url: '',
  pdf: null,
  max_page: 0,
  cur_page: 1,
  page_changed: 0,
  page_rect: {left: 0, top: 0, width: 0, height: 0},
  viewscale: 1.0,
  panselect: 0,
  added_elements: [],
  id_object: {},
  selected_element: {page: -1, idx: -1},
  enable_draw: 0,
  vertex_info: [],
  onlyme: null,
  placeto: [],
  layer: 0,
  imagelist:[],
}

function clone(source) {
  var target = {};
  for (let i in source) {
    if (source[i] != null && typeof source[i] === "object") {
      target[i] = clone(source[i]); // resursion
    } else {
      target[i] = source[i];
    }
  }
  return target;
}

function myclone(element) {
  var target = {};
  if (element.type === 'text')
  {
    target = {
      type: 'text',
      page: element.cur_page,
      id: element.id,
      pos: {x: element.pos.x, y: element.pos.y},
      size:{w: element.size.w, h: element.size.h},
      text: element.text,
      font: element.font,
      fontSize: element.fontSize,
      bold: 0,
      italic: element.italic,
      underline: 0,
      pen_color: element.pen_color,
      fill_color: 'none',
      align: 'left',
      opacity: '100%',
      editable: 0,
      active: 0,
      remove: 0,
    }
  }
  else if (element.type === 'img')
  {
    target = {
      type: 'img',
      page: element.cur_page,
      id: element.id,
      pos: {x: element.pos.x, y: element.pos.y},
      size:{w: element.size.w, h: element.size.h},
      imgfile: element.imgfile,
      opacity: '100%',
      rotate: 0,
      pen_color: 'none',//border
      active: 0,
      remove: 0,
    }
  }
  return target;
}

export const signSlice = createSlice({
  name: 'signOption',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setFile: (state, action) => {
      state.file = action.payload;
      state.url = URL.createObjectURL(state.file);
    },
    
    setPdf: (state, action) => {
      state.pdf = action.payload;
    },
    setMaxPage: (state, action:PayloadAction<number>) =>{
      state.max_page = action.payload;
      for (let i = 0; i <= action.payload; i++) {
        state.added_elements.push([]);
        state.vertex_info.push([]);
      }
    },
    setCurrentPage: (state, action) => {
      if(state.cur_page === action.payload.page)  return;
      
      state.cur_page = action.payload.page;
      state.page_changed = action.payload.changed;
      //console.log('slice: ', state.cur_page);
    },
    setPageChanged: (state, action) => {
      state.page_changed = action.payload;
    },
    setPageRect: (state, action) => {
      state.page_rect = action.payload;
    },
    setPanSelect: (state, action) => {
      state.panselect = action.payload;
    },
    setPageViewScale: (state, action) => {
      state.viewscale = action.payload;
    },
    setEnableDraw: (state, action) => {
      state.enable_draw = action.payload;
      if(action.payload === 1){
        //reset vertex info//
        for (let i = 0; i <= state.max_page; i++) {
          state.vertex_info[i] = [];
        }
      }
    },
    addElement: (state, action) => {
      state.added_elements[action.payload.page].push(action.payload.element);
      
      //console.log("slice add element: ", action.payload.element);
      state.id_object[action.payload.id] = {
        page: action.payload.page,
        idx: state.added_elements[action.payload.page].length - 1
      }

      //set selected element//
      if(state.selected_element.page >= 0)
        state.added_elements[state.selected_element.page][state.selected_element.idx].active = 0;
      state.selected_element = {page: state.id_object[action.payload.id].page, idx: state.id_object[action.payload.id].idx};
      state.added_elements[state.selected_element.page][state.selected_element.idx].active = 1;
    },
    updateSizeElement:(state, action) => {
      //page, id, pos, size//
      state.added_elements[action.payload.page][action.payload.idx].pos = action.payload.pos;
      state.added_elements[action.payload.page][action.payload.idx].size = action.payload.size;
    },    
    removeElement: (state, action) => {
      for (var i = 1; i <= state.max_page; i ++)
        for (var j = 0; j < state.added_elements[i].length; j ++)
          if (state.added_elements[i][j].id === action.payload.idx)
            state.added_elements[i][j].remove = 1;
      //state.added_elements[action.payload.page][action.payload.idx].remove = 1;
    },
    setSeletedElement: (state, action) => {
      //for (var i = 1; i < state.max_page; i ++)
      //  for (var j = 0; j < state.added_elements[i].length; j ++)
      //    state.added_elements[i][j].active = 0;
      //state.added_elements[state.selected_element.page][state.selected_element.idx].active = 0;   
      if(state.selected_element.page === action.payload.page && state.selected_element.idx === action.payload.idx)   return;

      var old = state.selected_element;
      if(old.page >= 0)//old selected//
      {
        state.added_elements[old.page][old.idx].active = 0;

        //old element text not editable//
        if(state.added_elements[old.page][old.idx].type==="text")
            state.added_elements[old.page][old.idx].editable = 0;
      }   
      state.selected_element = action.payload;
      state.added_elements[action.payload.page][action.payload.idx].active = 1;
    },
    setTextEditable: (state, action) => {
      //set text editable
      if(state.added_elements[action.payload.page][action.payload.idx].type === "text")
        state.added_elements[action.payload.page][action.payload.idx].editable = action.payload.editable;
      
      //set selected element//
      state.selected_element = action.payload;
      state.added_elements[action.payload.page][action.payload.idx].active = 1;
    },
    updateBorderColor: (state, action) => {
      state.added_elements[action.payload.page][action.payload.idx].border = action.payload.border;
    },
    updateOpacity: (state, action) => {
      state.added_elements[action.payload.page][action.payload.idx].opacity = action.payload.opacity;
    },
    addVertex4Drawpen: (state, action) => {
      if(action.payload.new === 1){
          state.vertex_info[action.payload.page].push([]);
      }
      
      state.vertex_info[action.payload.page][state.vertex_info[action.payload.page].length - 1].push(action.payload.vertex);            
    },
    resetVertexInfo: (state, action) => {
      for (let i = 0; i <= state.max_page; i++) {
        state.vertex_info[i] = [];
      }
    },
    updateElementText: (state, action) => {
      state.added_elements[action.payload.page][action.payload.idx].text = action.payload.text;
    },

    updateElementPos: (state, action) => {
      //console.log("updatepos, id[%s] left[%d] top[%d]", action.payload.idx, action.payload.left, action.payload.top);
      for (var i = 1; i <= state.max_page; i ++){
        for (var j = 0; j < state.added_elements[i].length; j ++)
        {
          if (state.added_elements[i][j].id === action.payload.idx)
          {
            //console.log("find org(%d, %d)", state.added_elements[i][j].pos.x, state.added_elements[i][j].pos.y);
            state.added_elements[i][j].pos.x = action.payload.left;
            state.added_elements[i][j].pos.y = action.payload.top;
          }
        }
      }
    },
    
    viewLog: (state, action) => {
      //console.log("slice place view :", state.placeto.length);
      //for (var i = 0; i < state.placeto.length; i ++)
      //  console.log("[%d]th from[%d] to[%d]", i, state.placeto[i].from, state.placeto[i].to);
    },
    setPlaceToAdd: (state, action) => {
      //console.log("slice place add :", action.payload);
      state.placeto.push(action.payload);
    },

    setPlaceToChange: (state, action) => {
      //console.log("slice place change: ", action.payload);
      if (action.payload.type === 'from')
        state.placeto[action.payload.index].from = action.payload.from;
      else if (action.payload.type === 'to')
        state.placeto[action.payload.index].to = action.payload.to;
      else if (action.payload.type === "remove")//state.filelist.splice(state.selindex, 1);
        state.placeto.splice(action.payload.index, 1);
    },
    unactiveSeletedElement: (state, action) => {
      if(state.selected_element.page < 0) return;
  
      state.added_elements[state.selected_element.page][state.selected_element.idx].active = 0;
  
      //text not editable//
      if(state.added_elements[state.selected_element.page][state.selected_element.idx].type==="text")
        state.added_elements[state.selected_element.page][state.selected_element.idx].editable = 0;
  
      state.selected_element = {page: -1, idx: -1};      
    },
    
    addImageList: (state, action) => {
      //console.log("imagelist is added: ", action.payload.file.name);
      //console.log("imagelist is added: ", action.payload);
      //if (state.imagelist.search(action.payload) == -1)
      var bexist = false;
      for (var i = 0; i < state.imagelist.length; i ++)
        if (state.imagelist[i].file.name === action.payload.file.name)
          bexist = true;

      if (!bexist)
      {
        state.imagelist.push(action.payload);
        //console.log("addition success");
      }  
    },
    setPlacetoPages: (state, action) => {
      //console.log("placetopage: ", action.payload);
      var element = state.added_elements[action.payload.page][action.payload.idx];
      
      // check if element is already added in list
      var bexist = false;
      for (var i = 1; i <= state.max_page; i ++){
        if (i === action.payload.page) continue;

        for (var j = 0; j < state.added_elements[i].length; j ++){
          if (element.id === state.added_elements[i][j].id){
            bexist = true;
            //console.log("element[%s] is already exist", element.id);
            break;
          }
        }
        break;
      }
      if (!bexist)
        for (i = 1; i <= state.max_page; i ++){
          if (i === action.payload.page) continue;
          var newelement = myclone(element);
          newelement.cur_page = i;
          state.added_elements[i].push(newelement);
        }
      if (action.payload.select === 2) {  //All pages
        if (bexist)
          for (i = 0; i <= state.max_page; i ++){
            for (j = 0; j < state.added_elements[i].length; j ++){
              if (state.added_elements[state.max_page][j].id === element.id){
                state.added_elements[state.max_page][j].remove = 0;
                break;
              }
            } 
          }
      }
      else if (action.payload.select === 3) {  //All pages but last
        if (!bexist) {
          for (j = 0; j < state.added_elements[state.max_page].length; j ++){
            if (state.added_elements[state.max_page][j].id === element.id){
              state.added_elements[state.max_page][j].remove = 1;
              break;
            }
          }
        }
        else{
          for (i = 0; i <= state.max_page; i ++){
            for (j = 0; j < state.added_elements[i].length; j ++){
              if (state.added_elements[state.max_page][j].id === element.id){
                if (i === state.max_page)
                  state.added_elements[state.max_page][j].remove = 1;
                else
                  state.added_elements[state.max_page][j].remove = 0;
                break;
              }
            } 
          }
        }
      }
      else if (action.payload.select === 4) { //Last page
        if (!bexist) {
          for (i = 1; i < state.max_page; i ++)
            for (j = 0; j < state.added_elements[i].length; j ++){
              if (state.added_elements[i][j].id === element.id){
                state.added_elements[i][j].remove = 1;
                break;
              }
            }
        }
        else {
          for (i = 1; i <= state.max_page; i ++)
            for (j = 0; j < state.added_elements[i].length; j ++){
              if (state.added_elements[i][j].id === element.id){
                if (i === state.max_page)
                  state.added_elements[i][j].remove = 0;
                else
                  state.added_elements[i][j].remove = 1;
                break;
              }
            }
        }
      }
      else if (action.payload.select === 5) { //custom page
        var pages = [];
        for (i = 0; i < state.placeto.length; i ++)
          for (j = state.placeto[i].from; j <= state.placeto[i].to; j ++)
            pages.push(j);
            
        for (var k = 1; k <= state.max_page; k ++){
          var a = pages.indexOf(k);
          for (j = 0; j < state.added_elements[k].length; j ++){
            if (state.added_elements[k][j].id === element.id){
              if (a === -1)
                state.added_elements[k][j].remove = 1;
              else
                state.added_elements[k][j].remove = 0;
              break;
            }
          }
        }
      }
      
    },
    setLayer: (state, action) => {
      state.layer = action.payload;
    },
    setOnlyMe: (state, action) => {
      //var prevOnlyme = state.onlyme;
      state.onlyme = action.payload;
      const fontName = ['Blackadder ITC','Brush Script MT','Curlz MT','Edwardian Script ITC','Kunstler Script','Lucida Calligraphy','Magneto','Vladimir Script',];
      //['Arial','Calibri','Cambria','Corbel','Comic Sans MS','Consolas','Constantia','Courie New','Georgia','Palatino Linotype','Sitka Heading','Times New Roman','Verdana'];
      var onlyme = state.onlyme;
      //console.log("slice only me: ", onlyme);
      if (onlyme.signName === '')
        return;

      for (let i = 0; i <= state.max_page; i ++)
      {
        for (let j = 0; j < state.added_elements[i].length; j ++)
        {
          if (state.added_elements[i][j].id.search("sign_text_") !== -1){
            state.added_elements[i][j].text = onlyme.signName;
            state.added_elements[i][j].font = fontName[onlyme.signFont - 1];
            state.added_elements[i][j].pen_color = onlyme.signFontColor;
          }
          else if (state.added_elements[i][j].id.search("sign_name_") !== -1){
            state.added_elements[i][j].text = onlyme.signName;
          }
          else if (state.added_elements[i][j].id.search("sign_draw_") !== -1){
            state.added_elements[i][j].imgfile = onlyme.signDrawUrl;
          }
          else if (state.added_elements[i][j].id.search("sign_img_") !== -1){
            state.added_elements[i][j].imgfile = URL.createObjectURL(onlyme.signUpload);
          }
          else if (state.added_elements[i][j].id.search("init_text_") !== -1){
            state.added_elements[i][j].text = onlyme.initName;
            state.added_elements[i][j].font = fontName[onlyme.initFont - 1];
            state.added_elements[i][j].pen_color = onlyme.initFontColor;
          }
          else if (state.added_elements[i][j].id.search("init_draw_") !== -1){
            state.added_elements[i][j].imgfile = onlyme.initDrawUrl;
          }
          else if (state.added_elements[i][j].id.search("init_img_") !== -1){
            state.added_elements[i][j].imgfile = URL.createObjectURL(onlyme.initUpload);
          }
        }
      }
    }
    
  },
  
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setFile, 
  setPdf,
  setMaxPage, 
  setCurrentPage, 
  setPageRect, 
  setPanSelect, 
  setPageViewScale,
  addElement, 
  removeElement,
  setPageChanged,
  updateSizeElement,
  setSeletedElement,
  setTextEditable,
  updateBorderColor,
  updateOpacity,
  setEnableDraw,
  addVertex4Drawpen,
  resetVertexInfo,
  updateElementText,
  setOnlyMe,
  setPlaceToAdd,
  setPlaceToChange,
  viewLog,
  setPlacetoPages,
  updateElementPos,
  setLayer,
  addImageList,
  unactiveSeletedElement,
} = signSlice.actions

export default signSlice.reducer