import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../editSlice';

import { Page } from 'react-pdf/dist/esm/entry.webpack';
import { Scrollbars } from 'react-custom-scrollbars';


const PageElement = ({ pagenum, clsname, viewpage }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setCurrentPage({page:pagenum, changed:1}));
  }

  var one_page = '';
  if((viewpage - 1 <= pagenum) && (pagenum <= viewpage + 6)){
    one_page = ( <Page pageNumber={pagenum} onClick={onClick} loading=""/> );
  } else {
    one_page = ( <div className='one_page' style={{width: 100, height: 135}} onClick={onClick}/> )
  }

  return(
    <div style={{marginBottom:40, marginTop: pagenum===1? 20:0}}>
      <div className={clsname}>
        {one_page}
        {pagenum}
      </div>
    </div>
  );
}


//====Sleep Function=====//
function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}

/*********************************************************************************/
var page_render = 1;
const PagePreviews = () => {
  const previewRef = useRef();

  const [viewpage, setViewPage] = useState(1);

  const cur_page = useSelector((state) => state.editOption.cur_page); 
  const max_page = useSelector((state) => state.editOption.max_page);   

  /*const handleClick = () => {
    const values = previewRef.current.getValues();
    previewRef.current.scrollTop(values.scrollHeight / 2);
  }*/
  useEffect(() => {
      //component mount//
      if (previewRef.current) {
        //const { scrollHeight } = previewRef.current.getValues();
        var top = (cur_page - 1) * (135 + 40) - 40;
        //previewRef.current.scrollTop(top);
        if(previewRef.current)    smoothTop(top);
      }

      return () => {
        //component will unmount
      };
  }, [cur_page]);

  
  const onScrollFrame = (values) => {
    //console.log('onScroll', values);
    const { scrollTop } = values;
    var minPage = parseInt((scrollTop - 20) / (135 + 40)) + 1;
    if(viewpage !== minPage && page_render)
      setViewPage(minPage);
  }

  const smoothTop = async (top) => {
    if(previewRef === undefined || previewRef === null )   return;
    page_render = 0;
    const { scrollTop } = previewRef.current.getValues();
    /*previewRef.current.scrollTop(top);
    console.log(cur_page, scrollTop, top);
    return;*/

    const _top_interval = (top - scrollTop);
    var n = 50;
    if(Math.abs(_top_interval) < 100)   n = 10;
    else if (Math.abs(_top_interval) < 300)   n = 15;
    else if (Math.abs(_top_interval) < 700)   n = 30;

    for (let i = 0; i < n; i++){
      previewRef.current.scrollTop(scrollTop + (_top_interval / n) * (i + 1));  
      await sleep(3);
    }

    page_render = 1;
    //view page setting//
    var minPage = parseInt((previewRef.current.getValues().scrollTop - 20) / (135 + 40)) + 1;
    if(viewpage !== minPage && page_render)
      setViewPage(minPage);
  }

  ///////////////////rendering////////////////
  var elements = [];
  for (let i = 0; i < max_page; i++){
    var clsname = 'pdf-canvas__previews';
    if(cur_page === i + 1)  clsname = clsname + ' active';
    
    elements.push(
      <PageElement 
        pagenum={i+1} 
        clsname={clsname} 
        key={'page_preview_' + i.toString()}
        viewpage={viewpage}/>
    );
  }
  
  return (
      <div className="editpdf__pagepreview">
        <Scrollbars style={{ width: '100%', height: '100%' }} ref={previewRef} onScrollFrame={onScrollFrame}>
            {elements}
        </Scrollbars>
      </div>
  );
}

export default PagePreviews;