import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFileOptionList } from './rotateSlice';

import { Badge, Row, Button } from 'antd';

import SelectFiles from '../SelectFiles';
import WorkSpaceRotate from './WorkSpaceRotate';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';

import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

var links = [];
const RotatePDF = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    //const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);

    const filelist = useSelector((state) => state.rotateOption.filelist);

    const id = 14;

    const goProcFunction = (files) => {        
        //setFile(files);
        var initFileList = [];
        for (var i = 0; i < files.length; i++) {
            initFileList.push({
                idx: i,
                file: files[i],
                url: URL.createObjectURL(files[i]),
                remove: 0,
                rotate: 0,
                select: 0,
            });
        }

        dispatch(setFileOptionList(initFileList));

        setProc(1);
    }

    const goAction = () => {
        //rotate action//
        setProc(2);        
    }

    
    const goDownload = (downlinks) => {
        //rotate download action//
        links = downlinks;
        setProc(3);
    }


    /*****************rendering*****************/
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles id={id} proc={goProcFunction} multiple={true} btn_text={t('tools.rotate.Select')} fileTypes = {["PDF"]}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpaceRotate goAction={goAction}/>
            </div>
        );
    } else if (proc === 2) {//action space
        //option payload//
        var _files = [];
        var rotate = [];
        for (var i = 0; i < filelist.length; i++) {
            if(filelist[i].remove !== 0)  continue;
            _files.push(filelist[i].file);
            rotate.push(filelist[i].rotate);
            //formData.append("files",  filelist[i]);
        }

        var option = {
            rotate: rotate,
        }

        //uploading//
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api} 
                    goDownload={ goDownload } 
                    text={t('tools.rotate.RotatingPDF')}
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default RotatePDF;