import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateSizeElement, updateRotateElement, setSeletedElement, setTextEditable } from '../editSlice';

import ResizableRect from 'react-resizable-rotatable-draggable';

import SubContent from './SubContent';

const ResizableContent = props => {
  const { page_rect, active } = props;

  const {pagenum, idx, element} = props;
  const dispatch = useDispatch();

  const [width, setWidth] = useState(props.width)
  const [height, setHeight] = useState(props.height)
  const [top, setTop] = useState(props.top)
  const [left, setLeft] = useState(props.left)
  const [rotateAngle, setRotateAngle] = useState(props.rotateAngle)

  var paddingDepth = element.type === "pen"?0:0;

  const checkRange = (top, left, width, height) => {
    var ret = true;
    if (top < page_rect.top - paddingDepth){
      ret = false;
    } else if(left < page_rect.left - paddingDepth){
      ret = false;
    } else if(top + height > page_rect.top + page_rect.height + paddingDepth){
      ret = false;
    } else if(left + width > page_rect.left + page_rect.width + paddingDepth){
      ret = false;
    }
    return ret;
  }

  const handleResize = (style, isShiftKey, type) => {    
    const { top, left, width, height } = style
    if(checkRange(top, left, width, height)){
      setWidth(Math.round(width))
      setHeight(Math.round(height))
      setTop(Math.round(top))
      setLeft(Math.round(left))
    }
  }
  
  const handleResizeStop = () => {
      const payload = {
        page: pagenum,
        idx: idx,
        pos:{x: left, y: top},
        size:{w: width, h: height},
      }
      dispatch(updateSizeElement(payload));
  }

  const handleRotate = rotateAngle => {  	
    setRotateAngle(rotateAngle)
  }
  
  const handleRotateEnd = () => {  	
    //alert(rotateAngle);
    const payload = {
      page: pagenum,
      idx: idx,
      rotate: rotateAngle,
    }
    dispatch(updateRotateElement(payload));
  }

  const handleDrag = (deltaX, deltaY) => {
  	if(checkRange(top + deltaY, left + deltaX, width, height)){
      setTop(top + deltaY)
      setLeft(left + deltaX)
    }
  }
  
  const handleDragEnd = () => {  	
    const payload = {
      page: pagenum,
      idx: idx,
      pos:{x: left, y: top},
      size:{w: width, h: height},
    }
    dispatch(updateSizeElement(payload));
  }

  const onClickElement = () => {
    const payload = {
      page: pagenum,
      idx: idx
    }

    dispatch(setSeletedElement(payload));
  }

  const onDblClick = () => {
    if(element.type == "text")
    {
        const payload = {
          page: pagenum,
          idx: idx,
          editable: 1,
        }

        dispatch(setTextEditable(payload));
    }
  }  

  var zoomable = "n, w, s, e, nw, ne, se, sw";
  if(element.type === "pen")
  {
    zoomable = "";
  } 
  else if(element.type === "line")
  {
    zoomable = "w, e";
  }


  var resizable_rect = "";
  if(active){
    //console.log(document.getElementById(props.id), left, top, width, height);
    resizable_rect = (
      <ResizableRect
        top={top - paddingDepth}
        left={left - paddingDepth}
        width={width + paddingDepth * 2}
        height={height + paddingDepth * 2}
        minWidth={10 * 2}        
        minHeight={10 * 2}
        onDrag={handleDrag}
    	  onDragEnd={handleDragEnd}
        onRotate={handleRotate}
    	  onRotateEnd={handleRotateEnd}
        onResize={handleResize}
    	  onResizeEnd={handleResizeStop}
        aspectRatio={false}
        zoomable={zoomable}
        rotatable={true}
        rotateAngle={rotateAngle}
      />
    );
  }

  const rect = {
    left: left - page_rect.left,
    top: top,
    width: width,
    height: height
  }

  var zIndex = 0;
  if(element.type === 'text' && element.editable)   zIndex = 1;

  const contentStyle = {
    top: top,
    left: left,
    width: width,
    height: height,
    position: 'absolute',
    transform: `rotate(${rotateAngle}deg)`,
    padding: paddingDepth,
    zIndex: zIndex,
  }

  return (
  	<div 
      id={element.id} 
      onMouseDown={onClickElement} 
      onDoubleClick={onDblClick}>
      
      <div style={contentStyle}>
        <SubContent pagenum={pagenum} idx={idx} element={element} rect={rect}/>
      </div>
      
      {resizable_rect}
    </div>
  )
}

export default ResizableContent
