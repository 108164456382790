import React, { useState }  from 'react';
import Tools from './tools/Tools';
import Medium from './Medium';
import Footer from './Footer';

import Languages, { getCurrentLanguageText } from './Languages';
import { Dropdown, Space } from 'antd';
import { GlobalOutlined, DownOutlined } from '@ant-design/icons';

//lang test//
import { useTranslation } from 'react-i18next';

const Home = ({setChangedLang}) => {
  const {t} = useTranslation();

  const [langtext, setLangText] = useState(getCurrentLanguageText());

  const setLanguage = (lang, langText) => {
    setLangText(langText);
    setChangedLang(lang);    
  }

  return (
    <div className="home ">
      	<div className="title">
            <div className='language-part'>
              <Dropdown overlay={<Languages setLanguage={setLanguage}/>}>
                <Space>
                  <GlobalOutlined />
                  {langtext}
                  <DownOutlined style={{fontSize:10}}/>
                </Space>
              </Dropdown>
            </div>

      		  <h1>{t("home.title")}</h1>
  			    <label style={{margin:0}}>{t("home.subtitle")}</label>                  
      	</div>
        <div style={{backgroundColor: '#E4E9F1'}} className="container-fluid centered">
              <svg style={{marginBottom: '-7px', marginTop: '40px'}} preserveAspectRatio="none" viewBox="0 0 1280 215" height="100" width="100%" version="1.1">
                <path d="M1280 163.653V215H0V5.61c26.826 46.8 98.357 51.079 214.593 12.836 222.612-73.24 273.824 98.742 453.222 54.775 179.398-43.967 201.285 39.675 334.641 105.96 81.17 40.346 173.684 35.17 277.544-15.528z" fillRule="evenodd" fill="rgb(243, 240, 236)"></path>
              </svg>
        </div>
        
		  <Tools/>
    	<Medium/>
    	<Footer/>
    </div>
  );
}

export default Home;