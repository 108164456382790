import React from 'react';

import { useDispatch } from 'react-redux'
import { setTool, setOnlyMe } from './signSlice';

import WorkAreaSign from './WorkAreaSign';
import WorkSideSign from './WorkSideSign';
import { Scrollbars } from 'react-custom-scrollbars';
import { setFile } from './signSlice';

const WorkSpaceSign = ({ files, goAction }) => {
    const dispatch = useDispatch();

    
    const onAction = (filelist) => {        
        goAction();
    }


    /***********rendering**********/
    return (
        <>
            
            <WorkAreaSign/>
            <WorkSideSign onAction={onAction}/>
            
        </>
    );
}

export default WorkSpaceSign;