import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { 
    en,
    it,
    cn,
    es,
    fr,
    de,
    pt,
    ru,
    ko,
    ja,
    ar,
} from './locales';

const resource = {
    en: { translations: en },
    it: { translations: it },
    cn: { translations: cn },
    es: { translations: es },
    fr: { translations: fr },
    de: { translations: de },
    pt: { translations: pt },
    ru: { translations: ru },
    ko: { translations: ko },
    ja: { translations: ja },
    ar: { translations: ar },
}

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .init({
        resources:resource,
        // initial language setting//
        lng: "en",
        fallbackLng: "en",
        debug: false,
        defaultNS: "translations",
        ns: "translations",
        keySeparator: ".", // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        //Detects and caches a cookie from the language provided
        detection: {
          order: ['queryString', 'cookie'],
          cache: ['cookie']
        },
        react: {
          wait: true
        }
    })

export default i18n;