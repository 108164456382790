import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFileOptionList, setUrlOptionList } from './watermarkSlice';

import SelectFiles from '../SelectFiles';
import WorkSpaceWatermark from './WorkSpaceWatermark';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';
import { useTranslation } from 'react-i18next';
var links = [];
const Watermark = () => {
    const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.watermarkOption.filelist);
    const mode = useSelector((state) => state.watermarkOption.mode);
    const text = useSelector((state) => state.watermarkOption.text);
    const imagefile = useSelector((state) => state.watermarkOption.imagefile);
    const fontname = useSelector((state) => state.watermarkOption.fontname);
    const fontsize = useSelector((state) => state.watermarkOption.fontsize);
    const bold = useSelector((state) => state.watermarkOption.bold);
    const italic = useSelector((state) => state.watermarkOption.italic);
    const underline = useSelector((state) => state.watermarkOption.underline);
    const fontcolor = useSelector((state) => state.watermarkOption.fontcolor);

    const position = useSelector((state) => state.watermarkOption.position);
    const mosaic = useSelector((state) => state.watermarkOption.mosaic);
    const transparency = useSelector((state) => state.watermarkOption.transparency);
    const rotation = useSelector((state) => state.watermarkOption.rotation);

    const singlestart = useSelector((state) => state.watermarkOption.singlestart);
    const singleend = useSelector((state) => state.watermarkOption.singleend);
    const sellayer = useSelector((state) => state.watermarkOption.sellayer);
    
    
    //console.log("font color: ", fontcolor);

    const id = 13;

    const goProcFunction = (files) => {        
        //setFile(files);
        var initFileList = [];
        //var initUrl = [];
        for (var i = 0; i < files.length; i++) {
            initFileList.push(files[i]);
            //initUrl.push(URL.createObjectURL(files[i]));
        }

        dispatch(setFileOptionList(initFileList));
        //dispatch(setUrlOptionList(initUrl));

        setProc(1);
    }

    const goAction = () => {
        setProc(2);        
    }

    
    const goDownload = (downlinks) => {
        links = downlinks;
        setProc(3);
    }


    /*****************rendering*****************/
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles id={id} proc={goProcFunction} multiple={true} btn_text={t('tools.watermark.Select')} fileTypes = {["PDF"]}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpaceWatermark files={file} goAction={goAction}/>
            </div>
        );
    } else if (proc === 2) {//action space
        //option payload//
        var fontNameList = ['Arial', 'Calibri','Cambria','Corbel','Comic Sans MS','Consolas','Constantia','Courie New','Georgia','Palatino Linotype','Sitka Heading','Times New Roman','Verdana'];
        var fontfamily = fontNameList[fontname];
        if (bold == 1) fontfamily = fontfamily + 'b';
        if (italic == 1) fontfamily = fontfamily + 'i';

        var fontrgb = [fontcolor.r, fontcolor.g, fontcolor.b];
        var option = {
            method:parseInt(mode),
            text: mode == 0? text: '',
            imagepath: mode == 1?imagefile.name:'',
            fontsize:fontsize,
            fontfamily: fontfamily,
            underline:underline,
            fontcolor:fontrgb,
            position:position,
            mosaic:mosaic,
            transparency:transparency,
            rotation:rotation,
            startpage:singlestart,
            endpage:singleend,
            overcontent:sellayer,
        }
        var _file = [];
        for(var i = 0; i < filelist.length; i ++)
        {
            _file.push(filelist[i]);
        }
        if (mode == 1)
            _file.push(imagefile);

        
        //uploading//
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api}
                    goDownload={ goDownload } 
                    text={t('tools.watermark.Adding')}
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_file}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default Watermark;