import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux'
import { setTool, setOption } from './toolSlice';

import WorkArea from './WorkArea';
import WorkSide from './WorkSide';

const WorkSpace = ({ goAction }) => {
    const dispatch = useDispatch();

    const onAction = (filelist) => {        

        dispatch(setTool("MERGE"));
        //const filelist = {};
        var option = {
            cnt: 0,
            rotate: [],
        }

        for (var i = 0; i < filelist.length; i++) {
            if(filelist[i].remove !== 0)    continue;
            option.cnt++;
            option.rotate.push(filelist[i].rotate);
        }

        dispatch(setOption(option));

        goAction();
    }

    /***********rendering**********/
    return (
        <>
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <WorkArea/>
            </Scrollbars>
            <WorkSide onAction={onAction}/>
        </>
    );
}

export default WorkSpace;