import React from 'react';
import { Page } from 'react-pdf/dist/esm/entry.webpack'

const RangeElement = ({ title, start, end }) => {

  ///////////////////rendering////////////////
  return (
        <div style={{marginBottom:20}}>
          <span style={{color:'#1890ff'}}><b>{title}</b></span>
          <div className="pdf-range-element">
              <div className="pdf-canvas">
                <Page pageNumber={start} />
                {start}
              </div>
              <div style={{marginLeft:10, marginRight:10}}><b>...</b></div>
              <div className="pdf-canvas">
                <Page pageNumber={end} loading="" loading="" />
                {end}
              </div>
          </div>
        </div>
  );
}

export default RangeElement;