import React from 'react';
import { Page } from 'react-pdf/dist/esm/entry.webpack';
import { useSelector } from 'react-redux';

const PageElement = ({id, cnt, show}) => {
  const pageMode = useSelector((state) => state.pageNumbersOption.pageMode);
  const firstCover = useSelector((state) => state.pageNumbersOption.firstCover);

  var clsName = '';
  if (pageMode === 0)
    clsName = 'normalmargin';
  else {
    if (firstCover === 1){
      if (id % 2 === 1) clsName = 'leftmargin';
      else clsName = 'rightmargin';

      if (id === 0) clsName = 'normalmargin';
    }
    else {
      if (id % 2 === 0) clsName = 'leftmargin';
      else clsName = 'rightmargin';
    }
  }
  //<div className="pdf-element"></div>

  var clsFace = '';
  if (id % 2 === 0) clsFace = 'facing-page';

  var clsPage = "pdf-canvas_pagenumber";
  if (pageMode === 0)
    clsPage = "pdf-canvas_pagenumber_space";
  else
  {
    if (firstCover === 0){
      if (id % 2 === 1)
        clsPage = "pdf-canvas_pagenumber_space";
    }
    else{
      if (id % 2 === 0)
        clsPage = "pdf-canvas_pagenumber_space";
    }
  }
  
  var element = '';
  var element1 = '';
  if (show){
    element = (<Page pageNumber={id+1} loading="" />);
    element1 = (<ElementStyle id={id+1} cnt={cnt}/>);
  }
  ///////////////////rendering////////////////
  return (
        <div className={clsFace}>
          <div style={{marginTop:'20px'}}>
            <div className={clsPage}>
              <div className={clsName}>
                {element}
              </div>
                {element1}
            </div>
          </div>
        </div>
  );
  
}

const ElementStyle = ({id, cnt}) => {
  const maxPage = useSelector((state) => state.pageNumbersOption.maxPage);
  const pageMode = useSelector((state) => state.pageNumbersOption.pageMode);
  const firstCover = useSelector((state) => state.pageNumbersOption.firstCover);
  const position = useSelector((state) => state.pageNumbersOption.position);
  const margin = useSelector((state) => state.pageNumbersOption.margin);
  
  const singlestart = useSelector((state) => state.pageNumbersOption.singlestart);
  const singleend = useSelector((state) => state.pageNumbersOption.singleend);
  const multiplestart = useSelector((state) => state.pageNumbersOption.multiplestart);
  const multipleend = useSelector((state) => state.pageNumbersOption.multipleend);

  var clsName = "";
  

  var marginName = "recommargin";
  if (margin === 0) marginName = "smallmargin";
  else if(margin === 2) marginName = "bigmargin";

  //console.log("received start:", singlestart);
  //console.log("received end:", singleend);
  var bshow = false;
  
  var start = singlestart;
  var end = singleend;
  if (cnt != 1)
  {
      start = multiplestart + 1;
      end = maxPage - multipleend;
  }
  if ((id >= start) && (id <= end)) bshow = true;

  if (pageMode === 0)
  {
    if (position === 0) clsName = "pdf-pagenumber-new_lefttop";
    else if (position === 1) clsName = "pdf-pagenumber-new_middletop";
    else if (position === 2) clsName = "pdf-pagenumber-new_righttop";
    else if (position === 3) clsName = "pdf-pagenumber-new_leftbottom";
    else if (position === 4) clsName = "pdf-pagenumber-new_middlebottom";
    else if (position === 5) clsName = "pdf-pagenumber-new_rightbottom";
  }
  else{
    if (firstCover === 1){
      if (id === 1){
        if (position === 0) clsName = "pdf-pagenumber-new_lefttop";
        else if (position === 1) clsName = "pdf-pagenumber-new_middletop";
        else if (position === 2) clsName = "pdf-pagenumber-new_righttop";
        else if (position === 3) clsName = "pdf-pagenumber-new_leftbottom";
        else if (position === 4) clsName = "pdf-pagenumber-new_middlebottom";
        else if (position === 5) clsName = "pdf-pagenumber-new_rightbottom";
        bshow = false;
      }
      else {
        if (id % 2 === 0) {
          if (position === 0)      clsName = "pdf-pagenumber-area_face_righttop1";
          else if (position === 1) clsName = "pdf-pagenumber-area_face_middletop1";
          else if (position === 2) clsName = "pdf-pagenumber-area_face_lefttop1";
          else if (position === 3) clsName = "pdf-pagenumber-area_face_rightbottom1";
          else if (position === 4) clsName = "pdf-pagenumber-area_face_middlebottom1";
          else if (position === 5) clsName = "pdf-pagenumber-area_face_leftbottom1";   
        }
        else{
          if (position === 0)      clsName = "pdf-pagenumber-area_face_righttop2";
          else if (position === 1) clsName = "pdf-pagenumber-area_face_middletop2";
          else if (position === 2) clsName = "pdf-pagenumber-area_face_lefttop2";
          else if (position === 3) clsName = "pdf-pagenumber-area_face_rightbottom2";
          else if (position === 4) clsName = "pdf-pagenumber-area_face_middlebottom2";
          else if (position === 5) clsName = "pdf-pagenumber-area_face_leftbottom2";  
        }
      }
    }
    else {
      if (id % 2 === 1) {
        if (position === 0)      clsName = "pdf-pagenumber-area_face_righttop1";
        else if (position === 1) clsName = "pdf-pagenumber-area_face_middletop1";
        else if (position === 2) clsName = "pdf-pagenumber-area_face_lefttop1";
        else if (position === 3) clsName = "pdf-pagenumber-area_face_rightbottom1";
        else if (position === 4) clsName = "pdf-pagenumber-area_face_middlebottom1";
        else if (position === 5) clsName = "pdf-pagenumber-area_face_leftbottom1";   
      }
      else{
        if (position === 0)      clsName = "pdf-pagenumber-area_face_righttop2";
        else if (position === 1) clsName = "pdf-pagenumber-area_face_middletop2";
        else if (position === 2) clsName = "pdf-pagenumber-area_face_lefttop2";
        else if (position === 3) clsName = "pdf-pagenumber-area_face_rightbottom2";
        else if (position === 4) clsName = "pdf-pagenumber-area_face_middlebottom2";
        else if (position === 5) clsName = "pdf-pagenumber-area_face_leftbottom2";  
      }
    }
  }  
  
  var element = '';
  element = (
      <div className={bshow?clsName:"pdf-non-checked"}>
        <div className={marginName}>
            <div className="pdf-pagenumber-circle">
            </div>  
        </div>
      </div>
    );
  return (
    <div>
    {element}
    </div>
  );
}
export default PageElement;