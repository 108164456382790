import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface InitOptionState {
  method: number,
  filelist: Array,
}

const initialState: InitOptionState = {
  method: 0,
  filelist: [],
}

export const pdf2jpgSlice = createSlice({
  name: 'pdf2jpgOption',
  initialState,
  reducers: {
    setMethod: (state, action:PayloadAction<number>) =>{
      state.method = action.payload;
    },
    setFileList: (state, action:PayloadAction<Array>) =>{
      state.filelist = action.payload;
    },
    insertFilelist: (state, action) => {
      state.filelist.push({
        idx: state.filelist.length,
        file: action.payload,
        url: URL.createObjectURL(action.payload),
        rotate: 0,
        remove: 0,
      });
    },
    removeFilelist: (state, action) => {
      state.filelist[action.payload].remove = 1;
    },
    setRotateFilelist: (state, action) => {
      state.filelist[action.payload.idx].rotate = action.payload.rotate;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setMethod, 
  setFileList, 
  insertFilelist, 
  removeFilelist,
  setRotateFilelist } = pdf2jpgSlice.actions

export default pdf2jpgSlice.reducer