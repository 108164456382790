import React, {useState} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage, setPageViewScale, addElement, setEnableDraw  } from './signSlice';

import { FileUploader } from "react-drag-drop-files";

import { InputNumber, Tooltip, Dropdown } from 'antd';

import { BsHandIndexThumb, BsZoomOut, BsZoomIn, BsFileCode, BsFileEarmarkFont,
  BsFileEarmarkImage, BsPen } from 'react-icons/bs';
import ShapeSvg from '../../../assets/svg/shape.svg';
import { useTranslation } from 'react-i18next';


const ShapeSubMenu = () => {
  return(
    <div className="editpdf-submenu">
        ADD SHAPE
    </div>
  );
}


////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////EDIT TOOLBAR///////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
const EditToolBar = () => {

    const {t} = useTranslation(); 
    const [submenu, setSubmenu] = useState(-1);

    const dispatch = useDispatch();
    const cur_page = useSelector((state: RootState) => state.signOption.cur_page); 
    const page_rect = useSelector((state: RootState) => state.signOption.page_rect);
    const max_page = useSelector((state: RootState) => state.signOption.max_page);
    const viewscale = useSelector((state: RootState) => state.signOption.viewscale);

    //console.log("EditToolBar max page: ", max_page);
    
    const onChangePageNumber = (value) => {
        //dispatch(setCurrentPage(value));
        dispatch(setCurrentPage({page:value, changed:1}));
    }

    
    return (
        <div className="editpdf-menu">
            <div className="editpdf__menu__block">
                <div className="wrapper">
                    <InputNumber 
                      min={1}
                      max={max_page} 
                      keyboard={true} 
                      value={cur_page} 
                      onChange = { onChangePageNumber }
                      prefix={<span>
                            {t('tools.sign.Page')}
                          </span>}
                      style={{ width: 100, marginRight:5 }}
                    />
                    <span>/ {max_page}</span>
                </div>
            </div>
            
            
        </div>
    );
}

export default EditToolBar;