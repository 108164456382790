import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setFileOptionList, setMaxPageOption, setSelIndex, setSingleEndOption } from './pageNumbersSlice';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Badge, Row, Button, Tooltip, Layout} from 'antd';
import { PlusOutlined} from '@ant-design/icons';
import { FileUploader } from "react-drag-drop-files";

import PageElement from './PageElement';
import SelectFileCombo from './SelectFileCombo';
import { useTranslation } from 'react-i18next';

const { Header, Content } = Layout;

var preSelName='';
var bChange=false;
const WorkAreaPageNumbers = ({scroll}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.pageNumbersOption.filelist);
    const url = useSelector((state) => state.pageNumbersOption.url);
    const selindex = useSelector((state) => state.pageNumbersOption.selindex);
    const maxPage = useSelector((state) => state.pageNumbersOption.maxPage);
    const dispatch = useDispatch();
    
    const [numPages, setNumbPages] = useState(maxPage);
    const [pdf, setPdf] = useState(null);
    const [curUrl, setCurUrl] = useState('');
    //const [container, setContainer] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        //console.log('page:', numPages);
        setNumbPages(numPages);
        dispatch(setMaxPageOption(numPages));
        dispatch(setSingleEndOption(numPages));
        bChange = false;
    }

    const onAddFileList = (files) => {
        var dFileList = [];
       
        filelist.map(file => (dFileList.push(file)));
        for (var i = 0; i < files.length; i++) {
            dFileList.push(files[i]);
        }

        dispatch(setFileOptionList(dFileList));
        dispatch(setSelIndex(dFileList.length - 1));
    };

    if (selindex != -1){
        if (preSelName !== filelist[selindex].name){
            preSelName = filelist[selindex].name;
            setCurUrl(url[selindex]);
            bChange = true;
        }
    }
    else preSelName = '';
        

    var cnt = filelist.length;
    var pdf_files_com = [];
    for (var i = 0; i < filelist.length; i++) {
        pdf_files_com.push({id: i, name: filelist[i].name});
    }
    
    var pdf_element_list = [];
    if (!bChange){
        var top = 0;
        if (scroll != null)
            top = scroll.top
        var value = top * maxPage;
        var start = value - 20;
        var end = value + 15;

        for (let i = 0; i < maxPage; i++) {
            var show = false;
            if (i >= start & i <= end) show = true;

            pdf_element_list.push(<PageElement id={ i } cnt={cnt} key={'page_' + i} show={show}/>);
        }
    }
    return (
        <div className="tool__workarea" style={{paddingTop:100}}>
            
            <Layout style={{justifyContent:'center', backgroundColor:'#f3f0ec'}}>
                <Header style={{backgroundColor:'#f3f0ec', justifyContent:'center', marginTop:'-70px'}}>
                    <SelectFileCombo 
                    filelist={pdf_files_com} 
                    selected={selindex} />
                    <div className="sidetools_pagenumbers">
                        <Row>
                            <div style={{marginBottom:10}}>
                                <Tooltip title={t('tools.pageNumbers.Addmorefiles')} placement="left">
                                    <div className="hover-black-btn shadow-btn" style={{marginTop:'-70px'}}>
                                    <Badge count={cnt} color="#2db7f5" style={{marginTop:'10px'}}>
                                        <FileUploader
                                                multiple={true}
                                                handleChange={onAddFileList}
                                                name={t('tools.pageNumbers.CustomFiles')}
                                                types={["PDF"]}
                                                children={(<Button 
                                                                type="primary" 
                                                                shape="circle" 
                                                                icon={<PlusOutlined style={{ fontSize: '22px', color: 'white' }}/>} 
                                                                size="large">                                
                                                            </Button>)}
                                        />
                                    </Badge>
                                    </div>
                                </Tooltip>
                            </div>
                        </Row>
                    </div>
                
                </Header>
                <Content style={{backgroundColor:'rgb(243, 240, 236)', margin:'0 auto'}}>
                <Document file={curUrl} onLoadSuccess={onDocumentLoadSuccess} >
                    {pdf_element_list}
                </Document>  
                </Content>
            </Layout>
        </div>
    );
}

export default WorkAreaPageNumbers;