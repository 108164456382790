import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PageNumbersOptionState {
  tool_header: string,
  filelist: Array,
  url: Array,
  option: Object,
  uploadpath: Array,
  selindex: Number,
  maxPage: Number,
  pageMode: Number,
  firstCover: Number,
  position: Number,
  margin: Number,
  firstnumber: Number,
  singlestart: Number,
  singleend: Number,
  multiplestart: Number,
  multipleend: Number,
  numbertype: Number,
  customtext:String,
  fontname: Number,
  fontsize: Number,
  bold: Number,
  italic: Number,
  underline: Number,
  fontcolor: string,
}

const initialState: PageNumbersState = {
  tool_header: "PageNumbers",
  filelist: [],
  url:[],
  option: {},
  selindex: 0,
  maxPage: 1,
  pageMode: 0,
  firstCover:0,
  position:0,
  margin:1,
  firstnumber:1,
  singlestart: 1,
  singleend: 1,
  multiplestart: 0,
  multipleend: 0,
  numbertype: 0,
  customtext:'',
  fontname: 0,
  fontsize: 14,
  bold: 0,
  italic: 0,
  underline: 0,
  fontcolor: '#200D03',
}

export const pageNumbersSlice = createSlice({
  name: 'pageNumbersOption',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setOption: (state, action:PayloadAction<Object>) =>{
      state.option = action.payload;
    },
    setFileOptionList: (state, action:PayloadAction<Array>) => {
      state.filelist = action.payload;
      state.url = [];
      for (var i = 0; i < state.filelist.length; i ++)
        state.url.push(URL.createObjectURL(state.filelist[i]))
    },
    setRemoveOption: (state, action:PayloadAction<Object>) => {
      state.filelist.splice(state.selindex, 1);
      state.url.splice(state.selindex, 1);
      if (state.selindex >= state.filelist.length)
        state.selindex = state.selindex - 1;
      if (state.filelist.length === 0)
      {
        state.selindex = -1;
        state.maxPage = 0;
      }
    },
    
    setUploadPath: (state, action:PayloadAction<Array>) => {
      state.uploadpath = action.payload;
    },
    setSelIndex: (state, action:PayloadAction<Number>) => {
      state.selindex = action.payload;
    },
    setMaxPageOption: (state, action:PayloadAction<Number>) => {
      state.maxPage = action.payload;
    },
    setPageModeOption:(state, action:PayloadAction<Number>) => {
      state.pageMode = action.payload;
    },
    setFirstCoverOption:(state, action:PayloadAction<Number>) => {
      state.firstCover = action.payload;
    },
    setPositionOption:(state, action:PayloadAction<Number>) => {
      state.position = action.payload;
    },
    setMarginOption:(state, action:PayloadAction<Number>) => {
      state.margin = action.payload;
    },
    
    setFirstNumberOption:(state, action:PayloadAction<Number>) => {
      state.firstnumber = action.payload;
    },
    setSingleStartOption:(state, action:PayloadAction<Number>) => {
      state.singlestart = action.payload;
    },
    setSingleEndOption:(state, action:PayloadAction<Number>) => {
      state.singleend = action.payload;
    },
    setMultipleStartOption:(state, action:PayloadAction<Number>) => {
      state.multiplestart = action.payload;
    },
    setMultipleEndOption:(state, action:PayloadAction<Number>) => {
      state.multipleend = action.payload;
    },
    setNumberTypeOption:(state, action:PayloadAction<Number>) => {
    state.numbertype = action.payload;
    },
    setCustomTextOption:(state, action:PayloadAction<Number>) => {
      state.customtext = action.payload;
    },
    setFontNameOption:(state, action:PayloadAction<Number>) => {
      state.fontname = action.payload;
    },
    setFontSizeOption:(state, action:PayloadAction<Number>) => {
      state.fontsize = action.payload;
    },
    setBoldOption:(state, action:PayloadAction<Number>) => {
      state.bold = action.payload;
    },
    setItalicOption:(state, action:PayloadAction<Number>) => {
      state.italic = action.payload;
    },
    setUnderlineOption:(state, action:PayloadAction<Number>) => {
      state.underline = action.payload;
    },
    setFontColorOption:(state, action:PayloadAction<Number>) => {
      state.fontcolor = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setOption, 
  setFileOptionList, 
  setRemoveOption, 
  setUploadPath,
  setSelIndex,
  setMaxPageOption,
  setPageModeOption,
  setFirstCoverOption,
  setPositionOption,
  setMarginOption,
  setFirstNumberOption,
  setSingleStartOption,
  setSingleEndOption,
  setMultipleStartOption,
  setMultipleEndOption,
  setNumberTypeOption,
  setCustomTextOption,
  setFontNameOption,
  setFontSizeOption,
  setBoldOption,
  setItalicOption,
  setUnderlineOption,
  setFontColorOption
} = pageNumbersSlice.actions

export default pageNumbersSlice.reducer