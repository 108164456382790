import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setMethod } from './pdf2wordSlice';

import {  Button, Layout, Radio, Space } from 'antd';
import { SendOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.pdf2wordOption.filelist);
    const method = useSelector((state) => state.pdf2wordOption.method);

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) 
            cnt++;
    }

    const onClickAction = () => {
        onAction(filelist);
    }

    const onChangeMethod = (e) => {
        dispatch(setMethod(parseInt(e.target.value)));
    }

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>PDF TO EXCEL</Header>

                <Content>
                    <Radio.Group onChange={onChangeMethod} value={method} size="small">
                        <Space direction="vertical">
                            <Radio.Button value={0}>
                                <span style={{color:'#1890ff', fontSize:'16px', float:'left'}}>
                                    NO OCR
                                </span>
                                <span style={{color:'black', marginTop:'5px', float:'left'}}>
                                    {t('tools.pdf2excel.side_msg1')}
                                </span>
                                <div className={method===0?"radio-check":"hide-element"}>
                                    <CheckCircleFilled />
                                </div>
                            </Radio.Button>

                            <Radio.Button value={1}>
                                <span style={{color:'#1890ff', fontSize:'16px', float:'left'}}>
                                    OCR
                                </span>
                                <span style={{color:'black', marginTop:'5px', float:'left'}}>
                                    {t('tools.pdf2excel.side_msg2')}
                                </span>
                                <div className={method===1?"radio-check":"hide-element"}>
                                    <CheckCircleFilled />
                                </div>
                            </Radio.Button>
                        </Space>
                    </Radio.Group>
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt > 0 ? false:true}>
                      {t('tools.pdf2excel.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;