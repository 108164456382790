import { Modal, Button, Space, Input, Tabs, Radio, InputNumber, Select, Tooltip } from 'antd';
import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import single from '../../../assets/png/sign_single.png';
import multiple from '../../../assets/png/sign_multiple.png';
import pen from '../../../assets/svg/tools/sign_onlypen.svg';
import ini from '../../../assets/svg/tools/sign_onlyinitial.svg';
import text from '../../../assets/svg/tools/sign_onlytext.svg';
import draw from '../../../assets/svg/tools/sign_onlydraw.svg';
import up from '../../../assets/svg/tools/sign_onlyup.svg';
import user from '../../../assets/svg/tools/sign_user.svg';

import { Scrollbars } from 'react-custom-scrollbars';
import { FileUploader } from "react-drag-drop-files";
import { ImBin } from 'react-icons/im';
import Draw from './Draw';
import { setOnlyMe, setPlaceToAdd, setPlaceToChange, unactiveSeletedElement } from './signSlice';// 
import { useTranslation } from 'react-i18next';
//import invite_spin from '../../../assets/signsvg/invite_spin.svg';
//import invite_pass from '../../../assets/signsvg/invite_password.svg';
//import invite_sms from '../../../assets/signsvg/invite_sms.svg';
//import invite_sign from '../../../assets/signsvg/invite_signature.svg';
//import {AiTwotoneSecurityScan, AiFillCloseCircle} from 'react-icons/ai';

const { TabPane } = Tabs;
const {Option} = Select;

const SelectModal = ({filename}) => {
  const {t} = useTranslation();
  const [visible, setVisible] = useState(true);
  //const [modalText, setModalText] = useState('Content of the modal');
  const [winkind, setWinKind] = useState(0);

  const onOnlyme = () => {
    //setVisible(false);
    setWinKind(1);
  };
  const onInvite = () => {
    //setVisible(false);
    //alert("Premium possible");
    setWinKind(2);
  };
  const onOther = () => {

  }
  var element = '';
  if (winkind === 0){
    element = (
      <Modal
        title="Who will sign this document?"
        visible={visible}
        closable={false}
        footer={
          <div style={{pos:'absolute', textAlign:'left', marginLeft:'26px'}}>{t('tools.sign.Uploaded')}:{filename}
          </div>
        }
      >
        <Space>
          <SelectItem img={single} name="Only me" text={t('tools.sign.Signthis')} kind={onOnlyme}/>
          <SelectItem img={multiple} name="Request signatures" text={t('tools.sign.Inviteothers')} kind={onInvite}/>
        </Space>
      </Modal>
      );
  }
  else if(winkind === 1){
    element = (
      <OnlyMe initvalue={1} filename={filename} other={onOther}/>
      );
  }
  else if(winkind === 2) {
    element = (
      <InviteSign />
    );
  }
  //onOk={handleOk}
  return (
    <>
      {element}
    </>
  );
};

export default SelectModal;

const SelectItem = ({img, name, text, kind}) => {
  return (
    <>
      <div className="sign-modal-item">
        <img src={img} alt='select'/>
        <Button className="side__btns_html" style={{marginTop:'15px', width:'150px', height:'40px', borderRadius:'2px'}} onClick={kind}>{name} </Button>
        <div>
          <small style={{marginLeft:'30px'}}>{text}</small>
        </div>
      </div>
    </>
  );
}

const StaticRect = () => {
  /*const onChangeName = (e) => {

  }
  const onChangeEmail = (e) => {

  }
  const onChangeReceiver = (e) => {}
  return (
    
    <div style={{width:'100%', height:'43px', border:'1px solid', padding:'5px'}}>
          <div style={{width:'2%', position:'absolute', marginTop:'1px'}}>
            <img src={invite_spin} alt='invite'/>
          </div>
          <div style={{width:'7%', position:'absolute', left:'6%', marginTop:'-3px'}}>
            <img src={user} alt='user' style={{fontSize:'12px'}}/>
          </div>
          <div style={{width:'22%', position:'absolute', left:'12%'}}>
            <Input placeholder="Name" onChange={onChangeName}/>
          </div>
          <div style={{width:'22%', position:'absolute', left:'36%'}}>
            <Input placeholder="Email" onChange={onChangeEmail}/>
          </div>
          <div style={{width:'15%', position:'absolute', left:'60%'}}>
            <Select defaultValue={0} onChange={onChangeReceiver} style={{width:'100%'}} >
                <Option value={0}>Signer </Option>
                <Option value={1}>Validator</Option>
                <Option value={2}>Witness</Option>
            </Select>
          </div>
          <Tooltip title='Password protection'>
            <div style={{width:'22px', position:'absolute', marginTop:'5px', left:'80%',
                  background: '#aeaeb2',borderRadius: '100%'}}>
              <img src={invite_pass} alt='password' style={{fontSize:'12px', marginLeft:'4px', 
                  marginTop:'-3px'}}/>
            </div>
          </Tooltip>
          <Tooltip title='SMS Validation'>
            <div style={{width:'22px', position:'absolute', marginTop:'5px', left:'84%',
                  background: '#aeaeb2',borderRadius: '100%'}}>
              <img src={invite_sms} alt='sms' style={{fontSize:'12px', marginLeft:'6px', 
                  marginTop:'-3px'}}/>
            </div>
          </Tooltip>
          <Tooltip title='Select Signature Format'>
            <div style={{width:'22px', position:'absolute', marginTop:'5px', left:'88%',
                  background: '#aeaeb2',borderRadius: '100%'}}>
              <img src={invite_sign} alt='password' style={{fontSize:'12px', marginLeft:'5px',
                  marginTop:'-3px'}}/>
            </div>
          </Tooltip>
          <Tooltip title='close'>
            <div style={{width:'22px', position:'absolute', marginTop:'3px', left:'92%',}}>
              <AiFillCloseCircle style={{fontSize:'25px'}}/>
            </div>
          </Tooltip>
          
    </div>
  );*/
}


const VariableRect = () => {
  /*const [_count, setCount] = useState(1);
  const onAddReceiver = () => {
    setCount(_count+1);
  }
  var element = [];
  for (var i = 0; i < _count; i ++)
    element.push(<StaticRect />);
  return (
    <>
      {element}
      <Button className='primary' style={{width:'100%', height:'43px'}} onClick={onAddReceiver}>ADD RECEIVER </Button>
      <span>Settings</span>
    </>
  );*/
}

export const InviteSign = () => {
  /*const [visible, setVisible] = useState(true);
  const onCancel = () => {}
  const onApply = () => {}
  return (
    <Modal
      width='calc(100vw/2)'
      title={
        <div style={{width:'calc(100vw/2 - 50%)'}}>Create your signature request</div>
      }
      visible={visible}
      closable={false}
      footer={
        <Space>
          <Button className="side__btns_html" style={{width:'100px', marginTop:'5px', borderRadius:'4px'}} onClick={onCancel} >Cancel</Button>
          <Button className="side__btns_html" style={{width:'100px', marginTop:'5px', borderRadius:'4px'}} onClick={onApply} >Apply</Button>
        </Space>
      }
    >
    <VariableRect />      
    </Modal>
  );*/
}

export const OnlyMe = ({initvalue, filename, other}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch(); 
  
  var defaultKey = "1";
  if (initvalue === 2) defaultKey = "2";

  const onlyme = useSelector((state: RootState) => state.signOption.onlyme);

  
  const [selOuterTab, setSelOuterTab] = useState(defaultKey);
  const [visible, setVisible] = useState(true);
  //const [save, setSave] = useState(0);

  const [signature, setSignature] = useState(onlyme.signName);
  const [initials, setInitials] = useState(onlyme.initName);
  
  const [signTab, setSignTab] = useState(onlyme.signSel);
  const [initTab, setInitTab] = useState(onlyme.initSel);
  const [fontNameIndex1, setFontNameIndex1] = useState(onlyme.signFont);
  const [fontColor1, setFontColor1] = useState(onlyme.signFontColor);
  const [drawurl1, setDrawUrl1] = useState(onlyme.signDrawUrl);
  const [drawColor1, setDrawColor1] = useState(onlyme.signDrawColor);
  const [uploadUrl1, setUploadUrl1] = useState(onlyme.signUpload);

  const [fontNameIndex2, setFontNameIndex2] = useState(onlyme.initFont);
  const [fontColor2, setFontColor2] = useState(onlyme.initFontColor);
  const [drawurl2, setDrawUrl2] = useState(onlyme.initDrawUrl);
  const [drawColor2, setDrawColor2] = useState(onlyme.initDrawColor);
  const [uploadUrl2, setUploadUrl2] = useState(onlyme.initUpload);

  const [signStatus, setSignStatus] = useState('');
  const [initStatus, setInitStatus] = useState('');

  const onApplyClick = () => {
    if (signature === '') {setSignStatus('error'); return;}
    if (initials === '') {setInitStatus('error'); return;}

    var onlyme = {
      signName: signature,
      initName: initials,

      signSel: signTab,
      signFont: fontNameIndex1,
      signFontColor: fontColor1,
      signDrawUrl: drawurl1,
      signDrawColor: drawColor1,
      signUpload: uploadUrl1,

      initSel: initTab,
      initFont: fontNameIndex2,
      initFontColor: fontColor2,
      initDrawUrl: drawurl2,
      initDrawColor: drawColor2,
      initUpload: uploadUrl2,
    };
    dispatch(setOnlyMe(onlyme));
    other(1); 
    setVisible(false); 
  }

  const onCancelClick = () => {setVisible(false);other(0); }
  const onSignatureTextChange = (e) => {
    setSignature(e.target.value);
    if (e.target.value !== '') 
    {
      setSignStatus('');
      var temp = e.target.value.split(' ');
      
      var init = '';
      for (var i = 0; i < temp.length; i ++)
        if (temp[i] !== '')
          init += temp[i][0];
      
      setInitials(init);
    }
  }
  const onInitialsTextChange = (e) => {
    setInitials(e.target.value);
    if (e.target.value !== '') setInitStatus('');
  }
  
  const onTabChange = (e) => {
    //console.log("outer tab is changed: [%s][%s]", e, typeof(e));
    setSelOuterTab(e);
  }

  const onSignatureTabChange = (e) => {
    //console.log("signature tab is changed: ", e);
    setSignTab(e);
  }
  const onInitialsTabChange = (e) => {
    //console.log("Initials tab is changed: ", e);
    setInitTab(e);
  }

  const onValueChange = (p1, p2) => {
    switch (p1) {
      case 1:
        selOuterTab === "1"? setFontNameIndex1(p2) : setFontNameIndex2(p2);break;
      case 2:
        selOuterTab === "1"? setFontColor1(p2) : setFontColor2(p2);break;
      case 3:
        selOuterTab === "1"? setDrawUrl1(p2) : setDrawUrl2(p2);break;
      case 4:
        selOuterTab === "1"? setDrawColor1(p2) : setDrawColor2(p2);break;
      default:
      {
        selOuterTab === "1"? setUploadUrl1(p2) : setUploadUrl2(p2);
        //console.log("outer tab [%s][%d]", selOuterTab, selOuterTab === "1");
      }     
    }
  }
  

  var footerElement = '';//width:'100px', 
  if (initvalue === 0){
    footerElement = (<Button className="side__btns_html" style={{marginTop:'5px', borderRadius:'4px'}} onClick={onApplyClick} >{t('tools.sign.Apply')}</Button>);
  }
  else{
    footerElement = (
    <Space>
      <Button className="side__btns_html" style={{marginTop:'5px', borderRadius:'4px'}} onClick={onCancelClick} >{t('tools.sign.Cancel')}</Button>
      <Button className="side__btns_html" style={{marginTop:'5px', borderRadius:'4px'}} onClick={onApplyClick} >{t('tools.sign.Apply')}</Button>
    </Space>
    );
  }

  return(
    <>
      <Modal
        width='calc(100vw/2)'
        title={
          <Space style={{width:'calc(100vw/2 - 5%)'}}> 
            <div style={{width:'calc(100vw/2 - 50%)'}}>{t('tools.sign.Customize')}</div>
            {/*<Button className="sign__domodal__login">{t('tools.sign.LOGIN')}</Button>*/}
          </Space>
        }
        visible={visible}
        closable={false}
        footer={
          <div>
          {footerElement}
          </div>
        }
      >
      <div style={{width:'97%', height:'70px'}}>
        <div className="signature-avatar" style={{width:'100%'}}>
          <div style={{width:'7%', position:'absolute', left:'5%', top:'17%'}}>
            <img src={user} alt='user'/>
          </div>
          <div style={{width:'45%', left:'16%', top:'15%', position:'absolute'}}>
            <div>{t('tools.sign.Fullname')}:</div>
            <Input placeholder={t('tools.sign.Yourname')} status={signStatus} style={{marginTop:'10px'}} onChange={onSignatureTextChange} defaultValue={signature}/>
          </div>
          <div style={{width:'25%', left:'65%', top:'15%', position:'absolute'}}>
            <div>{t('tools.sign.Initials')}:</div>
            <Input placeholder={t('tools.sign.Yourinitials')} status={initStatus} value={initials} style={{marginTop:'10px'}} onChange={onInitialsTextChange} defaultValue={initials}/>
          </div>
        </div>
      </div>
      <div style={{marginLeft:'40px'}}>
        <Tabs type="card" onChange={onTabChange} defaultActiveKey={defaultKey}>
            <TabPane
                tab={
                  <div style={{width:'calc(100vw/15)'}}>
                    <Space>
                      <div><img src={pen} alt='pen' style={{fontSize: '50px'}} /></div>
                      <div>{t('tools.sign.SIGNATURE')}</div>
                    </Space>
                  </div>
              }
              key="1"
            >
                <TabDetail content={signature} onInnerTab={onSignatureTabChange} onValueChange={onValueChange} sel="sign"/>            
            </TabPane>
            <TabPane
                tab={
                <div style={{width:'calc(100vw/15)'}}>
                  <Space style={{marginLeft:'10px'}}>  
                    <div><img src={ini} alt='initial' style={{fontSize: '50px'}} /></div>
                    <div>{t('tools.sign.INITIALS')}</div>
                  </Space>
                </div>
            }
            key="2"
            >
                <TabDetail content={initials} onInnerTab={onInitialsTabChange} onValueChange={onValueChange}  sel="init"/>
            </TabPane>
        </Tabs>
      </div>
      </Modal>
    </>
  );
}

const TabDetail = ({content, onInnerTab, onValueChange, sel}) => {
  const {t} = useTranslation();
  const onlyme = useSelector((state: RootState) => state.signOption.onlyme);
  var varColor = ['#555555','#DC3545','#0078FF','#28A745'];
  //console.log("selected tab: ", sel);
  var flag = sel === "sign";
  var selTab = flag ? onlyme.signSel : onlyme.initSel;
  var fontName = flag ? onlyme.signFont: onlyme.initFont;
  var fontColor = flag ? onlyme.signFontColor : onlyme.initFontColor;
  var drawUrl = flag ? onlyme.signDrawUrl : onlyme.initDrawUrl;
  var drawColor = flag ? onlyme.signDrawColor : onlyme.initDrawColor;
  var uploadUrl = flag ? onlyme.signUpload : onlyme.initUpload;

  const onTabChange = (e) => { onInnerTab(e);}

  var selFontColor = 0;
  for (var i = 0; i < 4; i ++)
    if (fontColor === varColor[i]){
      selFontColor = i;break;
    }
  var selDrawColor = 0;
  for (i = 0; i < 4; i ++)
    if (drawColor === varColor[i]){
      selDrawColor = i;break;
    }
  ///////////////////// Font and color select part //////////////////////////
  const [selFontName, setFontName] = useState(fontName);
  const [fontcolor, setFontColor] = useState(fontColor);

  const onFontName = (e) => {setFontName(e); onValueChange(1, e);}
  const onFontColor = (e) => {setFontColor(varColor[e]); onValueChange(2, varColor[e]);}

  ///////////////////// Drawing and color select part ///////////////////////
  const [color, setColor] = useState(drawColor);
  const [erase, setErase] = useState(false);
  //const [url, setUrl] = useState('');
  const onForgroundColor = (e) => {setColor(varColor[e]); onValueChange(4, varColor[e]);}
  const onDrawRemove = () => {setErase(!erase);}
  const onUrlChange = (e) => {onValueChange(3, e);}

  ///////////////////// File upload and remove part //////////////////////////
  const [filename, setFileName] = useState(uploadUrl);
  const onUploadImage = (file) => {setFileName(file); onValueChange(5, file);}
  const onImageRemove = () => {setFileName(null);}

  var upelement = '';
  if (filename === null){
    upelement = (
    <FileUploader
      multiple={false}
      handleChange={onUploadImage}
      name="selectFile"
      types={["JPG", "SVG", "PNG", ]}
      children={<div className="side__btns_html" style ={{width: '40%', marginLeft: '30%', marginTop:'10%'}}>{t('tools.sign.UPLOADIMAGE')}</div>}
    />);
  }else {
    upelement = (
    <div>
      <ImBin style={{position:'absolute', right: '8%', marginTop: '2%'}} onClick={onImageRemove}/>
      <img src={URL.createObjectURL(filename)} alt='upload' style={{width: '80%', border:'1px solid rgba(0, 0, 0, 0.2)', marginLeft:'10%'}}/>
    </div>
    );
  }

  return (
  <div style={{marginLeft: '-53px'}}>
    <Tabs tabPosition={'left'} type='card' onChange={onTabChange} defaultActiveKey={selTab}>
      {/******************Signature and Initials Font select part *****************/}
      <TabPane
        tab={<div><img src={text} alt='text' style={{width: '20px'}} /></div>}
        key="1"
      >
        <SelectFont onSelectFont={onFontName} fontcolor={fontcolor} content={content} selFont={selFontName} sel={sel}/>
        <SelectColor onSelectColor={onFontColor} selColor={selFontColor} />
      </TabPane>
      
      {/******************Signature and Initials drawing part **********************/}
      <TabPane
        tab={<div><img src={draw} alt='draw' style={{fontSize: '40px'}} /></div>}
        key="2"
      >
        <div className="sign__drawcanvase_above">
          <Draw _url={drawUrl} _width={400} _height={150} _color={color} _thick={3} _erase={erase} changeUrl={onUrlChange}/>
          <ImBin style={{position:'absolute', right: '8%', marginTop: '-20%', cursor: 'pointer'}} onClick={onDrawRemove}/>
        </div>
        
        <SelectColor onSelectColor={onForgroundColor} selColor={selDrawColor}/>
        
      </TabPane>

      {/******************Signature and Initials image upload part *****************/}
      <TabPane
        tab={<div><img src={up} alt='uplaod' style={{fontSize: '40px'}} /></div>}
        key="3"
      >
        <div style={{border:'1px solid rgba(0, 0, 0, 0.2)', height:'calc(25vh)', width:'97%', overflow:'hidden'}}>
          {upelement}
        </div>
      
      </TabPane>
    </Tabs>
  </div>
  );
}

const SelectColor = ({onSelectColor, selColor}) => {

  const [selcolor, setSelColor] = useState(selColor);
 
  const onClickColor1 = () => {setSelColor(0); onSelectColor(0);}
  const onClickColor2 = () => {setSelColor(1); onSelectColor(1);}
  const onClickColor3 = () => {setSelColor(2); onSelectColor(2);}
  const onClickColor4 = () => {setSelColor(3); onSelectColor(3);}

  var colorClsName0 = selcolor === 0 ? "sign__selectmodal_color_active":"sign__selectmodal_color";
  var colorClsName1 = selcolor === 1 ? "sign__selectmodal_color_active":"sign__selectmodal_color";
  var colorClsName2 = selcolor === 2 ? "sign__selectmodal_color_active":"sign__selectmodal_color";
  var colorClsName3 = selcolor === 3 ? "sign__selectmodal_color_active":"sign__selectmodal_color";
  
  return (
    <div style={{marginTop: '10px'}}>
      <Space>
        <div> Colors </div>
        <div className={colorClsName0} style={{backgroundColor:'#555555'}} onClick={onClickColor1}> </div>
        <div className={colorClsName1} style={{backgroundColor:'#DC3545'}} onClick={onClickColor2}> </div>
        <div className={colorClsName2} style={{backgroundColor:'#0078FF'}} onClick={onClickColor3}> </div>
        <div className={colorClsName3} style={{backgroundColor:'#28A745'}} onClick={onClickColor4}> </div>
      </Space>
    </div>
  );
}

const SelectFont = ({onSelectFont, fontcolor, content, selFont, sel}) => {
  const [value, setValue] = useState(selFont);
  const onOptionChange = (e) => {setValue(e.target.value); onSelectFont(e.target.value);}

  const fontName = ['Blackadder ITC','Brush Script MT','Curlz MT','Edwardian Script ITC','Kunstler Script','Lucida Calligraphy','Magneto','Vladimir Script',];

  var _content = content;
  if (_content === '')
  {
    _content = sel === "sign" ? "Signature" : "Initials";
  }
  return (
    <div style={{border:'1px solid rgba(0, 0, 0, 0.2)', height:'calc(25vh)', width:'97%'}}>
      <Scrollbars style={{ width: '100%', height: '100%' }}>
        <Radio.Group onChange={onOptionChange} value={value} className="sign__selectmodal_radiogroup">
          <Space direction="vertical" style={{width:'100%'}}>
          <Radio value={1} className='sign__selectmodal_radio' style={{marginTop:'10px', fontFamily:fontName[0], color:fontcolor,}}>{_content}</Radio>
            <Radio value={2} className='sign__selectmodal_radio' style={{fontFamily:fontName[1], color:fontcolor}}>{_content}</Radio>
            <Radio value={3} className='sign__selectmodal_radio' style={{fontFamily:fontName[2], color:fontcolor}}>{_content}</Radio>
            <Radio value={4} className='sign__selectmodal_radio' style={{fontFamily:fontName[3], color:fontcolor}}>{_content}</Radio>
            <Radio value={5} className='sign__selectmodal_radio' style={{fontFamily:fontName[4], color:fontcolor}}>{_content}</Radio>
            <Radio value={6} className='sign__selectmodal_radio' style={{fontFamily:fontName[5], color:fontcolor}}>{_content}</Radio>
            <Radio value={7} className='sign__selectmodal_radio' style={{fontFamily:fontName[6], color:fontcolor}}>{_content}</Radio>
            <Radio value={8} className='sign__selectmodal_radio' style={{fontFamily:fontName[7], color:fontcolor}}>{_content}</Radio>
          </Space>
        </Radio.Group>           
      </Scrollbars>
    </div>
  );
}

const SpinButton = ({proc, _index}) => {
  const {t} = useTranslation();
  const max_page = useSelector((state: RootState) => state.signOption.max_page);
  const placeto = useSelector((state: RootState) => state.signOption.placeto);
  const dispatch = useDispatch();

  const onChangeFrom = (e) => { 
    var payload = {
      type:'from',
      index: _index,
      from: e,
    };
    dispatch(setPlaceToChange(payload));
  }
  const onChangeTo = (e) => { 
    var payload = {
      type:'to',
      index: _index,
      to: e,
    };
    dispatch(setPlaceToChange(payload));
  }

  const onRemove = () => { proc(_index); }//border: '1px solid #8ab8e2'
  return (
    <div  style={{width:'290px', marginTop:'5px', marginBottom:'5px', border: '1px solid #8ab8e2', borderRadius:'4px'}}>
      <InputNumber 
        min={1}
        max={max_page} 
        keyboard={true} 
        value={placeto[_index].from} 
        onChange = { onChangeFrom }
        prefix={ <span>{t('tools.sign.from')}</span> }
        style={{ width: 100, margin:5 }}
      />
      <InputNumber 
        min={1}
        max={max_page} 
        keyboard={true} 
        value={placeto[_index].to} 
        onChange = { onChangeTo }
        prefix={ <span>{t('tools.sign.to')}</span> }
        style={{ width: 100, marginRight:5 }}
      />

    <ImBin style={{fontSize:'18px', cursor: 'pointer', 
      position:'absolute', left:'330px', marginTop:'10px'}} onClick={onRemove}/>
    </div>
  );

}
//var _count = 1;
export const PlaceModal = ({proc}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const placeto = useSelector((state: RootState) => state.signOption.placeto);
  const max_page = useSelector((state: RootState) => state.signOption.max_page);

  const [value, setValue] = useState(1);
  const [visible, setVisible] = useState(true);

  //const [countClick, setCountClick] = useState(placeto.length);

  const onCancelClick = (e) => { setVisible(false);proc(0); }
  const onApplyClick = (e) => { 
    //console.log("apply button click");
    setVisible(false);
    
    
    proc(value); 
  }
  const onOptionChange = (e) => { setValue(e.target.value); }
  const onClickAddPlace = () => {
    var payload = {
      from: 1,
      to: max_page,
    };
    dispatch(setPlaceToAdd(payload));
    //setCountClick(countClick+1);
  }
  const onClickRemovePlace = (e) => {
    //if (countClick === 1) return;
    if (placeto.length === 1) return;
    var payload = {
      type: 'remove',
      index: e,
    };
    dispatch(setPlaceToChange(payload));
    //setCountClick(countClick-1);
  }

  var addElement = '';
  if (value === 5)//custom setting
  {
    //console.log("count: ", placeto.length);//countClick);
    var element = [];
    for (var i = 0; i < placeto.length; i ++)//countClick
      element.push(<SpinButton key={'spin'+i.toString()} proc={onClickRemovePlace} _index={i}/>);

    addElement = (
      <div style={{marginLeft:'50px', marginTop:'10px'}}>
        {element}
        <Button style={{marginTop:'10px', height:'30px', borderRadius:'4px'}} onClick={onClickAddPlace}>{t('tools.sign.AddPlaceRange')}</Button>
      </div>
    );
  }
  return(
    <div className="placemodal">
      <Modal
        width='40%'
        title={
          <div style={{width:'40%'}}>Place to</div>
        }
        visible={visible}
        closable={false}
        footer={
          <Space>
            <Button className="primary" style={{marginTop:'5px', borderRadius:'4px'}} onClick={onCancelClick} >{t('tools.sign.Cancel')}</Button>
            <Button className="side__btns_html" style={{marginTop:'5px', borderRadius:'4px'}} onClick={onApplyClick} >{t('tools.sign.Apply')}</Button>
          </Space>
        }
        style={{borderRadius:'15px'}}
      >
      <div >
        <div style={{width:'100%', height:'50%', marginLeft:'30px'}}>
          <div style={{color: 'red', marginLeft:'10px'}}> 
            {t('tools.sign.linkedclones')} 
          </div>
          <Radio.Group onChange={onOptionChange} value={value}>
            <Space direction="vertical" style={{width:'100%'}}>
              <Radio value={1} style={{marginTop:'10px'}}>{t('tools.sign.Onlythispage')}</Radio>
              <Radio value={2} >{t('tools.sign.Allpages')}</Radio>
              <Radio value={3} >{t('tools.sign.Allpagesbutlast')}</Radio>
              <Radio value={4} >{t('tools.sign.Lastpage')}</Radio>
              <Radio value={5} >{t('tools.sign.Custompages')}</Radio>
            </Space>
          </Radio.Group> 
          
            
          
        </div>
        {addElement} 
      </div>
      </Modal>
    </div>
  );
}