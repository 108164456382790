import React, { useState } from 'react';
import { toolsListInfo } from '../../../utils/toolsInfo'//from '../../../../utils/toolsInfo'
import { FileUploader } from "react-drag-drop-files";
import Html2Modal from './Html2Modal';
import { useTranslation } from 'react-i18next';

const SelectHtml = ({ id, proc}) => {  
    //const [file, setFile] = useState(null);
    const {t} = useTranslation();
    const handleChange = (file) => {
        proc(file);
    };

    return (
        <div className="select-file">
            <div className="tool__header">
                <h1>{toolsListInfo[id].title}</h1>
                <h3>{t(`home.tools.${id}`)}</h3>
            </div>
            <div>
                <Html2Modal handleChange={handleChange}/>
            </div>
        </div>
    );
}

export default SelectHtml;