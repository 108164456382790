import React from 'react';

import { useDispatch } from 'react-redux'


import WorkArea from './WorkArea';
import WorkSide from './WorkSide';
import { Scrollbars } from 'react-custom-scrollbars';

const WorkSpace = ({ files, goAction }) => {

    const onAction = (filelist) => {        
        goAction();
    }

    /***********rendering**********/
    return (
        <>
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <WorkArea/>
            </Scrollbars>
            <WorkSide onAction={onAction}/>
        </>
    );
}

export default WorkSpace;