import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface InitOptionState {
  password: string,
  filelist: Array,
}

const initialState: InitOptionState = {
  password: "",
  filelist: [],
}

export const protectSlice = createSlice({
  name: 'protectOption',
  initialState,
  reducers: {
    setPassword: (state, action:PayloadAction<string>) =>{
      state.password = action.payload;
    },
    setFileList: (state, action:PayloadAction<Array>) =>{
      state.filelist = action.payload;
    },
    insertFilelist: (state, action) => {
      state.filelist.push({
        idx: state.filelist.length,
        file: action.payload, 
        remove: 0,
      });
    },
    removeFilelist: (state, action) => {
      state.filelist[action.payload].remove = 1;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setPassword, 
  setFileList, 
  insertFilelist, 
  removeFilelist } = protectSlice.actions

export default protectSlice.reducer