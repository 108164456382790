import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setFileList } from './splitSlice';

import SelectFiles from '../SelectFiles';
import WorkSpace from './WorkSpace';
import UploadingFile, { UplodFile, uploadFiles } from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';
import { useTranslation } from 'react-i18next';
import { axiosInstance } from '../../../utils/axiosInstance';

var links = [];
var _files = [];
const SplitPDF = () => {
    const { t } = useTranslation();
    //const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);
    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.splitOption.filelist);
    const mode = useSelector((state) => state.splitOption.mode);
    const custom_ranges = useSelector((state) => state.splitOption.custom_ranges);
    const fixed_range = useSelector((state) => state.splitOption.fixed_range);
    const select_pages = useSelector((state) => state.splitOption.select_pages);
    const merge_check = useSelector((state) => state.splitOption.merge_check);
    const id = 1;


	useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Prevent the default behavior which triggers the reload
            event.preventDefault();
            console.log('beforeunload SplitPDF')
    
            // Stop the page from reloading by setting the URL to itself
            setTimeout(() => {
                window.location.href = window.location.href;
            }, 0); // Delay execution to ensure it doesn't interfere with other browser operations
        };
    
        // Attach the beforeunload event listener
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    


    const goProcFunction = (files) => {
        //setFile(files);
        var initFileList = [];
        if (files.length) {
            for (let i = 0; i < files.length; i++) {
                initFileList.push(files[i]);
                //console.log('init file list : ', files, initFileList);
            }
        } else {
            initFileList.push(files)
        }
        dispatch(setFileList(initFileList));
        setProc(1);
    }

    const goAction = () => {
        //merge action//
        setProc(2);
        // submitFormData()
    }


    const goDownload = (downlinks) => {
        //merge action//
        links = downlinks;
        setProc(3);
    }


    /*****************rendering*****************/
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles
                    id={id}
                    proc={goProcFunction}
                    multiple={false}
                    btn_text={t('tools.split.btn')}
                    fileTypes={["PDF"]} />
            </div>
        );
    } else if (proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpace goAction={goAction} />
            </div>
        );
    } else if (proc === 2) {//uploading and action space
        for (var i = 0; i < filelist.length; i++) {
            if (filelist[i].remove !== 0) continue;
            _files.push(filelist[i].file);
        }
        console.log('files', _files.length);

        //option payload//
        var option = {};
        if (mode === t('tools.split.custom_ranges')) {
            option = {
                page_ranges: custom_ranges,
                merge_page: merge_check,
                mode: 0,
            };
        } else if (mode === t('tools.split.fixed_ranges')) {
            option = {
                split_page: fixed_range,
                merge_page: 0,
                mode: 1,
            };
        } else if (mode === t('tools.split.extract_all') || mode === t('tools.split.select_pages')) {

            option = {
                page_ranges: [],
                merge_page: 0,
                mode: 0,
            };

            for (let i = 0; i < select_pages.length; i++) {
                if (select_pages[i] === 0) continue;
                option.page_ranges.push([i + 1, i + 1]);
            }

            if (mode === t('tools.split.select_pages'))
                option.merge_page = merge_check;
        }
        //setOptions(option);
        //uploading//

        element = (
            <div className="tool">
                <UploadingFile id={id}
                    api={toolsListInfo[id].api}
                    goDownload={goDownload}
                    // submit={(e)=>[uploadFiles]}
                    text="Splitting PDF..."
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}
                />
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile
                    downlinks={links}
                    backurl={toolsListInfo[id].link}
                    e_mode={links === '' ? '' : 'download'} />
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default SplitPDF;