import React, { useState} from 'react';
import auth_img from '../../../assets/png/auth_img.png';

const Auth_info = (param) => {

	const [allItemShow, SetToggle] = useState(false);

	const toggleClick = () => {
		//alert(allItemShow);
		SetToggle(!allItemShow);
	}

	let ListItems = <div></div>;

	if (allItemShow) {
		ListItems = <div className="toggle_content" style={{display:allItemShow?'normal':'none'}}>
	        <ul className="listcheck">
	            <li className="listcheck_item listcheck_item--ok" key={'auth_tools_0'}>Merge PDF</li>
	            <li className="listcheck_item listcheck_item--ok" key={'auth_tools_1'}>Split PDF</li>
	            <li className="listcheck_item listcheck_item--ok" key={'auth_tools_2'}>Compress PDF</li>
	            <li className="listcheck_item listcheck_item--ok" key={'auth_tools_3'}>Office to PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_4'}>WORD to PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_5'}>POWERPOINT to PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_6'}>EXCEL to PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_7'}>PDF to WORD</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_8'}>PDF to WORD<span>(OCR)</span></li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_9'}>PDF to POWERPOINT</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_10'}>PDF to EXCEL</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_11'}>PDF to JPG</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_12'}>Image to PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_13'}>Add page numbers</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_14'}>Add watermark</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_15'}>Rotate PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_16'}>Unlock PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_17'}>Protect PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_18'} >Remove PDF pages</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_19'}>Reorder PDF pages</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_20'}>Organize PDF pages</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_21'}>PDF to PDF/A</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_22'}>Repair PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_23'}>Web to PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_24'}>Edit PDF</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_25'}>PDF Signature</li>
				<li className="listcheck_item listcheck_item--ok" key={'auth_tools_26'}>Certify PDF</li>
	        </ul>    
        </div>;
	};

	 	
	if(param.type === 'login'){
		return (
		    <div className="auth_box">
		      	<img className="auth_img" src={auth_img} alt=""/>
		      	<h1 className="title1">Log in to your workspace</h1>
		      	<p>Enter your email and password to access your iLovePDF account. You are one step closer to boosting your document productivity.</p>
		      	<p className="list_dropdown"  onClick={toggleClick}>See all tools</p>
		      	{ListItems}
		    </div>
		  );
	}
  	else if(param.type === 'register'){
  		
  		return (
		    <div className="auth_box">
		      	<img className="auth_img" src={auth_img} alt=""/>
		      	<h1 className="title1">PDF tools for productive people</h1>
		      	<p>iLovePDF helps you convert, edit, e-sign, and protect PDF files quickly and easily. Enjoy a full suite of tools to effectively manage documents —no matter where you’re working.</p>
		      	<p className="list_dropdown"  onClick={toggleClick}>See all tools</p>
		      	{ListItems}
		    </div>
		  );
  	}
  	else{
  		return (
		    <div className="auth_box">
		      	<h1 className="title1">Lost password?</h1>
		      	<p>No worries. Let's get you a new one quickly!</p>
		    </div>
		  );
  	}
}

export default Auth_info;