import React, {useState} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { removeElement } from './editSlice';

//import { Draggable } from "react-drag-reorder";
//import DraggableList from "react-draggable-lists";

import { Button } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";
import { BsFileEarmarkFont, BsFileEarmarkImage, BsPen , BsSlashLg, BsSquare, BsCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

/////////////////////////////////////////////
const AddedEachElement = ({ page, idx }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const added_elements = useSelector((state) => state.editOption.added_elements);

  const getIconFromType = (type) => {
    if(type==="text")
      return (<BsFileEarmarkFont style={{fontSize:20}}/>)
    else if(type==="img")
      return (<BsFileEarmarkImage style={{fontSize:20}}/>)
    else if(type==="pen")
      return (<BsPen style={{fontSize:20}}/>)
    else if(type==="line")
      return (<BsSlashLg style={{fontSize:20}}/>)
    else if(type==="rectangle")
      return (<BsSquare style={{fontSize:20}}/>)
    else if(type==="circle")
      return (<BsCircle style={{fontSize:20}}/>)
  }

  const getLabelFromType = (type) => {
    if(type==="text")
      return t('tools.edit.side_text')
    else if(type==="img")
      return t('tools.edit.side_img')
    else if(type==="pen")
      return t('tools.edit.side_pen')
    else if(type==="line")
      return t('tools.edit.side_line')
    else if(type==="rectangle")
      return t('tools.edit.side_rect')
    else if(type==="circle")
      return t('tools.edit.side_circle')
  }

  const onClickRemove = () => {
      const payload = {
          page: page,
          idx: idx,
      }
      dispatch(removeElement(payload));
  }

  return (
    <div style={{fontSize:14, display:'flex', paddingLeft:0}}>
        <div style={{paddingTop:4}}>
            {getIconFromType(added_elements[page][idx].type)}
        </div>
        <span style={{marginLeft:6, width: '83%', textAlign:'left', paddingTop:2}}>
          {getLabelFromType(added_elements[page][idx].type)} (id: {added_elements[page][idx].id})
        </span>
        <div style={{paddingTop:2}}>
          <Button size="small" shape="circle" icon={<DeleteOutlined/>} onClick={onClickRemove}/>
        </div>
    </div>
  );
}

const AddedElement = ({ page }) => {
  const {t} = useTranslation();
  const added_elements = useSelector((state) => state.editOption.added_elements);

  var eachElements = [];
  for(let i = 0; i < added_elements[page].length; i++)
  {
    if(added_elements[page][i].remove === 0)
      eachElements.push(
          <AddedEachElement page={page} idx={i} key={'added_element_' + i.toString()}/>
      );
  }

  if(eachElements.length === 0)
    return (<></>);
  
  return(
    <div className="range_option">      
      <div className="range-title">
        <span style={{color:'#41474e', fontWeight:600}}>{t('tools.edit.page')} {page}</span>
      </div>

      {eachElements}
    </div>
  );
}

export default AddedElement;