import React from 'react';
import WorkArea from './WorkArea';
import WorkSide from './WorkSide';
import { Scrollbars } from 'react-custom-scrollbars';

const WorkSpace = ({ files, goAction }) => {    

    const onAction = () => {
        goAction();
    }

    /***********rendering**********/
    return (
        <>
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <WorkArea file={files}/>
            </Scrollbars>
            <WorkSide onAction={onAction}/>
        </>
    );
}

export default WorkSpace;