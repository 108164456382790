import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setMode, setSelectPages, setMergeCheck, setPageString } from './splitSlice';

import { Segmented, Checkbox, Input, AutoComplete } from 'antd';
import { useTranslation } from 'react-i18next';

const TabExtractPages = () => {
	//const [option, setOption] = useState(t('tools.split.extract_all'));
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const option = useSelector((state) => state.splitOption.mode);
	const max_page = useSelector((state) => state.splitOption.max_page);
	const extract_pages_string = useSelector((state) => state.splitOption.extract_pages_string);
	const select_pages = useSelector((state) => state.splitOption.select_pages);
	
	const onSegmentChange = (value) => {
		//setOption(value);
		dispatch(setMode(value));//option set//

		if (value === t('tools.split.extract_all')) {
			let checked_page = [];
			for (let i = 0; i < max_page; i++) {
				checked_page.push(1);
			}
			dispatch(setSelectPages(checked_page));
		}
	}

	const onCheckMerge = (e) => {
		//console.log(`checked = ${e.target.checked}`);
		dispatch(setMergeCheck(e.target.checked?1:0));
	}

	const onChangePageExtract = (value) => {
		dispatch(setPageString(value));
	}

	//-------rendering---------//
	var option_element = '';
	if (option === t('tools.split.extract_all')) {
		option_element = (
			<p>
				{t('tools.split.extract_msg1')} 
				<b>{max_page} PDF</b> 
				{t('tools.split.extract_msg2')} 
			</p>
		);
	} else if (option === t('tools.split.select_pages')) {
		var cnt = 0;
		for (let i = 0; i < select_pages.length; i++) {
			cnt = cnt + select_pages[i];
		}

		option_element = (
			<>
				<div className="text-left"  style={{marginTop:20, padding:10}}>
					<span style={{fontWeight:'500'}}>{t('tools.split.extract_op1')}</span>
					<Input.Group compact>
				      <AutoComplete
				        style={{ width: '100%' }}
				        placeholder={t('tools.split.example') + ": 1-3,6"}
				        onChange={onChangePageExtract}
				        value={extract_pages_string}	    	
				      />
				    </Input.Group>
				</div>
				<div style={{marginTop:10, paddingLeft:10}}>
	            	<Checkbox onChange={onCheckMerge}>
	            		{t('tools.split.merge_check')}
	            	</Checkbox>
	            </div>
				<p>
					{t('tools.split.extract_msg3')}
					<b>{cnt} PDF</b> 
					{t('tools.split.extract_msg2')} 
				</p>
			</>
		);
	}

    return (
        <div className="option__tab__content option__tab__content--active">
            <div className="option__panel__content">
            	<div className="option__title">{t('tools.split.extract_op2')} </div>
            	<div style={{paddingLeft:3, paddingRigth:3}}>
            		<Segmented block options={[t('tools.split.extract_all'), t('tools.split.select_pages')]} onChange={onSegmentChange} value={option}/>
            	</div>
            </div>
            {option_element}
        </div>
    );
}

export default TabExtractPages;