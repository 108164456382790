import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {  Button, Layout, Input, Select, Checkbox, Tooltip, Radio} from 'antd';
import { SendOutlined} from '@ant-design/icons';
import { IoTabletLandscapeOutline, IoTabletPortraitOutline } from 'react-icons/io5';
import {FcRefresh} from 'react-icons/fc'

import nomarginsvg from '../../../assets/svg/jpg_to_pdf_margin1.svg';
import smallmarginsvg from '../../../assets/svg/jpg_to_pdf_margin2.svg';
import bigmarginsvg from '../../../assets/svg/jpg_to_pdf_margin3.svg';

import {IoEarthOutline} from 'react-icons/io5'
import { Scrollbars } from 'react-custom-scrollbars';

import { setPageSize, setScreenSize, setOnePage, setOrientation, setPageMargin, 
    setBlockAds, setRemovePopup, setUrl, setUploadStatus } from './html2Slice';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Header, Footer, Content } = Layout;
const WorkSideHtml2 = ({onAction}) => {
    const {t} = useTranslation();
    const url = useSelector((state) => state.html2Option.url);

    const dispatch = useDispatch();
    
    const [addr, setAddr] = useState('');
    const [onepage, setOne] = useState(0);
    const [orient, setOritent] = useState(0);

    const [margin, setMargin] = useState(0);
    const [ads, setAds] = useState(0);
    const [popup, setPopup] = useState(0);

    const onClickAction = () => {
        onAction(url);
    }
    const onUrlChange = (e) => {
        setAddr(e.target.value);
    }
    const onUrlKeyChange = (e) => {
        if(e.keyCode === 13){
            e.preventDefault(); 
            dispatch(setUrl(addr));
            //dispatch(setUploadStatus(0));
        }
    }
    const onSendUrl = () => {
        dispatch(setUrl(addr));
        //dispatch(setUploadStatus(0));
    }
    const onScreenSizeChange = (e) => {
        var varSize = ["DT", "D", "T", "M"];
        var nsel = -1;
        for (var i = 0; i < 4; i ++)
        {
            if (varSize[i] === e)
            {
                //alert(i);
                nsel = i;
            }
        }
        if (nsel === -1)
            return;
        dispatch(setScreenSize(nsel));
    }
    const onPageSizeChange = (e) => {
        dispatch(setPageSize(e));
    }
    const onOnepage = (e) => {
        var nsel = onepage === 0 ? 1 : 0;
        setOne(nsel);
        dispatch(setOnePage(nsel));
    }

    const onPortrait = () => {
        setOritent(0);
        dispatch(setOrientation(0));
    }
    const onLandScape = () => {
        setOritent(1);
        dispatch(setOrientation(1));
    }

    const onNoMargin = () => {
        setMargin(0);
        dispatch(setPageMargin(0));
    }
    const onSmall = () => {
        setMargin(1);
        dispatch(setPageMargin(1));
    }
    const onBig = () => {
        setMargin(2);
        dispatch(setPageMargin(2));
    }

    const onBlockAdsChange = () => {
        var nsel = ads === 0? 1: 0;
        setAds(nsel);
        dispatch(setBlockAds(nsel));
    }
    
    const onRemovePopupChange = () => {
        var nsel = popup === 0? 1: 0;
        setPopup(nsel);
        dispatch(setRemovePopup(nsel));
    }
/***
 * <div>
                                <p>
                                {t('tools.html2pdf.SCREENSIZE')}
                                </p>
                                
                                <Select defaultValue="DH" style={{width:'88%', textAlign:'left'}} onChange={onScreenSizeChange}>
                                    <Option value="DH">{t('tools.html2pdf.Desktop')} HD(1920px) </Option>
                                    <Option value="D">{t('tools.html2pdf.Desktop')} (1440px) </Option>
                                    <Option value="T">{t('tools.html2pdf.Tablet')} (768px) </Option>
                                    <Option value="M">{t('tools.html2pdf.Mobile')} (320px) </Option>
                                </Select>
                            </div>
 */
    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>HTML TO PDF</Header>
                <Content>
                    <div style={{height:'calc(100vh - 152px - 60px)'}}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <div>
                                <p>
                                {t('tools.html2pdf.WEBSITE')}  URL
                                </p>
                                <Input.Group compact>
                                    <Input placeholder="https://pdfmade.com" defaultValue={url} onChange={onUrlChange} onKeyDown={onUrlKeyChange} prefix={<IoEarthOutline/>} style={{width:'75%', textAlign:'left'}}/>
                                    <Tooltip title={t('tools.html2pdf.goaddress')}>
                                        <Button icon={<FcRefresh/>} onClick={onSendUrl}/>
                                    </Tooltip>
                                </Input.Group>
                            </div>
                            
                            <div>
                                <p>
                                {t('tools.html2pdf.PAGESIZE')}
                                </p>
                                <Select defaultValue={0} style={{width:'88%', textAlign:'left'}} onChange={onPageSizeChange}>
                                    <Option value={0}>A3 (297x420 mm) </Option>
                                    <Option value={1}>A4 (197x210 mm) </Option>
                                    <Option value={2}>A5 (148x210) </Option>
                                    <Option value={3}>US {t('tools.html2pdf.Letter')} (216x279 mm) </Option>
                                </Select>
                            </div>
                            
                            
                            <div>
                                <Checkbox onChange={onOnepage} style={{width:'88%', textAlign:'left', marginTop:'5px'}}>{t('tools.html2pdf.Onelongpage')}</Checkbox>
                            </div>
                            <div>
                                <p>
                                {t('tools.html2pdf.ORIENTATION')}
                                </p>
                            </div>
                            
                            <div className="side__btns__container">
                                <div className={orient === 0 ? "side__btns1_select":"side__btns1"}  onClick={onPortrait}>
                                    <div className="side__btns__items">
                                        <div>
                                        <IoTabletPortraitOutline style={{ fontSize: '40px', color: 'white', marginLeft:10, marginRight:10 }}/>
                                        </div>
                                        <div className="side__btns__item_text">
                                            {t('tools.html2pdf.Portrait')}
                                        </div>
                                    </div>
                                </div>
                                <div className={orient === 1 ? "side__btns1_select":"side__btns1"}  onClick={onLandScape}>
                                    <div className="side__btns__items">
										<div>
											<IoTabletLandscapeOutline style={{ fontSize: '40px', color: 'white', marginLeft:10, marginRight:10 }}/>
                                        </div>
                                        <div  className="side__btns__item_text">
                                            {t('tools.html2pdf.LandScape')}
                                        </div>
                                    </div>
                                </div>
                            </div>
							
                            <div>
                                <p>
                                {t('tools.html2pdf.MARGIN')}
                                </p>
                            </div>
                            <div className="side__btns__container">
                                <div className={margin === 0 ? "side__btns1_select":"side__btns1"}  onClick={onNoMargin}>
                                    <div className="side__btns__items">
                                        <div>
                                        <img src={nomarginsvg} alt="nomargin" style={{width:'50%', height:'50%'}}/>
                                        </div>
                                        <div className="side__btns__item_text">
                                            {t('tools.html2pdf.NoMargin')}
                                        </div>
                                    </div>
                                </div>
                                <div className={margin === 1 ? "side__btns1_select":"side__btns1"}  onClick={onSmall}>
                                    <div className="side__btns__items">
                                    <div>
                                        <img src={smallmarginsvg} alt="small margin" style={{width:'50%', height:'50%'}}/>
                                        </div>
                                        <div  className="side__btns__item_text">
                                            {t('tools.html2pdf.Small')}
                                        </div>
                                    </div>
                                </div>
                                <div className={margin === 2 ? "side__btns1_select":"side__btns1"}  onClick={onBig}>
                                    <div className="side__btns__items">
                                        <div>
                                        <img src={bigmarginsvg} alt="big margin" style={{width:'50%', height:'50%'}}/>
                                        </div>
                                        <div  className="side__btns__item_text">
                                            {t('tools.html2pdf.Big')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div >
                                <p>
                                    HTML {t('tools.html2pdf.SETTINGS')}
                                </p>
                            </div>
                            
                            
                            <div>
                                <Checkbox onChange={onBlockAdsChange} style={{width:'88%', textAlign:'left'}}>{t('tools.html2pdf.Trytoblockads')}</Checkbox>
                            </div>
                            <div>
                                <Checkbox onChange={onRemovePopupChange} style={{width:'88%', textAlign:'left', marginTop:'5px'}}>{t('tools.html2pdf.Removepopups')}</Checkbox> 
                            </div>
                        
                        </Scrollbars>
                    </div>
                </Content>
                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction}>
                    {t('tools.html2pdf.ConvertPDF')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSideHtml2;