import React from 'react';

import Auth_form from './Form/Auth_form';
import Auth_info from './Form/Auth_info';
/**
 * 
 * @returns 
 * <div className="auth_info">      	
      <Auth_info type='login'/>
    </div>
 */
const Login = () => {
  return (
    <div className="auth">
      	<div className="auth_form">
      		<Auth_form type='login'/>
      	</div>      	
    </div>
  );
}

export default Login;