import React from 'react';

//import { useSelector } from 'react-redux'
import WorkArea from './WorkArea';
import WorkSide from './WorkSide';

//import LoadingDocument from './LoadingDocument';

//var inited = 0;
const WorkSpace = ({ goAction }) => {
    //const filelist = useSelector((state) => state.organizeOption.filelist);

    const onAction = () => {        
        goAction();
    }

    /*var pdfdocumnets = [];
    for (let i = 0; i < filelist.length; i++) {
        pdfdocumnets.push(<LoadingDocument fileidx={i} key={'loading_' + i}/>);
    }*/


    /***********rendering**********/
    return (
        <>    
            <WorkArea/>
            <WorkSide onAction={onAction}/>
        </>
    );
}

export default WorkSpace;