import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import {  Button, Empty, Layout, Select, Slider, InputNumber, Tabs,
    Row, Col, Radio, Checkbox, Space, Popover, Input } from 'antd';
import { AiOutlineBold, AiOutlineItalic, AiOutlineUnderline, AiOutlineFontColors, AiOutlineFontSize } from 'react-icons/ai';

import { SendOutlined} from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import {setPositionOption, setMosaicOption, 
        setTransparencyOption, setRotationOption,
        setSingleStartOption, setSingleEndOption, 
        setSelectLayerOption, setFontNameOption, setFontSizeOption, 
        setBoldOption, setItalicOption, setUnderlineOption, setFontColorOption,
        setTextOption, setImageFileOption, setModeOption,
    } from './watermarkSlice';
import { useTranslation } from 'react-i18next';
import oversvg from '../../../assets/svg/watermark_over.svg';
import belowsvg from '../../../assets/svg/watermark_below.svg'; 
import {BsFileImage} from 'react-icons/bs';

import nomarginsvg from '../../../assets/svg/jpg_to_pdf_margin1.svg';
import { FileUploader } from "react-drag-drop-files";

const { Header, Footer, Content } = Layout;
const {Option} = Select;
const { TabPane } = Tabs;

const WorkSideWatermark = ({onAction}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.watermarkOption.filelist);
    const maxPage = useSelector((state) => state.watermarkOption.maxPage);
    const position = useSelector((state) => state.watermarkOption.position);
    const mosaic = useSelector((state) => state.watermarkOption.mosaic);
    const transparency = useSelector((state) => state.watermarkOption.transparency);
    const rotation = useSelector((state) => state.watermarkOption.rotation);
    const singlestart = useSelector((state) => state.watermarkOption.singlestart);
    const singleend = useSelector((state) => state.watermarkOption.singleend);
    
    const mode = useSelector((state) => state.watermarkOption.mode);
    const imagefile = useSelector((state) => state.watermarkOption.imagefile);
    const text = useSelector((state) => state.watermarkOption.text);

    const dispatch = useDispatch();
    
    //console.log("imagefile: ", imagefile);
    //console.log("mode: ", mode);
    //console.log("mode: ", mode == 1);
    //console.log("image: ", imagefile != null);

    var btndiasable = true;
    if (filelist.length > 0)
    {
        if ((mode == 0) & (text != ''))
            btndiasable = false;
        else if((mode == 1) & (imagefile != null))
            btndiasable = false;
    }
    //console.log("side singleend value: ", singleend);
    //const [selmode, setSelMode] = useState(-1);

    var cnt = filelist.length;
    
    const onClickAction = (e) => {
        e.preventDefault();
        onAction(filelist);
    }
    
    //////// Position and Margin
    const onPositionGridChange = (e) => {
        dispatch(setPositionOption(e));
    }
    const onMosaicChange = (e) => {
        dispatch(setMosaicOption(e));
    }
    //////// Transparency and Rotation
    const onTransparencyChange = (e) => {
        var value = e;
        //console.log(e);
        dispatch(setTransparencyOption(parseInt(value)));
    }
    const onRotationChange = (e) => {
        var value = e;
        dispatch(setRotationOption(parseInt(value)));
        //console.log(e);
    }
    //////// Pages
    const onSingleStartChange = (e) => {
        dispatch(setSingleStartOption(e));
    }
    const onSingleEndChange = (e) => {
        dispatch(setSingleEndOption(e));
    }
    

    //////////// Layer
    const onWaterLayerClick = (e) => {
        dispatch(setSelectLayerOption(e));
    }
    
    //////////// TEXT FORMAT
    const onFontNameChange = (e) => {
        dispatch(setFontNameOption(e));
    }
    const onFontSizeChange = (e) => {
        dispatch(setFontSizeOption(e));
    }
    const onBoldChange = (e) => {
        dispatch(setBoldOption(e));
    }
    const onItalickChange = (e) => {
        dispatch(setItalicOption(e));
    }
    const onUnderlineChange = (e) => {
        dispatch(setUnderlineOption(e));
    }
    const onFontColorChange = (e) => {
        dispatch(setFontColorOption(e));
    }
    const onImageAddChange = (e) => {
        dispatch(setImageFileOption(e));
    }
    const onTextChange = (e) => {
        dispatch(setTextOption(e));
    }
    const onModeChange = (e) => {
        dispatch(setModeOption(e));
    }
    //fnproc, fsproc, bproc, iproc, uproc, fcproc, imgproc, txtproc
    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>WATERMARK</Header>

                <Content>
                    <div style={{height:'calc(100vh - 152px - 60px)'}}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <WaterTab 
                                lproc={onModeChange}
                                fnproc={onFontNameChange}
                                fsproc={onFontSizeChange}
                                bproc={onBoldChange}
                                iproc={onItalickChange}
                                uproc={onUnderlineChange}
                                fcproc={onFontColorChange}
                                imgproc={onImageAddChange}
                                txtproc={onTextChange}
                            /> 
                            <WatermarkPos 
                                gpro={onPositionGridChange} 
                                mpro={onMosaicChange} 
                                pos={position}
                                mosaic={mosaic}
                            />
                            
                            <TransRotation
                                tpro={onTransparencyChange} 
                                rpro={onRotationChange} 
                                trans={transparency}
                                rotate={rotation}
                            />
                            <Pages 
                                cnt={cnt}
                                maxPage={maxPage}
                                sproc={onSingleStartChange}
                                eproc={onSingleEndChange}
                                start={singlestart}
                                end={singleend}
                            />
                            <WaterLayer pro={onWaterLayerClick}/>
                        </Scrollbars>
                    </div>
                </Content>
                
                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction}  disabled={btndiasable}>
                    {t('tools.watermark.AddWatermark')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSideWatermark;

const ColorPicker = ({proc}) => {
    const [color, setColor] = useState({r:0, b:0, g:0, a:1});
    
    const onChange = (color) => {
        //console.log(color.rgb);
        setColor(color.rgb);
        proc(color.rgb);
    };
    return (
        <SketchPicker  color={ color } onChange={ onChange }></SketchPicker>
    );
}

const FontSize = ({proc}) => {
  
    const [inputValue, setInputValue] = useState(14);
    const onChange = value => {
      setInputValue(value);
      proc(value);
    };
  
    return (
        <Row style={{width: '270px'}}>
            <Col style={{marginRight:'15px', marginTop:'5px'}}>Font Size:</Col>
            <Col style={{width: '30%'}}>
                <Slider
                min={1}
                max={100}
                onChange={onChange}
                value={typeof inputValue === 'number' ? inputValue : 0}
                />
            </Col>
            <Col style={{width: '20%'}}>
                <InputNumber
                min={1}
                max={100}
                style={{ margin: '0 16px' }}
                value={inputValue}
                onChange={onChange}
                />
            </Col>
        </Row>
    );
}

const PageMode = ({fcover, rpro, cpro}) => {
    const [radioSel, setRadioSel] = useState(0)
    const [firstSel, setFirstSel] = useState(fcover)
    const {t} = useTranslation();

    const onRadioChange = (e) => {
        setRadioSel(e.target.value);
        rpro(e.target.value);
    }
    const onFirstCoverPage = () => {
        var nsel = firstSel === 0 ? 1: 0;
        //console.log("start first cover", nsel);
        setFirstSel(nsel);
        cpro(nsel);
    }
    
    var element = '';
    if (radioSel === 1)
    {
        element = (
            <div>
                <Checkbox checked={firstSel} onChange={onFirstCoverPage} style={{width:'88%', textAlign:'left', marginTop:'10px', marginLeft:'12px'}}>{t('tools.watermark.Firstpage')}</Checkbox>
            </div>
        );
    }
    return (
        <div>
            <div> <p> {t('tools.watermark.PageMode')} </p> </div> 
            <div>
                <Radio.Group onChange={onRadioChange} value={radioSel}>
                    <Radio value={0}>{t('tools.watermark.SinglePage')}</Radio>
                    <Radio value={1}>{t('tools.watermark.FacingPage')}</Radio>
                </Radio.Group>
            </div>
            {element}
        </div>
    );
}

const Pages = ({cnt, maxPage,sproc, eproc, start, end}) => {
    /**
     * 
     * @param {*} e
     * cnt: file number
     * maxPage: when one pdf is opened, total page number
     * sproc: from process
     * eproc: to process 
     * start: start value
     * end: end value
     */
     const {t} = useTranslation();
    const [endValue, setEndValue] = useState(end);
    const [startValue, setStartValue] = useState(start);
    

    const onSingleStartChange = (e) => {
        sproc(e);
        setStartValue(e);
    }
    const onSingleEndChange = (e) => {
        eproc(e);
        setEndValue(e);
    }
    //console.log("pages max page: ", maxPage);
    //console.log("pages end value: ", end);
    var elementWhich = '';
    if (cnt === 1){
        elementWhich = (
            <div style={{marginTop:'5px'}}>
                <InputNumber min={1} max={maxPage} value={startValue} onChange = {onSingleStartChange}prefix={<span> from </span>} style={{ width: 120, marginRight:5, left:'-10px' }}/>
                <InputNumber min={1} max={maxPage} value={end} onChange = {onSingleEndChange}prefix={<span>to</span>} style={{ width: 90 }}/>
            </div>
        );
    }
    else{
        elementWhich = (
            <div style={{textAlign:'left', marginLeft:'15px'}}>
               {t('tools.watermark.AllPages')} 
            </div>
        );
    }
    
    return (
        <div>
            <div>
                <p>  {t('tools.watermark.Pages')} </p>
            </div> 
            {elementWhich}
        </div>
            
    );
}

const PositionGrid = ({proc, pos}) => {
    const [sel, setSel] = useState(pos);
    
    const onClick1 = () => {
      setSel(0);proc(0);
    }
    const onClick2 = () => {
      setSel(1);proc(1);
    }
    const onClick3 = () => {
      setSel(2);proc(2);
    }
    const onClick4 = () => {
      setSel(3);proc(3);
    }
    const onClick5 = () => {
      setSel(4);proc(4);
    }
    const onClick6 = () => {
      setSel(5);proc(5);
    }
    const onClick7 = () => {
      setSel(6);proc(6);
    }
    const onClick8 = () => {
      setSel(7);proc(7);
    }
    const onClick9 = () => {
      setSel(8);proc(8);
    }
    ///////////////////rendering////////////////
    var name = [];
    name[0] = sel == 0 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[1] = sel == 1 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[2] = sel == 2 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[3] = sel == 3 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[4] = sel == 4 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[5] = sel == 5 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[6] = sel == 6 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[7] = sel == 7 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    name[8] = sel == 8 ? "pdf-pagenumber-circle_grid":"pagenumbers_select_position";
    return (
      <div className="pagenumbers_group_select_position" style={{marginLeft: '20px'}}>
        <Row>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[0]} onClick={onClick1}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[1]} onClick={onClick2}>
              </div>
            </div>  
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[2]} onClick={onClick3}>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[3]} onClick={onClick4}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[4]} onClick={onClick5}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[5]} onClick={onClick6}></div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[6]} onClick={onClick7}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[7]} onClick={onClick8}></div>
            </div>
          </Col>
          <Col>
            <div className="pdf-pagenumber-circle_grid_above">
              <div className={name[8]} onClick={onClick9}></div>
            </div>
          </Col>
        </Row>
      </div>
    );
}

const TextFormat = ({nproc, sproc, bproc, iproc, uproc, cproc}) => {
    /**
     * 
     * @param {*} e 
     * nproc: name process
     * sproc: font size process
     * bproc: bold process
     * iproc: italick process
     * uproc: underline process
     * cproc: font color process
     */
     const {t} = useTranslation();
    const onSelectFontName = (e) => {
        //alert(e);
        nproc(parseInt(e));
    }

    const [bold, setBold] = useState(0);
    const [italic, setItalic] = useState(0);
    const [underline, setUnderline] = useState(0);
    const [fontcolor, setFontColor] = useState({
        r: '225',
        g: '155',
        b: '99',
        a: '2',
      });
    const [fontsize, setFontSize] = useState(14);

    const onBoldClick = () => {
        var nsel = bold === 0 ? 1 : 0;
        setBold(nsel);
        bproc(nsel);
    }
    const onItalicClick = () => {
        var nsel = italic === 0 ? 1 : 0;
        setItalic(nsel);
        iproc(nsel);
    }
    const onUnderlineClick = () => {
        var nsel = underline === 0 ? 1 : 0;
        setUnderline(nsel);
        uproc(nsel);
    }
    const onFontColorChange = (e) => {
        setFontColor(e);
        cproc(e);
        //console.log("font color:", e);
    }
    const onFontSizeChange = (e) => {
        setFontSize(e);
        sproc(e);
        //console.log("font size:", e);
    }
    const fontSizeContent = (
        <div>
            <FontSize proc={onFontSizeChange} style={{width: '200px'}}></FontSize>
        </div>
    )
    const colorPickerContent = (
        <ColorPicker proc={onFontColorChange}></ColorPicker>
    )

    const fontName = [
        {id:'0',name:'Arial'},
        {id:'1',name:'Calibri'},
        {id:'2',name:'Cambria'},
        {id:'3',name:'Corbel'},
        {id:'4',name:'Comic Sans MS'},
        {id:'5',name:'Consolas'},
        {id:'6',name:'Constantia'},
        {id:'7',name:'Courie New'},
        {id:'8',name:'Georgia'},
        {id:'9',name:'Palatino Linotype'},
        {id:'10',name:'Sitka Heading'},
        {id:'11',name:'Times New Roman'},
        {id:'12',name:'Verdana'},
    ]
    //style={{textAlign:'left', marginLeft:'15px'}}
    return (
        <div >
            <div>
                <p>
                {t('tools.watermark.textformat')}
                </p>
            </div>
            <div style={{float:'left', marginLeft:'15px', marginTop:'5px'}} >
                <Select 
                    defaultValue={fontName[0].id}
                    style={{marginRight: '10px', marginTop:'-5px'}}
                    dropdownMatchSelectWidth={false}
                    placement="topLeft"
                    onChange={onSelectFontName}
                >    
                {fontName.map(province => (
                    <Option key={province.id}>{province.name}</Option>
                    ))}
                </Select>
                <Popover placement="topRight" content={fontSizeContent}>
                    <AiOutlineFontSize style={{fintSize:'35px'}}/>
                </Popover>
            </div>
            <div style={{float:'left', marginLeft:'15px', marginTop:'5px'}}>
                <Space>
                    <div><AiOutlineBold className={bold === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onBoldClick}/></div>
                    <div><AiOutlineItalic className={italic === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onItalicClick}/></div>
                    <div><AiOutlineUnderline className={underline === 0?"textformat_BIU":"textformat_BIU_active"} onClick={onUnderlineClick}/></div>
                    <Popover placement="topRight" content={colorPickerContent}>
                        <AiOutlineFontColors style={{ fontSize: '20px'}}/>
                    </Popover>
                </Space>
            </div>
        </div>
    );
}

const TextPage = ({nproc, cproc}) => {//nproc: numbertype proc, cproc: custom text proc
    const {t} = useTranslation();
    const [numberType, setNumberType] = useState(0);
    const onNumberType = (e) => {
        setNumberType(e);
        nproc(e);
    }
    
    var bracketN = '\{n\}';
    var bracketP = '\{p\}';
    var elementCustomNumber = '';
    const onCustomText = (e) => {
        cproc(e.target.value);
    }
    if (numberType === 3)
    {
        elementCustomNumber = (
            <div>
                <div style={{textAlign:'left', marginLeft:'17px'}}>{t('tools.watermark.CustomText')}</div>
                <Input style={{width:'88%'}} placeholder="Page {n} of {p}" onChange={onCustomText}/>
                <div style={{textAlign:'left', marginLeft:'17px'}}><small>{t('tools.watermark.Textsample')}: {bracketN}, Page {bracketN}, Page {bracketN} of {bracketP}</small></div>
            </div>
        );
    }
    return (
        <div>
            <div>
                <p> {t('tools.watermark.TEXT')}   </p>
            </div>
            <div>
                <Select defaultValue={0} style={{width: '88%', textAlign:'left'}} onChange={onNumberType}>
                    <Option value={0}>{t('tools.watermark.Insertonly')}</Option>
                    <Option value={1}>{t('tools.watermark.Page')} {bracketN}</Option>
                    <Option value={2}>{t('tools.watermark.Page')}{bracketN} of {bracketP}</Option>
                    <Option value={3}>{t('tools.watermark.Custom')}</Option>
                </Select>
            </div>
            {elementCustomNumber}
        </div>
    );
}


const TransRotation = ({tpro, rpro, trans, rotate}) => {
    /**
     * 
     * @param {*} e 
     * tpro: transparency process
     * rpro: rotation process
     * trans: selected transparency
     * rotate: selected rotation
     */
     const {t} = useTranslation();
    const onRotationChange = (e) => {
        rpro(e);
        //console.log('rotation: ', e);
    }
    const onTransparency = (e) => {
        tpro(e);
        //console.log('transparency: ', e);
    }
    /**
     * <Select defaultValue={rotate} style={{marginLeft:'40px', width:'100px', textAlign:'left'}}  onChange={onRotationChange} >
                            <Option value={0}>{t('tools.watermark.Donot')}</Option>
                            <Option value={1}>45% {t('tools.watermark.degrees')}</Option>
                            <Option value={2}>90% {t('tools.watermark.degrees')}</Option>
                            <Option value={3}>180% {t('tools.watermark.degrees')}</Option>
                            <Option value={4}>270% {t('tools.watermark.degrees')}</Option>
                        </Select>
     */
    return (
        <div>
            <Row>
                <Col xs={2} sm={4} md={6} lg={8} xl={10} style={{marginRight:'10px'}}> 
                    <p style={{width:"115px"}}> {t('tools.watermark.TRANSPARENCY')} </p>
                    <div>
                        <Select defaultValue={0} style={{marginLeft:'15px', width:'115px', textAlign:'left'}}  onChange={onTransparency} >
                            <Option value={0}>{t('tools.watermark.Notransparency')}</Option>
                            <Option value={1}>75%</Option>
                            <Option value={2}>50%</Option>
                            <Option value={3}>25%</Option>
                        </Select>
                    </div>
                </Col>
                <Col xs={20} sm={16} md={12} lg={8} xl={4}>
                    <p style={{width:"100px", marginLeft:'40px'}}>
                    {t('tools.watermark.ROTATION')}
                    </p>
                    <div>
                        <Select defaultValue={0} style={{marginLeft:'40px', width:'100px', textAlign:'left'}}  onChange={onRotationChange} >
                            <Option value={0}>{t('tools.watermark.Donot')}</Option>
                            <Option value={1}>45 degrees</Option>
                            <Option value={2}>90 degrees</Option>
                            <Option value={3}>180 degrees</Option>
                            <Option value={4}>270 degrees</Option>
                        </Select>
                    
                    </div>
                </Col>
            </Row>
        </div>
    );
}

const WaterLayer = ({pro}) => {
    /**
     * 
     * @param {*} e 
     * gpro: grid process
     * spro: select process
     * pos: selected position
     */
     const {t} = useTranslation();
    const [selmode, setSelMode] = useState(0);
    const onOverClick = () => {
        setSelMode(0)
        pro(0);
    }
    const onBelowClick = () => {
        setSelMode(1);
        pro(1);
    }
    return (
        <div>
        <div>
            <p>
            {t('tools.watermark.layer')}
            </p>
        </div>
        
        <div className="side__btns__container">
            <div className={selmode === 0 ? "side__btns1_select":"side__btns1"}  onClick={onOverClick}>
                <div className="side__btns__items">
                    <div>
                        <img src={oversvg} alt="over layer" style={{width:'50%', height:'50%'}}/>
                    </div>
                    <div className="side__btns__item_text">
                        {t('tools.watermark.Over')}
                    </div>
                </div>
            </div>
            <div className={selmode === 1 ? "side__btns1_select":"side__btns1"}  onClick={onBelowClick}>
                <div className="side__btns__items">
                    <div>
                        <img src={belowsvg} alt="below layer" style={{width:'50%', height:'50%'}}/>
                    </div>
                    <div  className="side__btns__item_text">
                        {t('tools.watermark.Below')}
                    </div>
                </div>
            </div>
        </div>   
        </div>
    );
}

const WatermarkPos = ({gpro, mpro, pos, mosaic}) => {
    /**
     * 
     * @param {*} e 
     * gpro: grid process
     * mpro: mosaic process
     * pos: selected position
     * mosaic: mosaic selection
     */
     const {t} = useTranslation();
     const [selPos, setSelPos] = useState(pos);
     const [selMosaic, setSelMosaic] = useState(mosaic);

    const onPositionGridChange = (e) => {
        gpro(e);
    }
    const onMosaicChange = (e) => {
        var sel = mosaic === 0 ? 1 : 0;
        mpro(sel);
    }
    return (
        <div>
            <div>
                    <p style={{width:"100px"}}> {t('tools.watermark.Position')} </p>
            </div>
            <Space style={{width:'100%'}}>
                    <div style={{marginRight:'5px'}}>   <PositionGrid proc={onPositionGridChange} pos={pos}/>    </div>
                    <Checkbox onChange={onMosaicChange}>{t('tools.watermark.Mosaic')}</Checkbox>
            </Space>
        </div>
    );
}

var bFirst = true;
const  WaterTab = ({lproc, fnproc, fsproc, bproc, iproc, uproc, fcproc, imgproc, txtproc}) => {
    /**
     * lproc: layer select process
    * fnproc: font name process
    * fsproc: font size process
    * bproc: bold process
    * iproc: italic process
    * uproc: underline process
    * fcproc: font color process
    * imgproc: image process
    * txtproc: text process
    */
     const {t} = useTranslation();
    const [image, setImage] = useState(nomarginsvg);

    const onFontNameChange = (e) =>{fnproc(e);}
    const onFontSizeChange = (e) =>{fsproc(e);}
    const onBoldChange = (e) =>{bproc(e);}
    const onItalickChange = (e) =>{iproc(e);}
    const onUnderlineChange = (e) =>{uproc(e);}
    const onFontColorChange = (e) =>{fcproc(e);}
    
    var imageText = t('tools.watermark.AddImage');
    if (!bFirst)
        imageText = t('tools.watermark.ChangeImage');
    const onClickAddImage = (file) =>{
        imgproc(file);
        setImage(URL.createObjectURL(file));
        bFirst = false;
    }

    const onTextChange = (e) => {
        txtproc(e.target.value);
    }
    const onTabChange = (e) => {
        lproc(e);
    }
    return (
        <Tabs type="card" onChange={onTabChange}>
            <TabPane
            tab={
                <div className="side__btns__items" style={{width:'100px'}}>
                    <div>
                        <AiOutlineFontColors style={{fontSize: '50px'}} />
                    </div>
                    <div className="side__btns__item_text" style={{ whiteSpace: 'normal'}}>
                    {t('tools.watermark.Placetext')}
                    </div>
                </div>
            }
            key="0"
            
            >
            <div>
                <p>
                {t('tools.watermark.TEXT')}
                </p>
            </div>
            <Input placeholder={t('tools.watermark.InputText')} style={{width:'88%'}} onChange={onTextChange}/>
            <TextFormat 
            nproc={onFontNameChange}
            sproc={onFontSizeChange}
            bproc={onBoldChange}
            iproc={onItalickChange}
            uproc={onUnderlineChange}
            cproc={onFontColorChange}
            />
            </TabPane>
            <TabPane
            tab={
                <div className="side__btns__items" style={{width:'100px'}}>
                    <div>
                        <BsFileImage style={{fontSize: '50px'}} />
                    </div>
                    <div className="side__btns__item_text" style={{whiteSpace: 'normal'}}>
                    {t('tools.watermark.Placeimage')}
                    </div>
                </div>
            }
            key="1"
            >
                <div style={{width:'70%', marginLeft:'60px'}}>
                    <FileUploader
                        multiple={false}
                        handleChange={onClickAddImage}
                        name="Image Files"
                        types={["JPG", "JPEG", "PNG", "TIF"]}
                        children={(
                                <div style={{width: '170px', height:'50px'}}>
                                    <Button 
                                        type="primary" 
                                        icon={<img src={image} alt="watermark" 
                                            style={{width:'20%', 
                                                    height:'90%', 
                                                    float: 'left'}}/>} 
                                        size="large">   
                                        {imageText}                             
                                    </Button>
                                </div>
                                 )}
                    />
                
                </div>
            </TabPane>
        </Tabs>
    );
}