import { configureStore } from '@reduxjs/toolkit';

import commonReducer from './commonSlice';

//tools//
import toolReducer from '../features/tools/merge_pdf/toolSlice';
import splitReducer from '../features/tools/split_pdf/splitSlice';
import pdf2wordReducer from '../features/tools/pdf_to_word/pdf2wordSlice';
import rotateReducer from '../features/tools/rotate_pdf/rotateSlice';
import protectReducer from '../features/tools/protect_pdf/protectSlice';
import organizeReducer from '../features/tools/organize_pdf/organizeSlice';
import editReducer from '../features/tools/edit_pdf/editSlice';

import html2pdfReducer from '../features/tools/html_to_pdf/html2Slice';
import jpg2pdfReducer from '../features/tools/jpg_to_pdf/jpg2PdfSlice';
import repairReducer from '../features/tools/repair_pdf/repairSlice';
import pdf2jpgReducer from '../features/tools/pdf_to_jpg/pdf2jpgSlice';
import pageNumbersReducer from '../features/tools/page_numbers/pageNumbersSlice';
import watermarkReducer from '../features/tools/watermark/watermarkSlice';

import pdf2pdfAReducer from '../features/tools/pdf_to_pdfA/pdf2pdfASlice'
import signReducer from '../features/tools/sign_pdf/signSlice';

export const store = configureStore({
  reducer: {
    commonOption: commonReducer,
    toolOption: toolReducer,
    splitOption: splitReducer,
    pdf2wordOption: pdf2wordReducer,
    rotateOption: rotateReducer,
    protectOption: protectReducer,
    organizeOption: organizeReducer,
    editOption: editReducer,
    html2Option: html2pdfReducer,
    jpg2pdfOption: jpg2pdfReducer,
    repairOption: repairReducer,
    pdf2jpgOption: pdf2jpgReducer,
    pageNumbersOption: pageNumbersReducer,
    watermarkOption: watermarkReducer,
    pdf2pdfAOption: pdf2pdfAReducer,
    signOption: signReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  																serializableCheck: false,
															}),
  devTools: process.env.NODE_ENV !== "production",
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch