import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFilelist } from './organizeSlice';

import { Scrollbars } from 'react-custom-scrollbars';

import {  Button, Layout, Tooltip } from 'antd';
import { SendOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;

const FilesElement = ({ num, file, bordercolor, fileidx, removeFile, t }) => {
    return(
        <div className="organize-files" 
            style={{color: bordercolor, boderColor: bordercolor}}>

            {num.toString() + '.' + file.name}

            <Tooltip title={t('tools.organize.side_remove')} placement="left">
                <div className="organize-files_remove" onClick={()=>{removeFile(fileidx)}}>
                    <DeleteOutlined />
                </div>
            </Tooltip>
        </div>
    );
}

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.organizeOption.filelist);
    const dispatch = useDispatch();

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) 
            cnt++;
    }

    const onClickAction = () => {
        onAction(filelist);
    }

    const removeFile = (fileidx) => {
        dispatch(removeFilelist(fileidx));
    }

    var files_elements = [];
    var tmp = 0;
    for (let i = 0; i < filelist.length; i++) {
        if(filelist[i].remove === 1)    continue;
        const r = ((i + 1) * 243) % 255;
        const g = ((i + 3) * 17) % 255;
        const b = ((i + 11) * 143) % 255;
        const bordercolor = `rgb(${r}, ${g}, ${b})`;
        tmp++;
        files_elements.push(
            <FilesElement num={tmp} file={filelist[i].file} bordercolor={bordercolor} fileidx={i} removeFile={removeFile} key={'files_' + i.toString()} t={t}/>
        );
    }

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>ORGANIZE</Header>

                <Content>
                    <p>
                        {t('tools.organize.side_msg')}
                    </p>
                    {/*<div className="center-tab text-left" style={{margin:10}}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <span style={{color:'#1890ff', fontSize:16}}>{t('tools.organize.side_files')}</span>
                            {files_elements}
                        </Scrollbars>                   
                    </div>*/}
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt > 0 ? false:true}>
                      {t('tools.organize.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;