import React, {useState} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage, setPageViewScale, addElement, setEnableDraw } from '../editSlice';

import { FileUploader } from "react-drag-drop-files";

import { InputNumber, Tooltip, Dropdown, message } from 'antd';

import { BsHandIndexThumb, BsZoomOut, BsZoomIn, BsFileCode, BsFileEarmarkFont,
  BsFileEarmarkImage, BsPen , BsSlashLg, BsSquare, BsCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
/////////////////////////EDIT TOOLBAR///////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
const EditToolBar = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const cur_page = useSelector((state) => state.editOption.cur_page); 
    const cur_top = useSelector((state) => state.editOption.cur_top);
    const page_rect = useSelector((state) => state.editOption.page_rect);
    const max_page = useSelector((state) => state.editOption.max_page);
    const viewscale = useSelector((state) => state.editOption.viewscale);

    const onChagenPageNumber = (value) => {
        dispatch(setCurrentPage({page:value, changed:1}));
    }

    const onClickPan = () => {

    }

    const onClickZoomout = () => {
        if(viewscale <= 0.25)   return;
        dispatch(setPageViewScale(viewscale - 0.25));
    }

    const onClickZoomin = () => {
        if(viewscale > 3)      return;
        dispatch(setPageViewScale(viewscale + 0.25));   
    }

    const onClickFit = () => {
        dispatch(setPageViewScale(1.0));
    }

    const onClickAddText = () => {
        var d = new Date();

        const payload = {
            page: cur_page,
            element: {
                type: 'text',
                page: cur_page,
                id: 'element_' + d.getTime().toString(),
                pos: {x: 0 + page_rect.width / 2 - 115 + page_rect.left, y: cur_top + 20},
                size:{w: 230, h:45},
                text: 'Your text here',
                font: "Arial",
                fontSize: 22,
                bold: 0,
                italic: 0,
                underline: 0,
                pen_color: 'rgba(0,0,0,1.0)',//font color
                fill_color: 'rgba(0,0,0,0)',//background color
                align: 'left',
                opacity: '100%',
                editable: 0,
                active: 0,
                remove: 0,
                rotate: 0,
            },
        };
        dispatch(addElement(payload));
    }

    const onClickAddImage = (file) => {
        var d = new Date();

        const payload = {
            page: cur_page,
            element: {
                type: 'img',
                page: cur_page,
                id: 'element_' + d.getTime().toString(),
                pos: {x: 0 + page_rect.width / 2 - 60 + page_rect.left, y: cur_top + 20},
                size:{w: 120, h:120},
                imgfile: file,
                opacity: '100%',
                rotate: 0,
                pen_color: '2.3px solid rgba(24,144,255,0.0)',//border
                fill_color: 'rgba(24,144,255,1.0)',//not use
                active: 0,
                remove: 0,
                rotate: 0,
            },
        };
        dispatch(addElement(payload));
    }


    const onClickPen = () => {
        dispatch(setEnableDraw(1));
        message.info(t('tools.edit.tool_pen_msg'));
    }

    const onClickAddLine = () => {
        //setSubmenu(0)
        var d = new Date();
        const payload = {
            page: cur_page,
            element: {
                type: 'line',
                page: cur_page,
                id: 'element_' + d.getTime().toString(),
                pos: {x:0 + page_rect.width / 2 - page_rect.width / 4 + page_rect.left, y:cur_top + 20},
                size: {w: page_rect.width / 2, h:16},
                pen_color: 'rgba(24,144,255,1.0)',//not used
                pen_size: 3,
                fill_color: 'rgba(24,144,255,1.0)',
                opacity: '100%',
                active: 0,
                remove: 0,
                rotate: 0,
            },
        };
        dispatch(addElement(payload));
    }

    const onClickRectangle = () => {
        //setSubmenu(1);
        var d = new Date();
        const payload = {
            page: cur_page,
            element: {
                type: 'rectangle',
                page: cur_page,
                id: 'element_' + d.getTime().toString(),
                pos: {x:0 + page_rect.width / 2 - 60 + page_rect.left, y:cur_top + 20},
                size: {w: 120, h:120},
                pen_color: 'rgba(24,144,255,0.0)',
                pen_size: 2.3,
                fill_color: 'rgba(24,144,255,1.0)',
                opacity: '100%',
                active: 0,
                remove: 0,
                rotate: 0,
            },
        };
        dispatch(addElement(payload));
    }

    const onClickCircle = () => {
        //setSubmenu(1);
        var d = new Date();
        const payload = {
            page: cur_page,
            element: {
                type: 'circle',
                page: cur_page,
                id: 'element_' + d.getTime().toString(),
                pos: {x:0 + page_rect.width / 2 - 60 + page_rect.left, y:cur_top + 20},
                size: {w: 120, h:120},
                pen_color: 'rgba(24,144,255,0.0)',
                pen_size: 2.3,
                fill_color: 'rgba(24,144,255,1.0)',
                opacity: '100%',
                active: 0,
                remove: 0,
                rotate: 0,
            },
        };
        dispatch(addElement(payload));
    }

    return (
        <div className="editpdf-menu">
            <div className="editpdf__menu__block">
                <div className="wrapper">
                    <InputNumber 
                      min={1}
                      max={max_page} 
                      keyboard={true} 
                      value={cur_page} 
                      onChange = { onChagenPageNumber }
                      prefix={<span>
                            {t('tools.edit.page')}
                          </span>}
                      style={{ width: 100, marginRight:5 }}
                    />
                    <span>/ {max_page}</span>
                </div>
            </div>
            
            {/*<Tooltip title="Pan" placement="bottom">
            <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickPan}>
                <BsHandIndexThumb/>
            </div>
            </Tooltip>

            <Tooltip title="Zoom out" placement="bottom">
            <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickZoomout}>
                <BsZoomOut/>
            </div>
            </Tooltip>

            <Tooltip title="Zoom in" placement="bottom">
            <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickZoomin}>
                <BsZoomIn/>
            </div>
            </Tooltip>
            
            <Tooltip title="100%" placement="bottom">
            <div className="editpdf__menu__block editpdf__menu_item" style={{borderRight:'1px solid #00000021'}} onClick={onClickFit}>
                <BsFileCode/>
            </div>
            </Tooltip>*/}
            
            <Tooltip title={t('tools.edit.tool_add_text')} placement="bottom">
            <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickAddText}>
                <BsFileEarmarkFont/>
            </div>
            </Tooltip>

            
            <FileUploader
                    multiple={false}
                    handleChange={onClickAddImage}
                    name="selectImageFile"
                    types={["PNG", "JPG", "BMP", "JPEG"]}
                    children={(
                        <Tooltip title={t('tools.edit.tool_add_img')} placement="bottom">
                            <div className="editpdf__menu__block editpdf__menu_item">
                                <BsFileEarmarkImage/>
                            </div>
                        </Tooltip>)}  
            />

            <Tooltip title={t('tools.edit.tool_draw_pen')} placement="bottom">
            <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickPen}>
                <BsPen/>
            </div>
            </Tooltip>

            <Tooltip title={t('tools.edit.tool_add_line')} placement="bottom">
              <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickAddLine}>
                  <BsSlashLg/>
              </div>
            </Tooltip>

            <Tooltip title={t('tools.edit.tool_add_rect')} placement="bottom">
              <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickRectangle}>
                  <BsSquare/>
              </div>
            </Tooltip>

            <Tooltip title={t('tools.edit.tool_add_circle')} placement="bottom">
              <div className="editpdf__menu__block editpdf__menu_item" onClick={onClickCircle}>
                  <BsCircle/>
              </div>
            </Tooltip>
        </div>
    );
}

export default EditToolBar;