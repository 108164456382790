import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { addImageFileToList, setFile } from './editSlice';

import SelectFiles from '../SelectFiles';
import WorkSpace from './WorkSpace';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';

var links = [];
const EditPDF = () => {
    const {t} = useTranslation();
    //const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);
    const id = 9;
    
    const dispatch = useDispatch();
    const max_page = useSelector((state) => state.editOption.max_page);
    const added_elements = useSelector((state) => state.editOption.added_elements);
    const page_rect = useSelector((state) => state.editOption.page_rect);
    const imagelist = useSelector((state) => state.editOption.imagelist);
    const file = useSelector((state) => state.editOption.file);

    const saveAsPNG = async (element) => {
        var selector = "#" + element.id + " div:nth-child(1)";
        //console.log('Capture', selector)
        var dom_div = document.querySelector(selector);
        /*var oldTransform = dom_div.style.transform;
        dom_div.style.transform = 'rotate(' + 0 + 'deg)';*/

        await html2canvas(dom_div, {backgroundColor:null}).then(canvas => {
            //dom_div.style.transform = oldTransform;
            const imgUrl = canvas.toDataURL("image/png");

            //get blob from url//
            const blob = dataURItoBlob(imgUrl);
            //console.log('blob',blob);
            const f = new File([blob], element.id + ".png");
            //console.log('img file', f);
            dispatch(addImageFileToList({file: f}));
            
            /*var link = document.createElement('a');
            document.body.appendChild(link);
            link.href = imgUrl;
            link.download = 'test.png';
            link.click();
            document.body.removeChild(link);*/
        });
    };

    function dataURItoBlob(dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
		const blob = new Blob([ia], {type:mimeString});
        return blob;
    }

    const getAddedElementsByImagelist = () => {
        for (let i = 0; i <= max_page; i++) {
            for (let j = 0; j < added_elements[i].length; j++) {
                if(added_elements[i][j].remove === 1)	continue;


                if(added_elements[i][j].type === 'img'){
                    /*dispatch(addImageFileToList({file: added_elements[i][j].imgfile}));
                    continue;*/
                } else if (added_elements[i][j].type === 'text' && added_elements[i][j].rotate === 0){
                    /*dispatch(addImageFileToList({file: 'text'}));
                    continue;*/
                }

               	saveAsPNG(added_elements[i][j]);
            }
        }
    }

    const goProcFunction = (files) => {      
        //setFile(files);
        dispatch(setFile(files));
        setProc(1);
    }

    const goAction = () => {
        //merge action//        
        getAddedElementsByImagelist();   
        setProc(2);
    }
    
    //get count of image files
    var imgcnt = 0;
    if(proc === 2){//go action
	    for(let i = 0; i < added_elements.length; i++){
	    	for(let j = 0; j < added_elements[i].length; j++){
	    		if(added_elements[i][j].remove === 0)
	    			imgcnt++;
	    	}
	    }
	}

    
    const goDownload = (downlinks) => {
        //merge action//
        links = downlinks;
        setProc(3);
    }


    /*****************rendering*****************/
    const filetypes = ["PDF"];
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles 
                    id={id} 
                    proc={goProcFunction} 
                    multiple={false} 
                    btn_text={t('tools.edit.btn')}
                    fileTypes = {filetypes}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpace goAction={goAction} filetypes={filetypes}/>
            </div>
        );
    } else if (proc === 2 && imgcnt > 0 && imagelist.length === imgcnt) {//action space
        //pdf file and image files//
        var _files = [];
        _files.push(file);
        for(let i = 0; i < imagelist.length; i++){
            if(imagelist[i] !== 'text')
        	   _files.push(imagelist[i]);
        }
        
		//option payload//
        const elements = getElements(added_elements, page_rect);
        var option = {
            elements: elements,
        }

        //uploading//
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api}
                    goDownload={ goDownload } 
                    text="PDF Editing ..."
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default EditPDF;


/////////////////////////////////
const getElements = (added_elements, page_rect) => {
    var elements = [];
    for (let page = 1; page < added_elements.length; page++) {
        elements.push([]);//page array//
        for (let idx = 0; idx < added_elements[page].length; idx++){
            if(added_elements[page][idx].remove === 1)      continue;

            //----init----//
            const e_page = elements.length - 1;
            elements[e_page].push([]);//idx array//
            const e_idx = elements[e_page].length - 1;

            //----get added element info----//
            var ret = {};
            if(added_elements[page][idx].type === 'text_1' && added_elements[page][idx].rotate === 0){
                ret = getTextInfo(added_elements[page][idx], page_rect);
                elements[e_page][e_idx] = [
                    ret.type,
                    ret.text,
                    ret.fontsize,
                    ret.fontfamily,
                    ret.fontcolor,
                    ret.underline,
                    ret.position,
                    ret.backgroundcolor,
                    ret.textalign,
                    ret.opacity,
                    ret.rotate,
                    added_elements[page][idx].id
                ];
            } else {
                ret = getImageInfo(added_elements[page][idx], page_rect);
                elements[e_page][e_idx] = [
                    ret.type,
                    ret.imgpath,
                    ret.position,
                    ret.opacity,
                    ret.rotate,
                    added_elements[page][idx].id
                ];

                /*elements[e_page][e_idx].push(1);//type 1 : image
                elements[e_page][e_idx].push('image path');
                elements[e_page][e_idx].push(
                    [
                        getRate(page_rect.width, added_elements[page][idx].pos.x - page_rect.left),
                        getRate(page_rect.height, added_elements[page][idx].pos.y),
                        getRate(page_rect.width, added_elements[page][idx].size.w),
                        getRate(page_rect.height, added_elements[page][idx].size.h),
                    ]
                );
                //opacity//
                var opacity = added_elements[page][idx].type === 'img'?parseInt(added_elements[page][idx].opacity) : 100
                elements[e_page][e_idx].push(opacity);

                //rotate//
                elements[e_page][e_idx].push(parseInt(added_elements[page][idx].rotate));*/
            }            
        }
    }

    return elements;
}

const getRate = (total, value) => {
    var dblrate = 0.0;

    dblrate = (value / total);

    return dblrate;
}

const getImageInfo = (element, page_rect) => {
    var tmp = element.type === 'pen'? page_rect.left:page_rect.left;
    //console.log('getImageInfo', page_rect, element, tmp);
    var ret = {
        type: 1,
        imgpath: /*element.type === 'img'?element.imgfile.name :*/ element.type,
        position:   [
                        getRate(page_rect.width, element.pos.x - tmp),
                        getRate(page_rect.height, element.pos.y),
                        getRate(page_rect.width, element.size.w),
                        getRate(page_rect.height, element.size.h),
                    ],
        opacity: /*element.type === 'img'?parseInt(element.opacity) :*/ 100,
        rotate: element.rotate,
    }

    return ret;
}

const getTextInfo = (element, page_rect) => {
    const getAlignIdx = (aligntxt) => {
        if(aligntxt === 'left') return 0;
        if(aligntxt === 'medium') return 1;
        if(aligntxt === 'right') return 2;

    }

    const getFontFamily = (font, bold, italic) => {
        var ret = font;
        if(bold === 1)  ret = ret + 'b';
        if(italic === 1)  ret = ret + 'i';
        return ret;
    }

    var ret = {
        type: 0,
        text: element.text,
        fontsize: element.fontSize,
        fontfamily: getFontFamily(element.font, element.bold, element.italic),
        fontcolor: getRGBInfoFromText(element.pen_color),
        underline: element.underline,
        position:   [
                        getRate(page_rect.width, element.pos.x - page_rect.left),
                        getRate(page_rect.height, element.pos.y),
                        getRate(page_rect.width, element.size.w),
                        getRate(page_rect.height, element.size.h),
                    ],
        backgroundcolor: getRGBInfoFromText(element.fill_color),
        textalign: getAlignIdx(element.align),
        opacity: parseInt(element.opacity),
        rotate: element.rotate,
    }

    return ret;
}

const getRGBInfoFromText = (txt) => {    
    var ret = [];
    var tmp = txt.replaceAll('rgba(', '');
    tmp = tmp.replaceAll(')', '');
    var arry = tmp.split(',');
    for (let i = 0; i < arry.length; i++){
        if(i < 3)
            ret.push(parseInt(arry[i]));
        else
            ret.push(parseFloat(arry[i]));
    }

    return ret;
}