import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setCustomRanges, addCustomRange, removeCustomRange, 
	setFixedRange, setMode, setMergeCheck } from './splitSlice';

import { Segmented, Checkbox, InputNumber, Button } from 'antd';
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';


const Range = ({ id, max_page }) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const custom_ranges = useSelector((state) => state.splitOption.custom_ranges);

	const start = custom_ranges[id][0];
	const end = custom_ranges[id][1];

	const onClickRemove = () => {
		if (custom_ranges.length === 1) return;
		dispatch(removeCustomRange(id));
	}

	const onChangeStart = (value) => {
		var chval = value;
		if (chval > end) {
			chval = end;
		} else if (chval > max_page) {
			chval = max_page
		} else if (chval < 1) {
			chval = 1;
		}

		const payload = {
			id: id,
			start: chval,
			end: end,
		}

		dispatch(setCustomRanges(payload));
	}

	const onChangeEnd = (value) => {
		var chval = value;
		if (chval < start) {
			chval = start;
		} else if (chval > max_page) {
			chval = max_page
		} else if (chval < 1) {
			chval = 1;
		}

		const payload = {
			id: id,
			start: start,
			end: chval,
		}	

		dispatch(setCustomRanges(payload));
	}

	return(
		<div className="range_option">
			
			<div className="range-title">
				{t('tools.split.range')}{id + 1}

				<div className="white-btn hover-black-btn shadow-btn close_btn">
					<Button 
	                    type="primary" 
	                    shape="circle" 
	                    icon={<CloseOutlined style={{ fontSize: '14px' }}/>} 
	                    size="small"
	                    onClick={onClickRemove} />
                </div>
			</div>
			<div>
				<InputNumber 
					min={1}
					max={max_page} 
					keyboard={true} 
					value={start} 
					onChange = { onChangeStart }
					prefix={<span>
								from
							</span>}
					style={{ width: 120, marginRight:5 }}
				/>

				<InputNumber 
					min={1}
					max={max_page} 
					keyboard={true} 
					value={end} 
					onChange = { onChangeEnd }
					prefix={<span>
								to
							</span>} 
					style={{ width: 90 }}
				/>

			</div>
		</div>
	);
}

const Fixed = ({max_page}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const fixed_range = useSelector((state) => state.splitOption.fixed_range);

	const onChangeNumber = (value) => {
		var chval = value;
		if (chval < 1) {
			chval = 1;
		} else if (chval > max_page) {
			chval = max_page;
		}
		dispatch(setFixedRange(chval));
	}

	var cnt = Math.ceil(max_page / fixed_range);

	return(
		<>
			<div style={{marginTop:20}}>
				<span style={{marginRight:5, fontWeight:'500'}}>{t('tools.split.range_op1')}</span>
				<InputNumber min={1} max={max_page} defaultValue={max_page} onChange={onChangeNumber} />
			</div>
			<p>
				<b>{cnt} PDF</b> {t('tools.split.range_msg1')}
			</p>
		</>
		
	);
}


/////////////////////////////////////////////

const TabSplitByRange = () => {
	const {t} = useTranslation();
	//const [option, setOption] = useState(t('tools.split.custom_ranges'));

	const dispatch = useDispatch();

	const custom_ranges = useSelector((state) => state.splitOption.custom_ranges);
	//const fixed_range = useSelector((state) => state.splitOption.fixed_range);
	const option = useSelector((state) => state.splitOption.mode);
	const max_page = useSelector((state) => state.splitOption.max_page);
    


	const onSegmentChange = (value) => {
		//setOption(value);
		dispatch(setMode(value));
	}

	const onCheckMerge = (e) => {
		//console.log(`checked = ${e.target.checked}`);
		dispatch(setMergeCheck(e.target.checked?1:0));
	}

	const onClickAddRange = () => {
		if (max_page > 0) {
			dispatch(addCustomRange([1, max_page]));
		}
	}

	//-------rendering---------//
	var option_element = '';
	if (option === t('tools.split.custom_ranges')) {
		var range_element = [];
		for (let i = 0; i < custom_ranges.length; i++) {
			range_element.push(
				<Range id={ i } max_page={max_page} key={'range_' + i}/>
			);
		}

		option_element = (
			<>
				{range_element}

	            <div className="more__options">
	            	<div className="side__btns" onClick={onClickAddRange}>
		            	<PlusOutlined style={{marginRight:5}}/>
		            	{t('tools.split.add_range')}
	            	</div>

	            	<div style={{marginTop:10, paddingLeft:5}}>
		            	<Checkbox onChange={onCheckMerge}>
		            		{t('tools.split.merge_check')}
		            	</Checkbox>
	            	</div>
	            </div>
			</>
		);
	} else if (option === t('tools.split.fixed_ranges')) {
		option_element = (<Fixed max_page={max_page}/>);
	}

    return (
        <div className="option__tab__content option__tab__content--active">
            <div className="option__panel__content">
            	<div className="option__title">{t('tools.split.range_op2')}</div>
            	<div style={{paddingLeft:3, paddingRigth:3}}>
            		<Segmented block options={[t('tools.split.custom_ranges'), t('tools.split.fixed_ranges')]} onChange={onSegmentChange} value={option}/>
            	</div>
            </div>
            {option_element}
        </div>
    );
}

export default TabSplitByRange;