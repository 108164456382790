import React from 'react';

import { useDispatch } from 'react-redux'
import { setTool, setOption } from './repairSlice';

import WorkAreaRepair from './WorkAreaRepair';
import WorkSideRepair from './WorkSideRepair';

const WorkSpaceRepair = ({ goAction }) => {
    const dispatch = useDispatch();
    

    const onAction = (filelist) => {        

        dispatch(setTool("ROTATE"));
        //const filelist = {};
        var option = {
            cnt: 0,
            rotate: [],
        }

        for (var i = 0; i < filelist.length; i++) {
            if(filelist[i].remove !== 0)    continue;
            option.cnt++;
            option.rotate.push(filelist[i].rotate);
        }

        dispatch(setOption(option));

        goAction();
    }
    
    /***********rendering**********/
    return (
        <>
            <WorkAreaRepair/>
            <WorkSideRepair onAction={onAction}/>
        </>
    );
}

export default WorkSpaceRepair;