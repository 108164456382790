import React from 'react';

import WorkArea from './WorkArea';
import WorkSide from './WorkSide';


const WorkSpace = ({ goAction, filetypes }) => {
    
    const onAction = () => {        
        goAction();
    }

    /***********rendering**********/
    return (
        <>
            <WorkArea/>
            <WorkSide onAction={onAction}/>
        </>
    );
}

export default WorkSpace;