import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { insertFilelist } from './pdf2wordSlice';


import SelectFiles from '../SelectFiles';
import WorkSpace from './WorkSpace';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';

import { useTranslation } from 'react-i18next';

var links = [];
const CompressPDF = () => {
	const {t} = useTranslation();

    const dispatch = useDispatch();
    //const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);

    const filelist = useSelector((state) => state.pdf2wordOption.filelist);
    const method = useSelector((state) => state.pdf2wordOption.method);

    const id = 2;

    const goProcFunction = (files) => {        
        //setFile(files);
        for (var i = 0; i < files.length; i++) {
            dispatch(insertFilelist(files[i]));
        }
        setProc(1);
    }

    const goAction = () => {
        //merge action//
        setProc(2);
    }

    
    const goDownload = (downlinks) => {
        //merge action//
        links = downlinks;
        setProc(3);
    }


    /*****************rendering*****************/
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles id={id} proc={goProcFunction} multiple={true} btn_text={t('tools.compress.btn')} fileTypes = {["PDF"]}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpace goAction={goAction}/>
            </div>
        );
    } else if (proc === 2) {//action space
        //option payload//
        var _files = [];
        for (var i = 0; i < filelist.length; i++) {
            if(filelist[i].remove !== 0)  continue;
            _files.push(filelist[i].file);
            //formData.append("files",  filelist[i]);
        }

        var option = {
            method: method,
        }

        //uploading//
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api} 
                    goDownload={ goDownload } 
                    text="Compressing PDF..."
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link}
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default CompressPDF;