import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RotateOptionState {
  tool_header: string,
  filelist: Array,
  option: Object,
  uploadpath: Array,
  sellist: Array,
}

const initialState: RotateOptionState = {
  tool_header: "ROTATE",
  filelist: [],
  option: {},
}

export const rotateSlice = createSlice({
  name: 'rotateOption',
  initialState,
  reducers: {
    setTool: (state, action:PayloadAction<string>) =>{
      state.tool_header = action.payload;
    },
    setOption: (state, action:PayloadAction<Object>) =>{
      state.option = action.payload;
    },
    setFileOptionList: (state, action:PayloadAction<Array>) => {
      //state.filelist = action.payload;
      for(let i=0; i < action.payload.length; i++){
        state.filelist.push(action.payload[i]);
        state.filelist[state.filelist.length - 1].idx = state.filelist.length - 1;
      }
    },
    setRemoveOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].remove = action.payload.remove;
    },
    setRotateOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].rotate = action.payload.rotate;
    },
    setUploadPath: (state, action:PayloadAction<Array>) => {
      state.uploadpath = action.payload;
    },
    setSelectOption: (state, action:PayloadAction<Object>) => {
      state.filelist[action.payload.idx].select = action.payload.select;
    },
    rightRotationOption: (state, action) => {
      state.filelist.map((file) => {
        if (file.select === 1) {file.rotate = (file.rotate + 1) % 4}
      });
    },
    leftRotationOption: (state, action) => {
      state.filelist.map((file) => {
        if (file.select === 1) {
          file.rotate = (file.rotate - 1) % 4;
          if(file.rotate < 0)
            file.rotate = file.rotate + 4;
        }
      });
    },
    resetAllOption: (state, action) => {
      state.filelist.map((file) => {file.rotate = 0;});
    },
    selectAllOption:(state, action) => {
      state.filelist.map((file) => {file.select = 1;});
    },
    selectPortraitOption:(state, action) => {
      state.filelist.map((file) => {
        if (file.rotate == 0 || file.rotate == 2)
          file.select = 1;
        else
          file.select = 0;
      });
    },
    selectLandscapeOption:(state, action) => {
      state.filelist.map((file) => {
        if (file.rotate == 0 || file.rotate == 2)
          file.select = 0;
        else
          file.select = 1;
      });
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTool, 
  setOption, 
  setFileOptionList, 
  setRemoveOption, 
  setRotateOption,
  setUploadPath,
  setSelectOption,
  rightRotationOption,
  leftRotationOption,
  resetAllOption,
  selectAllOption,
  selectPortraitOption,
  selectLandscapeOption
} = rotateSlice.actions

export default rotateSlice.reducer