import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EditOptionState {
  file: File,
  url: String,
  pdf: Object,
  max_page: Number,
  cur_page: Number,
  cur_top: Number,
  page_changed: Number,
  page_rect: Object,
  viewscale: Number,
  panselect: Number,
  added_elements: Array,
  id_object: Object,
  selected_element: Object,
  enable_draw: Number,
  vertex_info: Array,
  imagelist: Array,
  imagecnt : Number,
}

const initialState: EditOptionState = {
  file: null,
  pdf: null,
  url: '',
  max_page: 0,
  cur_page: 1,
  cur_top: 0,
  page_changed: 0,
  page_rect: {left: 0, top: 0, width: 0, height: 0},
  viewscale: 1.0,
  panselect: 0,
  added_elements: [],
  id_object: {},
  selected_element: {page: -1, idx: -1},
  enable_draw: 0,
  vertex_info: [],
  imagelist: [],
  imagecnt: -1,
}

export const editSlice = createSlice({
  name: 'editOption',
  initialState,
  reducers: {
    setFile: (state, action) => {
      state.file = action.payload;
      state.imagelist = [];
      state.imagecnt = -1;
      state.url = URL.createObjectURL(action.payload);
    },
    setPdf: (state, action) => {
      state.pdf = action.payload;
    },
    setMaxPage: (state, action:PayloadAction<number>) =>{
      state.max_page = action.payload;
      for (let i = 0; i <= action.payload; i++) {
        state.added_elements.push([]);
        state.vertex_info.push([]);
      }
    },
    setCurrentPage: (state, action) => {
      if(state.cur_page === action.payload.page)  return;
      state.cur_page = action.payload.page;
      state.page_changed = action.payload.changed;
    },
    setCurrentTop: (state, action) => {
      state.cur_top = action.payload.top;
    },
    setPageChanged: (state, action) => {
      state.page_changed = action.payload;
    },
    setPageRect: (state, action) => {
      state.page_rect = action.payload;
    },
    setPanSelect: (state, action) => {
      state.panselect = action.payload;
    },
    setPageViewScale: (state, action) => {
      state.viewscale = action.payload;
      //element change scale//
      
    },
    setEnableDraw: (state, action) => {
      state.enable_draw = action.payload;
      if(action.payload === 1){
        //reset vertex info//
        for (let i = 0; i <= state.max_page; i++) {
          state.vertex_info[i] = [];
        }
      }
    },
    addElement: (state, action) => {
      state.added_elements[action.payload.page].push(action.payload.element);
      //insert id object//
      state.id_object[action.payload.id] = {
        page: action.payload.page,
        idx: state.added_elements[action.payload.page].length - 1
      }

      //set selected element//
      if(state.selected_element.page >= 0)
        state.added_elements[state.selected_element.page][state.selected_element.idx].active = 0;
      state.selected_element = {page: state.id_object[action.payload.id].page, idx: state.id_object[action.payload.id].idx};
      state.added_elements[state.selected_element.page][state.selected_element.idx].active = 1;
    },
    updateSizeElement:(state, action) => {
      //page, id, pos, size//
      state.added_elements[action.payload.page][action.payload.idx].pos = action.payload.pos;
      state.added_elements[action.payload.page][action.payload.idx].size = action.payload.size;
    },
    updateRotateElement: (state, action) => {
      state.added_elements[action.payload.page][action.payload.idx].rotate = action.payload.rotate;
    },
    removeElement: (state, action) => {
      state.added_elements[action.payload.page][action.payload.idx].remove = 1;
      state.selected_element = {page: -1, idx: -1}
    },
    removeAllElement: (state, action) => {
      for (let i = 0; i <= state.max_page; i++) {
        for (var j = 0; j < state.added_elements[i].length; j++) {
          state.added_elements[i][j].remove = 1;
        }
      }
    },
    setSeletedElement: (state, action) => {
      //old element none-active//
      if(state.selected_element.page === action.payload.page && state.selected_element.idx === action.payload.idx)   return;

      var old = state.selected_element;
      if(old.page >= 0)//old selected//
      {
        state.added_elements[old.page][old.idx].active = 0;

        //old element text not editable//
        if(state.added_elements[old.page][old.idx].type==="text")
            state.added_elements[old.page][old.idx].editable = 0;
      }      

      //set selected//
      state.selected_element = action.payload;
      state.added_elements[action.payload.page][action.payload.idx].active = 1;
    },
    unactiveSeletedElement: (state, action) => {
      if(state.selected_element.page < 0) return;

      state.added_elements[state.selected_element.page][state.selected_element.idx].active = 0;

      //text not editable//
      if(state.added_elements[state.selected_element.page][state.selected_element.idx].type==="text")
        state.added_elements[state.selected_element.page][state.selected_element.idx].editable = 0;

      state.selected_element = {page: -1, idx: -1};      
    },
    setTextEditable: (state, action) => {
      //set text editable
      if(state.added_elements[action.payload.page][action.payload.idx].type === "text")
        state.added_elements[action.payload.page][action.payload.idx].editable = action.payload.editable;
      
      //set selected element//
      state.selected_element = action.payload;
      state.added_elements[action.payload.page][action.payload.idx].active = 1;
    },
    updateBorderColor: (state, action) => {
      if(state.added_elements[action.payload.page][action.payload.idx].type==="img")
        state.added_elements[action.payload.page][action.payload.idx].pen_color = `2.3px solid ${action.payload.pen_color}`;
      else
        state.added_elements[action.payload.page][action.payload.idx].pen_color = action.payload.pen_color;
    },
    updateOpacity: (state, action) => {
      state.added_elements[action.payload.page][action.payload.idx].opacity = action.payload.opacity;
    },
    addVertex4Drawpen: (state, action) => {
      if(action.payload.new === 1){
          state.vertex_info[action.payload.page].push([]);
      }
      
      state.vertex_info[action.payload.page][state.vertex_info[action.payload.page].length - 1].push(action.payload.vertex);            
    },
    resetVertexInfo: (state, action) => {
      for (let i = 0; i <= state.max_page; i++) {
        state.vertex_info[i] = [];
      }
    },
    updateElementText: (state, action) => {
      state.added_elements[action.payload.page][action.payload.idx].text = action.payload.text;
    },
    updateElementTextFormat: (state, action) => {
      if(action.payload.font !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].font = action.payload.font;
      
      if(action.payload.fontSize !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].fontSize = action.payload.fontSize;
      
      if(action.payload.bold !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].bold = action.payload.bold;
      
      if(action.payload.italic !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].italic = action.payload.italic;
      
      if(action.payload.underline !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].underline = action.payload.underline;
      
      if(action.payload.pen_color !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].pen_color = action.payload.pen_color;
      
      if(action.payload.fill_color !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].fill_color = action.payload.fill_color;
      
      if(action.payload.align !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].align = action.payload.align;

      if(action.payload.opacity !== undefined)
        state.added_elements[action.payload.page][action.payload.idx].opacity = action.payload.opacity;
    },
    updateFillColor: (state, action) => {
        state.added_elements[action.payload.page][action.payload.idx].fill_color = action.payload.fill_color;  
    },
    addImageFileToList: (state, action) => {
        state.imagelist.push(action.payload.file);        
    },
  },
});

// Action creators are generated for each case reducer function
export const { 
  setFile, 
  setPdf,
  setMaxPage, 
  setCurrentPage, 
  setCurrentTop,
  setPageRect, 
  setPanSelect, 
  setPageViewScale,
  addElement, 
  removeElement,
  removeAllElement,
  setPageChanged,
  updateSizeElement,
  updateRotateElement,
  setSeletedElement,
  unactiveSeletedElement,
  setTextEditable,
  updateBorderColor,
  updateOpacity,
  setEnableDraw,
  addVertex4Drawpen,
  resetVertexInfo,
  updateElementText,
  updateElementTextFormat,
  updateFillColor,
  addImageFileToList,
} = editSlice.actions;

export default editSlice.reducer;
