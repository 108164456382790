import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPassword } from './protectSlice';

import {  Button, Layout, Input, Space, notification } from 'antd';
import { SendOutlined, EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { BsFileEarmarkLockFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;

const openNotification = (placement, type, message, description) => {
  notification[type]({
    message: message,
    description: description,      
    placement,
  });
};

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.protectOption.filelist);

    var pwd = '', re_pwd = '';

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) 
            cnt++;
    }

    const onClickAction = () => {
        if (pwd === ''){
            openNotification(
                'top', 
                'warning',
                t('tools.protect.wrong'),
                t('tools.protect.not1')
            );
        } else if(pwd === re_pwd){
            dispatch(setPassword(pwd))
            onAction(filelist);
        } else if (pwd !== re_pwd){
            openNotification(
                'top', 
                'warning',
                t('tools.protect.wrong'),
                t('tools.protect.not2')
            );
        } 
    }

    const onChangePwd = (e) => {
        pwd = e.target.value;
    }

    const onChangeRePwd = (e) => {
        re_pwd = e.target.value;
    }

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>PROTECT PDF</Header>

                <Content>
                    <div style={{margin:'10px auto', width:'100%', padding:5}}>
                            <span className="option__title">{t('tools.protect.side_msg1')}</span>
                    </div>
                    <Space direction="vertical">
                        <Input.Password
                            onChange={onChangePwd}
                            size="large"
                            placeholder={t('tools.protect.side_input1')}
                            iconRender={visible => (visible ? <EyeFilled style={{color:'rgb(0, 0, 0, 0.8)'}}/> : <EyeInvisibleFilled />)}
                            prefix={<BsFileEarmarkLockFill style={{fontSize:22, marginLeft:-7}}/>}
                        />
                        <span style={{float: 'left', marginTop:10}}>{t('tools.protect.side_msg2')}</span>
                        <Input.Password
                            onChange={onChangeRePwd}
                            size="large"
                            placeholder={t('tools.protect.side_input2')}
                            iconRender={visible => (visible ? <EyeFilled style={{color:'rgb(0, 0, 0, 0.8)'}}/> : <EyeInvisibleFilled />)}
                            prefix={<BsFileEarmarkLockFill style={{fontSize:22, marginLeft:-7}}/>}
                        />
                    </Space>
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt > 0 ? false:true}>
                        {t('tools.protect.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;