import React, {useState} from 'react';
import { Select } from 'antd';

import oversvg from '../../../assets/svg/sign_simple.svg';
import belowsvg from '../../../assets/svg/sign_digital.svg'; 
import { useTranslation } from 'react-i18next';

const {Option} = Select;
const SignLayer = ({pro}) => {
    /**
     * 
     * @param {*} e 
     * gpro: grid process
     * spro: select process
     * pos: selected position
     */
    const {t} = useTranslation();
    const [selmode, setSelMode] = useState(0);
    const onSimple = () => {
        setSelMode(0)
        pro(0);
    }
    const onDigitalSignature = () => {
        setSelMode(1);
        pro(1);
    }
    return (
        
        <div className="side__btns__container">
            <div className={selmode === 0 ? "side__btns1_select":"side__btns1"}  onClick={onSimple}>
                <div className="side__btns__items">
                    <div>
                    <img src={oversvg} alt="nomargin" style={{width:'80%', height:'80%'}}/>
                    </div>
                    <div className="side__btns__item_text">
                    {t('tools.sign.SimpleSign')}
                    </div>
                </div>
            </div>
            <div className={selmode === 1 ? "side__btns1_select":"side__btns1"}  onClick={onDigitalSignature}>
                <div className="side__btns__items">
                <div>
                    <img src={belowsvg} alt="small margin" style={{width:'80%', height:'80%'}}/>
                    </div>
                    <div  className="side__btns__item_text">
                    {t('tools.sign.DigitalSign')}
                    </div>
                </div>
            </div>
        </div>    
    );
}
export default SignLayer;