import React from 'react';
import ToolItem from './ToolItem';

import { toolsListInfo } from '../../utils/toolsInfo';

const Tools = () => {


  /*var tools_list = [];
  tools_list.push(<ToolItem tool_info = {toolsListInfo[0]}/>);//merge
  tools_list.push(<ToolItem tool_info = {toolsListInfo[1]}/>);//split
  tools_list.push(<ToolItem tool_info = {toolsListInfo[2]}/>);//compress
  tools_list.push(<ToolItem tool_info = {toolsListInfo[14]}/>);//rotate
  tools_list.push(<ToolItem tool_info = {toolsListInfo[18]}/>);//organize
  tools_list.push(<ToolItem tool_info = {toolsListInfo[3]}/>);//pdf to word
  tools_list.push(<ToolItem tool_info = {toolsListInfo[6]}/>);//word to pdf
  tools_list.push(<ToolItem tool_info = {toolsListInfo[4]}/>);//pdf to ppt
  tools_list.push(<ToolItem tool_info = {toolsListInfo[7]}/>);//ppt to pdf
  tools_list.push(<ToolItem tool_info = {toolsListInfo[5]}/>);//pdf to excel
  tools_list.push(<ToolItem tool_info = {toolsListInfo[8]}/>);//excel to pdf
  tools_list.push(<ToolItem tool_info = {toolsListInfo[10]}/>);//pdf to jpg
  tools_list.push(<ToolItem tool_info = {toolsListInfo[11]}/>);//jpg to pdf
  tools_list.push(<ToolItem tool_info = {toolsListInfo[15]}/>);//html to pdf
  tools_list.push(<ToolItem tool_info = {toolsListInfo[12]}/>);//page numbers
  tools_list.push(<ToolItem tool_info = {toolsListInfo[9]}/>);//edit pdf
  tools_list.push(<ToolItem tool_info = {toolsListInfo[13]}/>);//watermark
  tools_list.push(<ToolItem tool_info = {toolsListInfo[21]}/>);//sign pdf
  tools_list.push(<ToolItem tool_info = {toolsListInfo[17]}/>);//protect
  tools_list.push(<ToolItem tool_info = {toolsListInfo[16]}/>);//unlock
  tools_list.push(<ToolItem tool_info = {toolsListInfo[19]}/>);//pdf to pdf/A
  tools_list.push(<ToolItem tool_info = {toolsListInfo[20]}/>);//repair pdf*/
  /*for (var i = 0; i < toolsListInfo.length; i++) {
    tools_list.push(<ToolItem tool_info = {toolsListInfo[i]}/>);
  }*/

  return (
    <div style={{backgroundColor:'rgb(0 0 0 / 0%)', clear:'both', paddingBottom:'40px'}}>
      <div className="tools">
  		<ToolItem tool_info = {toolsListInfo[0]} idx={0}/>
		<ToolItem tool_info = {toolsListInfo[1]} idx={1}/>
		<ToolItem tool_info = {toolsListInfo[2]} idx={2}/>
		<ToolItem tool_info = {toolsListInfo[14]} idx={14}/>
		<ToolItem tool_info = {toolsListInfo[18]} idx={18}/>
		<ToolItem tool_info = {toolsListInfo[3]} idx={3}/>
		<ToolItem tool_info = {toolsListInfo[6]} idx={6}/>
		<ToolItem tool_info = {toolsListInfo[4]} idx={4}/>
		<ToolItem tool_info = {toolsListInfo[7]} idx={7}/>
		<ToolItem tool_info = {toolsListInfo[5]} idx={5}/>
		<ToolItem tool_info = {toolsListInfo[8]} idx={8}/>
		<ToolItem tool_info = {toolsListInfo[10]} idx={10}/>
		<ToolItem tool_info = {toolsListInfo[11]} idx={11}/>
		<ToolItem tool_info = {toolsListInfo[15]} idx={15}/>
		<ToolItem tool_info = {toolsListInfo[12]} idx={12}/>
		<ToolItem tool_info = {toolsListInfo[9]} idx={9}/>
		<ToolItem tool_info = {toolsListInfo[13]} idx={13}/>
		<ToolItem tool_info = {toolsListInfo[21]} idx={21}/>
		<ToolItem tool_info = {toolsListInfo[17]} idx={17}/>
		<ToolItem tool_info = {toolsListInfo[16]} idx={16}/>
		<ToolItem tool_info = {toolsListInfo[19]} idx={19}/>
		<ToolItem tool_info = {toolsListInfo[20]} idx={20}/>	
      </div>
    </div>    
  );
}

export default Tools;