import React from 'react';

import { useDispatch } from 'react-redux'
import { setFileOptionList, setTool, setOption, setDirectionOption, setMarginOption } from './jpg2PdfSlice';
import { Scrollbars } from 'react-custom-scrollbars';

import WorkAreaJpg2 from './WorkAreaJpg2';
import WorkSideJpg2 from './WorkSideJpg2';


const WorkSpaceJpg2 = ({ files, goAction }) => {
    const dispatch = useDispatch();
    

    const onAction = (filelist) => {        

        dispatch(setTool("JPG2PDF"));
        var option = {
            cnt: 0,
            rotate: [],
        }

        for (var i = 0; i < filelist.length; i++) {
            if(filelist[i].remove !== 0)    continue;
            option.cnt++;
            option.rotate.push(filelist[i].rotate);
        }

        dispatch(setOption(option));

        goAction();
    }
    
    /***********rendering**********/
    return (
        <>
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <WorkAreaJpg2/>
            </Scrollbars>
            <WorkSideJpg2 onAction={onAction}/>
        </>
    );
}

export default WorkSpaceJpg2;