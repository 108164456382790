import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface InitOptionState {
  filelist: Array,
  pagelist: Array,
  elements: Array,
}

const initialState: InitOptionState = {
  filelist: [],
  pagelist: [],
  elements: [],
}

export const organizeSlice = createSlice({
  name: 'organizeOption',
  initialState,
  reducers: {
    setFileList: (state, action:PayloadAction<Array>) =>{
      state.filelist = action.payload;
    },
    setPdfInFilelist: (state, action) => {
      state.filelist[action.payload.fileidx].pdf = action.payload.pdf;
    },
    insertFilelist: (state, action) => {
      state.filelist.push({
        idx: state.filelist.length,
        file: action.payload,
        url: URL.createObjectURL(action.payload),
        max_page: 0,
        remove: 0,
        pdf: null,
      });
    },
    updateFilelistMaxpage: (state, action) => {
        state.filelist[action.payload.idx].max_page = action.payload.max_page;
    },
    removeFilelist: (state, action) => {
      state.filelist[action.payload].remove = 1;
      for(let i = 0; i < state.pagelist.length; i++){
        if(state.pagelist[i].fileidx === action.payload)
          state.pagelist[i].remove = 1;
      }
    },
    insertPageList: (state, action) => {
      //set max page//
      state.filelist[action.payload.fileidx].max_page = action.payload.max_page;
      state.filelist[action.payload.fileidx].pdf = action.payload.pdf;

      //insert page list//
      for(let i=0; i < action.payload.max_page; i++){
          var index = state.pagelist.length;
          state.pagelist.push({
            index: index,
            pos: index,
            fileidx: action.payload.fileidx, 
            pagenumber: i+1,
            remove: 0,
            rotate: 0,
          });
      }
    },
    insertBlankPage: (state, action) => {
        for (let i = 0; i < state.pagelist.length; i++) {
            if(state.pagelist[i].pos >= action.payload)
              state.pagelist[i].pos++;
        }

        state.pagelist.push({
            index: state.pagelist.length,
            pos: action.payload,
            fileidx: -1, 
            pagenumber: 0,
            remove: 0,
            rotate: 0,
        });
    },
    removePageList: (state, action) => {
      state.pagelist[action.payload].remove = 1;
      const fileidx = state.pagelist[action.payload].fileidx;
      if(fileidx < 0)   return;
      var cnt = 0;
      for(let i = 0; i < state.pagelist.length; i++){
        if(state.pagelist[i].fileidx === fileidx && state.pagelist[i].remove === 0)
          cnt = cnt + 1;
      }

      if(cnt === 0){
        state.filelist[fileidx].remove = 1;
      }
    },
    rotatePageList: (state, action) => {
      state.pagelist[action.payload].rotate = (state.pagelist[action.payload].rotate + 90) % 360;
    },
    insertElements: (state, action) => {
      state.elements.push(action.payload);
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  setFileList, 
  setPdfInFilelist,
  insertFilelist, 
  updateFilelistMaxpage,
  removeFilelist,
  insertPageList,
  insertBlankPage,
  removePageList,
  rotatePageList,
  insertElements } = organizeSlice.actions

export default organizeSlice.reducer