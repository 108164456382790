import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFileOptionList, setTool, setOption, setDirectionOption, setMarginOption } from './jpg2PdfSlice';

import SelectFiles from '../SelectFiles';
import WorkSpaceJpg2 from './WorkSpaceJpg2';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';
import { useTranslation } from 'react-i18next';

var links = [];
const JpgToPdf = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    //const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);
    const filelist = useSelector((state) => state.jpg2pdfOption.filelist);
    const direction = useSelector((state) => state.jpg2pdfOption.direction);
    const margin = useSelector((state) => state.jpg2pdfOption.margin);
    const pagesize = useSelector((state) => state.jpg2pdfOption.pagesize);
    const onefile = useSelector((state) => state.jpg2pdfOption.onefile);
   
    const goProcFunction = (files) => {        
        //setFile(files);
        var initFileList = [];
        for (var i = 0; i < files.length; i++) {
            initFileList.push({
                idx: i,
                file: files[i],
                remove: 0,
                rotate: 0,
            });
        }

        dispatch(setFileOptionList(initFileList));
        dispatch(setDirectionOption(0));
        dispatch(setMarginOption(0));

        setProc(1);
    }
    const goAction = () => {
        setProc(2);        
    }
    const goDownload = (downlinks) => {
        links = downlinks;
        setProc(3);
    }

    var id = 11;
    /*****************rendering*****************/
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles id={id} proc={goProcFunction} multiple={true} btn_text={t('tools.jpg2pdf.btn')} fileTypes = {["JPG", "JPEG", "PNG", "TIF", "TIFF"]}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpaceJpg2 goAction={goAction}/>
            </div>
        );
    } else if (proc === 2) {//action space
        //option payload//
        var _files = [];
        var rotate = [];
        for (var i = 0; i < filelist.length; i++) {
            if(filelist[i].remove !== 0)  continue;
            _files.push(filelist[i].file);
            rotate.push(filelist[i].rotate);
            //formData.append("files",  filelist[i]);
        }

        var option = {
            rotate: rotate,
            size: pagesize,
            margin: margin,
            merge: onefile,
            pagetype: direction,
        }
        /**arg1 : tool_header = 'JPG2PDF'
	    arg2 : option = {
			path : ['', '']
			rotate : [0, 2, 1]
			size : 0 (0: fit, 1:A4, 2:us letter)
			margin : 0 (0:no, 1 mall, 2:big)
			merge : 0 (merge all 0 : none checked, 1:checked)
			pagetype : 0 (0 : portrait, 1:landscape)
		} */
        //uploading//
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api}
                    goDownload={ goDownload } 
                    text={t('tools.jpg2pdf.uploadtext')}
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }
    /*   */
    //<ImageElement />
    return (
        <div>
            <div>{element}</div>
        </div>      
    );
}

export default JpgToPdf;