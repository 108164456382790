import { Slider, InputNumber, Row, Col } from 'antd';
import React from 'react';

const FontSize = ({proc, defaultValue}) => {
  
  //const [inputValue, setInputValue] = useState(defaultValue);
  const onChange = value => {
    //setInputValue(value);
    proc(value);
  };

    return (
        <Row style={{width: '270px'}}>
            <Col style={{marginRight:'15px', marginTop:'5px'}}>Font Size:</Col>
            <Col style={{width: '30%'}}>
                <Slider
                min={1}
                max={100}
                onChange={onChange}
                value={defaultValue}
                />
            </Col>
            <Col style={{width: '20%'}}>
                <InputNumber
                min={1}
                max={100}
                style={{ margin: '0 16px' }}
                value={defaultValue}
                onChange={onChange}
                />
            </Col>
        </Row>
    );
}

export default FontSize;