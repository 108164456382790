import React from 'react';

import {getCookie, setCookie} from '../utils/cookie';

const translationLanguages = [
    {lang:'en', text:'English'},
    {lang:'it', text:'Italiano'},
    {lang:'cn', text:'中文（简体）'},
    {lang:'es', text:'Español'},
    {lang:'fr', text:'Français'},
    {lang:'de', text:'Deutsch'},
    {lang:'pt', text:'Português'},
    {lang:'ru', text:'Pусский'},
    {lang:'ko', text:'한국어'},
    {lang:'ja', text:'日本語'},
    {lang:'ar', text:'العربية'},
];


const Languages = ({ setLanguage }) => {
  //const dispatch = useDispatch();  

  const onClickLang = (e) => {
    /*const payload = {text: e.target.innerText};
    dispatch(setLang(payload));*/
    //set language//
    var idx = 0;
    for (let index = 0; index < translationLanguages.length; index++) {
        if(translationLanguages[index].text === e.target.innerText){
            idx = index;
            break;
        }
    }
    setLanguage(translationLanguages[idx].lang, translationLanguages[idx].text);

    //set language cookie//
    setCookie('lang', translationLanguages[idx].lang);
  }

  var lang_elements = [];
  for(let i = 0; i < translationLanguages.length; i++){
    lang_elements.push(
      <div className='lang-btn' 
        onClick={onClickLang}
        key={translationLanguages[i].lang}>
        {translationLanguages[i].text}
      </div>
    );
  }

  return (
    <div className="languages">
      {lang_elements}
    </div>
  );
}

export default Languages;

//common functions//
export const getCurrentLanguageText = () => {
  var ret = translationLanguages[0].text;
  var curLang = getCookie('lang');
  if(curLang){
      var idx = 0;
      for (let index = 0; index < translationLanguages.length; index++) {
          if(translationLanguages[index].lang === curLang){
              idx = index;
              break;
          }
      }
      ret = translationLanguages[idx].text;   
  }
  
  return ret;
}

export const getCurrentLang = () => {
  var ret = translationLanguages[0].lang;
  var curLang = getCookie('lang');
  if(curLang){
      ret = curLang;
  }  
  return ret; 
}