import React from 'react';

import { useDispatch } from 'react-redux'
import { setUrl, setTool } from './html2Slice';

import WorkAreaHtml2 from './WorkAreaHtml2';
import WorkSideHtml2 from './WorkSideHtml2';
import { Scrollbars } from 'react-custom-scrollbars';
var init = 0;

const WorkSpaceHtml2 = ({ files, goAction }) => {
    const dispatch = useDispatch();

    /*if (init === 0) {
        dispatch(setUrl(files));
        init = 1;
    }*/
    
    const onAction = (filelist) => {        

        dispatch(setTool("HTML2PDF"));
        goAction();
    }

    /***********rendering**********/
    return (
        <>
            <Scrollbars >
                <WorkAreaHtml2 />
            </Scrollbars>
            <WorkSideHtml2 onAction={onAction}/>
        </>
    );
}

export default WorkSpaceHtml2;