import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelIndex,  setRemoveOption} from './watermarkSlice';
import {  Select, Tooltip, Button } from 'antd';
import { ImBin } from 'react-icons/im';
import { useTranslation } from 'react-i18next';

const {Option} = Select;
const SelectFileCombo = ({filelist, selected}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const onChangeCombo = (value) => {
    dispatch(setSelIndex(value));
  }
  
  const onRemoveClick = (e) => {
    e.preventDefault();
    dispatch(setRemoveOption());
  }
  var selText = selected;
  if (selected === -1)
    selText = "No data";
  ///////////////////rendering//////////////// defaultValue={filelist.find(({ value }) => value === props.state)}
  return (
    <div 
      className="pdf-page-add-element" 
      //style={{left:'38%'}}
      style={{margin:'0 auto'}}
    >
      <Tooltip title="Page number will be applied to these files">
        <Select 
        id="file"
        value={selText}          
        style={{ width: 150, marginRight:'20px' }} onChange={onChangeCombo}>
          {filelist.map(province => (
            <Option value={province.id} key={'sel' + province.id}>{province.name}</Option>
          ))}
        </Select>
      </Tooltip>
      <Tooltip>
        
      </Tooltip>
      <Tooltip title={t('tools.watermark.remove')}>
        <Button icon={<ImBin />} style={{height:'30px'}} onClick={onRemoveClick}></Button>
      </Tooltip>
    </div>
  );
}

export default SelectFileCombo;