import React from 'react';
import { useSelector } from 'react-redux';

import {  Button, Layout } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.pdf2wordOption.filelist);

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0)
            cnt++;
    }

    const onClickAction = () => {
        onAction(filelist);
    }

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>PPT TO PDF</Header>

                <Content>
                    {/*<p>
                        Please, select more PDF files by clicking again on ’Select PDF files’.
                        <br/>
                        Select multiple files by mantaining pressed ’Ctrl’
                    </p>*/}
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt > 0 ? false:true}>
                        {t('tools.ppt2pdf.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;