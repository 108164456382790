import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { insertFilelist, removeFilelist, setRotateFilelist } from './pdf2jpgSlice';


import PDFElement from './PDFElement';

import { Badge, Row, Button, Tooltip } from 'antd';
import { PlusOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from 'react-i18next';

var sorting = -1;// A-> Z
const WorkArea = () => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.pdf2jpgOption.filelist);
    const dispatch = useDispatch();
    

    const addFileList = (files) => {
        for (let i = 0; i < files.length; i++) {
            dispatch(insertFilelist(files[i]));
        }
    };

    const removeFile = (index) => {
        dispatch(removeFilelist(index));
    }

    const setRotate = (index, value) => {
        //console.log(index, value);
        dispatch(setRotateFilelist({idx:index, rotate:value}));
    }

    const onClickSort = (e) => {
    }
    

    ////////rendering////////////
    var pdf_files_list = [];
    for (let i = 0; i < filelist.length; i++) {
        pdf_files_list.push(<PDFElement key={'pdf_' + i} fileinfo={filelist[i]} removeFile={removeFile} setRotate={setRotate}/>);
    }

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) cnt++;
    }

    return (
        <div className="tool__workarea a">
            {pdf_files_list}
            
            {/***************************/}
            <div className="sidetools">
                <Row>
                    <div style={{marginBottom:10}}>
                        <Tooltip title={t('tools.pdf2jpg.add_more')} placement="left">
                            <div className="hover-black-btn shadow-btn">
                            <Badge count={cnt} color="#2db7f5">
                                <FileUploader
                                        multiple={true}
                                        handleChange={addFileList}
                                        name="selectFile"
                                        types={["PDF"]}
                                        children={(<Button 
                                                        type="primary" 
                                                        shape="circle" 
                                                        icon={<PlusOutlined style={{ fontSize: '22px', color: 'white' }}/>} 
                                                        size="large">                                
                                                    </Button>)}
                                />
                            </Badge>
                            </div>
                        </Tooltip>
                    </div>
                </Row>


                {/*<Row>
                    <div className="hover-black-btn white-btn shadow-btn" style={{padding:4}}>
                        <Tooltip title="Sort files by name" placement="left">
                            <Button 
                                type="primary" 
                                shape="circle" 
                                icon={<SortAscendingOutlined style={{ fontSize: '22px' }}/>} 
                                size="medium"
                                onClick = { onClickSort }/>
                        </Tooltip>
                    </div>
                </Row>*/}
            </div>
        </div>
    );
}

export default WorkArea;