import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, 
  setCurrentTop,
  setPageRect, 
  setPageChanged, 
  setSeletedElement,
  setTextEditable,
  unactiveSeletedElement } from '../editSlice';

import { Page } from 'react-pdf/dist/esm/entry.webpack';
import { Scrollbars } from 'react-custom-scrollbars';

import ResizableContent from './ResizableContent';
import DrawPenContent from './DrawPenContent';

import { Input } from 'antd';

const { TextArea } = Input;


var old_scale = 0;

const EachPage = ({ pagenum, scale, elements}) => {
  const pageRef = useRef();

  const dispatch = useDispatch();
  const page_rect = useSelector((state) => state.editOption.page_rect);
  const cur_page = useSelector((state) => state.editOption.cur_page);
  const added_elements = useSelector((state) => state.editOption.added_elements);
  const enable_draw = useSelector((state) => state.editOption.enable_draw);

  useEffect(() => {
      //component mount//
      if(pagenum === 1){
        setTimeout(() => {
          const payload = {
            left: pageRef.current.offsetLeft,
            top: pageRef.current.offsetTop,
            width: pageRef.current.offsetWidth,
            height: pageRef.current.offsetHeight,
          };
          //console.log(payload)
          dispatch(setPageRect(payload));
        }, 200);
      }

      return () => {
        //component will unmount
      };
  }, []);

  const childs = elements.map((element, idx) => {
    if(element.remove === 0)  {
        return(
          <ResizableContent 
            key={'resizable_content_' + element.id}
            left={element.pos.x}
            top={element.pos.y}
            width={element.size.w}
            height={element.size.h}
            rotateAngle={element.rotate}
            page_rect={page_rect}
            id={element.id}
            active={element.active}
            pagenum={pagenum}
            idx={idx}
            element={element}/>
        );
    }
  });

  ///////////////////////////////////////////////////
  var one_page = '';
  if(pagenum === 1 || pagenum === cur_page || pagenum === cur_page + 1 || pagenum === cur_page - 1){
    one_page = (
      <Page pageNumber={pagenum} scale={scale} canvasRef={pageRef} loading="" width={850}>
        {childs}
        {enable_draw?(<DrawPenContent pagenum={pagenum}/>):""}
      </Page>
    );
  }

  //rendering//
  return(
    <div style={{marginBottom:20, marginTop:20}}>
      <div className='one_page' style={{width: page_rect.width?page_rect.width:850, height: page_rect.height}}>
        {one_page}
      </div>
    </div>
  );
}

//====Sleep Function=====//
function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}



/**********************************************************/
const PageEditView = () => {
  const dispatch = useDispatch();
  const cur_page = useSelector((state) => state.editOption.cur_page);
  const viewscale = useSelector((state) => state.editOption.viewscale);
  const max_page = useSelector((state) => state.editOption.max_page); 
  const page_rect = useSelector((state) => state.editOption.page_rect);
  const page_changed = useSelector((state) => state.editOption.page_changed);
  const added_elements = useSelector((state) => state.editOption.added_elements);

  const workRef = useRef();
  const onScrollFrame = (values) => {
    //get current page//
    var pagenumber = Math.floor(Math.abs(values.scrollTop + 300) / (page_rect.height + 20)) + 1;
    //console.log(pagenumber, values);
    if(pagenumber !== cur_page)
      dispatch(setCurrentPage({page: pagenumber, changed:0}));

    var top = values.scrollTop - (page_rect.height + 20) * (pagenumber - 1);
    if(top < 0) top = 0;
    dispatch(setCurrentTop({top: top}));
  }

  const smoothTop = async (top) => {
    workRef.current.scrollTop(top);
    return;

    const { scrollTop } = workRef.current.getValues();
    const _top_interval = (top - scrollTop);
    for (let i = 0; i < 50; i++){
      workRef.current.scrollTop(scrollTop + (_top_interval / 50) * (i + 1));  
      await sleep(3);
    }
  }

  
  const onClickNotSelect = (e) => {
    if(e.target.className === 'react-pdf__Page__canvas'){
      dispatch(unactiveSeletedElement());
    }
  }



  //////////////SET CURRENT PAGE VIEW///////////////
  var elements = [];
  useEffect(() => {    
    if(page_changed === 1 && workRef.current){
      dispatch(setPageChanged(0));
      //const { scrollHeight } = workRef.current.getValues();
      var top = (cur_page - 1) * (page_rect.height + 20) - 20;
      smoothTop(top);      
    }
  });

  for (let i = 0; i < max_page; i++){
    elements.push(
      <EachPage 
        pagenum={i+1} 
        scale={viewscale} 
        elements={added_elements[i+1]} 
        key={'eachpage_' + i.toString()}/>
    );
  }
    

  ///////////////////rendering////////////////
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 1,
      backgroundColor: '#1890ff',   
      width : 4,
      zIndex: 10,
      cursor: 'pointer',
    };
    return (<div style={{ ...style, ...thumbStyle }} {...props} />);
  };

  return (
        <div className="editpdf__editpage" onClick={onClickNotSelect}>
          <Scrollbars 
            style={{ width: '100%', height: '100%' }} 
            renderThumbVertical={renderThumb}  
            renderThumbHorizontal={renderThumb}
            ref={workRef} 
            onScrollFrame={onScrollFrame}>
            <div>
              {elements}
            </div>
          </Scrollbars>
        </div>
  );
}

export default PageEditView;
