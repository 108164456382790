import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { RotateRightOutlined, MinusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const PDFElement = (propsparam) => {
  const { fileinfo, removeFile, setRotate} = propsparam;
  
  const {t} = useTranslation();
  const direction = useSelector((state) => state.jpg2pdfOption.direction);
  const margin = useSelector((state) => state.jpg2pdfOption.margin);
  const pagesize = useSelector((state) => state.jpg2pdfOption.pagesize);
  
  ////////////////////////// get width and height of real image
  const imgElement = React.useRef(null);
  
  const [ratio, setRatio] = useState(0);

  const onImageLoad = () => {
    var nRatio = imgElement.current.naturalHeight / imgElement.current.naturalWidth;
    //alert(nRatio);
    setRatio(nRatio);
  }
  var image1 = <img src={URL.createObjectURL(fileinfo.file)} ref={imgElement} onLoad={onImageLoad} style={{display:'none'}}/>
  ////////////////////////////////////////////////////////////////
  const onClickRotate = () => {
    setRotate(fileinfo.idx, (fileinfo.rotate + 1) % 4);
  }
  const onClickSelectPDF = () => {
    var select = fileinfo.select === 0 ? 1 : 0;
  }

  var marginname = "pdfcanvas_jpg_box_nomargin";
  if (margin === 1)
    marginname = "pdfcanvas_jpg_box_smallmargin";
  else if(margin === 2)
    marginname = "pdfcanvas_jpg_box_bigmargin";

  ///////////////////rendering////////////////
  const tooltip_pdf = fileinfo.file.name;  
  const degvalue = (fileinfo.rotate) * 90;
  const display = fileinfo.remove === 0 ? 'flex' : 'none';

  var element = '';
  if (pagesize === 0) {
    element = (
      <div style={{transform: `rotate(${degvalue}deg)`}}>
        <img width={130} src={URL.createObjectURL(fileinfo.file)} className={marginname} />
      </div>
    );
  }
  else if(pagesize === 1)
  {
    // style={{paddingTop:top}}
    var nPaperRatio = direction === 0 ? (297 / 210) :(210 / 297);
    if (ratio === nPaperRatio)
    {
      element = (
      <div className ={direction == 0?"pdf-canvas0":"pdf-canvas1"} style={{transform: `rotate(${degvalue}deg)`}} onClick={onClickSelectPDF}>
        <img width={100} src={URL.createObjectURL(fileinfo.file)} className={marginname} />
      </div>
    );}
    else if(ratio < nPaperRatio)
    {
      var padding = parseInt((nPaperRatio - ratio) * 50) + 1;
      var top = padding + margin * 4 + '%';
      
      element = (
        <div className ={direction == 0?"pdf-canvas0":"pdf-canvas1"} style={{transform: `rotate(${degvalue}deg)`}} onClick={onClickSelectPDF}>
          <img width='100%' src={URL.createObjectURL(fileinfo.file)}  className={marginname}  style={{paddingTop:top}}/>
          
        </div>
      );
    }
    else
    {
      element = (
        <div className ={direction == 0?"pdf-canvas0":"pdf-canvas1"} style={{transform: `rotate(${degvalue}deg)`}} onClick={onClickSelectPDF}>
          <img height='100%' src={URL.createObjectURL(fileinfo.file)} className={marginname} />
        </div>
      );
    }
  }
  else if(pagesize === 2)
  {
    var nPaperRatio = direction === 0 ? (279 / 215) :(215 / 279);
    if (ratio === nPaperRatio)
    {
      element = (
      <div className ={direction == 0?"pdf-canvas2":"pdf-canvas3"} style={{transform: `rotate(${degvalue}deg)`}} onClick={onClickSelectPDF}>
        <img width={100} src={URL.createObjectURL(fileinfo.file)} className={marginname} />
      </div>
    );}
    else if(ratio < nPaperRatio)
    {
      var padding = parseInt((nPaperRatio - ratio) * 50) + 1;
      var top = padding + margin * 4 + '%';
      
      element = (
        <div className ={direction == 0?"pdf-canvas2":"pdf-canvas3"} style={{transform: `rotate(${degvalue}deg)`}} onClick={onClickSelectPDF}>
          <img width='100%' src={URL.createObjectURL(fileinfo.file)}  className={marginname}  style={{paddingTop:top}}/>
          
        </div>
      );
    }
    else
    {
      element = (
        <div className ={direction == 0?"pdf-canvas2":"pdf-canvas3"} style={{transform: `rotate(${degvalue}deg)`}} onClick={onClickSelectPDF}>
          <img height='100%' src={URL.createObjectURL(fileinfo.file)} className={marginname}/>
        </div>
      );
    }
    
  }
  
  return (
    <Tooltip  placement="bottom" title={tooltip_pdf}>
        <div>{image1}</div>
        <div className="pdf-element" style={{display:display}}>
            {element}
            
          <div className="pdf-action">
            <Tooltip title={t("tools.jpg2pdf.Rotate")} onClick={onClickRotate}>
              <div className="circle-btn">
                  <RotateRightOutlined />
              </div>
            </Tooltip>

            <Tooltip title={t("tools.jpg2pdf.Remove")}>
              <div className="circle-btn" onClick={() => removeFile(fileinfo.idx)}>
                  <MinusOutlined />
              </div>
            </Tooltip>
          </div>
        </div>
    </Tooltip>
  );
}

export default PDFElement;