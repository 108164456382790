import React from 'react';
import { Page } from 'react-pdf/dist/esm/entry.webpack';

import { useSelector } from 'react-redux';

const PageElement = ({id, cnt, show}) => {
  /*var display = 'Normal';
  if(show)
    display = 'None';*/
  var element = '';
  var element1 = '';
  if (show){
    element = (<Page pageNumber={id+1}/>);
    element1 = (<ElementStyle id={id+1} cnt={cnt}/>);
  }
  ///////////////////rendering////////////////
  return (
    <div style={{marginBottom:20}}>
      <div className="pdf-element">
        <div className="pdf-canvas_page">
          {element}
        </div>
          {element1}
      </div>
    </div>
  );
}

const ElementStyle = ({id, cnt}) => {
  const position = useSelector((state) => state.watermarkOption.position);
  const mosaic = useSelector((state) => state.watermarkOption.mosaic);
  const transparency = useSelector((state) => state.watermarkOption.transparency);
  const singlestart = useSelector((state) => state.watermarkOption.singlestart);
  const singleend = useSelector((state) => state.watermarkOption.singleend);

  var element = '';
  if (mosaic === 0) {
    var clsName = "pdf-pagenumber-area_lefttop";
    if (position === 1) clsName = "pdf-pagenumber-area_middletop";
    else if (position === 2) clsName = "pdf-pagenumber-area_righttop";
    else if (position === 3) clsName = "pdf-pagenumber-area_leftmiddle";
    else if (position === 4) clsName = "pdf-pagenumber-area_middlemiddle";
    else if (position === 5) clsName = "pdf-pagenumber-area_rightmiddle";
    else if (position === 6) clsName = "pdf-pagenumber-area_leftbottom";
    else if (position === 7) clsName = "pdf-pagenumber-area_middlebottom";
    else if (position === 8) clsName = "pdf-pagenumber-area_rightbottom";

    var trans = "transparency0";
    if (transparency === 1) trans = "transparency1";
    else if (transparency === 2) trans = "transparency2";
    else if (transparency === 3) trans = "transparency3";
    
    var bshow = false;
    if (cnt != 1)bshow = true;
    else{
        if ((id >= singlestart) && (id <= singleend)) bshow = true;
    }
    //console.log("bshow value:", bshow);
    element = (
      <div className={bshow?clsName:"pdf-non-checked"}>
        <div className="recommargin">
          <div className={trans}>
            <div className="pdf-pagenumber-circle">
            </div>  
          </div>
        </div>
      </div>
    );
  }
  else{
    var clsName0 = "pdf-pagenumber-area_lefttop";
    var clsName1 = "pdf-pagenumber-area_middletop";
    var clsName2 = "pdf-pagenumber-area_righttop";
    var clsName3 = "pdf-pagenumber-area_leftmiddle";
    var clsName4 = "pdf-pagenumber-area_middlemiddle";
    var clsName5 = "pdf-pagenumber-area_rightmiddle";
    var clsName6 = "pdf-pagenumber-area_leftbottom";
    var clsName7 = "pdf-pagenumber-area_middlebottom";
    var clsName8 = "pdf-pagenumber-area_rightbottom";

    var marginName = "recommargin";
    
    var trans = "transparency0";
    if (transparency === 1) trans = "transparency1";
    else if (transparency === 2) trans = "transparency2";
    else if (transparency === 3) trans = "transparency3";
    
    var bshow = false;
    if (cnt != 1)bshow = true;
    else{
        if ((id >= singlestart) && (id <= singleend)) bshow = true;
    }
    
    element = (
      <div>
        <div className={bshow===false?"pdf-non-checked":clsName0}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName1}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName2}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName3}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName4}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName5}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName6}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName7}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
        <div className={bshow===false?"pdf-non-checked":clsName8}>
          <div className={marginName}>
            <div className={trans}>
              <div className="pdf-pagenumber-circle">
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  

  return (
    <div>
    {element}
    </div>
  );
}
export default PageElement;