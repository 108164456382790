//cookie//
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const setCookie = (key, value) => { 
	return cookies.set(key, value, {path: '/'});
	//return cookies.set('myCat', 'Pacman', { path: '/' });
  //return cookies.set(name, value, { ...option }) 
} 

export const getCookie = (key) => { 
  return cookies.get(key) 
}

export const removeCookie = (key) => {
    return cookies.remove(key);
}