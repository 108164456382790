import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface InitOptionState {
  method: number,
  filelist: Array,
}

const initialState: InitOptionState = {
  method: 0,
  filelist: [],
}

export const pdf2pdfASlice = createSlice({
  name: 'pdf2pdfAOption',
  initialState,
  reducers: {
    setMethod: (state, action:PayloadAction<number>) =>{
      state.method = action.payload;
    },
    setFileList: (state, action:PayloadAction<Array>) =>{
      state.filelist = action.payload;
      for(let i = 0; i < action.payload.length; i++){
        state.filelist[i].url = URL.createObjectURL(action.payload[i].file);
      }
    },
    insertFilelist: (state, action) => {
      state.filelist.push({
        idx: state.filelist.length,
        file: action.payload, 
        url: URL.createObjectURL(action.payload),
        rotate: 0,
        remove: 0,
      });
    },
    removeFilelist: (state, action) => {
      state.filelist[action.payload].remove = 1;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setMethod, 
  setFileList, 
  insertFilelist, 
  removeFilelist } = pdf2pdfASlice.actions

export default pdf2pdfASlice.reducer