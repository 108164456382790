import React from 'react';
import { Menu, MenuProps } from 'antd';
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { toolsListInfo } from '../../utils/toolsInfo';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key | null,
  icon: React.ReactNode,
  children: MenuItem[],
  type: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  };// as MenuItem;
}

const items: MenuItem[] = [
  getItem('ALL PDF TOOLS', 'all-tools', <UnorderedListOutlined />, [
    getItem('ORGANIZE PDF', 'organize-pdf-tools', null, [
        getItem(<a href={toolsListInfo[0].link}>{toolsListInfo[0].title}</a>, 'organ1', <img src = {toolsListInfo[0].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[1].link}>{toolsListInfo[1].title}</a>, 'organ2', <img src = {toolsListInfo[1].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[18].link}>{toolsListInfo[18].title}</a>, 'organ5', <img src = {toolsListInfo[18].svg} style={{width:'20px'}} alt="img"/>),
    ]),
    getItem('OPTIMIZE PDF', 'optimize-pdf-tools', null, [
        getItem(<a href={toolsListInfo[2].link}>{toolsListInfo[2].title}</a>, 'opt1', <img src = {toolsListInfo[2].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[20].link}>{toolsListInfo[20].title}</a>, 'opt2', <img src = {toolsListInfo[20].svg} style={{width:'20px'}} alt="img"/>),
    ]),
    getItem('CONVERT TO PDF', 'convert-to-pdf', null, [
        getItem(<a href={toolsListInfo[11].link}>{toolsListInfo[11].title}</a>, 'convert1', <img src = {toolsListInfo[11].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[6].link}>{toolsListInfo[6].title}</a>, 'convert2', <img src = {toolsListInfo[6].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[7].link}>{toolsListInfo[7].title}</a>, 'convert3', <img src = {toolsListInfo[7].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[8].link}>{toolsListInfo[8].title}</a>, 'convert4', <img src = {toolsListInfo[8].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[15].link}>{toolsListInfo[15].title}</a>, 'convert5', <img src = {toolsListInfo[15].svg} style={{width:'20px'}} alt="img"/>),
    ]),
    getItem('CONVERT FROM PDF', 'convert-from-pdf', null, [
        getItem(<a href={toolsListInfo[10].link}>{toolsListInfo[10].title}</a>, 'from1', <img src = {toolsListInfo[10].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[3].link}>{toolsListInfo[3].title}</a>, 'from2', <img src = {toolsListInfo[3].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[4].link}>{toolsListInfo[4].title}</a>, 'from3', <img src = {toolsListInfo[4].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[5].link}>{toolsListInfo[5].title}</a>, 'from4', <img src = {toolsListInfo[5].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[19].link}>{toolsListInfo[19].title}</a>, 'from5', <img src = {toolsListInfo[19].svg} style={{width:'20px'}} alt="img"/>),
    ]),
    getItem('EDIT PDF', 'edit-pdf-tools', null, [
        getItem(<a href={toolsListInfo[14].link}>{toolsListInfo[14].title}</a>, 'edt1', <img src = {toolsListInfo[14].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[12].link}>{toolsListInfo[12].title}</a>, 'edt2', <img src = {toolsListInfo[12].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[13].link}>{toolsListInfo[13].title}</a>, 'edt3', <img src = {toolsListInfo[13].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[9].link}>{toolsListInfo[9].title}</a>, 'edt4', <img src = {toolsListInfo[9].svg} style={{width:'20px'}} alt="img"/>),
    ]),
    getItem('PDF SECURITY', 'pdf-security-tools', null, [
        getItem(<a href={toolsListInfo[16].link}>{toolsListInfo[16].title}</a>, 'sec1', <img src = {toolsListInfo[16].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[17].link}>{toolsListInfo[17].title}</a>, 'sec2', <img src = {toolsListInfo[17].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[21].link}>{toolsListInfo[21].title}</a>, 'sec3', <img src = {toolsListInfo[21].svg} style={{width:'20px'}} alt="img"/>),
    ]),
  ]),
  getItem('CONVERT FROM PDF', 'convertfrompdf', <UnorderedListOutlined />, [
        getItem(<a href={toolsListInfo[10].link}>{toolsListInfo[10].title}</a>, 'pdftojpg', <img src = {toolsListInfo[10].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[3].link}>{toolsListInfo[3].title}</a>, 'pdftoword', <img src = {toolsListInfo[3].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[4].link}>{toolsListInfo[4].title}</a>, 'pdftoppt', <img src = {toolsListInfo[4].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[5].link}>{toolsListInfo[5].title}</a>, 'pdftoxls', <img src = {toolsListInfo[5].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[19].link}>{toolsListInfo[19].title}</a>, 'pdftopdfA', <img src = {toolsListInfo[19].svg} style={{width:'20px'}} alt="img"/>),
  ]),
  getItem('CONVERT TO PDF', 'convert2pdf', <UnorderedListOutlined />, [
        getItem(<a href={toolsListInfo[11].link}>{toolsListInfo[11].title}</a>, 'jpgtopdf', <img src = {toolsListInfo[11].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[6].link}>{toolsListInfo[6].title}</a>, 'wordtopdf', <img src = {toolsListInfo[6].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[7].link}>{toolsListInfo[7].title}</a>, 'ppttopdf', <img src = {toolsListInfo[7].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[8].link}>{toolsListInfo[8].title}</a>, 'xlstopdf', <img src = {toolsListInfo[8].svg} style={{width:'20px'}} alt="img"/>),
        getItem(<a href={toolsListInfo[15].link}>{toolsListInfo[15].title}</a>, 'htmltopdf', <img src = {toolsListInfo[15].svg} style={{width:'20px'}} alt="img"/>),
  ]),
  getItem(<a href={toolsListInfo[2].link}>{toolsListInfo[2].title}</a>, 'compresspdf'),
  getItem(<a href={toolsListInfo[0].link}>{toolsListInfo[0].title}</a>, 'mergepdf'),
  getItem(<a href={toolsListInfo[9].link}>{toolsListInfo[9].title}</a>, 'editpdf'),
];

const onClick: MenuProps['onClick'] = e => {
  //console.log('click', e);
};

const HeaderNav = () => {
  return (
    <div className="header__nav">
        <Menu onClick={onClick} mode="horizontal" items={items} />
    </div>
  );
}

export default HeaderNav;




