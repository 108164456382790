import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setMode, setSelectPages } from './splitSlice';

import {  Button, Layout, Tabs } from 'antd';
import { SendOutlined } from '@ant-design/icons';

import splitrange_svg from '../../../assets/svg/splitbyrange.svg';
import extractpages_svg from '../../../assets/svg/extractpages.svg';

import TabSplitByRange from './TabSplitByRange';
import TabExtractPages from './TabExtractPages';

import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
const { Header, Footer, Content } = Layout;

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const max_page = useSelector((state) => state.splitOption.max_page);
    const option = useSelector((state) => state.splitOption.mode);
    const select_pages = useSelector((state) => state.splitOption.select_pages);

    useEffect(() => {
        //component mount//
        dispatch(setMode(t('tools.split.custom_ranges')));
  console.log('dispatch trigger')
        return () => {
          //component will unmount
        };
    }, []);

    const onClickAction = (e) => {
        e.preventDefault();
        onAction(2);
    }

    const onChangeTab = (activeKey) => {
        if (activeKey==="1") {
            dispatch(setMode(t('tools.split.custom_ranges')));
        } else if (activeKey === "2") {
            dispatch(setMode(t('tools.split.extract_all')));
            let checked_page = [];
            for (let i = 0; i < max_page; i++) {
                checked_page.push(1);
            }
            dispatch(setSelectPages(checked_page));
        }
    }

    /***********rendering**********/
    var btn_disabled = false;
    if (option === t('tools.split.select_pages')) {
        var cnt = 0;
        for (let i = 0; i < select_pages.length; i++) {
            cnt = cnt + select_pages[i];
        }
        if(cnt === 0)
            btn_disabled = true; 
    }

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>SPLIT PDF</Header>

                <Content>
                    <div className="center-tab">
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <Tabs defaultActiveKey="1" onChange={onChangeTab}>
                                <TabPane
                                tab={
                                    <div className='split-tab'>
                                        <div>
                                            <img src = {splitrange_svg} style={{width:'30px'}} alt=""/>
                                        </div>
                                        <span style={{whiteSpace: 'break-spaces'}}>{t('tools.split.side_op1')}</span>
                                    </div>
                                }
                                key="1"
                                >
                                    <TabSplitByRange/>
                                </TabPane>

                                <TabPane
                                tab={
                                    <div  className='split-tab'> 
                                        <div>
                                            <img src = {extractpages_svg} style={{width:'30px'}} alt=""/>
                                        </div>
                                        <span style={{whiteSpace: 'break-spaces'}}>{t('tools.split.side_op2')}</span>
                                    </div>
                                }
                                key="2"
                                >
                                    <TabExtractPages/>
                                </TabPane>
                            </Tabs>
                        </Scrollbars>                   
                    </div>
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={btn_disabled}>
                        {t('tools.split.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;