import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setFileOptionList, setRemoveOption, setRotateOption, setSelectOption } from './jpg2PdfSlice';


import PDFElement from './PDFElement';

import { Badge, Row, Button, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FileUploader } from "react-drag-drop-files";
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

const WorkAreaJpg2 = () => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.jpg2pdfOption.filelist);
    const dispatch = useDispatch();
    const addFileList = (files) => {
        var dFileList = [];
        for (var i = 0; i < filelist.length; i++) {
            dFileList.push(filelist[i]);
        }

        for (i = 0; i < files.length; i++) {
            dFileList.push({
                idx: dFileList.length,
                file: files[i],
                remove: 0,
                rotate: 0,
            });
        }
        dispatch(setFileOptionList(dFileList));
    };
    const removeFile = (index) => {
        dispatch(setRemoveOption({idx: index, remove:1}));
    }
    const setRotate = (index, value) => {
        dispatch(setRotateOption({idx: index, rotate:value}));
    }
    ////////rendering////////////
    var pdf_files_list = [];
    
    for (let i = 0; i < filelist.length; i++) {
        pdf_files_list.push(
            <PDFElement 
                fileinfo={filelist[i]} 
                removeFile={removeFile} 
                setRotate={setRotate}
                key={'image_' + i}/>
        );
    }

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) cnt++;
    }
    return (
        <div className="tool__workarea" style={{paddingTop:100}}>
                    <div className="element-region">
                    {pdf_files_list}
                    </div>
                    {/***************************/}
                    <div className="sidetools">
                        <Row>
                            <div style={{marginBottom:10}}>
                                <Tooltip title={t("tools.jpg2pdf.Addmorefiles")} placement="left">
                                    <div className="hover-black-btn shadow-btn">
                                    <Badge count={cnt} color="#2db7f5">
                                        <FileUploader
                                                multiple={true}
                                                handleChange={addFileList}
                                                name="Custom Files"
                                                types={["JPG", "JPEG", "PNG", "TIF", "TIFF"]}
                                                children={(<Button 
                                                                type="primary" 
                                                                shape="circle" 
                                                                icon={<PlusOutlined style={{ fontSize: '22px', color: 'white' }}/>} 
                                                                size="large">                                
                                                            </Button>)}
                                        />
                                    </Badge>
                                    </div>
                                </Tooltip>
                            </div>
                        </Row>
                    </div>
                                           
        </div>
    );
}

export default WorkAreaJpg2;