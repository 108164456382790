import React, { useState } from 'react';

import { Row, Col, Space } from 'antd';

import { BsTwitter, BsFacebook } from "react-icons/bs";
import pdf_logo from '../assets/svg/foot_logo.svg';

const style = { padding: '3px 0' };

  
const Footer = () => {

  return (
    <div className="footer">
      	<Row>
            <Col span={8}>
  				<img src={pdf_logo} alt="PDFMade" style={{marginTop:10, width:'80%'}}/>
            </Col>

            <Col span={8}>
                <p style={{color: '#1890ff'}}><b>PDF MADE</b></p>
                <Col className="gutter-row" span={24}>
                    <div style={style}><a href="/">Home</a></div>
                </Col>
                <Col className="gutter-row" span={24}>
                    <div style={style}><a href="/features">Features</a></div>
                </Col>
                {/*<Col className="gutter-row" span={24}>
                    <div style={style}><a href="/pricing">Pricing</a></div>
                </Col>*/}
                <Col className="gutter-row" span={24}>
                    <div style={style}><a href="/tools">Tools</a></div>
                </Col>
                <Col className="gutter-row" span={24}>
                    <div style={style}><a href="/faq">FAQ</a></div>
                </Col>
            </Col>


              

            <Col span={8}>
                <p style={{color: '#1890ff'}}><b>DIGICORP SRL</b></p>
                <Col className="gutter-row" span={24}>
                    <div style={style}><a href="/privacy-policy">Privacy policy</a></div>
                </Col>
                <Col className="gutter-row" span={24}>
                    <div style={style}><a href="/">mail@pdfmade.com</a></div>
                </Col>
                <Col className="gutter-row" span={24}>
                    <div style={style}><a href="/">Telegram</a></div>
                </Col>
            </Col>
        </Row>
        

        <div style={{marginTop: 30, paddingTop:30, borderTop:'1px solid #fff', paddingBottom:60}}>        	
            <div style = {{fontSize:'16px', float:'left'}}>
        		© PDFMade 2022 ® - PDF Tool
            </div>
            
            <div style={{float:'right'}}>
                <a href = "https://twitter.com/" style={{marginRight:'10px'}}>
                  <BsTwitter className="footer-icons" />
                </a>
                
                <a href = "https://www.facebook.com/" style={{marginRight:'10px'}}>
                  <BsFacebook className="footer-icons" />
                </a>
            </div>
        </div>
    </div>
  );
}

export default Footer;