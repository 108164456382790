import React, { useState, useRef, useEffect} from "react";
//import "./Draw.css";
import { useSelector, useDispatch } from 'react-redux';
//import { setSignName,setSignInit } from './signSlice';

const Draw = ({_url, _width, _height, _color, _thick, _erase, changeUrl}) => {
  //const dispatch = useDispatch(); 
  //const signSave = useSelector((state: RootState) => state.signOption.signSave);

  //console.log("received signSave:", signSave);
  //const [w, h, color, thick] = props;
  const canvasRef = useRef();
  const contextRef = useRef();
  const colorRef = useRef();
  const thickRef = useRef();
  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  
  const [status, setStatus] = useState("success");
  const [warning, setWarning] = useState("Successfully Saved!");
  const [desc, setDesc] = useState("");
  const [open, setOpen] = useState(false);
  useEffect( ()=> {
    if (_url != '')
    {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      var img = new Image();
      img.onload = function() {
        context.drawImage(img, 0, 0);
        contextRef.current = context;
      };
      img.src = _url;
    }
  }, [_url]);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = _width;// * 2;
    canvas.height = _height;// * 2;
    
    const context = canvas.getContext("2d");
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.scale(1, 1);
    context.lineCap = 'round';
    context.strokeStyle = _color;
    context.lineWidth = _thick;
    contextRef.current = context;
  }, []);

  useEffect(()=> {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    var src = context.getImageData(0, 0, _width, _height);
    var __color = _color.replace('#', ''); 
    var aRgbHex = __color.match(/.{1,2}/g);
    var aRgbNew = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
    
    for (var i = 0; i < src.data.length/4; i++) {
      var r = src.data[4*i];
      var g = src.data[4*i+1];
      var b = src.data[4*i+2];
      if (((r == 0) && (g == 0) && (b == 0)) || ((r == 255) && (g == 255) && (b == 255))) continue;
    
      src.data[4*i] = aRgbNew[0];
      src.data[4*i+1] = aRgbNew[1];
      src.data[4*i+2] = aRgbNew[2];
    }
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.scale(1, 1);
    context.lineCap = 'round';
    context.strokeStyle = _color;
    context.lineWidth = _thick;
    context.putImageData(src, 0, 0);
    contextRef.current = context;

    var url = canvasRef.current.toDataURL("image/png", 1.0);
    changeUrl(url);

  }, [_color])

  useEffect(()=> {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    contextRef.current = context;
  }, [_erase])


  useEffect(()=> {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    var src = context.getImageData(0, 0, canvas.width, canvas.height);
    context.fillStyle = "white";
    context.fillRect(0, 0, _width, _height);
    context.scale(1, 1);
    context.lineCap = 'round';
    context.strokeStyle = _color;
    context.lineWidth = 3;
    context.putImageData(src, 0, 0);
    contextRef.current = context;
  }, [_width, _height])

  /*useEffect(()=> {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    var src = context.getImageData(0, 0, canvas.width, canvas.height);
    
    console.log("content: ", src.data.length);
    var num = 0;

    var xmin = 1000, xmax = -1000;
    var ymin = 1000, ymax = -1000;
    for (var i = 0; i < src.data.length/4; i++) {
      var r = src.data[4*i];
      var g = src.data[4*i+1];
      var b = src.data[4*i+2];
      if (((r == 0) && (g == 0) && (b == 0)) || ((r == 255) && (g == 255) && (b == 255))) continue;
      
      var y = parseInt(i / canvas.width);
      var x = i % canvas.width;
      if (xmin > x) xmin = x;
      if (xmax < x) xmax = x;
      if (ymin > y) ymin = y;
      if (ymax < y) ymax = y;
    }
    console.log("region", [xmin, ymin, xmax, ymax]);

  }, [_rect])*/

  const onMouseDown = (e ) => {
    const { offsetX, offsetY } = e.nativeEvent;
    setStartX(offsetX);
    setStartY(offsetY);
    setIsDrawing(true);
  };

  const onMouseUp = () => {
    contextRef.current.closePath();
    setIsDrawing(false);

    const canvas = canvasRef.current;
    console.log("canvas:", canvas);
    var url = canvas.toDataURL("image/png", 1.0);
    changeUrl(url);
    //console.log("send url:", url);
    //if (_sel == 0) dispatch(setSignName(url));
    //else if (_sel == 0) dispatch(setSignInit(url));  
  };

  const draw = (e) => {
    if (!isDrawing) {
      return;
    }
   
    contextRef.current.beginPath();
    contextRef.current.moveTo(startX, startY);
    const { offsetX, offsetY } = e.nativeEvent;
    setStartX(offsetX);
    setStartY(offsetY);
    
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
  };

  return (
    <>
      <div className="draw-canvas">
        <canvas
          className="test"
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={draw}
          ref={canvasRef}
          style={{
            border: '1px solid #000000',
          }}
        />
      </div>
    </>
  );
}//)

export default Draw;