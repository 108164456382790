import { Menu } from 'antd';

const Html2Nav = () => (
  /*<Menu mode="horizontal" defaultSelectedKeys={['mail']}>
    <Menu.Item key="mail">
      URL
    </Menu.Item>
    
  </Menu>*/
  <div >URL</div>
);

export default Html2Nav;