import React, { useState }  from 'react';

import { Col, Row } from 'antd';

import { BsFillMegaphoneFill, 
	BsGraphUp,
	BsEnvelopeOpenFill,
	BsPaypal,
 } from 'react-icons/bs';

 import { AiTwotoneFile } from 'react-icons/ai';
 import { FaMailBulk, FaRegAddressBook } from 'react-icons/fa';

//lang test//
import { useTranslation } from 'react-i18next';


///
const Privacy = () => {
	const {t} = useTranslation();

	return (
		<div className="privacy">
			<div style={{margin:'0 auto', maxWidth:'60%'}}>
				<h1 style={{color:'#404040', fontWeight:'600', fontSize:40}}>
					{t('home.privacy_policy.title')}	
				</h1>
				<div className="group">
					<span style={{fontSize:16, fontWeight:510}}>
						{t('home.privacy_policy.subtitle1')}
					</span>

					<Row justify="space-around" align="top" className="faq__group">
						<Col span={8}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<BsFillMegaphoneFill size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol1')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol1cont1')}</span><br/>
											{t('home.privacy_policy.pol1txt1')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
						
						<Col span={8} offset={2}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<BsGraphUp size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol2')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol2cont1')}</span><br/>
											{t('home.privacy_policy.pol2txt1')}
										</p>
										<p>
											<span>{t('home.privacy_policy.pol2cont2')}</span><br/>
											{t('home.privacy_policy.pol2txt2')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
					</Row>

					<Row justify="space-around" align="top" className="faq__group">
						<Col span={8}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<BsEnvelopeOpenFill size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol3')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol3cont1')}</span><br/>
											{t('home.privacy_policy.pol3txt1')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
						
						<Col span={8} offset={2}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<BsPaypal size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol4')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol4cont1')}</span><br/>
											{t('home.privacy_policy.pol4txt1')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
					</Row>



					<Row justify="space-around" align="top" className="faq__group">
						<Col span={8}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<AiTwotoneFile size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol5')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol5cont1')}</span><br/>
											{t('home.privacy_policy.pol5txt1')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
						
						<Col span={8} offset={2}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<FaMailBulk size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol6')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol6cont1')}</span><br/>
											{t('home.privacy_policy.pol6txt1')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
					</Row>


					<Row justify="space-around" align="top" className="faq__group">
						<Col span={8}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<FaRegAddressBook size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol7')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol7cont1')}</span><br/>
											{t('home.privacy_policy.pol7txt1')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
						
						<Col span={8} offset={2}>
							<Row justify="space-around" align="top">
								<Col span={1}>
									<AiTwotoneFile size={18} style={{marginTop:4}}/>
								</Col>
								<Col span={21} offset={1}>
									<div className="item__text">
										<p><b>{t('home.privacy_policy.pol8')}</b></p>
										<p>
											<span>{t('home.privacy_policy.pol8cont1')}</span><br/>
											{t('home.privacy_policy.pol8txt1')}
										</p>
									</div>
								</Col>							
							</Row>
						</Col>
					</Row>
				</div>


				<div className="group">
					<div style={{marginBottom:20}}>
						<span style={{fontSize:16, fontWeight:510}}>
							{t('home.privacy_policy.subtitle2')}
						</span>
						<br/>
						<p>
							{t('home.privacy_policy.subtxt2')}
						</p>
					</div>
					<div style={{marginBottom:20}}>
						<span style={{fontSize:16, fontWeight:510}}>
							{t('home.privacy_policy.subtitle3')}
						</span>
						<Row justify="space-around" align="top" style={{marginTop:10}}>
							<Col span={1}>
								<AiTwotoneFile size={24} style={{marginTop:4}}/>
							</Col>
							<Col span={23}>
								<div className="item__text">
									<p>
										{t('home.privacy_policy.subtxt3')}
									</p>
								</div>
							</Col>							
						</Row>
					</div>
				</div>
			</div>

			<div style={{margin:'0 auto', maxWidth:'65%'}}>
				<h1 style={{color:'#404040', fontWeight:'600', fontSize:40}}>
					{t('home.security_policy.title')}
				</h1>
				<div style={{textAlign:'left'}} className="group">
					<p>
						{t('home.security_policy.txt1')}
					</p>

					<p>
						{t('home.security_policy.txt2')}
					</p>	

					<p>
						{t('home.security_policy.txt3')}
					</p>

					<p>
						<ul>
							<li>
								{t('home.security_policy.txt4')}
							</li>
							<li>
								{t('home.security_policy.txt5')}
							</li>
							<li>
								{t('home.security_policy.txt6')}
							</li>
						</ul>
					</p>

					<p>
						{t('home.security_policy.txt7')}
					</p>

					<p>
						{t('home.security_policy.txt8')}
					</p>
				</div>				
			</div>
		</div>
	);
}

export default Privacy;