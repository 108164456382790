import React from 'react';

import compress_pdf from '../assets/svg/tools/compress_pdf.svg';
import edit_pdf from '../assets/svg/tools/edit_pdf.svg';
import html_to_pdf from '../assets/svg/tools/html_to_pdf.svg';
import jpg_to_pdf from '../assets/svg/tools/jpg_to_pdf.svg';
import merge_pdf from '../assets/svg/tools/merge_pdf.svg';
import organize_pdf from '../assets/svg/tools/organize_pdf.svg';
import page_numbers from '../assets/svg/tools/page_numbers.svg';
import pdf_to_jpg from '../assets/svg/tools/pdf_to_jpg.svg';
import pdf_to_pdfA from '../assets/svg/tools/pdf_to_pdfA.svg';
import pdf_to_ppt from '../assets/svg/tools/pdf_to_ppt.svg';
import pdf_to_word from '../assets/svg/tools/pdf_to_word.svg';
import pdf_to_xls from '../assets/svg/tools/pdf_to_xls.svg';
import ppt_to_pdf from '../assets/svg/tools/ppt_to_pdf.svg';
import protect_pdf from '../assets/svg/tools/protect_pdf.svg';
import repair_pdf from '../assets/svg/tools/repair_pdf.svg';
import rotate_pdf from '../assets/svg/tools/rotate_pdf.svg';
import sign_pdf from '../assets/svg/tools/sign_pdf.svg';
import split_pdf from '../assets/svg/tools/split_pdf.svg';
import unlock_pdf from '../assets/svg/tools/unlock_pdf.svg';
import watermark from '../assets/svg/tools/watermark.svg';
import word_to_pdf from '../assets/svg/tools/word_to_pdf.svg';
import xls_to_pdf from '../assets/svg/tools/xls_to_pdf.svg';

const getToolInfo = (title, description, svg, link, tool_header, api) => {
    return {
        title: title,
        description: description,
        svg: svg,
        link: link,
        tool_header: tool_header,
        api: api,
    };
}

export const toolsListInfo = [
    /*0*/getToolInfo("Merge PDF", "Combine multiple PDF files into one quickly and easily", merge_pdf, "/merge-pdf", "MERGE", "merge_pdf"),
    /*1*/getToolInfo("Split PDF", "Split PDF file into pieces or pick just a few pages", split_pdf, "/split-pdf", "SPLIT", "split_pdf"),
    /*2*/getToolInfo("Compress PDF", "Reduce the size of your PDF file", compress_pdf, "/compress-pdf", "COMPRESSPDF", "compress_pdf"),
    /*3*/getToolInfo("PDF to Word", "Convert PDF files to Word and make them available for editing", pdf_to_word, "/pdf-to-word", "PDF2WORD", "pdf2word"),
    /*4*/getToolInfo("PDF to PPT", "Convert PDF files to PowerPoint presentations", pdf_to_ppt, "/pdf-to-ppt", "PDF2PPT", "pdf2ppt"),
    /*5*/getToolInfo("PDF to Excel", "Convert PDF files to Excel spreadsheets", pdf_to_xls, "/pdf-to-xls", "PDF2EXCEL", "pdf2excel"),
    /*6*/getToolInfo("Word to PDF", "Convert Word files (doc, docx) to PDF", word_to_pdf, "/word-to-pdf", "WORD2PDF", "word2pdf"),
    /*7*/getToolInfo("PPT to PDF", "Convert PowerPoint presentations (ppt, pptx) to PDF and make them easily readable", ppt_to_pdf, "/ppt-to-pdf", "PPT2PDF", "ppt2pdf"),
    /*8*/getToolInfo("Excel to PDF", "Convert Excel files (xls, xlsx) to PDF", xls_to_pdf, "/xls-to-pdf", "EXCEL2PDF", "excel2pdf"),
    /*9*/getToolInfo("Edit PDF", "Add text, images, shapes or freehand annotations to your PDF", edit_pdf, "/edit-pdf", "EDITPDF", "edit_pdf"),
    /*10*/getToolInfo("PDF to JPG", "Extract images from your PDF or save each page as a separate image", pdf_to_jpg, "/pdf-to-jpg", "PDF2JPG", "pdf2jpg"),
    /*11*/getToolInfo("JPG to PDF", "Transform image files to PDF", jpg_to_pdf, "/jpg-to-pdf", "JPG2PDF", "jpg2pdf"),
    /*12*/getToolInfo("Page Numbers", "Insert page numbers in PDF with ease", page_numbers, "/page-numbers", "PAGENUMBERS", "page_numbers"),
    /*13*/getToolInfo("Watermark", "Stamp an image or text over your PDF in seconds. Choose the typography, transparency and position", watermark, "/add-watermark", "ADDWATERMARK", "add_watermark"),
    /*14*/getToolInfo("Rotate PDF", "Rotate one or all pages in your PDF", rotate_pdf, "/rotate-pdf", "ROTATEPDF", "rotate_pdf"),
    /*15*/getToolInfo("HTML to PDF", "Convert webpages in HTML to PDF. Copy and paste the URL of the page you want and convert it to PDF", html_to_pdf, "/html-to-pdf", "HTML2PDF", "html2pdf"),
    /*16*/getToolInfo("Unlock PDF", "Remove password, encryption, and permission from your PDF", unlock_pdf, "/unlock-pdf", "UNLOCKPDF", "unlock_pdf"),
    /*17*/getToolInfo("Protect PDF", "Add a password and encrypt your PDF file", protect_pdf, "/protect-pdf", "PROTECTPDF", "protect_pdf"),
    /*18*/getToolInfo("Organize PDF", "Delete some pages or add blank pages to your PDF", organize_pdf, "/organize-pdf", "ORGANIZEPDF", "organize_pdf"),
    /*19*/getToolInfo("PDF to PDF/A", "Transform your PDF to PDF/A, the ISO-standardized version of PDF for long-term archiving", pdf_to_pdfA, "/pdf-to-pdfA", "PDF2PDFA", "pdf2pdfA"),
    /*20*/getToolInfo("Repair PDF", "Repair a damaged PDF and recover data from corrupted PDF", repair_pdf, "/repair-pdf", "REPAIRPDF", "repair_pdf"),
    /*21*/getToolInfo("Sign PDF", "Create your signature and sign your PDF", sign_pdf, "/sign-pdf", "SIGNPDF", "sign_pdf"),
];


export const toolsHelpText = [
    /*0*/"",
    /*1*/"",
    /*2*/"",
    /*3*/"",
    /*4*/"",
    /*5*/"",
    /*6*/"",
    /*7*/"",
    /*8*/"",
    /*9*/"",
    /*10*/"",
    /*11*/"",
    /*12*/"",
    /*13*/"",
    /*14*/"",
    /*15*/"",
    /*16*/"",
    /*17*/"",
    /*18*/"",
    /*19*/"",
    /*20*/"",
    /*21*/"",
];