import React from 'react';

import pdflogosvg from '../assets/svg/pdf-association-logo.svg';
import isologosvg from '../assets/svg/iso-logo.svg';
import ssllogosvg from '../assets/svg/ssl-encrypted-logo.svg';

import { useTranslation } from 'react-i18next'

const Medium = () => {
    const {t} = useTranslation();
    return (
        <div className="medium">
            <div className="block block--grey-admin">
                <div className="block__container">
                    <div className="block__header">
                            <div className="title1 text-center">{t("home.medium1")}</div>
                            <div className="subtitle text-center">{t("home.medium2")}</div>
                                    
                    </div>
                    <div className="block__body">
                        <div className="new-premium__logos">
                            <img style={{width:55, height:25, marginTop:1}} src={pdflogosvg} alt="PDF Association"/>
                            <img style={{width:67, height:30}} src={isologosvg} alt="ISO27001 certified"/>
                            <img style={{width:89, height:30}} src={ssllogosvg} alt="Secure connection Https"/>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="block block--black">
                <div className="block__container section">
                    <div className="block__header">
                        <div className="title1 text-left">Get more with Premium</div>
                        <div className="subtitle text-left">Complete projects faster with batch file processing, convert scanned documents with OCR and e-sign your business agreements.</div>
                    </div>
                    <div style={{float:'right', marginTop:'15px'}}>
                        <a href='/user/premium' className="btn btn-yellow btn--lg">Get Premium</a>
                    </div>
                    
                </div>            
            </div>*/}
        </div>
    );
}

export default Medium;