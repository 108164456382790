import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SplitOptionState {
  filelist: Array,
  mode: string,
  custom_ranges: Array,
  fixed_range: number,
  extract_all_pages: number,
  select_pages:Array,
  extract_pages_string: string,
  merge_check: number,
  max_page: number,
}

const initialState: SplitOptionState = {
  filelist: [],
  max_page: 0,
  mode: '',
  custom_ranges: [],
  fixed_range: 1,
  extract_all_pages: 1,
  select_pages:[],
  extract_pages_string: "",
  merge_check: 0,
}

export const splitSlice = createSlice({
  name: 'splitOption',
  initialState,
  reducers: {
    setFileList: (state, action) => {
      for(let i = 0; i < action.payload.length; i++){
        state.filelist.push({
          file: action.payload[i],
          url: URL.createObjectURL(action.payload[i]),
          remove: 0,
        });
      }
    },
    setMaxPage: (state, action:PayloadAction<number>) =>{
      state.max_page = action.payload;
    },
    setMode: (state, action:PayloadAction<string>) =>{
      state.mode = action.payload;
    },
    setCustomRanges: (state, action:PayloadAction<Array>) =>{
      state.custom_ranges[action.payload.id] = [action.payload.start, action.payload.end];
    },
    addCustomRange: (state, action) =>{
      state.custom_ranges.push(action.payload);
    },
    removeCustomRange: (state, action) => {
      state.custom_ranges.splice(action.payload, 1);
    },
    setFixedRange: (state, action:PayloadAction<number>) =>{
      state.fixed_range = action.payload;
    },
    setExtractAllPages: (state, action:PayloadAction<number>) =>{
      state.extract_all_pages = action.payload;
    },
    setSelectPages: (state, action:PayloadAction<Array>) =>{
      state.select_pages = action.payload;
      state.extract_pages_string = parseArray2PagesString(state.select_pages);
    },
    setPageChecked: (state, action) => {
      state.select_pages[action.payload.id] = action.payload.checked;
      state.extract_pages_string = parseArray2PagesString(state.select_pages);
    },
    setPageString: (state, action) => {
      state.extract_pages_string = action.payload;
      state.select_pages = parsePagesString2Array(state.extract_pages_string, state.max_page)
    },
    setMergeCheck: (state, action:PayloadAction<number>) =>{
      state.merge_check = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFileList,
  setMode, 
  setCustomRanges, addCustomRange, removeCustomRange,
  setFixedRange, 
  setExtractAllPages, 
  setSelectPages, setPageChecked, setPageString,
  setMergeCheck,
  setMaxPage } = splitSlice.actions;

export default splitSlice.reducer;

export const parseArray2PagesString = (arr) => {
  var ret = "";
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 1) {
        if (i === 0 || arr[i - 1] === 0) {
          ret = ret + (i + 1).toString();
        } else if (i === arr.length - 1 || arr[i + 1] === 0) {
          if (i === 1 || arr[i - 2] === 0) {
            ret = ret + ",";
          }
          ret = ret + (i + 1).toString() + ",";
        } else{
          ret = ret + "-";
        }
    }
    else if(ret !== ""){
      ret = ret + ",";
    }
  }


  while(1){
    ret = ret.replaceAll("--", "-");
    if (ret.indexOf("--") < 0) {
      break;
    }
  }

  while(1){
    ret = ret.replaceAll(",,", ",");
    if (ret.indexOf(",,") < 0) {
      break;
    }
  }

  if (ret.lastIndexOf(",") >= 0 && ret.lastIndexOf(",") === ret.length - 1) {
    ret = ret.slice(0, ret.length - 1);
  }

  return ret;
}

export const parsePagesString2Array = (str, cnt) => {
  var ret = [];
  for (let i = 0; i < cnt; i++) {
    ret.push(0);
  }

  var arr = str.split(",");
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].indexOf("-") < 0) {
      let idx = parseInt(arr[i]) - 1;
      if (idx >= 0 && idx < cnt) {
        ret[idx] = 1;  
      }
      
    } else {
      var subarr = arr[i].split("-");
      if(subarr.length !== 2) continue;
      for (let j = subarr[0]; j <= subarr[1]; j++) {
        let idx = j - 1;
        if (idx >= 0 && idx < cnt) {
          ret[idx] = 1;  
        }
      }
    }
  }

  return ret;
}