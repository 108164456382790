import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMethod } from './pdf2jpgSlice';
import {  Button, Layout } from 'antd';
import { Radio, Space } from 'antd';
import { SendOutlined, CheckCircleFilled } from '@ant-design/icons';
import page2jpg_svg from '../../../assets/svg/page2jpg.svg';
import extract_images_svg from '../../../assets/svg/extract_images.svg';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.pdf2jpgOption.filelist);
    const method = useSelector((state) => state.pdf2jpgOption.method);

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) 
            cnt++;
    }

    const onClickAction = () => {
        onAction(filelist);
    }

    const onChange = e => {
        dispatch(setMethod(parseInt(e.target.value)));
    };

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>PDF TO JPG</Header>

                <Content>
                    <Radio.Group onChange={onChange} value={method} size="small">
                        <Space direction="vertical">
                            <Radio.Button value={0}>
                                <div style={{float:'left', marginRight:15}}>
                                    <img src = {page2jpg_svg} style={{width:30}} alt=""></img>
                                </div>
                                <span style={{color:'#1890ff', fontSize:'20px', float:'left'}}>
                                    {t('tools.pdf2jpg.side_op1')}
                                </span>
                                <span style={{color:'black', marginTop:'5px', float:'left'}}>
                                    {t('tools.pdf2jpg.side_msg1')}
                                </span>
                                <div className={method===0?"radio-check":"hide-element"}>
                                    <CheckCircleFilled />
                                </div>
                            </Radio.Button>

                            <Radio.Button value={1}>
                                <div style={{float:'left', marginRight:15}}>
                                    <img src = {extract_images_svg} style={{width:30}} alt=""></img>
                                </div>
                                <span style={{color:'#1890ff', fontSize:'20px', float:'left'}}>
                                    {t('tools.pdf2jpg.side_op2')}
                                </span>
                                <span style={{color:'black', marginTop:'5px', float:'left'}}>
                                    {t('tools.pdf2jpg.side_msg2')}
                                </span>
                                <div className={method===1?"radio-check":"hide-element"}>
                                    <CheckCircleFilled />
                                </div>
                            </Radio.Button>
                        </Space>
                    </Radio.Group>
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt > 0 ? false:true}>
                      {t('tools.pdf2jpg.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;