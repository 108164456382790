import React  from 'react';

import { Col, Row } from 'antd';

import { toolsListInfo } from '../../utils/toolsInfo';

//lang test//
import { useTranslation } from 'react-i18next';


///
const Tools = () => {
	const {t} = useTranslation();

	return (
		<div className="footer-tools">
		<div style={{margin:'0 auto', maxWidth:'65%'}}>
			<h1 style={{color:'#404040', fontWeight:'600', fontSize:48}}>
				{t('home.tools_doc.title')}
			</h1>
			<h4 style={{color:'#404040', fontWeight:'450', fontSize:22}}>
				{t('home.tools_doc.desc')}
			</h4>
			
			{/* ---------------------------------- ORGANIZE ---------------------------------- */}
			<div className='tool-group'>
				<h1>{t('home.tools_doc.group1')}</h1>
				{/*  ------ Merge ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[0].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[0].title}</div>						
						<p>
							{t('home.tools_doc.mergetxt1')}
							<br/>
							{t('home.tools_doc.mergetxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Split ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[1].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[1].title}</div>						
						<p>
							{t('home.tools_doc.splittxt1')}
							<br/>
							{t('home.tools_doc.splittxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Organize ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[18].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[18].title}</div>						
						<p>
							{t('home.tools_doc.organtxt1')}
							<br/>
							{t('home.tools_doc.organtxt2')}
						</p>
					</Col>							
				</Row>
			</div>


			{/* ---------------------------------- OPTIMIZE ---------------------------------- */}
			<div className='tool-group'>
				<h1>{t('home.tools_doc.group2')}</h1>
				{/*  ------ Compress ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[2].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[2].title}</div>						
						<p>
							{t('home.tools_doc.comptxt1')}
							<br/>
							{t('home.tools_doc.comptxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Repair ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[20].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[20].title}</div>						
						<p>
							{t('home.tools_doc.repairtxt1')}
							<br/>
							{t('home.tools_doc.repairtxt2')}
						</p>
					</Col>							
				</Row>
			</div>

			{/* ---------------------------------- CONVERT TO PDF ---------------------------------- */}
			<div className='tool-group'>
				<h1>{t('home.tools_doc.group3')}</h1>
				{/*  ------ JPG TO PDF ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[11].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[11].title}</div>						
						<p>
							{t('home.tools_doc.jpg2txt1')}
							<br/>
							{t('home.tools_doc.jpg2txt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ WORD TO PDF ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[6].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[6].title}</div>						
						<p>
							{t('home.tools_doc.word2txt1')}
							<br/>
							{t('home.tools_doc.word2txt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ PPT TO PDF ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[7].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[7].title}</div>						
						<p>
							{t('home.tools_doc.ppt2txt1')}
							<br/>
							{t('home.tools_doc.ppt2txt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ EXCEL TO PDF ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[8].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[8].title}</div>						
						<p>
							{t('home.tools_doc.excel2txt1')}
							<br/>
							{t('home.tools_doc.excel2txt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ HTML TO PDF ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[15].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[15].title}</div>						
						<p>
							{t('home.tools_doc.html2txt1')}
							<br/>
							{t('home.tools_doc.html2txt2')}
							<br/>
							{t('home.tools_doc.html2txt3')}
						</p>
					</Col>							
				</Row>
			</div>

			{/* ---------------------------------- CONVERT FROM PDF ---------------------------------- */}
			<div className='tool-group'>
				<h1>{t('home.tools_doc.group4')}</h1>
				{/*  ------ PDF TO JPG ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[10].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[10].title}</div>						
						<p>
							{t('home.tools_doc.tojpgtxt1')}
							<br/>
							{t('home.tools_doc.tojpgtxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ PDF TO WORD ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[3].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[3].title}</div>						
						<p>
							{t('home.tools_doc.towordtxt1')}
							<br/>
							{t('home.tools_doc.towordtxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ PDF TO PPT ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[4].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[4].title}</div>						
						<p>
							{t('home.tools_doc.toppttxt1')}
							<br/>
							{t('home.tools_doc.toppttxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ PDF TO EXCEL ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[5].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[5].title}</div>						
						<p>
							{t('home.tools_doc.toexceltxt1')}
							<br/>
							{t('home.tools_doc.toexceltxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ PDF TO PDF/A ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[19].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[19].title}</div>						
						<p>
							{t('home.tools_doc.topdfAtxt1')}
							<br/>
							{t('home.tools_doc.topdfAtxt2')}
						</p>

					</Col>							
				</Row>

			</div>


			{/* ---------------------------------- EDIT PDF ---------------------------------- */}
			<div className='tool-group'>
				<h1>{t('home.tools_doc.group5')}</h1>
				{/*  ------ Rotate ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[14].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[14].title}</div>						
						<p>
							{t('home.tools_doc.rotatetxt1')}
							<br/>
							{t('home.tools_doc.rotatetxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Page Numbers ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[12].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[12].title}</div>						
						<p>
							{t('home.tools_doc.pagenumberstxt1')}
							<br/>
							{t('home.tools_doc.pagenumberstxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Watermark ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[13].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[13].title}</div>						
						<p>
							{t('home.tools_doc.watermarktxt1')}
							<br/>
							{t('home.tools_doc.watermarktxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Edit ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[9].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[9].title}</div>						
						<p>
							{t('home.tools_doc.edittxt1')}
							<br/>
							{t('home.tools_doc.edittxt2')}
							<br/>
							{t('home.tools_doc.edittxt3')}
							<br/>
							{t('home.tools_doc.edittxt4')}
						</p>
					</Col>							
				</Row>	
			</div>

			{/* ---------------------------------- PDF SECURITY ---------------------------------- */}
			<div className='tool-group'>
				<h1>{t('home.tools_doc.group6')}</h1>
				{/*  ------ Unlock ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[16].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[16].title}</div>						
						<p>
							{t('home.tools_doc.unlocktxt1')}
							<br/>
							{t('home.tools_doc.unlocktxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Protect ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[17].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[17].title}</div>						
						<p>
							{t('home.tools_doc.protecttxt1')}
							<br/>
							{t('home.tools_doc.protecttxt2')}
						</p>
					</Col>							
				</Row>

				{/*  ------ Sign ------  */}
				<Row justify="space-around" align="top">
					<Col span={1}>
						<img src = {toolsListInfo[21].svg} alt="" style={{width:'80%'}}/>
					</Col>
					
					<Col span={22}>
						<div className='tool-title'>{toolsListInfo[21].title}</div>						
						<p>
							{t('home.tools_doc.signtxt1')}
							<br/>
							{t('home.tools_doc.signtxt2')}
							<br/>
							{t('home.tools_doc.signtxt3')}
							<br/>
							{t('home.tools_doc.signtxt4')}
						</p>
					</Col>							
				</Row>
			</div>
		</div>
		</div>
	);
}

export default Tools;