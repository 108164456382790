import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setFileOptionList, setTool } from './pageNumbersSlice';

import SelectFiles from '../SelectFiles';
import WorkSpacePageNumbers from './WorkSpacePageNumbers';
import UploadingFile from '../UploadingFile';
import DownloadFile from '../DownloadFile';
import { toolsListInfo } from '../../../utils/toolsInfo';
import { useTranslation } from 'react-i18next';

var links = [];
const PageNumbers = () => {
    const {t} = useTranslation();
    //const [file, setFile] = useState(null);
    const [proc, setProc] = useState(0);

    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.pageNumbersOption.filelist);
    const pageMode = useSelector((state) => state.pageNumbersOption.pageMode);
    const firstCover = useSelector((state) => state.pageNumbersOption.firstCover);
    const position = useSelector((state) => state.pageNumbersOption.position);
    const margin = useSelector((state) => state.pageNumbersOption.margin);
    const firstnumber = useSelector((state) => state.pageNumbersOption.firstnumber);
    const singlestart = useSelector((state) => state.pageNumbersOption.singlestart);
    const singleend = useSelector((state) => state.pageNumbersOption.singleend);
    const multiplestart = useSelector((state) => state.pageNumbersOption.multiplestart);
    const multipleend = useSelector((state) => state.pageNumbersOption.multipleend);
    const numbertype = useSelector((state) => state.pageNumbersOption.numbertype);
    const customtext = useSelector((state) => state.pageNumbersOption.customtext);
    const fontname = useSelector((state) => state.pageNumbersOption.fontname);
    const fontsize = useSelector((state) => state.pageNumbersOption.fontsize);
    const bold = useSelector((state) => state.pageNumbersOption.bold);
    const italic = useSelector((state) => state.pageNumbersOption.italic);
    const underline = useSelector((state) => state.pageNumbersOption.underline);
    const fontcolor = useSelector((state) => state.pageNumbersOption.fontcolor);

    //console.log("received file: ", filelist);
    const id = 12;

    const goProcFunction = (files) => {        
        //setFile(files);
        var initFileList = [];
        for (var i = 0; i < files.length; i++) {
            initFileList.push(files[i]);
        }

        dispatch(setFileOptionList(initFileList));

        setProc(1);
    }

    const goAction = () => {
        //rotate action//
        setProc(2);        
    }

    
    const goDownload = (downlinks) => {
        //rotate download action//
        links = downlinks;
        setProc(3);
    }


    /*****************rendering*****************/
    var element = '';
    if (proc === 0) {//select file area
        element = (
            <div className="tool">
                <SelectFiles id={id} proc={goProcFunction} multiple={true} btn_text={t('tools.pageNumbers.Select')}fileTypes = {["PDF"]}/>
            </div>
        );
    } else if(proc === 1) {//work space
        element = (
            <div className="tool">
                <WorkSpacePageNumbers goAction={goAction}/>
            </div>
        );
    } else if (proc === 2) {//action space
        //option payload//
        var _files = [];
        for (var i = 0; i < filelist.length; i++) {
            _files.push(filelist[i]);
        }

        //console.log("send file:", _files);
        var text = '{n}';
        if (numbertype === 1) text = 'Page {n}';
        else if (numbertype === 2) text = 'Page {n} of {p}';
        else if (numbertype === 3){
            var temp = customtext.replace('{n}', '{n}');
            text = temp.replace('{p}', '{p}');
        }

        var text_format = ['', ''];
        if (underline === 1)
        {
            text_format[0] = '<u><font ';
            text_format[1] = '</font></u>';
        }
        else
        {
            text_format[0] = '<font ';
            text_format[1] = '</font>';
        }

        var fontName = ['Arial', 'Calibri','Cambria','Corbel','Comic Sans MS','Consolas','Constantia','Courie New','Georgia','Palatino Linotype','Sitka Heading','Times New Roman','Verdana']
        text_format[0] = text_format[0] + 'size=\"' + fontsize + '\" ';
        text_format[0] = text_format[0] + 'color=\"' + fontcolor + '\" ';
        text_format[0] = text_format[0] + 'face=\"' + fontName[fontname];// + '\">'
        if (bold === 1) text_format[0] = text_format[0] + 'b';
        if (italic === 1) text_format[0] = text_format[0] + 'i';
        text_format[0] = text_format[0] + '\">';
        var option = {
            pagemode: pageMode,
            firstcover: firstCover,
            position: position,
            margin: margin,
            firstnumber: firstnumber,
            startpage: singlestart,
            endpage: singleend,
            excludestart: multiplestart,
            excludeend: multipleend,
            fontsize: fontsize,
            text: text,
            text_format: text_format,
        }

        //console.log(option);
        //uploading//
        element = (
            <div className="tool">
                <UploadingFile id={id} 
                    api={toolsListInfo[id].api}
                    goDownload={ goDownload } 
                    text={t('tools.pageNumbers.Addingpagenumbers')}
                    tool_header={toolsListInfo[id].tool_header}
                    filelist={_files}
                    option={option}/>
            </div>
        );
    } else if (proc === 3) {//download file
        element = (
            <div>
                <DownloadFile 
                    downlinks={links} 
                    backurl={toolsListInfo[id].link} 
                    e_mode={links === '' ? '' : 'download'}/>
            </div>
        );
    }

    return (
        <div>{element}</div>
    );
}

export default PageNumbers;