import React, { useState } from 'react';

import { useDispatch } from 'react-redux'
import { setFileOptionList, setTool } from './pageNumbersSlice';

import WorkAreaPageNumbers from './WorkAreaPageNumbers';
import WorkSidePageNumbers from './WorkSidePageNumbers';
import { Scrollbars } from 'react-custom-scrollbars';


const WorkSpacePageNumbers = ({ goAction }) => {
    const dispatch = useDispatch();
    const onAction = (filelist) => {        

        dispatch(setTool("PAGENUMBERS"));
        goAction();
    }
    const [scroll, setScroll] = useState(null);
    const onScrollFrame = (value) => {
        //console.log('value: ', value);
        setScroll(value);
    }
    /***********rendering**********/
    return (
        <>
            <Scrollbars style={{ width: '100%', height: '100%' }} onScrollFrame={onScrollFrame}>
                <WorkAreaPageNumbers scroll={scroll}/>
            </Scrollbars>
            <WorkSidePageNumbers onAction={onAction}/>
        </>
    );
}

export default WorkSpacePageNumbers;