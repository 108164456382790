import React from 'react';



import WorkArea from './WorkArea';
import WorkSide from './WorkSide';
import { Scrollbars } from 'react-custom-scrollbars';

var inited = 0;
const WorkSpace = ({ files, goAction, filetypes }) => {
    
    

    const onAction = (filelist) => {        
        goAction();
    }

    /***********rendering**********/
    return (
        <>
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <WorkArea filetypes={filetypes}/>
            </Scrollbars>
            <WorkSide onAction={onAction}/>
        </>
    );
}

export default WorkSpace;