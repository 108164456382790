import React, { useRef, useState, useEffect  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, 
  setPageRect, 
  setPageChanged, 
  updateSizeElement, 
  setSeletedElement,
  setTextEditable,
  updateElementText,
  removeElement,
  setPlacetoPages,
  updateElementPos,
  unactiveSeletedElement,
 } from '../signSlice';

import { Page } from 'react-pdf/dist/esm/entry.webpack';
import { Scrollbars } from 'react-custom-scrollbars';

import ResizableContent from './ResizableContent';
//import DrawPenContent, { generatePathFromVetices } from './DrawPenContent';

import { Input } from 'antd';

const { TextArea } = Input;

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 1,
    backgroundColor: '#1890ff',   
    width : 4,
    zIndex: 10,
    cursor: 'pointer',
  };
  return (<div style={{ ...style, ...thumbStyle }} {...props} />);
};


var old_scale = 0;
const EachPage = ({ pagenum, scale, elements}) => {
  const pageRef = useRef();
  const dispatch = useDispatch();

  const page_rect = useSelector((state: RootState) => state.signOption.page_rect);
  const cur_page = useSelector((state: RootState) => state.signOption.cur_page);
  const added_elements = useSelector((state: RootState) => state.signOption.added_elements);
  const enable_draw = useSelector((state: RootState) => state.signOption.enable_draw);

  useEffect(() => {
    //component mount//
    if(pagenum === 1){
      setTimeout(() => {
        const payload = {
          left: pageRef.current.offsetLeft,
          top: pageRef.current.offsetTop,
          width: pageRef.current.offsetWidth,
          height: pageRef.current.offsetHeight,
        };
        //console.log(payload)
        dispatch(setPageRect(payload));
      }, 200);
    }

    return () => {
      //component will unmount
    };
}, []);

  const clickedElement = (element_id) => {
    var idx = 0;
    for (let i = 0; i < added_elements[pagenum].length; i++) {
      if(element_id === added_elements[pagenum][i].id){
        idx = i;
        break;
      }
    }
    const payload = {
      page: pagenum,
      idx: idx
    }

    dispatch(setSeletedElement(payload));
  }

  const doubleClickTextElement = (element_id) => {
    var idx = 0;
    for (let i = 0; i < added_elements[pagenum].length; i++) {
      if(element_id === added_elements[pagenum][i].id){
        idx = i;
        break;
      }
    }
    const payload = {
      page: pagenum,
      idx: idx,
      editable: 1,
    }

    dispatch(setTextEditable(payload));
  }

  const resizeElement = (element_id, style) => {
    const { top, left, width, height } = style;
    
    var idx = 0;
    for (let i = 0; i < added_elements[pagenum].length; i++) {
      if(element_id === added_elements[pagenum][i].id){
        idx = i;
        break;
      }
    }

    const payload = {
      page: pagenum,
      idx: idx,
      pos:{x: left, y: top},
      size:{w: width, h: height},
    }
    dispatch(updateSizeElement(payload));
  }
  
  const deleteElement = (element_id) => {
    
    /*var idx = 0;
    for (let i = 0; i < added_elements[pagenum].length; i++) {
      if(element_id === added_elements[pagenum][i].id){
        idx = i;
        break;
      }
    }*/
    //console.log("removeElement: [%s][%d][%d]", element_id, pagenum, idx);
    const payload = {
        /*page: pagenum,
        idx: idx,*/
        idx: element_id,
    }
    dispatch(removeElement(payload));
  }

  const placeToElement = (element_id, selected) => {
    //console.log("element[%s], selected:[%d]", element_id, selected);
    dispatch(unactiveSeletedElement());

    if (selected < 2) return;

    var idx = 0;
    for (let i = 0; i < added_elements[pagenum].length; i++) {
      if(element_id === added_elements[pagenum][i].id){
        idx = i;
        break;
      }
    }
    //console.log("position: (%d, %d)", added_elements[pagenum][idx].pos.x, added_elements[pagenum][idx].pos.y);
    //console.log("removeElement: [%s][%d][%d]", element_id, pagenum, idx);
    const payload = {
        page: pagenum,
        idx: idx,
        select: selected,
        posx:page_rect.left,
    }
    dispatch(setPlacetoPages(payload));
  }
  
  const dragEnd = (element_id, _left, _top) => {
    //console.log("dragend received [%s] t(%d) l(%d)", element_id, _top, _left);
    const payload = {
      idx: element_id,
      left: _left,
      top: _top,
    }
    //console.log("drag pos(%d, %d)", _left, _top);
    dispatch(updateElementPos(payload));
  }
  const childs = elements.map((element) => {
    var subcontent = '';
    //console.log("[%d] element:", pagenum, element);
    if(element.remove === 0)  {
      //console.log("print element:", element);
        if (element.type === 'text') {
          const txtstyle = {
              color: element.pen_color, 
              backgroundColor: element.fill_color, 
              textAlign: element.align, 
              opacity: element.opacity,
              fontFamily: element.font,
              fontSize: element.fontSize,
              fontWeight: element.bold?'700':'400',
              fontStyle: element.italic?'italic': '',
              textDecoration: element.underline?'underline':'',
              zIndex: 1,
          };

          //console.log("text style: ", txtstyle);
          const onChangeText = (e) => {
            var idx = 0;
            for (let i = 0; i < added_elements[pagenum].length; i++) {
              if(element.id === added_elements[pagenum][i].id){
                idx = i;
                break;
              }
            }
            const payload = {
              page: pagenum,
              idx: idx,
              text: e.target.value,              
            }
            dispatch(updateElementText(payload));
          }

          if (element.editable) {
              subcontent = (
                  <TextArea
                    value={element.text}
                    autoSize={{
                        minRows: 1,
                        maxRows: 100,
                    }}
                    onChange={onChangeText}
                    style={txtstyle}
                  />
              );
          } else {
              
              var txtarray = element.text.split('\n');
              var txt_element = [];
              for(let i = 0; i < txtarray.length; i++){
                txt_element.push(
                  <label key={'label' + element.id + i.toString()}>
                    {i===0? '':(<br/>)}
                    {txtarray[i]}
                  </label>
                );
              }
              subcontent = (
                  <div style={txtstyle}>
                      {txt_element}
                  </div>
              );
          }          
        } else if(element.type === 'img'){//URL.createObjectURL(
          subcontent = (
              <img 
                height='100%'
                width='100%' 
                alt='img'
                src={element.imgfile} 
                draggable={false}
                style={{
                  opacity: element.opacity,
                  rotate: element.rotate.toString() + 'deg',
                  border: element.border
                }}
              />
          );
        }
        return(
          <ResizableContent 
			      key={'Resizable'+element.id+pagenum.toString()}
            left={element.pos.x} 
            top={element.pos.y} 
            width={element.size.w} 
            height={element.size.h} 
            rotateAngle={element.rotate}
            page_rect={page_rect}
            scale={scale}
            id={element.id}
            clickedElement={clickedElement}
            doubleClickTextElement={doubleClickTextElement}
            resizeElement={resizeElement}
            removeElement={deleteElement}
            placetoElement={placeToElement}
            dragend={dragEnd}
            type={element.type}
            active={element.active}>
            
            {subcontent}
          </ResizableContent>
        );
    }
  });

  //console.log("eachpage cur(%d) pagenum(%d)", cur_page, pagenum);
  var one_page = '';
  if(pagenum === 1 || pagenum === cur_page || pagenum === cur_page + 1 || pagenum === cur_page - 1){
    one_page = (
      <Page pageNumber={pagenum} canvasRef={pageRef} loading="" width={850}>
        {childs}
      </Page>
    );
  }

  //rendering//
  return(
    <div style={{marginBottom:20, marginTop:20}}>
      <div className='one_page' style={{width: page_rect.width?page_rect.width:850, height: page_rect.height}}>
        {one_page}
      </div>
    </div>
  );
}

//====Sleep Function=====//
function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}






/**********************************************************/
var posX = 0;
var posY = 0;

const PageEditView = () => {

  const [dragging, setDragging] = useState(0);

  const dispatch = useDispatch();
  const cur_page = useSelector((state: RootState) => state.signOption.cur_page);
  const viewscale = useSelector((state: RootState) => state.signOption.viewscale);
  const max_page = useSelector((state: RootState) => state.signOption.max_page); 
  const page_rect = useSelector((state: RootState) => state.signOption.page_rect);
  const page_changed = useSelector((state: RootState) => state.signOption.page_changed);
  const added_elements = useSelector((state: RootState) => state.signOption.added_elements);

  //console.log("Edit cur_page: ", cur_page);
  const workRef = useRef();
  const onScrollFrame = (values) => {
    //get current page//
    var pagenumber = Math.floor(Math.abs(values.scrollTop + 300) / (page_rect.height + 20)) + 1;
    if(pagenumber !== cur_page)
    {
      //console.log("scroll pagenumber: ", pagenumber);
      dispatch(setCurrentPage({page: pagenumber, changed:0}));
    }  
  }

  const smoothTop = async (top) => {
    workRef.current.scrollTop(top);
    return;
    const { scrollTop } = workRef.current.getValues();
    const _top_interval = (top - scrollTop);
    for (let i = 0; i < 50; i++){
      workRef.current.scrollTop(scrollTop + (_top_interval / 50) * (i + 1));  
      await sleep(3);
    }
  }

  /////////////////DRAG//////////////////////
  const onDragStartHandler = (e) => {
    const img = new Image();
    e.dataTransfer.setDragImage(img, 0, 0);

    //console.log('drag start');
    posX = e.clientX;
    posY = e.clientY;

    setDragging(1);
  }

  const onDragHandler = (e) => { 
    if(dragging === 0)    return;
    //view movement//
    const { scrollTop, scrollLeft } = workRef.current.getValues();
    workRef.current.scrollTop(scrollTop - (e.clientY - posY));
    workRef.current.scrollLeft(scrollLeft + (e.clientX - posX));

    //console.log('on drag', scrollTop, scrollTop + (e.clientX - posX));

    //updata pos info//
    posX = e.clientX;
    posY = e.clientY;
  }

  const onDragEndHandler = (e) => {
    if(dragging === 0)    return;
    //console.log('drag end');
    const { scrollTop, scrollLeft } = workRef.current.getValues();
    workRef.current.scrollTop(scrollTop - (e.clientY - posY));
    workRef.current.scrollLeft(scrollLeft + (e.clientX - posX));

    setDragging(0);
  }

  const onClickNotSelect = (e) => {

    if(e.target.className === 'react-pdf__Page__canvas'){
      //console.log("unactived");
      dispatch(unactiveSeletedElement());
    }
  }



  //////////////SET CURRENT PAGE VIEW///////////////
  var elements = [];
  var divcls = 'editpdf__editpage';

  useEffect(() => {    
    if(page_changed === 1 && workRef.current){
      dispatch(setPageChanged(0));
      //const { scrollHeight } = workRef.current.getValues();
      var top = (cur_page - 1) * (page_rect.height + 20) - 20;
      smoothTop(top);      
    }
  });

  for (let i = 0; i < max_page; i++){
    elements.push(<EachPage key={'eachpage'+i.toString()} 
    pagenum={i+1} 
    scale={viewscale} 
    elements={added_elements[i+1]}/>);
  }
    
  if (dragging) {
    divcls = "editpdf__editpage dragging";
  }

  ///////////////////rendering////////////////
  //const html = 'This is test messag with some random wor<span style="background-color: rgb(175, 81, 81);"><font color="#7ed321">ds jakjkj akja kjjkk kjak jkak kjkjjk</font></span> jka kja kkj kjkj ak kja';
  return (
    <div className={divcls} onClick={onClickNotSelect}>
      <Scrollbars 
        style={{ width: '100%', height: '100%' }} 
        renderThumbVertical={renderThumb}  
        renderThumbHorizontal={renderThumb}  
        ref={workRef} 
        onScrollFrame={onScrollFrame}>
        <div>
          {elements}
        </div>
      </Scrollbars>
    </div>
  );
}

export default PageEditView;
