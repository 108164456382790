import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface InitOptionState {
  lang: String,
}

const initialState: InitOptionState = {
  lang: 'en',
}

export const commonSlice = createSlice({
  name: 'commonOption',
  initialState,
  reducers: {
    setLang: (state, action) =>{
      state.method = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLang } = commonSlice.actions

export default commonSlice.reducer