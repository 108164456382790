import React, { useState } from 'react';
import { axiosInstance } from '../../utils/axiosInstance';
import Footer from '../../components/Footer';
import Medium from '../../components/Medium';

import { Button, Tooltip, message, Popconfirm } from 'antd';
import pdfsvg from '../../assets/svg/pdfmade.svg';

import { FaArrowLeft, FaTrash, FaRegCopy, FaHome } from "react-icons/fa";
import { DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const DownloadFile = ({ downlinks, backurl, e_mode }) => {
	const {t} = useTranslation();
	const [mode, setMode] = useState(e_mode);
	const [visible, setVisible] = useState(false);
	var top_text = '';

    //const [file, setFile] = useState(null);
    //console.log('download', downlinks, backurl, e_mode, mode);

    const copyToClipboard = () => {
	   	/* Copy the text inside the text field */
	  	navigator.clipboard.writeText(downlinks);

	  	//message//
	  	message.success(t('tools.download.msg1'));
	}

	const deleteFile = () => {
		//get filename//
		var foldername = downlinks.split('/');
		foldername = foldername[foldername.length - 2];

		axiosInstance.post('/delete', {dir: foldername})
		.then((res) => {
			console.log(res);
			//window.location.href = '/';
		})
		.error((error) => {
			console.log(error);
			//window.location.href = '/';
		});
	}

	function confirmDelete(e) {
		setVisible(false);
		setMode('');
		deleteFile();
	}

	function cancel(e) {
		setVisible(false);
	}

	const showPopconfirm = () => {
	    setVisible(true);
	};

	var element_div = '';
	var backtxt = backurl.replaceAll('/', '');
	if (mode === 'download') {
		//top_text = downlinks;
		top_text = (
			<>
				<h4>
					{downlinks}
				</h4>
				<label style={{fontSize:20, color:'#ff3100'}}>{t('tools.download.msg3')}</label>
			</>
		);	
		element_div = (
				<div className="btn-group">
            		<Tooltip title={t('tools.download.backto')} placement="top">
            			<a href={backurl} className="black-btn hover-black-btn shadow-btn">
                        <Button 
                            type="primary" 
                            shape="circle" 
                            icon={<FaArrowLeft style={{ fontSize: '22px', marginTop:3 }}/>} 
                            size="large"/>
                        </a>
                    </Tooltip>

                	<a className="uploader__btn" style={{margin:30}} href={downlinks} download>
                		<DownloadOutlined style={{marginRight:15}}/>
                		{t('tools.download.downfile')}
                	</a>

                	<div className="hover-black-btn shadow-btn" style={{maxWidth:40}}>
                		<div style={{marginBottom:10}}>
	                	<Tooltip title={t('tools.download.copylink')} placement="top">
	                        <Button 
	                            type="primary" 
	                            shape="circle" 
	                            icon={<FaRegCopy style={{ fontSize: '14px' }}/>} 
	                            size="medium" 
	                            onClick = {copyToClipboard}/>
	                    </Tooltip>
	                    </div>

	                    <div>


	                    <Popconfirm
						    title={t('tools.download.msg2')}
						    visible={visible}
						    onConfirm={confirmDelete}
						    onCancel={cancel}
						    okText={t('tools.download.yes')}
						    cancelText={t('tools.download.no')}
						>
		                    <Tooltip title={t('tools.download.delete')} placement="bottom">
		                        <Button 
		                            type="primary" 
		                            shape="circle" 
		                            icon={<FaTrash style={{ fontSize: '14px' }}/>} 
		                            size="medium"
		                            onClick={showPopconfirm}/>
		                    </Tooltip>
	                    </Popconfirm>
	                    </div>
                    </div>
            	</div>
		);
	} else {
		top_text = (<h1>{t('tools.download.failed')}</h1>);			
		element_div = (
				<div className="btn-group">
            		<Tooltip title={t('tools.download.backto')} placement="top">
            			<a href={`${backurl}`} className="black-btn hover-black-btn shadow-btn">
                        <Button 
                            type="primary" 
                            shape="circle" 
                            icon={<FaArrowLeft style={{ fontSize: '22px', marginTop:3 }}/>} 
                            size="large"/>
                        </a>
                    </Tooltip>

                	<a className="uploader__btn" style={{margin:30}} href='/'>
                		<FaHome style={{marginRight:15}}/>
                		{t('tools.download.gohome')}
                	</a>
            	</div>
		);
	}


    return (
        <div className="download-files">
            <div className="down-action">
                <img src={pdfsvg} alt="PDFMade" style={{width:'200px', height:'100px'}}/>
                <div style={{wordWrap:'break-word'}}>
                	{top_text}
                </div>
                {element_div}

                <div className="card">
                	<h2>{t('tools.download.txt1')}</h2>
                	<p style={{fontSize:16}}>{t('tools.download.txt2')}</p>
                	<ul className="share__buttons">
		                <li key={'share__buttons__item--facebook'}><a className="share__buttons__item share__buttons__item--facebook">
		                    <svg aria-hidden="true" width="12" height="12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229" className=""></path></svg>
		                    Facebook
		                </a></li>

			            <li key={'share__buttons__item--twitter'}><a className="share__buttons__item share__buttons__item--twitter">
			                    <svg aria-hidden="true" width="12" height="12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
			                    Twitter
			            </a></li>

			            <li key={'share__buttons__item--linkedin'}><a className="share__buttons__item share__buttons__item--linkedin">
			                    <svg aria-hidden="true" width="12" height="12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.3 448H7.4V148.9h92.9V448zM53.8 108.1C24.1 108.1 0 83.5 0 53.8S24.1 0 53.8 0s53.8 24.1 53.8 53.8-24.1 54.3-53.8 54.3zM448 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448h-.1z"></path></svg>
			                    LinkedIn
			            </a></li>
		            </ul>
                </div>
            </div>

            <Medium/>
            <Footer/>
        </div>
    );
}

export default DownloadFile;