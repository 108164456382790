import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Scrollbars } from 'react-custom-scrollbars';
import {  Button, Layout, Select, Checkbox} from 'antd';
import { SendOutlined} from '@ant-design/icons';
import { IoTabletLandscapeOutline, IoTabletPortraitOutline } from 'react-icons/io5';

import { setPageOption, setMarginOption, setOnefileOption, setDirectionOption} from './jpg2PdfSlice';
import nomarginsvg from '../../../assets/svg/jpg_to_pdf_margin1.svg';
import smallmarginsvg from '../../../assets/svg/jpg_to_pdf_margin2.svg';
import bigmarginsvg from '../../../assets/svg/jpg_to_pdf_margin3.svg';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;
const {Option} = Select;

const WorkSideJpg2 = ({onAction}) => {
    const {t} = useTranslation();
    const filelist = useSelector((state) => state.jpg2pdfOption.filelist);
    const dispatch = useDispatch();
    
    const [selmode, setSelMode] = useState(0);
    const [selsize, setSelSize] = useState(0);
    const [selone, setSelOne] = useState(0);

    const onClickAction = () => {
        onAction(filelist);
    }

    const onPortrait = () => {
        setSelMode(0);
        dispatch(setDirectionOption(0));
    }
    const onLandScape = () => {
        setSelMode(1);
        dispatch(setDirectionOption(1));
    }
    
    const onNoMargin = () => {
        setSelSize(0)
        dispatch(setMarginOption(0));
    }
    const onSmall = () => {
        setSelSize(1)
        dispatch(setMarginOption(1));
    }
    const onBig = () => {
        setSelSize(2)
        dispatch(setMarginOption(2));
    }
    
    const onSelectType = (e) => {
       
        dispatch(setPageOption(e));
    }

    const onCheckType = (e) => {
        var nsel = selone === 0? 1: 0;
        setSelOne(nsel);
        dispatch(setOnefileOption(nsel));
    }
    
    var cnt = 0;
    for (var i = 0; i < filelist.length; i ++)
    {
        if (filelist[i].remove === 0) cnt ++;
    }
    //console.log("side direction:", selmode);
    return (
        <div className="tool__sidebar">
            <Layout >
                <Header>IMAGE TO PDF</Header>
                <Content>
                    <div style={{height:'calc(100vh - 152px - 60px)'}}>
                        <Scrollbars style={{ width: '100%', height: '100%' }}>
                            <Content>
                                <div>
                                    <p>
                                    {t('tools.jpg2pdf.PageOrientation')}
                                    </p>
                                </div>
                            </Content>
                            
							<div className="side__btns__container">
                                <div className={selmode === 0 ? "side__btns1_select":"side__btns1"}  onClick={onPortrait}>
                                    <div className="side__btns__items">
                                        <div>
                                        <IoTabletPortraitOutline style={{ fontSize: '40px', color: 'white', marginLeft:10, marginRight:10 }}/>
                                        </div>
                                        <div className="side__btns__item_text">
                                            {t('tools.jpg2pdf.Portrait')}
                                        </div>
                                    </div>
                                </div>
                                <div className={selmode === 1 ? "side__btns1_select":"side__btns1"}  onClick={onLandScape}>
                                    <div className="side__btns__items">
										<div>
											<IoTabletLandscapeOutline style={{ fontSize: '40px', color: 'white', marginLeft:10, marginRight:10 }}/>
                                        </div>
                                        <div  className="side__btns__item_text">
                                            {t('tools.jpg2pdf.LandScape')}
                                        </div>
                                    </div>
                                </div>
                            </div>
							
                            <Content>
                                <div>
                                    <p>
                                    {t('tools.jpg2pdf.PAGESIZE')}
                                    </p>
                                </div>
                            </Content>
                            <Content>
                                <div>
                                    <Select defaultValue={0} style={{width:'88%', textAlign:'left'}} onChange={onSelectType}>
                                        <Option value={0}>{t('tools.jpg2pdf.Fit')}</Option>
                                        <Option value={1}>A4 (297x210 mm) </Option>
                                        <Option value={2}>US Letter (215x279.4 mm) </Option>
                                    </Select>
                                </div>
                            </Content>
                            <Content>
                                <div>
                                    <p>
                                    {t('tools.jpg2pdf.MARGIN')}
                                    </p>
                                </div>
                            </Content>
                            
                            <div className="side__btns__container">
                                <div className={selsize === 0 ? "side__btns1_select":"side__btns1"}  onClick={onNoMargin}>
                                    <div className="side__btns__items">
                                        <div>
                                        <img src={nomarginsvg} alt="nomargin" style={{width:'50%', height:'50%'}}/>
                                        </div>
                                        <div className="side__btns__item_text">
                                            {t('tools.html2pdf.NoMargin')}
                                        </div>
                                    </div>
                                </div>
                                <div className={selsize === 1 ? "side__btns1_select":"side__btns1"}  onClick={onSmall}>
                                    <div className="side__btns__items">
                                    <div>
                                        <img src={smallmarginsvg} alt="small margin" style={{width:'50%', height:'50%'}}/>
                                        </div>
                                        <div  className="side__btns__item_text">
                                            {t('tools.html2pdf.Small')}
                                        </div>
                                    </div>
                                </div>
                                <div className={selsize === 2 ? "side__btns1_select":"side__btns1"}  onClick={onBig}>
                                    <div className="side__btns__items">
                                        <div>
                                        <img src={bigmarginsvg} alt="big margin" style={{width:'50%', height:'50%'}}/>
                                        </div>
                                        <div  className="side__btns__item_text">
                                            {t('tools.html2pdf.Big')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Content>
                                <Checkbox onChange={onCheckType} style={{width:'88%', textAlign:'left', marginTop:'10px'}}>{t('tools.jpg2pdf.Merge')}</Checkbox>
                            </Content>
                        </Scrollbars>
                    </div>
                </Content>
                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt >= 1 ? false:true}>
                    {t('tools.jpg2pdf.ConverttoPDF')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSideJpg2;