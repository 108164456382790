import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setMethod } from './pdf2pdfASlice';

import {  Button, Layout, Select } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;
const { Option } = Select;

const WorkSide = ({onAction}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const filelist = useSelector((state) => state.pdf2pdfAOption.filelist);
    const method = useSelector((state) => state.pdf2pdfAOption.method);

    var cnt = 0;
    for (let i = 0; i < filelist.length; i++) {
        if (filelist[i].remove === 0) 
            cnt++;
    }

    const onClickAction = () => {
        onAction(filelist);
    }

    const onChangeMethod = (value) => {
        dispatch(setMethod(parseInt(value)));
    }

    return (
        <div className="tool__sidebar">
            <Layout>
                <Header>PDF TO PDF/A</Header>

                <Content>
                	<div className="center-tab">
	                    <Scrollbars style={{ width: '100%', height: '100%' }}>
		                    <p>
		                        {t('tools.pdf2pdfA.side_msg1')}
		                    </p>

		                    <span style={{color: '#1890ff', float:'left', paddingLeft:10, fontSize:16, marginBottom:10}}>
		                        {t('tools.pdf2pdfA.side_op')}
		                    </span>
		                    <Select defaultValue="0" 
		                        style={{width:'88%', textAlign:'left'}} 
		                        onChange={onChangeMethod}>
		                        <Option key="0">PDF/A-1a</Option>
		                        <Option key="1">PDF/A-1b</Option>
		                        <Option key="2">PDF/A-2a</Option>
		                        <Option key="3">PDF/A-2b</Option>
		                        <Option key="4">PDF/A-2u</Option>
		                        <Option key="5">PDF/A-3a</Option>
		                        <Option key="6">PDF/A-3b</Option>
		                        <Option key="7">PDF/A-3u</Option>                        
		                    </Select>
	                	</Scrollbars>                   
                	</div>
                </Content>

                <Footer>
                    <Button type="primary" size="large" icon={<SendOutlined />} onClick={onClickAction} disabled={cnt > 0 ? false:true}>
                      {t('tools.pdf2pdfA.action')}
                    </Button>
                </Footer>
            </Layout>
        </div>
    );
}

export default WorkSide;